import 'chart.js/auto';
import React from "react";
import { Pie } from "react-chartjs-2";

//interface DatasetType {
//  data: number[];
//  backgroundColor: string[];
//}

function PieChartComponent({
  labels = { labels },
  value1 = { value1 },
  value2 = { value2 },
  value3 = { value3 }
}) {
  return (
    <Pie
      width={10}
      // height={"100%"}
      options={
        {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: '% NTS Budget Set'
          },
          plugins: {
            legend: {
              position: 'right',
              align: "start",
              fullSize: false
            }
          }
        }
      }
      data={{
        labels: labels,
        redraw: true,
        updateMode: 'resize',
        datasets: [
          {
            data: [value1, value2, value3],//, 2300, 2222, 3333
            backgroundColor: ["#bc5090", "#ff6361", "#ffa600"], //, "#bc5090", "#ff6361", "#ffa600"
            borderWidth: 1
          }
        ]
      }}
    />
  );
}

export default PieChartComponent;
