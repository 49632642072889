import { rolesList } from '@data/liveStores';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessage from '@jumbo/utils/IntlMessage';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import Form, {
    AsyncRule,
    GroupItem, Label,
    RequiredRule, SimpleItem
} from 'devextreme-react/form';
import { StringLengthRule } from 'devextreme-react/tree-list';
import { useEffect, useRef, useState } from 'react';
import { useStyles } from 'theme/cashflowsTheme';
import AssociatedFuncs from './AssociatedFunc';
import Dropdown from './DropBox';
import { showErrorToast, showSuccessToast } from './Notify';

const row = {
    RoleParentId: null,
    RoleCode: "",
    RoleDescription: ""
};

export default function AddNewRole(props) {
    const { theme } = useJumboTheme()
    const classes = useStyles(theme)
    const roleForm = useRef(null);
    const [rolesCombo, setRolesCombo] = useState([])
    const [selectedParent, setSelectedParent] = useState(null)
    const [selected, setSelected] = useState({})

    function checkRoleCode(email) {
        let rID = props.mode === "new" ? 0 : props.selectedRole
        return axios.get(`api/Roles/Valid?code=${email.value}&id=${rID}`).then(x => {
            if (x.data)
                return true;
            return false;
        }).catch((e) => {
            showErrorToast(IntlMessage("E0010"))
        });
    }

    useEffect(() => {
        if (props.show == false) return
        if (props.mode === "new") {
            axios.get(`/api/Functions/GetSelectedFuncs?roleId=${-1}`).then(x => {
                setSelected(x.data)
            })
        } else {
            axios.get(`/api/Functions/GetSelectedFuncs?roleId=${props.selectedRole}`).then(x => {
                setSelected(x.data)
                console.log("🚀 ~ file: AddNewRole.jsx:53 ~ axios.get ~ x.data:", x.data)
            })
            axios.get(`/api/Roles/GetSingle?id=${props.selectedRole}`).then(x => {
                row.RoleCode = x.data.RoleCode
                row.RoleDescription = x.data.RoleDescription
                row.RoleParentId = x.data.RoleParentId
                setSelectedParent(x.data.RoleParentId)
                roleForm.current.instance.updateData(row)
            }).catch(e => {
                console.log("🚀 ~ file: AddNewRole.jsx ~ line 104 ~ axios.get ~ e.message", e.message)
            })
        }
    }, [props.selectedRole, props.show, props.mode])

    useEffect(() => {
        rolesList.load({ searchValue: null }).then(resp => {
            try {
                setRolesCombo(resp?.data)
            } catch (error) {
            }
        }).catch(err => {
        })
    }, [])

    function postAction(mode, data) {
        row.RoleParentId = selectedParent
        if (mode === "new") {
            axios.post("/api/Roles", { ...data }).then(x => {
                showSuccessToast(IntlMessage("S0011"))
                roleForm.current.instance.resetValues();
                props.onClose(true);
            }).catch(e => {
                console.log("🚀 ~ file: AddNewRole.jsx ~ line 138 ~ renderPopup ~ e.message", e.message)
                showErrorToast(IntlMessage("E0015"))
            });
        } else if (mode === "edit") {
            axios.put("/api/Functions/UpdateRoleFunc", {
                "RoleId": props.selectedRole,
                "Updates": { ...selected }
            })
                .then(resp => {
                })
                .catch(err => {
                })
            axios.put("/api/Roles", { ...data }).then(x => {
                showSuccessToast(IntlMessage("S0011"))
                roleForm.current.instance.resetValues();
                props.onClose(true);
            }).catch(e => {
                console.log("🚀 ~ file: AddNewRole.jsx ~ line 138 ~ renderPopup ~ e.message", e.message)
                showErrorToast(IntlMessage("E0015"))
            });
        }
    }

    function renderPopup() {
        return (
            <form action=""
                style={{ ...classes.popupSm }}
                id="newRoleForm"
                onSubmit={(x) => {
                    x.preventDefault()
                    //call backend
                }}>
                <Form
                    ref={roleForm}
                    style={classes.form}
                    colCount={1}
                    showColonAfterLabel={false}
                    validationGroup="userData"
                    showValidationSummary={true}
                    formData={row}>
                    <GroupItem colSpan={1}>
                        <SimpleItem
                            // cssClass={classes.formInput}
                            dataField="RoleCode"
                            editorType="dxTextBox">
                            <RequiredRule />
                            <StringLengthRule min={2} max={25} message={"Must be 2-25 characters long"} />
                            <Label text="Role Code" />
                            <AsyncRule validationCallback={checkRoleCode}
                                message={"RoleCode already exist"} />
                        </SimpleItem>
                        <SimpleItem
                            // cssClass={classes.formInput}
                            dataField="RoleDescription"
                            editorType="dxTextBox">
                            <RequiredRule />
                            <StringLengthRule min={3} max={100} message={"Must be 3-100 characters long"} />
                        </SimpleItem>
                        <SimpleItem>
                            <Label text="Select a parent role" />
                            <Dropdown
                                placeholder={"Select parent role..."}
                                data={rolesCombo}
                                value={selectedParent}
                                mapping={x => {
                                    return { value: x.RoleId, label: `${x.RoleCode} - ${x.RoleDescription}` }
                                }}
                                onChange={setSelectedParent}
                            />
                        </SimpleItem>
                        <SimpleItem>
                            <Label text={"Select Associated function"} />
                            <AssociatedFuncs
                                roleId={props.mode === "new" ? -1 : props.selectedRole}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </SimpleItem>
                    </GroupItem>
                </Form>
            </form>
        );
    }

    return (
        <Dialog
            fullWidth={false}
            sx={classes.dialog}
            maxWidth="lg"
            open={props.show}>
            <DialogTitle
                disableTypography={true}
                sx={classes.dlgTitle}>
                {props.mode === "new" ? "Add New Role" : "Edit Role"}
            </DialogTitle>
            <DialogContent sx={classes.root}>
                {renderPopup()}
            </DialogContent>
            <DialogActions>
                <Button style={{ minWidth: "100px" }} onClick={x => {
                    row.RoleId = props.mode === "new" ? 0 : props.selectedRole
                    postAction(props.mode, row)
                }}>
                    {props.mode === "new" ? "Add" : "Update"}
                </Button>
                <Button style={{ minWidth: "100px" }} onClick={x => {
                    props.onClose(false)
                    roleForm.current.instance.resetValues()
                }}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
