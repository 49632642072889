import { useJumboTheme } from '@jumbo/hooks';
import { isNil, isNull, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export default function Dropdown(props) {
    const { theme } = useJumboTheme()
    const placeholder = !isNil(props.placeholder) ? props.placeholder : "Select ...."
    const multiple = isUndefined(props.multiple) ? false : true
    const marginBottom = isUndefined(props.marginBottom) ? "2px" : props.marginBottom
    const required = isUndefined(props.required) ? false : true

    if (isUndefined(props.data)) {
        throw "data set empty"
    }
    if (isUndefined(props.mapping)) {
        throw "mapping function empty"
    }
    if (isUndefined(props.onChange)) {
        throw "onChange not defined"
    }

    const [opts, setOpts] = useState([])
    // const [loading, setLoading] = useState(true)

    useEffect(() => {
        // if (!loading) return
        setOpts(props.data.map(elem => { return props.mapping(elem) }))
        // setLoading(false)
    }, [props.data])

    const styles = {
        control: (s) => {
            return {
                ...s,
                fontFamily: theme.typography.fontFamily,
                backgroundColor: theme.palette.background.paper,
                fontSize: '0.85rem',
                fontWeight: 'normal'
            }
        },
        container: (s) => {
            return {
                ...s,
                maxWidth: "95%",
                marginBottom: marginBottom
            }
        },
        input: (s) => {
            return {
                ...s,
                // color: "#FFF"
                // color: theme.palette.primary
            }
        },
        singleValue: (s) => {
            return {
                ...s,
                color: theme.palette.text,
            }
        },
        menuList: s => {
            return {
                ...s,
                backgroundColor: theme.palette.background.paper,
                maxHeight: '120px',
                fontSize: '0.85rem',
                fontWeight: 'normal'
            }
        },
        option: (s, state) => {
            return {
                ...s,
                backgroundColor: state.isSelected || state.isFocused ? theme.palette.action.hover : "transparent",
            }
        },
        multiValueRemove: s => {
            return {
                ...s,
                color: 'black'
            }
        }
    }

    return <Select
        placeholder={placeholder}
        hideSelectedOptions={true}
        // isLoading={loading}
        closeMenuOnScroll={false}
        escapeClearsValue={true}
        isClearable={false}
        isMulti={multiple}
        styles={styles}
        backspaceRemovesValue={true}
        value={opts.filter(elem => {
            if (multiple && !isNull(props.value)) return props.value.includes(elem.value)
            return props.value == elem.value
        })}
        options={opts}
        onChange={val => {
            if (isNull(val)) {
                props.onChange(null)
                return
            }
            if (multiple) {
                let res = val.map(elem => {
                    return elem.value
                })
                props.onChange(res)
            } else {
                props.onChange(val.value)
            }
        }}
    />
}
