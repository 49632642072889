import GridContainer from '@jumbo/components/GridContainer';
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';
import PageContainer from '@jumbo/components/PageContainer';
import { useJumboTheme } from '@jumbo/hooks';
import ContentPaste from '@mui/icons-material/ContentPaste';
import MoreVert from '@mui/icons-material/MoreVert';
import { Avatar, Box, Button, Grid, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { sumBy } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { hiddenProjects, newProjData } from '../../../@data/localStore';
import { EditorCtx } from '../CurrentProjectsSummary';
import { m5 } from '../Partials/Mui5';
import { ProjectsGrid } from './Projects';
// import { claimsData } from './data';
import { createContext, useContext } from 'react';
import { buttonStyles } from 'theme/cashflowsTheme';

const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Construction', isActive: true },
];

export function Developers(props) {
    return (
        <>
            {/* <BlogProvider> */}
            <PageContainer breadcrumbs={breadcrumbs}>
                <GridContainer>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FullWidthTabs />
                    </Grid>
                </GridContainer>
            </PageContainer>
            {/* </BlogProvider > */}
        </>
    );
};

// function a11yProps(index) {
//     return {
//         id: `full-width-tab-${index}`,
//         'aria-controls': `full-width-tabpanel-${index}`,
//     };
// }

const Total = {
    projectValue: 0,
    claimToDate: 0,
    paidToDate: 0,
    paidToBuilder: 0,
    paidToTrades: 0
}

export function MenuItemC({ label, onClick, ...rest }) {
    const { setAnchorEl } = useContext(MenuContext)

    return <MenuItem key={label} onClick={x => {
        onClick()
        setAnchorEl(null)
    }}
        {...rest}>
        {label}
    </MenuItem>
}

export const MenuContext = createContext()

export function MoreMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return <Fragment>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
        >
            <MoreVert />
        </IconButton>
        <MenuContext.Provider value={{ setAnchorEl }}>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 216,
                        width: '22ch',
                    },
                }}>
                {props?.children}
            </Menu>
        </MenuContext.Provider>
    </Fragment>
}

function FullWidthTabs() {
    const [editorProps, setEditorProps] = useState({})
    const [showProject, setShowProjects] = useState(false)
    const [editMode, setEditMode] = useState("new")
    const [showProjectDetails, setShowProjectDetails] = useState(false)
    const setProjData = useSetRecoilState(newProjData)
    const { theme } = useJumboTheme()
    const classes = m5(theme)
    const btnStyle = buttonStyles(theme)

    //const [total, setTotal] = useState({})
    //const [updateTotal, setUpdateTotal] = useState(claimsData._array.length)

    useEffect(() => {
        calculate()
    }, [])

    // useEffect(() => {
    //     //calculate()
    //     if (editorProps.dataGrid) {
    //         try {
    //             editorProps.dataGrid.current.instance.refresh()
    //         } catch {
    //             console.error("Data grid reference is null")
    //         }
    //     }
    // }, [updateTotal])

    function calculate() {
        // console.log("🚀 ~ file: Developers.jsx:85 ~ calculate ~ claimsData:", claimsData)
        // let d = claimsData._array
        let d = []
        Total.claimToDate = sumBy(d, x => {
            return x.ClaimedAmount
        })
        Total.paidToBuilder = sumBy(d, x => {
            return x.PaidToBuilder
        })
        Total.paidToTrades = sumBy(d, x => {
            return x.PaidToTrades
        })
        Total.paidToDate = sumBy(d, x => {
            if (x.Status === "Paid") return x.PaidToBuilder + x.PaidToTrades
            return 0
        })
        //setTotal({ ...Total })
    }
    const [hiddenProjs, setHiddenProjs] = useRecoilState(hiddenProjects)

    return (
        <Box
            sx={classes.cRoot}
            width={1}>
            <JumboCardQuick
                sx={classes.cardRoot}
                avatar={<Avatar color="primary" size="small"><ContentPaste fontSize="small" /></Avatar>}
                title={"My Projects"}
                subheader={"Projects"}
                action={
                    <Fragment>
                        <Button
                            variant="contained"
                            aria-controls="fade-menu"
                            color="info"
                            onClick={x => {
                                setEditMode("new")
                                setShowProjectDetails(true)
                                setProjData({})
                            }}
                            sx={btnStyle.button}
                            aria-haspopup="true">New Project</Button>
                        <MoreMenu>
                            <MenuItemC label={"Show All Projects"} onClick={x => setHiddenProjs([])}></MenuItemC>
                        </MoreMenu>
                    </Fragment>
                }>
                <EditorCtx.Provider value={{ editorProps, setEditorProps, setShowProjects, setEditMode }}>
                    {<ProjectsGrid
                        isDeveloper={true}
                        showProjectDetails={showProjectDetails}
                        setShowProjectDetails={setShowProjectDetails} />}
                </EditorCtx.Provider>
            </JumboCardQuick>
        </Box>
    );
}
