import GridContainer from '@jumbo/components/GridContainer';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import axios from 'axios';
import FileUploader from 'devextreme-react/file-uploader';
import _, { isEmpty, isNil, padStart } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { buttonStyles } from 'theme/cashflowsTheme';
import Dropdown from '../Partials/DropBox';
import { m5 } from '../Partials/Mui5';
import { EmptyTextarea } from '../Partials/StyledTextArea';
import { showErrorToast } from '../Partials/Notify';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloseIcon from '@mui/icons-material/Close';
import { errorMessage } from 'app/utils/constants/AppConstants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
const materialUITextFieldProps = x => {
    return {
        label: x,
        multiline: false,
        margin: "dense",
        fullWidth: true,
        size: 'small',
        variant: 'standard'
    }
}

export default function AddPayment(props) {
    const [attachment, setAttachment] = useState([])
    const [Origattachment, setOrigAttachment] = useState([])
    const [prefix, setPrefix] = useState('')
    const [paymentRow, setPaymentRow] = useState({})
    const [paymentNumberErr, setPaymentNumberErr] = useState("")
    const [orgData, setOrgData] = useState([])
    const [vendorOrg, setVendorOrg] = useState(0)
    const confirm = useConfirm()
    const { theme } = useJumboTheme()
    const classes = m5(theme)
    const btnStyles = buttonStyles(theme)
    const [submit, setSumbit] = useState(false)
    const [iterator, setIterator] = useState(0);
    // PaidToBuilder

    useEffect(() => {
        console.log("🚀 ~ file: AddPayment.jsx ~ line 52 ~ AddPayment ~ prefix", props.projDetails)
        setPrefix("P-" + props.projDetails.ProjectShortCode)
    }, [props.projDetails.ProjectShortCode])

    useEffect(() => {
        let payment = {
            PaymentNumber: prefix + padStart(props.numRows, 3, '0') + (props.projDetails.PaymentCount + 1),
            PaymentPeriod: Date.now(),
            PaymentAmount: 0,
            PaidToOrganisation: 0,
            Notes: "",
            ClaimNumber: "",
            ReferenceNumber: ""
        }
        setPaymentRow(payment)
        setVendorOrg(-1)
    }, [prefix])

    function filterByRole(obj) { //Head Contractor, Sub Contractor and Supplier
        if (obj.ContactTypeID == 43 || obj.ContactTypeID === 51 || obj.ContactTypeID === 66) {
            return true
        }
        return false;
    }

    useEffect(() => {
        const arrProjectContactByRole = props.projData['ProjectContacts']?.filter(filterByRole);
        const arrProjectContactByRole1 = arrProjectContactByRole.filter(d => d.Status !== "Ended")
        const data = arrProjectContactByRole1?.map(d => ({ OrganisationID: d.OrgID, BusinessName: d.Organisation, StatusID: d.StatusID }))
        setOrgData(data)
    }, [])

    useEffect(() => {
        if (props.mode === "new") {
                //alert(props.subAmount);
            setPaymentRow(prev => ({
                ...prev,
                PaymentAmount: 0,
                PaidToOrganisation: 0,
                Notes: "",
                ClaimNumber: "",
                ReferenceNumber: "",
                PaymentPeriod: Date.now(),
            }))
            setPaymentNumberErr("")
            setVendorOrg(-1)
        }
    }, [props.mode, props.show])

    const onSave = async (type) => {
        try {
            let d = {
                "PaymentNumber": paymentRow.PaymentNumber,
                "PaymentAmount": paymentRow.PaymentAmount,
                'Notes': paymentRow.Notes,
                "StatusID": 44, //Draft
                'PaidToOrganisation': vendorOrg,
                'ReferenceNumber': paymentRow.ReferenceNumber,
                'ProjectID': props.projID, //Get ProjectId of selected Project
                'ClaimNumber': paymentRow.ClaimNumber
            }
            if (type === "save") {
                d.StatusID = 58 //"Submitted"
                //send to server
                let f = new FormData();
                attachment.forEach((file, index) => {
                    f.append('attachment', file);
                });

                // Create an object
                const originalNamesObject = {};

                // Make key value pairs for modified names : Original names
                attachment.forEach((file, index) => {
                    const attachmentName = file.name;
                    const origAttachmentName = Origattachment[index].name;
                    originalNamesObject[attachmentName] = origAttachmentName;
                });

                // Append the originalNamesObject to the FormData
                f.append('originalNames', JSON.stringify(originalNamesObject));

                _.forEach(d, (val, key) => {
                    f.append(key, val);
                });
                console.log(f, 'FormData')
                axios.post('/api/Payment/NewPayment', f, {
                    headers: {
                        "Accept": "*/*",
                        'Accept-Encoding': 'gzip, deflate, br'
                    }
                }).then(resp => {
                    props.onClose(true)
                }).catch(e => {
                    showErrorToast(errorMessage(e))
                    // e.response.statusText
                })
            } else if (type === "draft") {
                d.Status = "Draft"
            }
        }
        catch (err) {
            console.error(err);
            // setError("failed")
        }
    }
    const handleRemoveFile = (index) => {
        const UpdatedOrigAttachment = [...Origattachment];
        UpdatedOrigAttachment.splice(index, 1);

        const UpdatedAttachment = [...attachment];
        UpdatedAttachment.splice(index, 1);

        console.log(UpdatedOrigAttachment, 'ORIG')
        console.log(UpdatedAttachment, 'MODIFIED')
        setOrigAttachment(UpdatedOrigAttachment);
        setAttachment(UpdatedAttachment)
        setIterator(prevIterator => prevIterator - 1);
    };
    // Generate the current date and time in the format "ddmmyyhhmmss"
    const currentDate = new Date();
    const formattedDate = currentDate
        .toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })
        .replace(/\/|,|:| /g, ''); // Remove special characters
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
    let time = formattedDate.concat(milliseconds);

    return (
        <Dialog sx={classes.dialog}
            maxWidth={'xl'}
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            open={props.show}>
            <DialogTitle sx={{ ...classes.root, ...classes.dlgTitle }}
                disableTypography={true}
                children={
                    props.mode === "new" ? <IntlMessages id="Payment.DetailDlg.Title" /> : <IntlMessages id="Payment.DetailDlg.Title" />
                } />
            {/* remove scrollbar */}
            <DialogContent sx={{ ...classes.root, ...classes.dlgContent }}>
                <GridContainer>
                    <Grid item lg={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField variant='standard' margin="dense" fullWidth required={true} size={'small'} placeholder={'Payment Number'} value={paymentRow.PaymentNumber}
                                    //TODO call backend to check claim number
                                    onChange={x => {
                                        setPaymentRow(prev => ({
                                            ...prev,
                                            PaymentNumber: x.target.value
                                        }))
                                        axios.get("/api/Payment/CheckPaymentNumber", { params: { id: x.target.value, ProjID: props.projID } }).then(resp => {
                                            setPaymentNumberErr("")
                                        }).catch(err => {
                                            // console.log("🚀 ~ file: ClaimsDetails.jsx ~ line 186 ~ axios.get ~ err", err.response, err.message)
                                            setPaymentNumberErr(err.response.data)
                                        })
                                    }}
                                    sx={{ ...classes.root, ...classes.formControl }}
                                    error={paymentNumberErr !== ""}
                                    helperText={paymentNumberErr}
                                    label="Payment Number" />
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        format="dd/MM/yyyy"
                                        formatDensity='dense'
                                        id="date-picker-inline"
                                        label="Payment Period"
                                        value={paymentRow.PaymentPeriod}
                                        variant={'inline'}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Change date',
                                        }}
                                        sx={{
                                            ...classes.root, ...classes.formControl
                                        }}
                                        onChange={x => {
                                            setPaymentRow(prev => ({
                                                ...prev,
                                                PaymentPeriod: x
                                            }))
                                        }}
                                    /></LocalizationProvider>
                                {/* <TextField
                                    variant='standard'
                                    margin="dense"
                                    fullWidth
                                    required={true}
                                    size={'small'}
                                    placeholder={'Payment Period'}
                                    value={moment(paymentRow.PaymentPeriod).format('DD/MM/YYYY')}
                                    onChange={x => {
                                        setPaymentRow(prev => ({
                                            ...prev,
                                            PaymentPeriod: x.target.value
                                        }))
                                    }}
                                    sx={{ ...classes.root, ...classes.formControl }}
                                    label="Payment Date" /> */}
                            </Grid>
                            <Grid item xs={6} >
                                <NumericFormat
                                    value={paymentRow.PaymentAmount}
                                    prefix='$'
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    onClick={(event) => {
                                        if (event.target.value === '$0.00') {
                                            event.target.value = '';
                                        }
                                    }}
                                    onFocus={(e) => {
                                        const value = e.target.value;                                   
                                        const dotIndex = value.indexOf('.');
                                        e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                                      }}
                                    onChange={x => {
                                        setPaymentRow(prev => ({
                                            ...prev,
                                            PaymentAmount: numeral(x.target.value).value()
                                        }))
                                    }}
                                    customInput={TextField}
                                    {...materialUITextFieldProps("Payment Amount")} />
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{
                                    fontSize: "0.90rem",
                                    fontWeight: "normal",
                                }}>
                                    <Dropdown
                                        size={'small'}
                                        placeholder="Select an Organisation"
                                        data={orgData}
                                        value={vendorOrg}
                                        onChange={setVendorOrg}
                                        mapping={x => {
                                            // console.log("🚀 ~ file: AddPayment.jsx:263 ~ AddPayment ~ x.StatusID:", x)
                                            return { value: x.OrganisationID, label: x.BusinessName, isDisabled: x.StatusID !== 13 }
                                        }}
                                        sx={{ ...classes.root, ...classes.formControl }}
                                    />
                                    <small style={{ fontSize: '0.65rem', fontWeight: '300' }}>*Org missing banking info are disabled</small>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField variant='standard' margin="dense" fullWidth required={true} size={'small'} placeholder={'Reference Number'} value={paymentRow.ReferenceNumber}
                                    onChange={x => {
                                        setPaymentRow(prev => ({
                                            ...prev,
                                            ReferenceNumber: x.target.value
                                        }))
                                    }}
                                    sx={{ ...classes.root, ...classes.formControl }}
                                    label="Reference Number" />
                            </Grid>
                            <Grid item xs={6}>
                                {/* <TextField variant='standard' margin="dense" fullWidth size={'small'} placeholder={'Claim Number'} value={paymentRow.ClaimNumber}
                                    onChange={x => {
                                        setPaymentRow(prev => ({
                                            ...prev,
                                            ClaimNumber: x.target.value
                                        }))
                                    }}
                                    sx={{ ...classes.root, ...classes.formControl }}
                                    label="Claim Number" /> */}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={6}>
                        <div className="fileuploader-container">
                            <FileUploader
                                showFileList={false}
                                multiple={true}
                                tabIndex={6}
                                onValueChange={files => {
                                    setOrigAttachment(prevAttachments => [...prevAttachments, ...files]);
                                    const modifiedFiles = files.map((file, index) => {
                                        console.log(props.projDetails.ProjectShortCode);
                                        let shortCode = props.projData.ProjectShortCode
                                        const fileIterator = iterator + index + 1;
                                        // Create a new file with a unique name
                                        const modifiedFile = new File(
                                            [file],
                                            `${shortCode}_P_${paymentRow.PaymentNumber}_${time}_${fileIterator}` +
                                            file.name.substring(file.name.lastIndexOf('.')),
                                            {
                                                type: file.type // Preserve the file type
                                            }
                                        );
                                        return modifiedFile;
                                    });
                                    // Save all the modified files in the 'attachments' state                                                
                                    setAttachment(prevAttachments => [...prevAttachments, ...modifiedFiles]);
                                    setIterator(prevIterator => prevIterator + files.length);
                                    // console.log(modifiedFiles)   
                                    // console.log(attachment,'ATTACHMENTS')
                                    console.log(Origattachment.map(file => file.name), "ORIGINAL")
                                }}

                                selectButtonText="Select Attachment"
                                //accept={'image/*, application/pdf'}
                                accept={'.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .zip, .gif'}
                                uploadMode="useForm" />
                            {/* For Custom UI List */}
                            <List>
                                {Origattachment.map((file, index) => (
                                    <ListItem key={index}>
                                        <a
                                            href={URL.createObjectURL(file)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <ListItemText primary={file.name} />
                                        </a>
                                        <Button onClick={() => handleRemoveFile(index)}>
                                            <ListItemIcon>
                                                <CloseIcon sx={{ fontSize: 15 }} />
                                            </ListItemIcon>
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Grid>
                    <Grid item lg={12}>
                        <EmptyTextarea
                            aria-label='Notes'
                            placeholder='Notes'
                            spellCheck={true}
                            minRows={5}
                            value={paymentRow.Notes}
                            onChange={x => {
                                setPaymentRow(prev => ({
                                    ...prev,
                                    Notes: x.target.value
                                }))
                            }}
                        />
                    </Grid>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    tabIndex={9}
                    sx={btnStyles.button}
                    color='info'
                    variant='contained'
                    disabled={submit}
                    onClick={x => {
                        var headContractor = 0;
                        const projDetails = props.projDetails
                        const availableFunds = projDetails.PaidtoDate - (projDetails.PaidToBuilder + projDetails.PaidToTrades); 
                        if (availableFunds < 1) {
                            confirm({
                                description: "No available funds",
                                title: "Add Payment",
                                hideCancelButton: true
                            }).then(resp => {
                                return
                            })
                        }
                        const headContractorMaxClaimAmount = (projDetails.PaidtoDate * (projDetails.MaxBuilderPercent / 100)) - projDetails.PaidToBuilder - parseFloat(props.subAmount);
                        const projectParticipants = projDetails.ProjectContacts;
                        if (!isNil(projectParticipants)) {
                            const headContractorRole = projectParticipants.filter(({ ContactTypeID }) => ContactTypeID === 43);
                            if (!isNil(headContractorRole) && !isEmpty(headContractorRole)) {
                                headContractor = headContractorRole[0].OrgID;
                            }
                        }
                        //Payment Org dropdown selection check
                        if (vendorOrg === -1 || vendorOrg === 0) {
                            confirm({
                                description: "Please select an Organisation",
                                title: "Add Payment",
                                hideCancelButton: true
                            }).then(resp => { }).catch(err => {
                            })
                        } else {
                            if (headContractor !== 0 && headContractor === vendorOrg && paymentRow.PaymentAmount > headContractorMaxClaimAmount) {
                                confirm({
                                    description: "This payment exceeds the current maximum entitlements for the Head Contractor",
                                    title: "Add Payment",
                                    hideCancelButton: true
                                }).then(resp => {
                                }).catch(err => {
                                })
                            } else {
                                if (paymentRow.PaymentAmount > availableFunds) {
                                    confirm({
                                        description: "Payment amount cannot exceed the available funds",
                                        title: "Add Payment",
                                        hideCancelButton: true,
                                    }).then(resp => {
                                        return
                                    }).catch((e) => {
                                        console.error("🚀 ~ file: Add Payment.jsx:312 ~ Add Payment ~ e", e)
                                    })
                                } else {
                                    const confirmationMessage = "Do you want to submit this payment?";
                                    confirm({
                                        allowClose: true,
                                        description: confirmationMessage,
                                        title: "Confirm action",
                                    }).then(async resp => {
                                        setSumbit(true)
                                        onSave("save").then(resp => {
                                            setSumbit(false)
                                        }).catch(err => {
                                            setSumbit(false)
                                        })
                                    }).catch((e) => {
                                        console.error("🚀 ~ file: Add Payment.jsx:312 ~ Add Payment ~ e", e)
                                    })
                                }
                            }
                        }

                    }}>Submit Payment</Button>
                <Button
                    tabIndex={10}
                    sx={btnStyles.button}
                    color='inherit'
                    variant='contained'
                    onClick={x => {
                        props.onClose(false)
                    }}>Cancel</Button>
            </DialogActions>
        </Dialog>)
}
