import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AppBar, Avatar, Box, IconButton, Tab, Tabs, alpha } from '@mui/material';
import { Group, PersonAdd } from '@mui/icons-material';
import { useJumboTheme } from '@jumbo/hooks';
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: '0.85em'
    },
});

const useStyles1 = (theme) => ({
    cardRoot: {
        position: 'relative',
        '& .Cmt-card-content': {
            padding: 0,
            paddingBottom: 24,
        },
    },
    scrollbarRoot: {
        height: 280,
    },
    headerRoot: {
        padding: "10px 24px"
    },
    badgeRoot: {
        fontSize: 14,
        letterSpacing: 0.25,
        backgroundColor: alpha(theme.palette.warning.main, 0.15),
        color: theme.palette.warning.main,
        padding: '2px 10px',
        borderRadius: 30,
    },
});

function TabPanel(props) {
    const { theme } = useJumboTheme()
    const classes = useStyles1(theme);
    function renderAddButton(show) {
        if (show) {
            return <React.Fragment>{props.addButtonfx}{props.editButtonfx}</React.Fragment>
        }
        else {
            return null;
        }
    }
    return (
        <React.Fragment>
            <JumboCardQuick className={classes.cardRoot} title={props.iconTitle} avatar={<Avatar color="primary" size="small">
                <Group fontSize="small" /></Avatar>} subheader={props.iconSubTitle} headerSx={classes.headerRoot}
                action={renderAddButton(props.addButtonfx)}>
                {props.child}
            </JumboCardQuick>
        </React.Fragment>
    );
}

function TabsPanel(props) {
    const classes = useStyles1();
    const [addPeronShow, setAddPeronShow] = useState(false);
    function renderAddButton(show) {
        if (show) {
            if (!isUndefined(props.addButtonfx)) {
                return <React.Fragment>{props.addButtonfx}{props.editButtonfx}</React.Fragment>
            }
            return <IconButton onClick={() => setAddPeronShow(true)} size={'medium'}><PersonAdd /></IconButton>
        }
        else {
            return null;
        }
    }
    return (
        props.value === props.index &&
        <React.Fragment>
            <JumboCardQuick className={classes.cardRoot} title={props.iconTitle} avatar={<Avatar color="primary" size="small">
                <Group fontSize="small" /></Avatar>} subheader={props.iconSubTitle} action={renderAddButton(props.addButton)}>
                {props.child}
            </JumboCardQuick>
        </React.Fragment>
    );
}

export default function OneTab(props) {
    const { theme } = useJumboTheme()
    const classes = useStyles(theme);
    const [value, setValue] = React.useState(0);

    return (
        <Box className={classes.root} width={1} id="one_tab">
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="standard"
                    aria-label={props.label}>
                    <Tab
                        // label={props.label}
                        icon={props.icon}
                        {...a11yProps(0)} />
                </Tabs>
            </AppBar>
            <TabPanel
                child={props.child}
                {...props} />
        </Box>
    );
}

export function TwoTab(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const tabChange = (e, newTab) => {
        setValue(newTab);
    }

    return (
        <Box className={classes.root} width={1} id="two_tabs">
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="standard"
                    onChange={tabChange}
                    aria-label={props.label}>
                    <Tab label={props.label1} icon={props.icon1} {...a11yProps(0)} />
                    <Tab label={props.label2} icon={props.icon2} {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabsPanel index={0} value={value}
                {...props}
                child={props.child1}
                iconTitle={props.iconTitle1}
                iconSubTitle={props.iconSubTitle1}
                addButtonfx={props.addButtonfx1}
                editButtonfx={props.editButtonfx1} />
            <TabsPanel index={1} value={value}
                {...props}
                child={props.child2}
                iconTitle={props.iconTitle2}
                addButtonfx={props.addButtonfx2}
                editButtonfx={props.editButtonfx2}
                iconSubTitle={props.iconSubTitle2} />
        </Box>
    );
}

OneTab.propTypes = {
    label: PropTypes.string.isRequired,
    child: PropTypes.node.isRequired,
    icon: PropTypes.node.isRequired,
    addButton: PropTypes.bool.isRequired
}
