import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import {
    Alert,
    Box, FormHelperText, Grid,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import { isEmpty, isNil } from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useRecoilState } from 'recoil';
import { newProjData } from '../../../../@data/localStore';
import Address from '../Address';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//     return {
//         id: `full-width-tab-${index}`,
//         'aria-controls': `full-width-tabpanel-${index}`,
//     };
// }

const materialUITextFieldProps = x => {
    return {
        label: x,
        multiline: false,
        margin: "dense",
        fullWidth: true,
        size: 'small',
        variant: 'standard'
    }
}

export default function ProjectInfo(props) {
    const { theme } = useJumboTheme()
    const [projectValue, setProjectValue] = React.useState(0);
    const [selectedStatus, setStatus] = useState(13)
    const [projName, setProjName] = useState("")
    const [projShortCode, setProjShortCode] = useState("")
    const [imageData, setImageData] = useState(null)
    // const [orgData, setOrgData] = useState([])
    const [org, setOrg] = useState({ OrganisationID: 0 })
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(null)
    const [image, setImage] = useState(null)
    const [open, setOpen] = useState(null)
    const [projData, setProjData] = useRecoilState(newProjData)
    const [nameError, setNameError] = useState('')
    const [codeError, setCodeError] = useState('')
    const [priceError, setPriceError] = useState('')
    const [addressError, setAddressError] = useState('')
    const [logoError, setLogoError] = useState('')
    const [maxBuilderPercent, setMaxBuilderPercent] = useState(0);

    //TODO remove this hook
    useEffect(() => {
        setProjData({
            ...projData,
            ProjectId: -1,//not Saved
            Project: projName,
            ProjectShortCode: projShortCode,
            ProjectValue: projectValue,
            ProjectOrg: org,
            StatusID: 13,
            EstConstructionStart: startDate,
            EstFinishDate: endDate,
            Active: true,
            SortOrder: 1,
            EstConstructionDuration: 100,
            attachment: image != null ? image[0] : null,
            MaxBuilderPercent: maxBuilderPercent
        })
    }, [projName, projShortCode, selectedStatus, startDate, endDate, image, org, projectValue, maxBuilderPercent])

    useEffect(() => {
        if (!isNil(props) && !isNil(props.projDetails)) {
            setStatus(props.projDetails.status)
            setProjName(props.projDetails.name)
            setProjShortCode(props.projDetails.shortCode)
            setImage(`data:image/png;base64,${props.projDetails.Filebody}`)
        }
    }, [props])

    useEffect(() => {
        // orgs.load()
        //     .then(({ data }) => {
        //         setOrgData(data)
        //     })
        //     .catch(err => {
        //     })
        setStatus(13)
        //load data if there projData
        if (isEmpty(projData)) return
        setProjName(projData['Project'])
        setProjShortCode(projData['ProjectShortCode'])
        setStatus(projData['StatusID'])
        setStartDate(projData['EstConstructionStart'])
        setEndDate(projData['EstFinishDate'])
        setImage(projData['attachment'])
        setProjectValue(projData['ProjectValue'])
        setOrg(projData['ProjectOrg'])
        setMaxBuilderPercent(projData['MaxBuilderPercent'])
    }, [])

    useEffect(() => {
        if (image != null && image.length == 1) {
            setImageData(window.URL.createObjectURL(image[0]))
        }
    }, [image])

    return <>
        <Box sx={{ bgcolor: 'background.paper' }}>
            <IntlMessages id="ProjectSetup.DetailDlg.Title" />
            <Grid container columns={8} columnSpacing={"8px"} sx={{ padding: '16px', width: "100%" }}>
                <Grid item xs={5}>
                    <TextField
                        id="standard-basic"
                        label="Project"
                        name='Project'
                        value={projName}
                        required
                        variant={'standard'}
                        margin={'none'}
                        fullWidth
                        error={!isEmpty(nameError)}
                        helperText={nameError}
                        onChange={event => {
                            setNameError('')
                            setProjName(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="standard-basic"
                        label="Project Short Code"
                        name='ProjShortCode'
                        value={projShortCode}
                        required
                        variant={'standard'}
                        margin={'none'}
                        fullWidth
                        inputProps={
                            { maxLength: 3 }
                        }
                        error={!isEmpty(codeError)}
                        helperText={codeError}
                        onChange={x => {
                            setCodeError('')
                            setProjShortCode(x.target.value)
                        }}
                    />
                </Grid>
                {/* <Grid item xs={3} style={{ marginTop: 8, marginLeft: 16 }}>
                    <Dropdown
                        placeholder="Select Principal Organization"
                        data={orgData}
                        value={org.OrganisationID}
                        onChange={(value) => {
                            setOrgError('')
                            setOrg(orgData.find(item => item.OrganisationID === value))
                        }}
                        mapping={x => {
                            return { value: x.OrganisationID, label: x.BusinessName }
                        }}
                    />
                    {!isEmpty(orgError) && <FormHelperText style={{ color: '#da253c', marginLeft: 2 }}>
                        {orgError}
                    </FormHelperText>}
                </Grid> */}
                {/* <Grid item xs={2} style={{ marginTop: 8 }}>
                    <StatusSelect selectedStatus={selectedStatus} setStatus={(value) => {
                        setStatus(value)
                        setStatusError('')
                    }}
                    />
                    {!isEmpty(statusError) && <FormHelperText style={{ color: '#da253c', marginLeft: 2 }}>
                        {statusError}
                    </FormHelperText>}
                </Grid> */}
                {/* <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="none"
                            fullWidth={true}
                            size={"small"}
                            id="date-picker-inline"
                            label="Actual Start Date"
                            value={startDate}
                            onChange={(v) => {
                                setStartDate(v)
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        /></MuiPickersUtilsProvider>
                </Grid> */}
                {/* style={{ marginTop: 18 }} */}
                <Grid item xs={5} >
                    <NumericFormat
                        value={projectValue}
                        prefix='$'
                        name='ProjectValue'
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        error={!isEmpty(priceError)}
                        helperText={priceError}
                        required
                        onChange={x => {
                            setPriceError('')
                            setProjectValue(numeral(x.target.value).value())
                        }}
                        onClick={(event) => {
                            if (event.target.value === '$0.00') {
                                event.target.value = '';
                            }
                        }}
                        onFocus={(e) => {
                            const value = e.target.value;                                   
                            const dotIndex = value.indexOf('.');
                            e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                          }}
                        customInput={TextField}
                        {...materialUITextFieldProps("Project Value (ex GST)")} />
                </Grid>
                <Grid item xs={3}>
                    <NumericFormat
                        value={maxBuilderPercent}
                        suffix='%'
                        name='MaxBuilderPercent'
                        allowNegative={false}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        onValueChange={(v) => {
                            setMaxBuilderPercent(v.floatValue)
                        }}
                        onClick={(event) => {
                            if (event.target.value === '0.00%') {
                                event.target.value = '';
                            }
                        }}
                        onFocus={(e) => {
                            const value = e.target.value;                                   
                            const dotIndex = value.indexOf('.');
                            e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                          }}
                        valueIsNumericString={true}
                        customInput={TextField}
                        {...materialUITextFieldProps("Max Head Contractor %")} />
                </Grid>
                <Grid item xs={2} lg={1} md={1}>
                    <input id='image-upload'
                        type={'file'}
                        multiple={false}
                        accept={'image/*'}
                        style={{ display: 'none' }}
                        name={'Upload Image'}
                        onChange={(e) => {
                            console.log({ file: e?.target?.files[0] })
                            if (e?.target?.files[0]?.size <= 50000) {
                                setImage(e?.target?.files)
                                setLogoError('')
                            } else {
                                setImageData('')
                                setLogoError("Image size exceeded! Allowed maximum size is 50 kb!")
                            }
                        }} />
                </Grid>
            </Grid>
            <Grid container columns={10} md={9} lg={8} xs={12}>
                <Grid item xs={12} md={6} lg={6}>
                    <div>
                        <Address addressType="Address" onAddressChanged={() => {
                            setAddressError('')
                        }}></Address>
                        {!isEmpty(addressError) && <FormHelperText style={{ color: '#da253c', marginLeft: 8 }}>
                            {addressError}
                        </FormHelperText>}
                    </div>
                </Grid>
                {/* <Grid item xs={12} md={6} lg={6}>
                            <Address addressType="Billing Address" onAddressChanged={() => {
                                setBillingAddressError('')
                            }}></Address>
                            {!isEmpty(billingAddressError) &&
                                <FormHelperText style={{ color: '#da253c', marginLeft: 8 }}>
                                    {billingAddressError}
                                </FormHelperText>}
                        </Grid> */}
                <Grid container md={4} lg={4} xs={12}>
                    <Grid item xs={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Box sx={{
                            '& a': {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: '0.85rem'
                            },
                            '& span': {
                                padding: '0 5px',
                            }
                        }}>
                            <a href="javascript:document.getElementById('image-upload').click()"
                                style={{
                                    fontFamily: theme.typography.fontFamily,
                                    ...theme.typography.h6,
                                    fontWeight: 'normal',
                                }}>Upload Image<span>+</span></a>
                            <img
                                src={imageData}
                                height={'100%'}
                                style={{
                                    maxHeight: '150px',
                                    padding: '5px',
                                    margin: '2px',
                                    // border: '0.20px solid lightgray',
                                    borderRadius: '5px'
                                }} />
                            {!isEmpty(logoError) && <FormHelperText style={{ color: '#da253c', marginLeft: 2 }}>
                                {logoError}
                            </FormHelperText>}
                        </Box>
                    </Grid>
                </Grid>
                <Snackbar open={open} autoHideDuration={6000} onClose={x => setOpen(false)}>
                    <Alert severity="success" elevation={6} variant='filled'>
                        Project saved
                    </Alert>
                </Snackbar>
            </Grid>
        </Box>
    </>
}

ProjectInfo.propTypes = {
    projDetails: PropTypes.object,
    step: PropTypes.number.isRequired
}
