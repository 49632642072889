import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import configureStore, { history } from './redux/store';
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import JumboAuthProvider from "@jumbo/components/JumboAuthProvider";
import { RecoilRoot } from "recoil";
import { ConfirmProvider } from "material-ui-confirm";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.orange.light.css';
import AppLocale from "i18n";
import { IntlProvider } from "react-intl";
import { BlogProvider } from "@data/projectStore";
import { OrgProvider } from "@data";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const store = configureStore();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter history={history}>
                    <AppProvider>
                        <RecoilRoot>
                            <ConfirmProvider defaultOptions={{
                                allowClose: true,
                                dialogProps: { disableEnforceFocus: true },
                                confirmationButtonProps: { autoFocus: true },
                                titleProps: {
                                    style: {
                                        fontSize: '16px'
                                    }
                                }
                            }}>
                                <JumboApp activeLayout={config.defaultLayout}>
                                    <JumboTheme init={config.theme}>
                                        <IntlProvider locale={AppLocale["en"].locale} messages={AppLocale["en"].messages}>
                                            <JumboRTL>
                                                <JumboDialogProvider>
                                                    <JumboDialog />
                                                    <SnackbarProvider
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        maxSnack={3}>
                                                        <JumboAuthProvider>
                                                            <AppLayout>
                                                                <Suspense
                                                                    fallback={
                                                                        <Div
                                                                            sx={{
                                                                                display: 'flex',
                                                                                minWidth: 0,
                                                                                alignItems: 'center',
                                                                                alignContent: 'center',
                                                                                height: '100%',
                                                                            }}
                                                                        >
                                                                            <CircularProgress sx={{ m: '-40px auto 0' }} />
                                                                        </Div>
                                                                    }>
                                                                    <BlogProvider>
                                                                        <OrgProvider>
                                                                            <AppRoutes />
                                                                        </OrgProvider>
                                                                    </BlogProvider>
                                                                </Suspense>
                                                            </AppLayout>
                                                        </JumboAuthProvider>
                                                    </SnackbarProvider>
                                                </JumboDialogProvider>
                                            </JumboRTL>
                                        </IntlProvider>
                                    </JumboTheme>
                                </JumboApp>
                            </ConfirmProvider>
                        </RecoilRoot>
                    </AppProvider>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
