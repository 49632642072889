//** Manage User roles i.e. Assign existing users to existing roles */
import { useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { hiddenProjects } from '../../../@data/localStore';
import BlogContext from '../../../@data/projectStore';
import { EditorCtx } from '../CurrentProjectsSummary';
import { SimplePopup } from '../Partials/Popup';
import { useJumboTheme } from '@jumbo/hooks';
import { Badge, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { gridStyles } from 'theme/cashflowsTheme';
import { CustomPagination, printCurrency } from '../Partials/MaterialGrid';
import ProjectDetail from './ProjectDetails';
import Popover from '@mui/material/Popover';
import axios from 'axios';
import _, { isEmpty } from 'lodash';

const columns = [
    {
        field: 'Attachment',
        headerName: '',
        filterable: false,
        type: 'number',
        height: 170,
        flex: 0.5,
        renderCell: (params) => {
            if (params.value) { return <img className='popup' src={'data:image/png;base64, ' + params.value} /> }
            else {
                return <img src='' />
            }
        },
    },
    {
        field: 'Project',
        headerName: 'Project Name',
        //width: 380,
        flex: 2,
    },
    {
        field: 'Status',
        headerName: 'Status',
        //width: 110,
        flex: 0.5,
    },
    {
        field: 'ProjectValue',
        headerName: 'Project Value',
        renderCell: ({ row }) => {
            return printCurrency(row.ProjectValue)
        },
        //width: 150,
        flex: 1,
    },
    {
        field: 'SubmittedValue',
        headerName: 'Submitted Value',
        renderCell: (params) => {
            const value = params.row.SubmittedValue
            return printCurrency(value)
        },
        // width: 150,
        flex: 1,
    },
    {
        field: 'PaidToDate',
        headerName: 'Paid To Date',
        renderCell: (params) => {
            const value = params.row.PaidToDate
            return printCurrency(value)

        },
        // width: 150,
        flex: 1,
    },
    {
        field: 'PaidToBuilder',
        headerName: 'Paid To Builder',
        renderCell: (params) => {
            const value = params.row.PaidToBuilder
            return printCurrency(value)
        },
        //width: 150,
        flex: 1,
    },
    {
        field: 'PaidToTrades',
        headerName: 'Paid To Trades',
        renderCell: (params) => {
            const value = params.row.PaidToTrades
            return printCurrency(value)
        },
        // width: 150,
        flex: 1,
    }
];

export function ProjectsGrid(props) {
    const [editorProps, setEditorProps] = useState({})
    const [showProject, setShowProjects] = useState(false)
    const history = useNavigate()
    // const projectsBtn = useRef(null)
    const hiddenProjs = useRecoilValue(hiddenProjects)
    const [showPopup, setShowPopup] = useState(false)
    const [popupImage, setPopupImage] = useState(null)
    const { theme } = useJumboTheme()
    const { projects } = useContext(BlogContext)

    const [filters, setFilters] = useState([]);
    const [quickfilters, setQuickFilters] = useState();
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    const [anchorEl, setAnchorEl] = useState(null);
    const [actionMessages, setActionMessages] = useState([])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // Load saved filters and columns from session storage
    useEffect(() => {
        const savedFilters = JSON.parse(sessionStorage.getItem("gridFilters"));
        const savedColumns = JSON.parse(sessionStorage.getItem("gridColumns"));

        if (savedFilters) {
            setFilters(savedFilters);
        }

        if (savedColumns) {
            setColumnVisibilityModel(savedColumns);
        }
    }, []);

    // Save filters and columns to session storage whenever they change
    useEffect(() => {
        sessionStorage.setItem("gridFilters", JSON.stringify(filters));
        sessionStorage.setItem(
            "gridColumns",
            JSON.stringify(columnVisibilityModel)
        );
    }, [filters, columnVisibilityModel]);



    useEffect(() => {
        // const listenerProjs = x => {
        //     try {
        //         projectsBtn.current.click()
        //     } catch (error) {
        //     }
        // }
        // document.addEventListener("dblclick", listenerProjs)
        // return () => {
        // savePreferences({
        //     "hiddenProjects": hiddenProjs
        // })
        // }
        const listener = x => {
            if (x.target.getAttribute('class') === 'popup') {
                setPopupImage(x.target.getAttribute('src'))
                setShowPopup(true)
            }
        }
        document.addEventListener('click', listener)
        // projectData()
        return () => {
            document.removeEventListener('click', listener)
            // document.removeEventListener("dblclick", listenerProjs)
        }
    }, [])

    // const projectData = useCallback(() => {
    //     if (props.showProjectDetails == true) return // don't refresh, only refresh on close
    //     Projects.load().then(resp => {
    //     }).catch(err => { })
    // }, [props.showProjectDetails])

    function loadBuilderProjects() {
        //builderProjects filter:
        let x = projects?.filter(x => {
            return -1 == hiddenProjs.findIndex(e => {
                return e === x.ProjectShortCode
            })
        })
        return x
    }

    useEffect(() => {
        axios.post(`/api/Project/ProjectBankAccountNotifications?projectID=${0}`)
            .then(resp => {
                if (!isEmpty(resp.data) && _.has(resp.data, 'ActionItems') && !isEmpty(resp.data['ActionItems'])) {
                    setActionMessages(resp.data['ActionItems'])
                } else {
                }
            })
            .catch(err => {
            })
    }, [])

    return <EditorCtx.Provider value={{ editorProps, setEditorProps, showProject, setShowProjects }}>
        <SimplePopup selectedValue={'xxx'} open={showPopup} onClose={x => {
            setShowPopup(false)
        }}
            image={popupImage}
        />
        <Grid container spacing={2} style={{ padding: '5px 20px', marginTop: '10px' }}>
            <Grid item md={4} lg={4} sm={4}>
                <Badge badgeContent={actionMessages.length} color="primary" anchorOrigin={{ horizontal: "left", vertical: "top" }} showZero>
                    <Typography onClick={handleClick} style={{ fontSize: '0.85rem', paddingLeft: '10px', cursor: 'pointer' }}>Actions Required by you</Typography>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                        {actionMessages.map(e => <Typography sx={{ p: 2 }}>{e}</Typography>)}
                        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
                    </Popover>
                </Badge>
            </Grid>
            <Grid item md={4} lg={4} sm={4}>
                <Badge badgeContent={0} color="secondary" anchorOrigin={{ horizontal: "left", vertical: "top" }} showZero>
                    <Typography style={{ fontSize: '0.85rem', paddingLeft: '10px' }}>Actions You've Requested</Typography>
                </Badge>
            </Grid>
            <Grid item md={4} lg={4} sm={4}>
                <Badge badgeContent={0} color="error" anchorOrigin={{ horizontal: "left", vertical: "top" }} showZero>
                    <Typography style={{ fontSize: '0.85rem', paddingLeft: '10px' }}>Notifications</Typography>
                </Badge>
            </Grid>
        </Grid>

        <Box sx={{ height: 700, width: '100%' }}>
            <DataGrid
                rows={loadBuilderProjects() ?? []}
                columns={columns}
                getRowId={(row) => row.ProjectID}
                responsive='onResize'
                density={'standard'}
                sx={{
                    ...gridStyles(theme),
                }}
                // sx={userTableStyles}
                // checkboxSelection
                // disableSelectionOnClick
                onRowDoubleClick={(row) => {
                    console.log("🚀 ~ file: Projects.jsx:323 ~ ProjectsGrid ~ row:", row)
                    history('/projects', {
                        state: {
                            //table of projects selected row
                            projID: row.id,
                            Project: row.row.Project,
                            ProjectShortCode: row.row.ProjectShortCode
                        }
                    })
                }}
                pagination
                autoPageSize
                pageSize={25}
                rowsPerPageOptions={[15, 25, 50]}
                // experimentalFeatures={{ newEditingApi: true }}
                loading={projects == null}
                components={{ Toolbar: GridToolbar, Pagination: CustomPagination, }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        sx: {
                            '& .MuiButtonBase-root': {
                                color: '#ff5722'
                            }
                        }
                    },
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                    setColumnVisibilityModel(newModel)
                    // console.log(columnVisibilityModel, 'COL STATE')
                }
                }
                filterModel={{
                    items: filters,
                    quickFilterValues: quickfilters
                }}
                onFilterModelChange={(model) => {
                    setFilters(model.items)
                    setQuickFilters(model.quickFilterValues)
                    console.log(model.quickFilterValues)
                }
                }
            />
        </Box>
        <>
            {/* {props.isDeveloper === true ?
                <Button
                    variant="contained"
                    aria-controls="fade-menu"

                    onClick={x => {
                        setEditMode("new")
                        setShowProjectDetails(true)
                        setProjData({})
                    }}
                    style={{
                        margin: '10px 10px 0'
                    }}
                    aria-haspopup="true">New Project</Button>
                : <div />} */}
        </>
        {/* <Button
            variant="contained"
            ref={projectsBtn}
            aria-controls="fade-menu"
            onClick={x => {
                //if (props.isDeveloper == true) {
                console.log("🚀 ~ file: Projects.jsx ~ line 79 ~ useEffect ~ editorProps", editorProps.selectedUser)
                history.push({
                    pathname: '/projects',
                    state: {
                        //table of projects selected row
                        projID: editorProps.selectedUser
                    }
                })
                //}
                //else {
                //    setEditMode("edit")
                //    setShowProjects(true);
                //}
            }}
            style={{
                display: 'none',
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">Projects</Button> */}
        {/* <Button 
                    variant="contained"
                    aria-controls="fade-menu"
                    onClick={x => {
                        setShowProjectParticipants(true)
                }}
                style={{
                    margin: '10px 10px 0'
                }}
                aria-haspopup="true">Participants</Button> */}

        <ProjectDetail
            show={props.showProjectDetails}
            mode={"new"}
            onClose={x => {
                props.setShowProjectDetails(false)
                //TODO: refresh project grid
            }} />
    </EditorCtx.Provider>
}
