import { functionsList, functionsListFull } from '@data/liveStores';
import { useJumboTheme } from '@jumbo/hooks';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Template } from 'devextreme-react/core/template';
import { Column, Editing, Pager, Paging, Scrolling, Selection, TreeList } from 'devextreme-react/tree-list';
import React, { useRef, useState } from 'react';
import { roleStyles } from 'theme/cashflowsTheme';
import AddNewFunction from './AddFunction';

const allowedPageSizes = [10, 20, 50];

/**
 * Show hierarchy of functions parent then children
 * allow to edit / delete functions:
 * @param {*} props 
 * @returns 
 */

export default function FunctionsBody(props) {
    const { theme } = useJumboTheme()
    const classes = roleStyles(theme);
    const tree = useRef(null);
    const [selectedRoleID, setSelectedRoleID] = useState(0)
    const [showSubFuncDlg, setShowSubFuncDlg] = useState(false);

    function deleteFunc(e) {
        let u = e.currentTarget.getAttribute("data-key");
        functionsList.remove(u).then(resp => {
            tree.current.instance.refresh()
        }).catch(err => {
        })
    }

    function EditingCell(options) {
        const key = options.data.FunctionId;

        if (options.data.FunctionParentId == null || options.data.FunctionParentId == 0) {
            return (
                <div style={{
                    padding: 0,
                    margin: 0
                }}>
                    <IconButton size={"small"} data-key={key}
                        onClick={deleteFunc}>
                        <Delete fontSize="small" />
                    </IconButton>
                    <IconButton
                        title={"Add/Edit Child Function"}
                        size={"small"}
                        data-key={key}
                        onClick={(e) => {
                            let r = e.currentTarget.getAttribute("data-key");
                            setSelectedRoleID(parseInt(r));
                            setShowSubFuncDlg(true);
                        }}>
                        {/* <PostAddIcon fontSize="small" /> */}
                    </IconButton>
                </div>)
        } else {
            return (
                <div>
                    <IconButton size={"small"} data-key={key}
                        onClick={deleteFunc}>
                        <Delete fontSize="small" />
                    </IconButton>
                </div>)
        }
    }

    return (
        <React.Fragment>
            <TreeList
                ref={tree}
                style={{ ...classes.root, ...classes.rolestable }}
                dataSource={functionsListFull}//
                showBorders={true}
                columnAutoWidth={true}
                showRowLines={true}
                itemsExpr="items"
                dataStructure="tree"
                // parentIdExpr="FunctionParentId" //* sub tasks parent ket id
                onRowClick={x => {
                    // props.setEditMode("edit")
                    setSelectedRoleID(x.data.FunctionId)
                }}
                id="UsrFuncMgr" >
                <Selection mode="single" />
                <Editing
                    allowUpdating={true}
                    allowDeleting={true}
                    mode="row" />
                <Scrolling
                    mode="standard" />
                <Paging
                    enabled={true}
                    defaultPageSize={20} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showInfo={true} />
                <Column dataField="FunctionCode" />
                <Column dataField="FunctionDescription" />
                <Column dataField="FunctionControllerName" />
                <Column dataField="MenuDisplayText" />
                <Column
                    type="buttons"
                    allowSearch={"false"}
                    allowSorting={"false"}
                    width={200}
                    cellTemplate={"EditingCellTemplate"}
                />
                <Template name="EditingCellTemplate" render={EditingCell} />
            </TreeList>
            <AddNewFunction
                id={"functions_editor"}
                mode={props.editMode}
                show={props.showNewFunc}
                funcId={selectedRoleID}
                onClose={(refresh) => {
                    props.setEditMode("new")
                    props.setShowAddDlg(false);
                    if (refresh) {
                        tree.current.instance.refresh()
                        tree.current.instance.clearSelection()
                    }
                }} />
        </React.Fragment>
    );
}
