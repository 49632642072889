//** Manage User roles i.e. Assign existing users to existing roles */
import { useJumboTheme } from '@jumbo/hooks';
import { Alert, Button, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { buttonStyles } from 'theme/cashflowsTheme';
import { newProjData } from '../../../@data/localStore';
import MuiGrid, { TableColumns } from '../Partials/MuiTable';
import AddPayment from './AddPayment';
import ApprovePayment from './ApprovePayment';
import Notes from '../Partials/Notes';


export default function PaymentGrid(props) {
    const [showApprovePayment, setshowApprovePayment] = useState(false)
    const [showNewPayment, setshowNewPayment] = useState(false)
    const [editMode, setEditMode] = useState("new")
    const [error, setError] = useState('')
    const [paymentData, setPaymentData] = useState(null)
    const { theme } = useJumboTheme()
    const [projData, setProjData] = useRecoilState(newProjData)
    const [selectedRow, setSelectedRow] = useState({})
    const btnStyle = buttonStyles(theme)
    const [showPaymentNotes, setshowPaymentNotes] = useState(false)
    const [submittedAmount,setSubmittedAmount ]= useState(0);
    //Load data
    useEffect(() => {
        if (!isNil(props.projID) && props.projID != -1) {
            axios.get(`/api/Payment/GetPaymentFor?project=${props.projID}`).then(resp => {
                setPaymentData(resp.data)
            }).catch(err => {
                console.error("🚀 ~ file: Payments.jsx ~ line 86 ~ axios.get ~ err", err)
            })
        }
    }, [props.projID])
    return <Box>
        <Button
            variant="contained"
            color='info'
            aria-controls="fade-menu"
            onClick={x => {
                let count = 0;
                let valueAdded = 0;
                let headContractorOrgID =0;
                for (let c=0; c< projData.ProjectContacts.length; c++)
                {
                    //alert(projData.ProjectContacts[c].ContactTypeID);
                    if(parseInt(projData.ProjectContacts[c].ContactTypeID)===43)
                    {
                        headContractorOrgID = parseInt(projData.ProjectContacts[c].OrgID);
                    }

                }
                for(let i = 0 ; i < paymentData.length ; i++)
                {
                    //alert(headContractorOrgID);
                    if (paymentData[i].OrgID === headContractorOrgID)
                    {
                        if (paymentData[i].StatusID===58 || paymentData[i].StatusID===2) { //Submitted and Approved
                            count= parseFloat(paymentData[i].PaymentAmount)
                            valueAdded += count;
                            //alert(count);
                        }
                    }
                    
                }
                //alert(valueAdded);
                setSubmittedAmount(valueAdded);
                setshowNewPayment(true)
                setshowApprovePayment(false)
                setEditMode("new")
                setshowPaymentNotes(false)
            }}
            sx={{
                ...btnStyle.button,
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">Add Payment</Button>
        <Button
            variant="contained"
            aria-controls="fade-menu"
            color='info'
            disabled={(!isNil(selectedRow) && !isEmpty(selectedRow)) ? selectedRow.StatusID != 58 : true} //58 : Submitted
            onClick={x => {
                setshowNewPayment(false)
                setshowApprovePayment(true)
                setEditMode("edit")
                setshowPaymentNotes(false)
            }}
            sx={{
                ...btnStyle.button,
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">Approve Payment</Button>
        <Button
            disabled={isEmpty(selectedRow)}
            variant="contained"
            color='info'
            aria-controls="fade-menu"
            onClick={x => {
                setshowNewPayment(false)
                setshowApprovePayment(false)
                //setEditMode("new")
                setshowPaymentNotes(true)
            }}
            sx={{
                ...btnStyle.button,
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">Payment History</Button>
        <Box sx={{ height: 700, width: '100%' }}>
            <MuiGrid
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                    sorting: {
                        sortModel: [{ field: 'PaymentNumber', sort: 'asc' }],
                    },
                }}
                rows={paymentData ?? []}
                loading={paymentData == null}
                columns={TableColumns("tables.PaymentsTable.header.columns")}
                getRowId={(row) => row.PaymentID}
                onRowClick={x => {
                    setSelectedRow(x.row)
                }}
            ></MuiGrid>
        </Box>
        <ApprovePayment
            show={showApprovePayment}
            projID={props.projID}
            paymentID={!isNil(selectedRow) ? selectedRow.PaymentID : 0}
            onClose={x => {
                setshowApprovePayment(false)
                if (x && !isNil(props.projID) && props.projID != -1) {
                    axios.get(`/api/Payment/GetPaymentFor?project=${props.projID}`).then(resp => {
                        setPaymentData(resp.data)
                        setProjData({
                            ...projData,
                            reload: !projData.reload
                        })
                    }).catch(err => {
                        console.error("🚀 ~ file: Payments.jsx ~ line 146 ~ axios.get ~ err", err)
                    })
                }
            }} />
        <Notes
            show={showPaymentNotes}
            moduleID={8}
            ID={!isEmpty(selectedRow) ? selectedRow.ClaimID : 0}
            onClose={x => {
                setshowPaymentNotes(false)
            }} />
        <AddPayment
            show={showNewPayment}
            mode={editMode}
            subAmount ={submittedAmount}
            projID={props.projID}
            projDetails={props.projDetails}
            projData={projData}
            onClose={x => {
                setshowNewPayment(false)
                if (!isNil(props.projID) && props.projID != -1 && x) {
                    axios.get(`/api/Payment/GetPaymentFor?project=${props.projID}`).then(resp => {
                        setPaymentData(resp.data)
                        setProjData({
                            ...projData,
                            reload: !projData.reload
                        })
                    }).catch(err => {
                        console.error("🚀 ~ file: Payments.jsx ~ line 169 ~ axios.get ~ err", err)
                    })
                }
            }} />
        <Snackbar open={error === 'success'} autoHideDuration={6000} onClose={x => setError('closed')}>
            <Alert severity="error">
                Failed to save Payment data, please try again later
            </Alert>
        </Snackbar>
        <Snackbar open={error === 'failed'} autoHideDuration={6000} onClose={x => setError('closed')}>
            <Alert severity="error">
                Failed to save Payment data, please try again later
            </Alert>
        </Snackbar>
    </Box>
}
