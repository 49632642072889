import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import contactsApp from "./contactsApp";
import Common from './Common';
import Auth from './Auth';

const exportReducers = history => {
    return combineReducers({
        router: connectRouter(history),
        contactsApp: contactsApp,
        common: Common,
        auth: Auth,
    });
};

export default exportReducers;
