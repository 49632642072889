import GridContainer from '@jumbo/components/GridContainer';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { isUndefOrNull } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import FileUploader from 'devextreme-react/file-uploader';
import _, { padStart, sumBy } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { buttonStyles } from 'theme/cashflowsTheme';
import { m5 } from '../Partials/Mui5';
import { claimsData, subCons } from './data';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';
const materialUITextFieldProps = x => {
    return {
        label: x,
        multiline: false,
        margin: "dense",
        fullWidth: true,
        size: 'small',
        variant: 'standard'
    }
}

export default function ClaimDetails(props) {
    const [attachment, setAttachment] = useState([])
    const [Origattachment, setOrigAttachment] = useState([])
    const [prefix, setPrefix] = useState('')
    const [claimRow, setClaimRow] = useState({})
    const [claimNumberErr, setClaimNumberErr] = useState("")
    const [submit, setSubmit] = useState(false)
    const confirm = useConfirm()
    const [iterator, setIterator] = useState(0);

    useEffect(() => {
        setPrefix(props.projDetails.ProjectShortCode)
    }, [props.projDetails.ProjectShortCode, props.mode, props.show])

    useEffect(() => {
        if (props.mode === "new") {
            setClaimRow({})
        }
    }, [props.mode])

    useEffect(() => {
        let claim = {
            ClaimNumber: prefix + padStart(props.numRows, 3, '0') + (props.projDetails.ClaimCount + 1),
            ClaimPeriod: Date.now(),
            ClaimAmount: 0,
            RetentionAmount: 0,
            Notes: "",
            ClaimedAmount: 0,
            RetainedNet: 0,
            ApprovedNet: 0,
            MethodOfApproval: ''
        }
        setClaimRow(claim)
    }, [prefix, props.show])

    const onSave = async (type) => {
        try {
            setIsLoading(true);
            let d = {
                "ClaimNumber": claimRow.ClaimNumber,
                "ClaimedAmount": numeral(claimRow.ClaimAmount).value(),
                "AssessedAmount": 0,//claimRow.AssessedAmount,
                'Notes': claimRow.Notes,
                "StatusID": 44, //Draft
                'RetentionAmount': numeral(claimRow.RetentionAmount).value(),
                'RetainedNet': 0,//claimRow.RetainedNet,
                'ApprovedNet': 0,//claimRow.ApprovedNet,
                'MethodOfApproval': claimRow.MethodOfApproval,
                'ProjectID': props.projID //Get ProjectId of selected Project
            }
            if (type === "save") {
                d.StatusID = 58 //"Submitted"
                console.log("🚀 ~ file: ClaimsDetails.jsx ~ line 272 ~ ClaimDetails ~ save", d)
                //send to server
                // let f = new FormData()
                // f.append('attachment', attachment)
                // _.forEach(d, (val, key) => {
                //     f.append(key, val)
                // })
                // Create a new FormData object to send the data and attachments
                let f = new FormData();
                attachment.forEach((file, index) => {
                    f.append('attachment', file);
                });


                // Create an object with the desired structure
                const originalNamesObject = {};

                // Assuming attachment and Origattachment arrays have the same length
                attachment.forEach((file, index) => {
                    const attachmentName = file.name;
                    const origAttachmentName = Origattachment[index].name;
                    originalNamesObject[attachmentName] = origAttachmentName;
                });

                // Append the originalNamesObject to the FormData
                f.append('originalNames', JSON.stringify(originalNamesObject));


                // Origattachment.forEach((file, index) => {
                //     f.append('originalName', file.name);
                // });
                _.forEach(d, (val, key) => {
                    f.append(key, val);
                });
                await axios.post('/api/Project/NewClaim', f, {
                    headers: {
                        "Accept": "*/*",
                        'Accept-Encoding': 'gzip, deflate, br'
                    }
                });
                setIsLoading(false);
                props.onClose(true)
            } else if (type === "draft") {
                d.Status = "Draft"
            }
            claimsData.insert(d);
            subCons.clear()
        }
        catch (err) {
            setIsLoading(false);
            console.error(err);
            // setError("failed")
        }
    }
    const { theme } = useJumboTheme()
    const classes = m5(theme)
    const btnStyle = buttonStyles(theme)
    const handleRemoveFile = (index) => {
        const UpdatedOrigAttachment = [...Origattachment];
        UpdatedOrigAttachment.splice(index, 1);

        const UpdatedAttachment = [...attachment];
        UpdatedAttachment.splice(index, 1);

        console.log(UpdatedOrigAttachment, 'ORIG')
        console.log(UpdatedAttachment, 'MODIFIED')
        setOrigAttachment(UpdatedOrigAttachment);
        setAttachment(UpdatedAttachment)
        setIterator(prevIterator => prevIterator - 1);
    };

    // Generate the current date and time in the format "ddmmyyhhmmss"
    const currentDate = new Date();
    const formattedDate = currentDate
        .toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })
        .replace(/\/|,|:| /g, ''); // Remove special characters
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
    let time = formattedDate.concat(milliseconds);
    const [isLoading, setIsLoading] = useState(false);
    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            sx={classes.dialog}
            open={props.show}>
            <DialogTitle
                sx={{ ...classes.dlgTitle }}
                disableTypography={true}
                children={
                    props.mode === "new" ? <IntlMessages id="Claim.DetailDlg.Title" /> : <IntlMessages id="Claim.DetailDlg.TitleEdit" />
                } />
            {/* remove scrollbar */}
            <DialogContent
                sx={{ ...classes.root, ...classes.dlgContent }}
            >
                <GridContainer>
                    <Grid item lg={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    variant='standard'
                                    required={true}
                                    size={'small'}
                                    placeholder={'Claim Number'}
                                    value={claimRow.ClaimNumber}
                                    //TODO call backend to check claim number
                                    onChange={x => {
                                        setClaimRow({
                                            ...claimRow,
                                            ClaimNumber: x.target.value
                                        })
                                        axios.get("/api/Project/CheckClaimID", { params: { id: x.target.value, ProjID: props.projID } }).then(resp => {
                                            setClaimNumberErr("")
                                        }).catch(err => {
                                            // console.log("🚀 ~ file: ClaimsDetails.jsx ~ line 141 ~ axios.get ~ err", err.response, err.message)
                                            setClaimNumberErr(err.response.data)
                                        })
                                    }}
                                    sx={{ ...classes.formControl, ...classes.root }}
                                    error={claimNumberErr !== ""}
                                    helperText={claimNumberErr}
                                    label="Claim Number" />
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        format="dd/MM/yyyy"
                                        formatDensity='dense'
                                        id="date-picker-inline"
                                        label="Claim Period"
                                        value={claimRow.ClaimPeriod}
                                        variant={'inline'}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Change date',
                                        }}
                                        sx={{
                                            ...classes.root, ...classes.formControl
                                        }}
                                        onChange={x => {
                                            setClaimRow(prev => ({
                                                ...prev,
                                                ClaimPeriod: x
                                            }))
                                        }}
                                    /></LocalizationProvider>
                                {/* <TextField
                                    margin="dense"
                                    variant='standard'
                                    fullWidth
                                    required={true}
                                    size={'small'}
                                    placeholder={'Claim Period'}
                                    value={moment(claimRow.ClaimPeriod).format('yyyyMM')}
                                    onChange={x => {
                                        setClaimRow({
                                            ...claimRow,
                                            ClaimPeriod: x.target.value
                                        })
                                    }}
                                    sx={{ ...classes.formControl, ...classes.root }}
                                    label="Claim Period" /> */}
                            </Grid>
                            <Grid item xs={6}>
                                <NumericFormat
                                    value={claimRow.ClaimAmount}
                                    prefix='$'
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    onChange={x => setClaimRow({
                                        ...claimRow,
                                        ClaimAmount: x.target.value
                                    })}
                                    customInput={TextField}
                                    onClick={(event) => {
                                        if (event.target.value === '$0.00') {
                                            event.target.value = '';
                                        }
                                    }}
                                    onFocus={(e) => {
                                        const value = e.target.value;                                   
                                        const dotIndex = value.indexOf('.');
                                        e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                                      }}
                                    {...materialUITextFieldProps("Claim Amount")} />
                            </Grid>
                            <Grid item xs={6}>
                                <NumericFormat
                                    value={claimRow.RetentionAmount}
                                    prefix='$'
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    onChange={x => setClaimRow({
                                        ...claimRow,
                                        RetentionAmount: x.target.value
                                    })}
                                    customInput={TextField}
                                    onClick={(event) => {
                                        if (event.target.value === '$0.00') {
                                            event.target.value = '';
                                        }
                                    }}
                                    onFocus={(e) => {
                                        const value = e.target.value;                                   
                                        const dotIndex = value.indexOf('.');
                                        e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                                      }}
                                    {...materialUITextFieldProps("Retention Amount")} />
                            </Grid>
                            <>
                                {props.mode === "edit" ?
                                    <Grid item xs={6}>
                                        <TextField margin="dense" fullWidth required={true} size={'small'} placeholder={'Approved Net'} value={claimRow.ApprovedNet}
                                            onChange={x => {
                                                setClaimRow({
                                                    ...claimRow,
                                                    ApprovedNet: x.target.value
                                                })
                                            }}
                                            sx={{ ...classes.formControl, ...classes.root }}
                                            label="Approved Net" />
                                    </Grid> : <div />}
                            </>
                            <>
                                {props.mode === "edit" ?
                                    <Grid item xs={6}>
                                        <TextField margin="dense" fullWidth required={true} size={'small'} placeholder={'Retained Net'} value={claimRow.RetainedNet}
                                            onChange={x => {
                                                setClaimRow({
                                                    ...claimRow,
                                                    RetainedNet: x.target.value
                                                })
                                            }}
                                            sx={{ ...classes.formControl, ...classes.root }}
                                            label="Retained Net" />
                                    </Grid> : <div />}
                            </>
                            <>
                                {props.mode === "edit" ?
                                    <Grid item xs={6}>
                                        <TextField margin="dense" fullWidth required={true} size={'small'} placeholder={'Method of Approval'} value={claimRow.MethodOfApproval}
                                            onChange={x => {
                                                setClaimRow({
                                                    ...claimRow,
                                                    MethodOfApproval: x.target.value
                                                })
                                            }}
                                            sx={{ ...classes.formControl, ...classes.root }}
                                            label="Method of Approval" />
                                    </Grid> : <div />}
                            </>
                        </Grid>
                    </Grid>

                    <Grid item lg={6}>
                        <div className="fileuploader-container">
                            <FileUploader
                                showFileList={false}
                                multiple={true}
                                tabIndex={6}
                                onValueChange={files => {
                                    setOrigAttachment(prevAttachments => [...prevAttachments, ...files]);
                                    const modifiedFiles = files.map((file, index) => {
                                        console.log(props.projDetails.ProjectShortCode);
                                        let shortCode = props.projDetails.ProjectShortCode;
                                        const fileIterator = iterator + index + 1;
                                        // Create a new file with a unique name
                                        const modifiedFile = new File(
                                            [file],
                                            `${shortCode}_C_${claimRow.ClaimNumber}_${time}_${fileIterator}` +
                                            file.name.substring(file.name.lastIndexOf('.')),
                                            {
                                                type: file.type // Preserve the file type
                                            }
                                        );
                                        return modifiedFile;
                                    });
                                    // Save all the modified files in the 'attachments' state                                                
                                    setAttachment(prevAttachments => [...prevAttachments, ...modifiedFiles]);
                                    setIterator(prevIterator => prevIterator + files.length);
                                    // console.log(modifiedFiles)   
                                    // console.log(attachment,'ATTACHMENTS')
                                    console.log(Origattachment.map(file => file.name), "ORIGINAL")
                                }}
                                selectButtonText="Select Attachment"
                                //accept={'image/*, application/pdf'}
                                accept={'.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .zip, .gif'}
                                uploadMode="useForm" />
                            {/* For Custom UI List */}
                            <List>
                                {Origattachment.map((file, index) => (
                                    <ListItem key={index}>
                                        <a
                                            href={URL.createObjectURL(file)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <ListItemText primary={file.name} />
                                        </a>
                                        <Button onClick={() => handleRemoveFile(index)}>
                                            <ListItemIcon>
                                                <CloseIcon sx={{ fontSize: 15 }} />
                                            </ListItemIcon>
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Grid>
                    <Grid item lg={12} sm={12} md={12}>
                        <TextField
                            rows={5}
                            maxRows={5}
                            size='medium'
                            placeholder='Claim Notes'
                            fullWidth
                            multiline
                            onChange={x => {
                                setClaimRow(prev => ({
                                    ...prev,
                                    Notes: x.target.value
                                }))
                            }} />
                    </Grid>
                    {/* //TODO Jason asked to disable in mvp */}
                    {/* <Grid item lg={12}>
                        <ClaimEditGrid />
                    </Grid> */}
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    tabIndex={7}
                    variant='contained'
                    color='info'
                    disabled={submit}
                    onClick={x => {
                        let t = sumBy(subCons._array, x => {
                            return x.AssessedAmount
                        })
                        if (isUndefOrNull(t) == false && claimRow.ClaimAmount < t) {
                            confirm({
                                description: "Cannot submit claim of value less than Sub-Contractor payments",
                                title: "Claim Amount",
                                hideCancelButton: true
                            }).then(resp => { }).catch(err => {
                            })
                        } else {
                            // var ret = confirm("Do you want to submit this claim?")
                            // if (ret) {
                            // }
                            const confirmationMessage = "Do you want to submit this claim?";
                            confirm({
                                allowClose: false,
                                description: confirmationMessage,
                                title: "Confirm action",
                            }).then(async resp => {
                                setSubmit(true)
                                onSave("save").then(r => {
                                    setSubmit(false)
                                }).catch(err => {
                                    setSubmit(false)
                                })
                            }).catch((e) => {
                                console.error("🚀 ~ file: claimsdetails.jsx:306 ~Submit Claim  ~ e", e)
                            })
                        }
                    }}
                    sx={btnStyle.button}> {isLoading ? <CircularProgress sx={{color:'#2EB5C9'}} size={24} /> : 'Submit Claim'}</Button>
                <Button
                    tabIndex={8}
                    variant='contained'
                    color='inherit'
                    sx={btnStyle.button}
                    onClick={x => {
                        props.onClose(false)
                        setClaimRow({
                            ...claimRow,
                            ClaimAmount: 0,
                            RetentionAmount: 0,
                            Notes: ""
                        })
                        subCons.clear()
                    }}>Cancel</Button>
            </DialogActions>
        </Dialog>)
}
