import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';

const JumboDdPopover = ({ triggerButton, children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = () => {
    handleClose(); // Close the popover when an option is selected
  };

  return (
    <>
      <span onClick={handleClick}>{triggerButton}</span>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          mt: 2,
          mr: 6,
        }}
      >
        {React.Children.map(children, (child) => {
          // Adding onClick handler to each children
          return React.cloneElement(child, {
            onClick: handleOptionClick,
          });
        })}
      </Popover>
    </>
  );
};

JumboDdPopover.propTypes = {
  open: PropTypes.bool,
  triggerButton: PropTypes.node,
  children: PropTypes.node,
};

export default JumboDdPopover;

//////////////////////////////////////////////////////////////////////////////////////////

// import React from 'react';
// import PropTypes from 'prop-types';
// import Popover from "@mui/material/Popover";

// const JumboDdPopover = ({triggerButton, children}) => {

//     const [anchorEl, setAnchorEl] = React.useState(null);
//     const isOpen = Boolean(anchorEl);

//     const handleClick = React.useCallback((event) => {
//         setAnchorEl(event.currentTarget);
//     }, []);

//     const handleClose = React.useCallback(() => {
//         setAnchorEl(null);
//     }, []);

//     return (
//         <>
//             <span onClick={handleClick}>
//                 {triggerButton}
//             </span>
//             <Popover
//                 open={isOpen}
//                 anchorEl={anchorEl}
//                 onClose={handleClose}
//                 anchorOrigin={{
//                     vertical: 'bottom',
//                     horizontal: 'left',
//                 }}
//                 sx={{
//                     mt: 2,
//                     mr: 6
//                 }}
//             >
//                 {children}
//             </Popover>
//         </>
//     );
// };

// JumboDdPopover.propTypes = {
//     open: PropTypes.bool,
//     triggerButton: PropTypes.node,
//     children: PropTypes.node,
// };

// export default JumboDdPopover;

