import { useEffect, useState } from "react";
import { ContactRoles } from "../../../@data/liveStores";
import Dropdown from "./DropBox";

export default function ContactRole({ selectedStatus, setStatus }) {
    const [orgData, setOrgData] = useState([])
    useEffect(() => {
        ContactRoles.load().then(({ data }) => {
            let d = data.map(e => {
                return {
                    value: e.ContactRoleID,
                    Name: e.Name,
                    Description: e.Description,
                    ContactRoleID: e.ContactRoleID
                }
            })
            setOrgData(d)
        })
    }, [])

    return (<Dropdown
        placeholder="Role"
        value={selectedStatus}
        data={orgData}
        mapping={x => {
            return { value: x.ContactRoleID, label: x.Name }
        }}
        onChange={setStatus}
    />)
}
