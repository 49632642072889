import { OrgContext } from '@data';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, InputLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { errorMessage } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import { useFormik } from 'formik';
import { isEmpty, isNil, clone } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as Yup from 'yup';
import { StatusStore, orgs } from '../../../@data/liveStores';
import { _userInvited, newProjData, reloadOrg } from '../../../@data/localStore';
import { EditParticipants, InviteUserToOrg } from '../../../@data/project_api';
import { buttonStyles, useStyles } from '../../../theme/cashflowsTheme';
import Address from '../Partials/Address';
import ContactsType from '../Partials/ContactsType';
import { m5 } from '../Partials/Mui5';
import MuiGrid, { TableColumns } from '../Partials/MuiTable';
import { showErrorToast, showSuccessToast } from '../Partials/Notify';
import { PersonAddDlg } from '../Partials/PersonAddDlg';
import StatusSelect from '../Partials/StatusSelect';
import BankDetails from './BankDetails';
import InviteUser from './InviteUser';

const orgData = {
    BusinessName: null,
    IsActive: true,
    TradingName: null,
    ABN: null,
    ACN: null,
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function OrgUsers(props) {
    const [addPeronShow, setAddPeronShow] = useState(false);
    const editRef = useRef(null)
    const [selectedUser, setSelectedUser] = useState(0)
    const userInvited = useRecoilValue(_userInvited)
    const [dataStore, setDataStore] = useState([])
    const handler = () => {
        try {
            editRef.current.click()
        } catch (error) {
        }
    }
    useEffect(() => {
        if (typeof (props.OrgID) === "object") return
        if (!isNil(props.OrgID) && props.OrgID != -1) {
            // setDataStore(OrgUsersData(props.OrgID))//userInvited
            axios.get(`/api/Organisation/OrgTeams?OrgID=${props.OrgID}`).then(resp => {
                setDataStore(resp.data)
            }).catch(e => {
            })
        } else if (props.OrgID == -1 && !isEmpty(userInvited)) {
            setDataStore([{
                ContactID: -1,
                FirstName: userInvited.FirstName,
                LastName: userInvited.LastName,
                IsLocked: true,
                Status: "Invited"
            }])
        }
        try {
            document.getElementById("devxtable").addEventListener('dblclick', handler)
        } catch (error) {
        }
        return () => {
            try {
                document.getElementById("devxtable").removeEventListener('dblclick', handler)
            } catch (error) {
            }
        }
    }, [props.OrgID, props.reload])

    return <>
        <Button
            style={{ display: 'none' }}
            ref={editRef}
            onClick={x => {
                setAddPeronShow(true)
            }}>Edit</Button>
        <PersonAddDlg
            mode={"edit"}
            selectedUser={selectedUser}
            show={addPeronShow}
            onClose={x => {
                setAddPeronShow(false)
            }} />
        <MuiGrid
            rows={dataStore}
            columns={TableColumns("tables.OrganisationUserTable.header.columns")}
            disableDensitySelector
            disableColumnSelector
            onRowClick={e => {
                setSelectedUser(e.id)
            }}
            getRowId={x => x.ContactID}></MuiGrid>
    </>
}

export default function OrgDetails(props) {
    const { theme } = useJumboTheme()
    const classes = useStyles(theme)
    const m5Classes = m5(theme)
    const btnStyle = buttonStyles(theme)

    const orgForm = useRef(null)
    const [selectedStatus, setStatus] = useState(null)
    const [value, setValue] = useState(0);
    const [showAddUser, setShowAddUser] = useState(false)
    const [reload, setReload] = useState(false)
    const [checkABN, setCheckABN] = useState("")
    const [abnConfirmation, setAbnConfirmation] = useState(false)
    const [selectedContactRole, setSelectedContactRole] = useState(props.selectedContactRole)
    const [orgAddress, setOrgAddress] = useState({})
    const [_orgData, setOrgData] = useState(orgData)

    const [userInvited, setUserInvited] = useRecoilState(_userInvited)
    const [projData, setProjData] = useRecoilState(newProjData)
    const setReloadOrg = useSetRecoilState(reloadOrg)
    const confirm = useConfirm();
    const { contactTypes } = useContext(OrgContext)

    useEffect(() => {
        setSelectedContactRole(props.selectedContactRole)
    }, [props.selectedContactRole])
    useEffect(() => {
        if (isNil(checkABN) || isEmpty(checkABN) || props.mode !== "new" || !props.show) return
        axios.get(`/api/Organisation/ABNCheck?abn=${checkABN}`).then(resp => {
            if (isEmpty(resp.data)) return
            //1st check
            let found = false
            projData['ProjectContacts'].forEach(element => {
                if (element.OrgID === resp.data['OrganisationID']) {
                    found = true
                    confirm({
                        description: `The organisation ${resp.data['BusinessName']} - ${resp.data['ABN']} is already registered on your project.`,
                        title: "Organisation is already mapped to this project.",
                        hideCancelButton: true
                    }).then(resp2 => {
                    }).catch(err2 => { })
                    props.onClose(false, null)
                }
            });
            if (found) return
            //2nd check
            confirm({
                description: "The organisation " + resp.data['BusinessName'] + " - ABN : " + checkABN + " you are trying to add, is already registered with PayArk. Do you wish to invite them to your project?",
                title: "Organisation already exists",
            }).then(resp1 => {
                // populate with ABN
                props.onClose(false, checkABN)
            }).catch(err => {
            })
        }).catch(e => { })
    }, [checkABN, abnConfirmation])

    //for tabs
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const abnCheck = (v) => {
        setCheckABN(v)
        setAbnConfirmation(!abnConfirmation)
        return new Promise((resolve, reject) => {
            axios.get(`/api/Organisation/ABNCheck?abn=${v}`).then(resp => {
                if (!isEmpty(resp.data) && props.mode === "new") {
                    resolve(false)
                } else {
                    resolve(true)
                }
            }).catch(err => {
                reject("Failed to contact server")
            })
        })
    }

    const validationSchema = Yup.object().shape({
        ABN: Yup.string()
            .required('ABN is required')
            .min(14, "ABN is not valid")
            .test('abn-check', 'ABN is not valid or already exists', async function (value, ctx) {
                const v = value.replace(/\s+/g, '')
                if (v.length < 11) return false
                if (checkABN === v) return true
                const res = await abnCheck(v)
                return res
            }),
        BusinessName: Yup.string().required('Business Name is required'),
        TradingName: Yup.string(),
        IsActive: Yup.boolean(),
        ACN: Yup.string()
    });

    /**
     * Only do loading if edit mode for selected row
     */
    useEffect(() => {
        setUserInvited({})//reset invited user data for both modes
        if (props.mode === "new") {
            orgData.BusinessName = ""
            orgData.IsActive = true
            orgData.TradingName = ""
            orgData.ABN = ''
            orgData.ACN = ''
            setStatus(13)
            setOrgData(orgData)
        }
        if (props.mode === "new" && !isNil(orgForm.current) && !isNil(orgForm.current.instance)) {
            orgForm.current.instance.resetValues()
            return
        }
        if (props.mode === "new" || isNil(props.editID) || props.editID < 1 || typeof (props.editID) !== 'number') return
        axios.get(`/api/Organisation/GetOrg?key=${props.editID}`)
            .then(({ data }) => {
                orgData.BusinessName = data.BusinessName
                orgData.IsActive = data.IsActive
                orgData.TradingName = data.TradingName
                orgData.ABN = data.ABN
                orgData.ACN = data.ACN
                setStatus(data.StatusID)
                setOrgData(orgData)
                if (!isNil(orgForm.current) && !isNil(orgForm.current.instance)) {
                    orgForm.current.instance.updateData(orgData)
                }
                //load address:
                axios.get(`/api/Organisation/GetOrgAddress?key=${props.editID}`)
                    .then(resp1 => {
                        setOrgAddress(resp1.data)
                    })
                    .catch(err => {
                    })
            }).catch(err3 => { })
    }, [props.mode, props.editID, props.show])

    useEffect(() => {
        setReload(!reload)
        //add user data to table
    }, [showAddUser])

    function saveData(mode) {
        if (mode === "edit") {
            orgs.update(props.editID, {
                OrganisationID: props.editID,
                BusinessName: orgData.BusinessName,
                IsActive: orgData.IsActive,
                TradingName: orgData.TradingName,
                ABN: orgData.ABN.replace(/\s+/g, ''),
                ACN: orgData.ACN.replace(/\s+/g, ''),
                StatusID: selectedStatus
            }).then(async (resp) => {
                //update address
                axios.post("/api/Organisation/SaveOrgAddress", {
                    StreetAddress: orgAddress['street'],
                    City: orgAddress['city'],
                    State: orgAddress['state'],
                    Country: orgAddress['country'],
                    PostalCode: orgAddress['postal_code'],
                    Suburb: orgAddress['suburb'],
                    OrgID: props.editID
                }).then(resp1 => { }).catch(err1 => {
                    console.error("🚀 ~ file: OrgDetails.jsx:408 ~ saveData ~ err1:", err1)
                })
                let row = {}
                if ((!isNil(props.projID) && props.projID != 0) || props.abnRetry) {
                    var r2 = contactTypes.filter(e => e.ContactTypeID === selectedContactRole)
                    let r3 = await orgs.byKey(props.editID)
                    let r4 = await StatusStore.byKey(selectedStatus)
                    row = {
                        Organisation: clone(r3.BusinessName),
                        BusinessName: clone(r3.BusinessName),
                        OrganisationID: props.editID,
                        OrgID: props.editID,
                        ContactTypeID: clone(selectedContactRole),
                        ContactType: clone(r2[0].Name),
                        Status: clone(r4[0].Name),
                        StatusID: clone(selectedStatus),
                        StartDate: new Date(),
                        MapProjectContactID: Date.now()
                    }
                    if (!isNil(props.projID) && props.projID != 0) {
                        EditParticipants({
                            ProjectID: props.projID,
                            OrgID: props.editID,
                            ContactTypeID: selectedContactRole,
                            StatusID: selectedStatus,
                            StartDate: row.StartDate,
                            EndDate: null,
                        })
                    }
                    //add to project contacts:
                    // setProjData({
                    //     ...projData,
                    //     ProjectContacts: [...projData.ProjectContacts, row]
                    // })
                }
                showSuccessToast("Organisation Updated")
                setReloadOrg(prev => {
                    return {
                        reload: !prev.reload
                    }
                })
                props.onClose(true, null, row)
            }).catch(err => {
                console.error("🚀 ~ file: OrgDetails.jsx:335 ~ saveData ~ err:", err)
                showErrorToast("Cannot update Organisation at the time")
                props.onClose(false)
            })
        }
        if (mode === "new") {
            //** make sure we have an invited user as well */
            if (isEmpty(userInvited)) {
                // setShowError(true)
                confirm({
                    description: "You must also invite a user to add a new Organization",
                    title: "User info missing",
                }).then(resp => {
                }).catch(err => {
                })
                return false
            }
            let row = {
                Organisation: clone(orgData.BusinessName),
                OrgID: 0,
                ContactTypeID: clone(selectedContactRole),
                Status: "Invited",
                StatusID: 70,
                StartDate: new Date(),
                MapProjectContactID: Date.now()
            }
            orgs.insert({
                BusinessName: orgData.BusinessName,
                IsActive: orgData.IsActive,
                TradingName: orgData.TradingName,
                ABN: orgData.ABN.replace(/\s+/g, ''),
                ACN: orgData.ACN.replace(/\s+/g, ''),
                StatusID: 70//invited status
            }).then(async resp => {
                console.log("🚀 ~ file: OrgDetails.jsx ~ line 219 ~ saveData ~ resp", resp.data)
                try {
                    var r2 = contactTypes.filter(e => e.ContactTypeID === selectedContactRole)
                    row.OrgID = resp.data
                    row.ContactType = clone(r2[0].Name)
                } catch (error) {
                    console.log("🚀 ~ saveData ~ error:", error)
                }
                //Save address
                axios.post("/api/Organisation/SaveOrgAddress", {
                    StreetAddress: orgAddress['street'],
                    City: orgAddress['city'],
                    State: orgAddress['state'],
                    Country: orgAddress['country'],
                    PostalCode: orgAddress['postal_code'],
                    Suburb: orgAddress['suburb'],
                    OrgID: resp.data
                }).then(resp1 => { }).catch(err1 => {
                    console.error("🚀 ~ file: OrgDetails.jsx:408 ~ saveData ~ err1:", err1)
                    // showErrorToast("Cannot save Organisation Address")
                })
                //now invite user
                showSuccessToast("Added new Organisation")
                InviteUserToOrg(userInvited.email, userInvited.FirstName, userInvited.LastName, userInvited.Phone, resp.data, true)
                    .then(async resp1 => {
                        setUserInvited({})
                        //add to project
                        if (!isNil(props.projID) && props.projID != 0) {
                            EditParticipants({
                                ProjectID: props.projID,
                                OrgID: row.OrgID,
                                ContactTypeID: selectedContactRole,
                                StatusID: 70,
                                StartDate: row.StartDate,
                                EndDate: null
                            })
                            //add to project contacts:
                            //TODO is causing dupe in Chrome
                            // setProjData(prev => ({
                            //     ...prev,
                            //     'ProjectContacts': [...prev.ProjectContacts, row]
                            // }))
                        }
                    }).catch(err2 => {
                        console.error("🚀 ~ file: OrgDetails.jsx:453 ~ InviteUserToOrg ~ err1:", err2)
                        confirm({
                            description: errorMessage(err2),
                            title: "Invite User to Organisation",
                            hideCancelButton: true
                        }).then(resp => { }).catch(err => {
                        })
                    })
                //send reload event
                setReloadOrg(prev => {
                    return {
                        reload: !prev.reload
                    }
                })
                props.onClose(true, resp.data, row)
            }).catch(err => {
                showErrorToast("Cannot add Organisation at this time, try again later")
                props.onClose(false, -1)
            })
        }
        return true
    }

    const buttonName = () => {
        if (!isNil(props.projID) && props.projID != 0 || props.abnRetry) {
            return "Save"
        }
        return "Update"
    }

    const formik = useFormik({
        initialValues: {
            ABN: _orgData.ABN,
            ACN: _orgData.ACN,
            BusinessName: _orgData.BusinessName,
            TradingName: _orgData.TradingName,
            IsActive: _orgData.IsActive,
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            orgData.BusinessName = values.BusinessName
            orgData.TradingName = values.TradingName
            orgData.ABN = values.ABN
            orgData.ACN = values.ACN
            orgData.IsActive = true
            // do something with form data, e.g. submit to server
            if (selectedContactRole == 0) {
                confirm({
                    description: "Please select a Role for new Organisation",
                    title: "Missing Role",
                    hideCancelButton: true,
                }).then(resp => {
                }).catch(err => {
                })
            } else {
                // if (isNil(orgAddress['street']) || isEmpty(orgAddress['street'])) {
                //     confirm({
                //         description: "Please add a valid address for org",
                //         title: "Missing Address",
                //         hideCancelButton: true
                //     }).then(r => {
                //     }).catch(e => { })
                // } else {
                // }
                saveData(props.mode)
                setCheckABN("")
            }
            //check abn again here
            //     return
            // } 
            // else {
            //     if (saveData(props.mode)) {
            //         setCheckABN("")
            //         //TODO reset formik form data
            //         resetForm({
            //             ...orgData
            //         })
            //     }
            // }
        },
    });

    function status() {
        if (props.mode === "new") return <></>
        return <Grid item xs={12} md={6}>
            <InputLabel>Status</InputLabel>
            <StatusSelect
                selectedStatus={selectedStatus}
                setStatus={setStatus} />
        </Grid>
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                onClose={(x, reason) => {
                    if (reason === "backdropClick") return
                    props.onClose(false)
                }}
                sx={classes.dialog}
                maxWidth={'xl'}
                open={props.show}>
                <DialogTitle sx={{
                    ...m5Classes.dlgTitle
                }}>
                    {props.mode === "new" ?
                        <IntlMessages id="OrgManager.DetailDlg.Title" /> :
                        (!isNil(props.projID) && props.projID != 0 || props.abnRetry) ?
                            "Add Organisation" :
                            <IntlMessages id="OrgManager.DetailDlg.TitleEdit" />}
                </DialogTitle>
                <DialogContent
                    sx={{ ...classes.root }}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <InputMask
                                    mask="99 999 999 999"
                                    maskChar=" "
                                    name='ABN'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ABN}
                                    disabled={props.mode === "edit"}
                                >
                                    {() => <TextField
                                        variant='standard'
                                        name="ABN"
                                        label="ABN"
                                        required
                                        error={formik.touched.ABN && Boolean(formik.errors.ABN)}
                                        helperText={formik.touched.ABN && formik.errors.ABN}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />}
                                </InputMask>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputMask
                                    mask="999 999 999"
                                    maskChar=" "
                                    name='ACN'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ACN}>
                                    {() => <TextField
                                        variant='standard'
                                        name="ACN"
                                        label="ACN"
                                        error={formik.touched.ACN && Boolean(formik.errors.ACN)}
                                        helperText={formik.touched.ACN && formik.errors.ACN}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />}
                                </InputMask>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant='standard'
                                    name="BusinessName"
                                    label="Entity Name"
                                    value={formik.values.BusinessName}
                                    required
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.BusinessName && Boolean(formik.errors.BusinessName)}
                                    helperText={formik.touched.BusinessName && formik.errors.BusinessName}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            {status()}
                            {/* <Grid item xs={12} md={6}>
                                <Label text={"Status"} />
                                <StatusSelect
                                    selectedStatus={selectedStatus}
                                    setStatus={setStatus} />
                            </Grid> */}

                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="TradingName"
                                    label="Trading Name"
                                    variant='standard'
                                    value={formik.values.TradingName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.TradingName && Boolean(formik.errors.TradingName)}
                                    helperText={formik.touched.TradingName && formik.errors.TradingName}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} sx={{ display: props.mode === "new" ? 'none' : 'initial' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="IsActive"
                                            checked={formik.values.IsActive}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            color="primary"
                                        />
                                    }
                                    label="Active"
                                    name='IsActive'
                                    error={formik.touched.IsActive && Boolean(formik.errors.IsActive)}
                                    helperText={formik.touched.IsActive && formik.errors.IsActive}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <Grid item xs={4} style={{ marginTop: '5px', display: ((!isNil(props.projID) && props.projID > 0) || props.abnRetry) ? 'inherit' : 'none' }}>
                        <ContactsType selectedStatus={selectedContactRole} setStatus={setSelectedContactRole} />
                    </Grid>

                    <Box sx={{ width: '100%', padding: 0, margin: 0 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="More Details">
                                <Tab label="Team" {...a11yProps(0)} />
                                <Tab label="Account" {...a11yProps(1)} />
                                <Tab label="Address" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Grid container >
                                <Grid item xs={12} md={12} lg={12}>
                                    <Button
                                        onClick={x => setShowAddUser(true)}
                                        variant="contained"
                                        color='info'
                                        sx={btnStyle.button}
                                        disabled={props.mode === "new" ? !isEmpty(userInvited) : false}>Add User</Button>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} id="devxtable">
                                    <OrgUsers OrgID={props.mode === "new" ? -1 : props.editID} reload={reload} />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <BankDetails OrgID={props.editID} />
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Address addressType="" orgAddress={orgAddress} onAddressChanged={(addr) => {
                                        setOrgAddress({ ...addr })
                                    }}></Address>
                                </Grid>
                                {/* <Grid item xs={12} md={12} lg={8}>
                                    <Address addressType="Billing Address" onAddressChanged={() => { }}></Address>
                                </Grid> */}
                            </Grid>
                        </TabPanel>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        sx={btnStyle.button}
                        color='info'
                        //TODO: add more checks for props.projID
                        onClick={x => {
                            x.preventDefault();
                            formik.handleSubmit();
                            //let res = orgForm.current.instance.validate()
                            // if (res.isValid == false || (selectedContactRole == 0 && (!isNil(props.projID) && props.projID > 0))) {
                            // if (selectedContactRole == 0) {
                            //     confirm({
                            //         description: "Please select a Role for new Organisation",
                            //         title: "Missing Role",
                            //         hideCancelButton: true,
                            //     }).then(resp => {
                            //     }).catch(err => {
                            //     })
                            // }
                            //check abn again here
                            //     return
                            // } 
                            // else {
                            //     if (saveData(props.mode)) {
                            //         setCheckABN("")
                            //     }
                            // }
                        }}>{props.mode === "new" ? "Save" : buttonName()}</Button>
                    <Button
                        variant='contained'
                        sx={btnStyle.button}
                        color='inherit'
                        onClick={x => {
                            props.onClose(false)
                        }}>Close</Button>
                </DialogActions>
            </Dialog>
            <InviteUser mode={props.mode === "new" ? 'org' : 'user'} show={showAddUser} onClose={setShowAddUser} OrgID={props.editID} />
        </>)
}

OrgDetails.propTypes = {
    show: PropTypes.bool.isRequired,
    mode: PropTypes.oneOf(["new", "edit"]),
    editID: PropTypes.number,
    selectedContactRole: PropTypes.number,
    onClose: PropTypes.func.isRequired
}
