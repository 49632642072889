import GridContainer from '@jumbo/components/GridContainer';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import FileUploader from 'devextreme-react/file-uploader';
import { isEmpty, isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { buttonStyles } from 'theme/cashflowsTheme';
import { newProjData } from '../../../@data/localStore';
import { m5 } from '../Partials/Mui5';
import { EmptyTextarea } from '../Partials/StyledTextArea';
import { showErrorToast } from '../Partials/Notify';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function ApprovePayment(props) {
    const [attachment, setAttachment] = useState(null)
    const [paymentRow, setPaymentRow] = useState({})
    const projData = useRecoilValue(newProjData)
    const confirm = useConfirm()

    let payment = {
        PaymentNumber: null,
        PaymentPeriod: Date.now(),
        PaymentAmount: 0,
        PaidToOrganisation: 0,
        Notes: "",
        ReferenceNumber: "",
        ClaimNumber: "",
        Organisation: "",
        OrgID: 0,
        attachment: null,
        originalNames: {}
    }

    const headContractorCheck1 = useMemo(d => {
        const headContractor = projData?.ProjectContacts?.filter(e => e.ContactTypeID == 43)
        if (projData.MaxBuilderPercent == 0 && !isEmpty(headContractor)) {
            if (headContractor[0].OrgID == paymentRow.OrgID) {
                return true
            }
        }
        return false
    }, [props.paymentID, paymentRow.OrgID])

    useEffect(() => {
        if (props.paymentID > 0) {
            axios.get(`/api/Payment/GetPayment?key=${props.paymentID}`)
                .then(({ data }) => {
                    console.log("🚀 ~ file: ApprovePayment.jsx ~ line 60 ~ .then ~ data", data)
                    payment.PaymentNumber = data.PaymentNumber
                    payment.PaymentAmount = data.PaymentAmount
                    payment.Notes = data.Notes
                    payment.PaymentPeriod = data.PaymentPeriod
                    payment.ReferenceNumber = data.ReferenceNumber
                    payment.ClaimNumber = data.ClaimNumber
                    payment.Organisation = data.Organisation
                    payment.OrgID = data.OrgID
                    payment.attachment = data.attachments
                    payment.originalNames = data?.originalNames ?? {}
                    setPaymentRow({
                        ...payment
                    })
                })
                .catch(err => {
                })
        }
    }, [props.paymentID, props.show])

    const attachments = () => {
        // console.log("🚀 ~ file: AssessClaim.jsx:135 ~ arr ~ claimRow.attachment:", claimRow.attachment)
        const arr = paymentRow.attachment?.map(elem => {
            return <List dense>
            <ListItem >
            <ListItemText>
              <a
                style={{  fontSize: '12px' }}
                href={"images/"+`${elem}`}
                target='_blank'
              >
                {/* {elem} */}
                {paymentRow.originalNames[elem]}
              </a>
            </ListItemText>
          </ListItem>
          </List>
        })
        return arr
    }
    // const attachments = () => {
    //     // console.log("🚀 ~ file: AssessClaim.jsx:135 ~ arr ~ claimRow.attachment:", claimRow.attachment)
    //     const arr = paymentRow.attachment?.map(elem => {
    //         return <a style={{ color: 'black', fontSize: '12px' }} href={"images/"+`${elem}`} target='_blank'>{elem}</a>
    //     })
    //     return arr
    // }

    const onSave = async (type) => {
        const availableFunds = projData.PaidtoDate - (projData.PaidToBuilder + projData.PaidToTrades)
        const headContractorMaxClaimAmount = (projData.PaidtoDate * (projData.MaxBuilderPercent / 100)) - projData.PaidToBuilder;
        const projectParticipants = projData.ProjectContacts;
        let headContractor = 0;
        if (!isNil(projectParticipants)) {
            const headContractorRole = projectParticipants.filter(({ ContactTypeID }) => ContactTypeID === 43);
            if (!isNil(headContractorRole) && !isEmpty(headContractorRole)) {
                headContractor = headContractorRole[0].OrgID;
            }
        }
        try {
            if (type === "approve") {
                console.log("🚀 ~ file: ApprovePayment.jsx ~ line 77 ~ Approve ~ save", props.paymentID)
                ///checks ---:
                if (headContractor !== 0 && headContractor === payment.OrgID && payment.PaymentAmount > headContractorMaxClaimAmount) {
                    confirm({
                        description: "This payment exceeds the current maximum entitlements for the Head Contractor",
                        title: "Approve Payment",
                        hideCancelButton: true
                    }).then(resp => {
                    }).catch(err => {
                    })
                } else if (paymentRow.PaymentAmount > availableFunds) {
                    confirm({
                        description: "Payment amount cannot exceed the available funds",
                        title: "Approve Payment",
                        hideCancelButton: true,
                    }).then(resp => {
                    }).catch((e) => {
                    })
                } else {
                    //send to server
                    axios.post(`/api/Payment/ApprovePayment?key=${props.paymentID}`).then(res1 => {
                        props.onClose(true)
                    }).catch(e => {
                        showErrorToast("Failed to Approve payment")
                    })
                }
            } else if (type === "reject") {
                console.log("🚀 ~ file: ApprovePayment.jsx ~ line 82 ~ Reject ~ save", props.paymentID)
                //send to server
                await axios.post(`/api/Payment/RejectPayment?key=${props.paymentID}`);
                props.onClose(true)
            } else if (type === "request") {
                console.log("🚀 ~ file: ApprovePayment.jsx ~ line 89 ~ Request ~ save", props.paymentID)
                //send to server
                await axios.post(`/api/Payment/RequestForMoreInfo?key=${props.paymentID}`);
                props.onClose(true)
            }
        }
        catch (err) {
            console.error(err);
            // setError("failed")
        }
    }
    const { theme } = useJumboTheme()
    const classes = m5(theme)
    const btnStyle = buttonStyles(theme)

    return (
        <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            sx={classes.dialog}
            open={props.show}>
            <DialogTitle
                sx={classes.dlgTitle}
                children={
                    <IntlMessages id="ApprovePayment.DetailDlg.Title" />
                } />
            {/* remove scrollbar */}
            <DialogContent
                sx={{ ...classes.root, ...classes.dlgContent }}>
                <GridContainer>
                    <Grid item lg={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    size={'small'}
                                    variant='standard'
                                    placeholder={'Payment Number'}
                                    value={paymentRow.PaymentNumber}
                                    inputProps={{ readOnly: true, }}
                                    sx={{ ...classes.root, ...classes.formControl }}
                                    label="Payment Number" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    size={'small'}
                                    variant='standard'
                                    placeholder={'Payment Date'}
                                    value={moment(paymentRow.PaymentPeriod).format('DD/MM/YYYY')}
                                    inputProps={{ readOnly: true, }} sx={{ ...classes.root, ...classes.formControl }}
                                    label="Payment Date" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    required={true}
                                    variant='standard'
                                    size={'small'}
                                    placeholder={'Payment Amount'}
                                    value={numeral(paymentRow.PaymentAmount).format("$0,0.00")}
                                    onChange={x => {
                                        setPaymentRow({
                                            ...paymentRow,
                                            PaymentAmount: x.value
                                        })
                                    }}
                                    sx={{ ...classes.root, ...classes.formControl }}
                                    label="Payment Amount" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    required={true}
                                    variant='standard'
                                    size={'small'}
                                    placeholder={'Paid To Organisation'}
                                    value={paymentRow.Organisation}
                                    inputProps={{ readOnly: true, }} sx={{ ...classes.root, ...classes.formControl }}
                                    label="Paid To Organisation" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    size={'small'}
                                    variant='standard'
                                    placeholder={'Reference Number'}
                                    value={paymentRow.ReferenceNumber}
                                    inputProps={{ readOnly: true, }} sx={{ ...classes.root, ...classes.formControl }}
                                    label="Reference Number" />
                            </Grid>
                            {/* <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    size={'small'}
                                    variant='standard'
                                    placeholder={'Claim Number'}
                                    value={paymentRow.ClaimNumber}
                                    inputProps={{ readOnly: true, }} sx={{ ...classes.root, ...classes.formControl }}
                                    label="Claim Number" />
                            </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item lg={6}>
                        <div className="fileuploader-container">
                            <FileUploader
                                multiple={false}
                                onValueChange={x => {
                                    console.log("🚀 ~ file: ApprovePayment.jsx ~ line 182 ~ ApprovePayment ~ x", x[0])
                                    setAttachment(x[0])
                                }}
                                selectButtonText="Select Attachment"
                                //accept="image/*"
                                accept={'.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .zip, .gif'}
                                uploadMode="useForm" />
                        </div>
                        {attachments()}
                    </Grid>
                    
                    <Grid item lg={12}>
                        <EmptyTextarea
                            aria-label='Notes'
                            placeholder='Notes'
                            spellCheck={true}
                            minRows={5}
                            value={paymentRow.Notes}
                            onChange={x => {
                                setPaymentRow({
                                    ...paymentRow,
                                    Notes: x.target.value
                                })
                            }}
                        />
                    </Grid>
                    {/* //TODO Jason asked to disable in mvp */}
                    {/* <Grid item lg={12}>
                        <ClaimEditGrid />
                    </Grid> */}
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography variant='body2'>{headContractorCheck1 ? "Cannot Approve Payment for head contractor without setting Head Contractor percentage" : ""}</Typography>
                    </Grid>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='info'
                    disabled={headContractorCheck1}
                    onClick={x => {
                        // let t = _.sumBy(subCons._array, x => {
                        //     return x.AssessedAmount
                        // })
                        // if (isUndefOrNull(t) == false && claim.ClaimAmount < t) {
                        //     alert("Cannot submit claim of value less than Sub-Contractor payments")
                        //     return
                        // } else {
                        //     var ret = confirm("Do you want to approve this claim?")
                        // }
                        confirm({
                            description: "Do you want to approve this payment?",
                            title: "Approve Payment",
                            hideCancelButton: true
                        }).then(resp => {
                            onSave("approve")
                        }).catch(e => { })
                    }}>Approve</Button>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='secondary'
                    onClick={x => {
                        if (isEmpty(paymentRow.Notes) || paymentRow.Notes.length < 10) {
                            confirm({
                                description: "Please add payment rejection notes",
                                title: "Payment Notes",
                                hideCancelButton: true,
                            }).then(resp => { }).catch(err => {
                            })
                        }
                        else {
                            confirm({
                                description: "Do you want to reject this payment?",
                                title: "Reject Payment",
                                hideCancelButton: true
                            }).then(resp => {
                                onSave("reject")
                            }).catch(e => { })
                        }
                    }
                    }>Reject</Button>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='info'
                    onClick={x => {
                        confirm({
                            description: "Do you want to Request More Information for this payment?",
                            title: "Request more information",
                            hideCancelButton: true
                        }).then(resp => {
                            onSave("request")
                        }).catch(e => { })
                    }}>Request More Info</Button>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='inherit'
                    onClick={x => {
                        props.onClose(false)
                        //subCons.clear()
                    }}>Cancel</Button>
            </DialogActions>
        </Dialog >)
}
