import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { buttonStyles } from 'theme/cashflowsTheme';
import { m5 } from '../Partials/Mui5';
import { IconButton } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { DataGrid } from '@mui/x-data-grid';
export default function AttachedClaim(props) {
    // const [attachment, setAttachment] = useState(null)
    const [claimRow, setClaimRow] = useState({})
    const confirm = useConfirm()
    const { theme } = useJumboTheme()
    const styles = m5(theme)
    const btnStyle = buttonStyles(theme)

    const claim = {
        ClaimID: 0,
        ClaimNumber: '',
        ClaimPeriod: Date.now(),
        ClaimAmount: 0,
        Notes: "",
        RetentionAmount: 0,
        AssessedAmount: 0,
        RetainedNet: 0,
        ApprovedNet: 0,
        MethodOfApproval: '',
        StatusId: 0,
        attachment: null,
        originalNames: {}
    }

    useEffect(() => {
        if (props.claimID > 0 && props.show) {
            axios.get(`/api/Project/GetClaim?key=${props.claimID}`)
                .then(({ data }) => {
                    console.log("🚀 ~ file: AssessClaim.jsx ~ line 62 ~  ~ props", data)
                    claim.ClaimID = data.ClaimID
                    claim.ClaimNumber = data.ClaimNumber
                    claim.ClaimAmount = data.ClaimedAmount
                    claim.Notes = data.Notes
                    claim.ClaimPeriod = data.ClaimPeriod
                    claim.AssessedAmount = data.AssessedAmount
                    claim.RetentionAmount = data.RetentionAmount
                    claim.ClaimNumber = data.ClaimNumber
                    claim.StatusId = data.StatusId
                    claim.attachment = data.attachments
                    claim.originalNames = data?.originalNames ?? {}
                    setClaimRow({
                        ...claim
                    })
                })
                .catch(err => {
                })
        }
    }, [props.claimID, props.show])

    
    const attachments = () => {
        const handleDownload = (filename) => {
            const fileUrl = `/images/${filename}`;
            fetch(fileUrl)
              .then((response) => response.blob())
              .then((blob) => {
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = claimRow.originalNames[filename] || filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              })
              .catch((error) => {
                console.error('Error downloading file:', error);
              });
          };

        // const handleDownload = (filename) => {
        //     const link = document.createElement('a');
        //     link.href = `images/${filename}`;
        //     link.download = claimRow.originalNames[filename] || filename;
        //     link.click();
        //   };
      
        const renderFileName = (params) => (
          <a
            style={{ fontSize: '12px' }}
            href={`images/${params.row.attachment}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {params.value}
          </a>
        );
      
        const renderDownloadButton = (params) => (
          <IconButton
            color='primary'
            aria-label='Download'
            onClick={() => handleDownload(params.row.attachment)}
          >
            <CloudDownloadIcon 
                    variant='contained'
                    color='info' 
                    fontSize='35px'
                    />
          </IconButton>
        );
      
        const attachmentRows = claimRow.attachment?.map((elem, index) => ({
          id: index,
          attachment: elem,
          filename: claimRow.originalNames[elem],
          download: renderDownloadButton,
        })) || [];
      
        const columns = [
          { field: 'filename', headerName: 'File Name', width: 350, renderCell: renderFileName },
          { field: 'download', headerName: 'Download', width: 100, renderCell: renderDownloadButton },
        ];
        const localeText = {
            noRowsLabel: 'No rows',
          };
      
        return (
          <div style={{ height: 300, width: '100%' }}>
            <DataGrid
              rows={attachmentRows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              hideFooterPagination
              localeText={localeText}
            />
          </div>
        );
      };
    return (
        <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            sx={styles.dialog}
            open={props.show}>
            <DialogTitle
                sx={styles.dlgTitle}
                children={
                    <IntlMessages id="AttachedClaim.DetailDlg.Title" />
                } />
            <DialogContent
                sx={{ ...styles.root, ...styles.dlgContent }}>
                    <Grid>
                        <div id="attachment">
                            {attachments()}
                        </div>
                    </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='inherit'
                    onClick={x => {
                        props.onClose(false)
                    }}>Cancel</Button>
            </DialogActions>
        </Dialog>)
}
