import GridContainer from '@jumbo/components/GridContainer';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { buttonStyles } from 'theme/cashflowsTheme';
import { printDateTime, printCurrency } from '../Partials/MaterialGrid';
import { m5 } from './Mui5';
import MuiGrid from './MuiTable';

const dlgContent = (x) => ({
    root: {
        overflowY: "hidden"
    }
})

// const materialUITextFieldProps = x => {
//     return {
//         label: x,
//         multiline: false,
//         margin: "dense",
//         fullWidth: true,
//         size: 'small'
//     }
// }

const columns = [
    // { field: 'ClaimID', headerName: 'ClaimID', width: 90 },
    {
        field: 'BatchNo',
        headerName: 'Batch No',
        flex: 1,
    },
    {
        field: 'PaymentNumber',
        headerName: 'Payment Number',
        flex: 1,
    },
    {
        field: 'PaymentAmount',
        headerName: 'Payment Amount',
        renderCell: (params) => {
            return printCurrency(params.row.PaymentAmount)
        },
        flex: 1,
    },
    {
        field: 'PaymentDate',
        headerName: 'Payment Date',
        renderCell: (params) => {
            return printDateTime(params.row.PaymentDate)
        },
        flex: 1
    },
    {
        field: 'Organisation',
        headerName: 'Organisation',
        flex: 2,
    },    
];

export default function BatchPaymentDetails(props) {
    const { theme } = useJumboTheme()
    const classes = m5(theme)
    const classes2 = dlgContent()
    const [batchPaymentsData, setBatchPaymentsData] = useState([])
    const btnStyle = buttonStyles(theme)

    useEffect(() => {
        if (props.ID > 0) {
            axios.get(`/api/Payment/GetBatchPayments?key=${props.ID}`).then(resp => {
                setBatchPaymentsData(resp.data)
            }).catch(err => {
                console.error("🚀 ~ file: BatchPaymentDetails.jsx ~ line 68 ~ axios.get ~ err", err)
            })
        }  
    }, [props.ID])

    return (
        <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            sx={classes.dialog}
            open={props.show}>
            <DialogTitle
                sx={classes.dlgTitle}
                disableTypography={true}
                children={
                    <IntlMessages id="BatchPayment.DetailDlg.Title" />
                } />
            {/* remove scrollbar */}
            <DialogContent sx={{
                ...classes.root,
                ...classes2.root
            }}>
                <GridContainer>
                    <Grid item lg={12}>
                        <Grid container spacing={12}>
                            <Grid item xs={12}>
                                <Box sx={{ width: '100%', marginTop: '15px' }}>
                                    <MuiGrid
                                        disableColumnFilter
                                        disableDensitySelector
                                        disableColumnSelector
                                        disableExpo
                                        rows={batchPaymentsData}
                                        columns={columns}
                                        getRowId={(row) => row.PaymentID}
                                        />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color='inherit'
                    sx={btnStyle.button}
                    onClick={x => {
                        props.onClose(false)
                    }}>Close</Button>
            </DialogActions>
        </Dialog>)
}