import ProjectParticipantsWizard from "../../Construction/ProjectParticipantsWizard";
import NewProjectTeam from './NewProjectTeam';
import ProjectInfo from './ProjectInfo';

export default function AddNewProject(props) {
	switch (props.step) {
		case 1:
			return (
				<ProjectInfo step={props.step} />
			);
		//TODO removing incomplete team step for now
		// case 2:
		// 	return (
		// 		<NewProjectTeam step={props.step} />
		// 	);
		case 2:
			return <ProjectParticipantsWizard role={"Financier"} editMode="new" step={props.step} />
		case 3:
			return <ProjectParticipantsWizard role={"Quantity Surveyor"} editMode="new" step={props.step} />
		case 4:
			return <ProjectParticipantsWizard role={"Head Contractor"} editMode="new" step={props.step} />
		// case 4:
		//   return <Success />;
		// default:
		//   {console.log('This is a multi-step form built with React.')}
	}
}
