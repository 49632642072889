import React from 'react';
import styled from '@emotion/styled';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { alpha } from '@mui/material';

const bgImage = `${ASSET_IMAGES}/auth/auth-bg-pattern.png`;

const Outer = styled('div')(({ theme, variant }) => ({
  width: '100vw',
  height: '101vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: variant === 'bgColor' ? `URL(${bgImage})` : '',
  backgroundPosition: variant === 'bgColor' ? 'center center' : '',
  backgroundRepeat: variant === 'bgColor' ? 'no-repeat' : '',
  backgroundSize: variant === 'bgColor' ? 'cover' : '',
  position: 'relative',
  padding: 20,
  [theme.breakpoints.up('sm')]: {
    padding: 40,
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: props => (props.variant === 'bgColor' ? alpha(theme.palette.primary.main, 0.5) : ''),
  },
}))

const Inner = styled('div')(({ theme, variant }) => (
  {
    position: 'relative',
    zIndex: 3,
    maxWidth: variant === 'default' ? '850px' : '550px',
    width: '100%',
    // height: '500px',
    // boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.24), 0px 3px 1px rgba(0, 0, 0, 0.22), 0px 1px 5px rgba(0, 0, 0, 0.20)',
    boxShadow: "5px 5px 5px 0px rgba(55, 55, 55, 0.50)",
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: props => (props.variant === 'default' ? '1050px' : '750px'),
    },
  }
))

const AuthWrapper = ({ children, variant }) => {
  return (
    <Outer variant={variant}>
      <Inner id="inner" variant={variant}>{children}</Inner>
    </Outer>
  );
};

export default AuthWrapper;
