import { Pagination } from "@mui/material"
import { gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid"
import { isNil } from "lodash"
import moment from "moment"
import { FormattedDateParts, FormattedNumber } from "react-intl"

export const printCurrency = function (value) {
    var n = 0
    if (!isNil(value)) {
        n = value
    }
    return <FormattedNumber value={n} style={"currency"} currency={"USD"} />
}

export const printPercentage = function (value) {
    var n = 0
    if (!isNil(value)) {
        n = value / 100
    }
    return <FormattedNumber value={n} style={"percent"} />
}

export const printDate = function (value) {
    if (!isNil(value)) {
        return <span>{moment(value).format("DD/MM/YYYY")}</span>
    }
    return ""
}

export const printDateTime = function (value) {
    if (!isNil(value)) {
        return <span>{moment(value).format("DD/MM/YYYY HH:mm")}</span>
    }
    return ""
}

export function printDatePeriod(cellInfo) {
    return <FormattedDateParts
        value={cellInfo.value} year="numeric" month="2-digit" day="2-digit">
        {parts => (<> {parts[4].value}{parts[0].value}</>)}
    </FormattedDateParts>
}

export const printCurrency2 = function (value) {
    var n = 0
    if (!isNil(value.value)) {
        n = value.value
    }
    return <FormattedNumber value={n} style={"currency"} currency={"USD"} />
}

export const printPercentage2 = function (value) {
    var n = 0
    if (!isNil(value.value)) {
        n = value.value / 100
    }
    return <FormattedNumber value={n} style={"percent"} />
}

export const printDate2 = function (value) {
    if (!isNil(value.value)) {
        return <span>{moment(value.value).format("DD/MM/YYYY")}</span>
    }
    return ""
}

export const printDateTime2 = function (value) {
    if (!isNil(value.value)) {
        return <span>{moment(value.value).local().format("DD/MM/YYYY HH:mm")}</span>
    }
    return ""
}

export const printDateTime3 = function (value) {
    if (!isNil(value.value)) {
        return <span>{moment(value.value).format("DD/MM/YYYY")}</span>
    }
    return ""
}

export function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}
