import { alpha } from "@mui/system"

export const useStyles = x => ({
    root: {
        border: 'solid 0.10px',
        borderColor: "rgba(233, 233, 233, 0.85)",
        fontWeight: x.typography.fontWeight,
        fontFamily: x.typography.fontFamily,
        padding: '5px 20px',
        margin: '5px 5px',
    },
    dialog: {
        "& .MuiDialog-paper": {
            backgroundColor: x.palette.background.paper
        }
    },
    dialogTitle: {
        '& .dx-theme-material-typography': {
            fontSize: '15px !important',
            color: 'yellow',
        }
    },
    dlgTitle: {
        "& .MuiDialogTitle-root": {
            fontSize: '0.65rem',
            fontWeight: 'bold',
            lineHeight: '1.2em',
        }
        // "& .dx-theme-material-typography h2": {
        //     fontSize: '15px !important',
        //     color: 'yellow',
        // }
    },
    form: {
        padding: "0 15px",
        margin: "5px",
        // marginBottom: '20px',
        // [x.breakpoints.up('md')]: {},
        '& .dx-field-item': {
            padding: 0
        },
    },
    formInput: {
        '& .dx-field-item': {
            padding: 0
        },
        "& .dx-field-item-content.dx-field-item-content-location-bottom": {
            paddingBottom: "10px"
        }
    },
    myFonts: {
        '& .dx-widget': {
            fontFamily: x.typography.fontFamily
        },
        '& .dx-widget input': {
            fontFamily: x.typography.fontFamily
        }
    },
    popup: {
        width: '50vw',
        // [x.breakpoints.down("md")]: {
        //     width: '60vw'
        // }
    },
    popupSm: {
        width: '33vw',
        [x.breakpoints.down("md")]: {
            width: '50vw'
        }
    }
})

export const roleStyles = t => ({
    root: {
        maxHeight: '60vh',
        fontFamily: t.typography.fontFamily,
        backgroundColor: t.palette.background.paper,
    },
    rolestable: {
        '& .dx-treelist-container': {
            backgroundColor: t.palette.background.paper
        }
    },
    actionsRoot: {
        transition: 'all 0.3s ease',
        transform: 'translateX(-110%)',
        opacity: 0.70,
        marginLeft: 10,
    },
})

export const cardStyles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: '0.85rem'
    },
    cardRoot: {
        position: 'relative',
        '& .Cmt-card-content': {
            padding: 0,
            paddingBottom: 24,
        },
    },
    scrollbarRoot: {
        height: 280,
    },
    cardHeaderRoot: {
        padding: "10px 24px"
    },
    badgeRoot: {
        fontSize: 14,
        letterSpacing: 0.25,
        backgroundColor: alpha(theme.palette.warning.main, 0.15),
        color: theme.palette.warning.main,
        padding: '2px 10px',
        borderRadius: 30,
    }
})

export const buttonStyles = theme => ({
    button: {
        fontSize: '11px',
        margin: '0 15px 0 0',
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 'bold',
        maxHeight: "35px"
    },
    btnOutlines: {
        fontSize: '11px',
        margin: '0 15px 0 0',
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.85)',
        maxHeight: "35px",
        "&:hover": {
            backgroundColor: "rgba(115, 82, 199, 0.20)"
        }
    }
})

export const gridStyles = theme => ({
    fontFamily: theme.typography.fontFamily,
    lineHeight: '1.2rem',
    letterSpacing: '0.10rem',
    fontWeight: 500,
    fontSize: '0.85rem',
    // textShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
    padding: '0px 5px',
    margin: '0px 0px',
    minHeight: "500px",
    "& .MuiDataGrid-row:hover": {
        backgroundColor: "#03DAC5"
    },
    '& .MuiDataGrid-virtualScroller': {
        overflow: "hidden",
        paddingBottom: "20px"
        // display: 'none',
        // overflowY: 'hidden'
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
        overflow: "hidden",
        paddingBottom: "20px"
    },
    "& .MuiDataGrid-row": {
        border: 'rgba(211, 211, 211, 0.5) 0.25px solid',
        boxShadow: "10px 10px 5px 0px rgba(211,211,211,0.2)",
        borderRadius: '5px',
        width: '97%',
        margin: '1px 0px',
        // padding: '0'
    },
    "& .Mui-selected": {
        backgroundColor: "#03DAC5 !important"
    },
    '& .popup': {
        padding: '0 10px',
        margin: '-11px -10px',
        cursor: 'pointer',
        height: "100%",
        maxHeight: "32px",
    }
})
