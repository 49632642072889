import AppLocale from 'i18n';
import { isNil } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

// import AppLocale from 'i18n';

const InjectMassage = props => <FormattedMessage {...props} />;
export default injectIntl(InjectMassage, {
  withRef: false,
});

export function IntlMessage(id, msg) {
  let s = `${AppLocale["en"].messages[id]}`;
  if(!isNil(msg)){
    s += ` ${msg}`;
  }
  return s;
}
