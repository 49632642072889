import { OrgContext } from '@data';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Alert, Box, Button, Grid, IconButton, Snackbar } from '@mui/material';
import axios from 'axios';
import { isEmpty, isNil } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { buttonStyles, gridStyles } from 'theme/cashflowsTheme';
import { StatusStore } from '../../../@data/liveStores';
import { newProjData, projSaveState } from '../../../@data/localStore';
import { AddProjectContactRole } from '../../../@data/project_api';
import ContactRole from '../Partials/ContactRole';
import Dropdown from '../Partials/DropBox';
import MuiGrid, { TableColumns } from '../Partials/MuiTable';

const label = { inputProps: { 'aria-label': 'Checkbox' } };

export function validate(row, projectParticipants, setErrorTxt) {
    /*  Status: "Active"
        StatusID: 13 */
    /*  Role: "Financial Responsible Party"
        RoleID: 79 */
    /*  Role: "Head Contractor"
        RoleID: 43 */
    /*  Role: "Developer"
        RoleID: 77 */
    // RoleID: 66, Role: "Subcontractor"
    // RoleID: 51, Role: "Supplier"

    let error = false
    // if (row.RoleID == 43 && row.FRP) {
    //     error = true
    //     setErrorTxt("Head Contractor cannot be FRP")
    // }
    // if (row.RoleID == 66 && row.FRP) {
    //     error = true
    //     setErrorTxt("Sub Contractor cannot be FRP")
    // }
    // projectParticipants.forEach(e => {
    //     if (e.RoleID == row.RoleID && e.OrgID == row.OrgID) {
    //         error = true
    //         setErrorTxt("Organisation already exist with same role")
    //     }
    //     if (e.FRP && row.FRP) {
    //         error = true
    //         setErrorTxt("Project has already FRP assigned")
    //     }
    //     if (true) {//TODO add back this check: e.StatusID == 13 && row.StatusID == 13
    //         if (e.RoleID == 43 && row.RoleID == 43) {
    //             error = true
    //             setErrorTxt('Cannot have two active Contractors on same project')
    //         }
    //         if (e.RoleID == 79 && row.RoleID == 79) {
    //             error = true
    //             setErrorTxt('Cannot have two active FRP on same project')
    //         }
    //         if (e.RoleID == 77 && row.RoleID == 77) {
    //             error = true
    //             setErrorTxt('Cannot have two active Developers on same project')
    //         }
    //     }
    //     if (row.RoleID == 43) {
    //         if (e.OrgID == row.OrgID) {
    //             error = true
    //             setErrorTxt('Cannot have Contractors from same org on same project')
    //         }
    //     }
    //     if (e.RoleID == 77 || e.RoleID == 79) {
    //         if (row.RoleID == 51 || row.RoleID == 66) {
    //             if (e.OrgID == row.OrgID) {
    //                 error = true
    //                 setErrorTxt('Cannot have same org as supplier and developer / FRP at the same time')
    //             }
    //         }
    //     }
    //})
    console.log("🚀 ~ file: ProjectTeam.jsx ~ line 81 ~ validate ~ !error", !error)
    if (error == false) {
        setErrorTxt("")
    }
    return !error
}


export default function ProjectTeam(props) {
    const [selectedStatus, setStatus] = useState(null)
    const [selectedContactRole, setSelectedContactRole] = useState(null)
    const [user, setUser] = useState(0)
    const [org, setOrg] = useState(0)
    const [editorProps, setEditorProps] = useState({
        selectedUser: {
            StartDate: null,
            EndDate: null,
            Organization: "",
            Role: "",
            Status: "",
            FRP: false
        }
    })
    const [orgUserData, setOrgUserData] = useState([])
    const [projData, setProjData] = useRecoilState(newProjData)
    const setProjSaveState1 = useSetRecoilState(projSaveState)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(null)
    const [projectTeams, setProjectTeams] = useState([])
    const [errorTxt, setErrorTxt] = useState('')
    const [disableEditing, setDisableEditing] = useState(true)
    const [showEditor, setShowEditor] = useState(false)
    const { theme } = useJumboTheme()
    const editBtn = useRef(null)
    const [orgMode, setOrgMode] = useState("new")
    const btnStyles = buttonStyles(theme)
    const gridStyle = gridStyles(theme)
    const authUser = JSON.parse(sessionStorage.getItem("routes"))
    const { contactTypes } = useContext(OrgContext)

    useEffect(() => {
        if (!isNil(editorProps) && !isNil(editorProps.selectedUser) && editorProps.selectedUser['Organization'] !== "") {
            setDisableEditing(false)
        } else {
            setDisableEditing(true)
        }
    }, [editorProps, editorProps.selectedUser])


    useEffect(() => {
        axios.get(`/api/Organisation/OrgTeams?OrgID=${authUser.OrgID}`).then(resp => {
            setOrgUserData(resp.data)
        }).catch(e => {
        })

        setStatus(13)
        setProjSaveState1(false)
        //load saved project info
        if (!isNil(projData['ProjectTeams']) && !isEmpty(projData['ProjectTeams'])) {
            setProjectTeams(projData['ProjectTeams'])
        }
        // if (props.editMode === "new") {
        //     axios.get("api/Organisation/MyOrgs").then(resp => {
        //         //setUserOrg(resp.data.OrganisationID)
        //         let row = {
        //             Organization: resp.data.BusinessName,
        //             Organisation: resp.data.BusinessName,
        //             OrgID: resp.data.OrganisationID,
        //             RoleID: 77, //Developer
        //             Role: 'Developer',
        //             Status: 'Active',
        //             StatusID: 13,
        //             StartDate: utcDate,
        //             EndDate: null,
        //             FRP: false
        //         }
        //         setProjectParticipants([...projectParticipants, row])
        //         setProjData({
        //             ...projData,
        //             ProjectContacts: [...projectParticipants, row]
        //         })

        //     }).catch(err => {
        //         console.error(err)
        //     })
        // }
        return () => {
            setProjSaveState1(true)
        }
    }, [])




    /**
     * Only do loading if edit mode for selected row
     */

    const utcDate = startDate.toISOString()
    return (<>
        <Box>
            <IntlMessages id="ProjectTeam.DetailDlg.Title" />
            <Grid container spacing={3} rowSpacing={'8px'}>
                <Grid item xs={5}>
                    <Dropdown
                        placeholder="Add User to this Project"
                        data={orgUserData}
                        value={user}
                        onChange={setUser}
                        mapping={x => {
                            return { value: x.ContactID, label: x.FirstName + ' ' + x.LastName }
                        }}
                    />
                </Grid>
                {/* <Grid item xs={4}>
                    <StatusSelect selectedStatus={selectedStatus} setStatus={setStatus} />
                </Grid> */}
                <Grid item xs={3} >
                    <ContactRole selectedStatus={selectedContactRole} setStatus={setSelectedContactRole} />
                </Grid>

                <Grid item xs={3}>
                    <Button
                        aria-controls="fade-menu"
                        variant='contained'
                        color='info'
                        sx={btnStyles.button}
                        onClick={async (e) => {
                            //alert(user);

                            if (selectedStatus == null || selectedContactRole == null) return
                            var r = orgUserData.filter(e => {
                                return e.ContactID == user
                            })
                            var r2 = contactTypes.filter(e => { return e.value === selectedContactRole })
                            var r3 = await StatusStore.byKey(13)

                            let row = {
                                Contact: r[0].FirstName + " " + r[0].LastName,
                                Organisation: r[0].BusinessName,
                                OrgID: authUser.OrgID,
                                RoleID: selectedContactRole,
                                Role: r2.Name,
                                Status: r3[0].Name,
                                StatusID: selectedStatus,
                                Email: r[0].Email,
                                Phone: r[0].Phone,
                                ContactID: user
                            }
                            if (validate(row, projectTeams, setErrorTxt)) {
                                //alert(selectedContactRole);
                                setProjectTeams([...projectTeams, row])
                                // editorProps.dataGrid.current.instance.refresh()
                                //if in edit mode save
                                if (props.editMode === "edit") {
                                    
                                    AddProjectContactRole({
                                        ProjectID: projData.ProjectID,
                                        OrgID: authUser.OrgID,
                                        RoleID: selectedContactRole,
                                        StatusID: selectedStatus,
                                        StartDate: utcDate,
                                        EndDate: endDate,
                                        ContactID: user,
                                        deleted: false,
                                        edited: false
                                    })
                                } else {
                                    //save to project data
                                }
                                setProjData({
                                    ...projData,
                                    ProjectTeams: [...projectTeams, row]
                                })
                            }
                            //reset selections:
                            setOrg(0)
                            setSelectedContactRole(null)
                        }}
                    >
                        Add Team Member
                    </Button>
                </Grid>
            </Grid>
            <Box padding={"10px 0px"} id="participant-grid">
                <div style={{ width: "100%", textAlign: "right" }}>
                    <IconButton
                        ref={editBtn}
                        disabled={disableEditing}
                        style={{ marginRight: "50px" }}
                        title={"Select a row to edit user"}
                        onClick={() => {
                            setShowEditor(true)
                        }}
                        size={'medium'}>
                        {/* <Edit /> */}
                    </IconButton>
                </div>
            </Box>
            <Box sx={{ width: '100%' }} id="participantTable">
                <MuiGrid
                    rows={projectTeams ?? []}
                    loading={projectTeams == null}
                    columns={TableColumns("tables.ProjectTeamTable.header.columns")}
                    getRowId={(row) => {
                        return row.ContactID
                    }}
                // onRowClick={x => {
                //     setSelectedRow(x.row)
                // }}
                ></MuiGrid>
            </Box>

        </Box>
        <Snackbar open={errorTxt !== ''} autoHideDuration={6000}>
            <Alert severity="error">
                {errorTxt}
            </Alert>
        </Snackbar>
    </>)
}


