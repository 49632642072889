import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, SIGNIN_USER_SUCCESS } from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
    initialURL: '/',
    error: '',
    loading: false,
    message: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_START: {
            return { ...state, error: '', message: '', loading: true };
        }
        case FETCH_SUCCESS: {
            return { ...state, error: '', message: action.payload, loading: false };
        }
        case FETCH_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        case SIGNIN_USER_SUCCESS: {
            let s = {
                ...state,
                initialURL: '/dash'
            }
            return { ...s, loading: false, error: '' }
        }
        default:
            return state;
    }
};
