import { IntlMessage } from '@jumbo/utils/IntlMessages';
import { showErrorToast, showSuccessToast } from 'app/pages/Partials/Notify';
import { errorMessage } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';

const contacts = new CustomStore({
    key: "ContactID",
    load: function (loadOptions) {
        return axios.get("/api/Contacts").then((x) => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        });
    },
    // byKey: function (k) { }
});

export const orgs = new CustomStore({
    key: "OrganisationID",
    load: function (loadOptions) {
        return axios.get(`/api/Organisation?search=${loadOptions.searchValue}`).then((x) => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        });
    },
    remove: function (key) {
        return axios.delete(`/api/Organisation?key=${key}`)
    },
    insert: function (val) {
        return axios.post("/api/Organisation", { ...val })
    },
    update: function (key, val) {
        return axios.put("/api/Organisation", { ...val })
    },
    byKey: function (key) {
        return axios.get(`/api/Organisation/GetOrg?key=${key}`).then(x => {
            return x.data
        }).catch(e => {
            showErrorToast(IntlMessage("E0010"))
        })
    }
});

const teams = new CustomStore({
    key: "TeamID",
    load: function (loadOptions) {
        return axios.get(`/api/Team?search=${loadOptions.searchValue}`).then((x) => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        });
    },
    byKey: function (k) { }
});

const teamTypes = new CustomStore({
    key: "TeamTypeID",
    load: function (loadOptions) {
        return axios.get(`/api/TeamType?search=${loadOptions.searchValue}`).then((x) => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        });
    },
    byKey: function (k) { }
});

const userStore = new CustomStore({
    key: "UserId",
    load: function (loadOptions) {
        return axios.get(`/api/User?search=${loadOptions.searchValue}`).then((x) => {
            return {
                data: x.data,
                totalCount: x.data.length,
            }
        });
    },
    update: function (key, val) {
        return axios.put("/api/User", {
            UserId: key,
            ...val
        }).then((x) => {
            showSuccessToast(IntlMessage("S0005"))
        }).catch((e) => {
            showErrorToast(IntlMessage("E0007"))
        })
    },
    insert: function (row) {
        return axios.post("/api/User", row).then(x => {
            showSuccessToast(IntlMessage("S0001"))
        }).catch(e => {
            showErrorToast(IntlMessage("E0001"))
        })
    },
    remove: function (key) {
        return axios.delete(`/api/User?UserId=${key}`,).then((x) => {
            showSuccessToast(IntlMessage("S0006"))
        }).catch((x) => {
            showErrorToast(IntlMessage("E0005"))
        })
    }
});

export const rolesStore = new CustomStore({
    // key: "RoleId",
    load: function (loadOptions) {
        return axios.get("/api/Roles/GetRoleFunc").then((x) => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        }).catch((e) => {
            showErrorToast(IntlMessage("E0006"))
        })
    },
    remove: function (key) {
        axios.delete(`api/Roles?key=${key}`).then(x => {
            showSuccessToast(IntlMessage("S0007"))
        }).catch(e => {
            showErrorToast(IntlMessage("E0009"))
        })
    },
    byKey: function (id) {
        axios.get(`/api/Roles/GetSingle?id=${id}`).then(resp => {
            console.log("🚀 ~ file: liveStores.jsx ~ line 134 ~ axios.get ~ resp", resp)
        }).catch(err => {
            console.log("🚀 ~ file: liveStores.jsx ~ line 136 ~ axios.get ~ err", err)
        })
    }
});

const rolesList = new CustomStore({
    key: "RoleId",
    load: function (loadOptions) {
        return axios.get(`/api/Roles/RoleList?search=${loadOptions.searchValue}`).then(x => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        }).catch(e => {
            showErrorToast(IntlMessage("E0010"))
        })
    },
    byKey: function (key) {
        return axios.get(`/api/Roles/GetSingle?id=${key}`).then(x => {
            return x.data
        }).catch(e => {
            showErrorToast(IntlMessage("E0010"))
        })
    }
});

export const rolesListFull = new CustomStore({
    key: "RoleId",
    load: function (loadOptions) {
        return axios.get(`/api/Roles/`).then(x => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        }).catch(e => {
            showErrorToast(IntlMessage("E0010"))
        })
    },
    remove: function (key) {
        axios.delete(`/api/Roles?id=${key}`).then(resp => {
            showSuccessToast("Role deleted")
        })
            .catch(err => {
                showErrorToast("Failed to delete role")
            })
    },
    byKey: function (key) {
    }
})

export const functionsListFull = new CustomStore({
    key: "FunctionId",
    load: function (loadOptions) {
        return axios.get(`/api/Functions/List?topOnly=false&search=${loadOptions.searchValue}`).then(x => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        }).catch(e => {
            showErrorToast(IntlMessage("E0010"))
        })
    },
});

export const subFunctions = new CustomStore({
    key: "FunctionId",
    load: function (loadOptions) {
        return axios.get("/api/Functions/SubList").then(x => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        }).catch(e => {
            showErrorToast(IntlMessage("E0010"))
        })
    }
})

const functionsList = new CustomStore({
    key: "FunctionId",
    load: function (loadOptions) {
        return axios.get(`/api/Functions/List2?search=${loadOptions.searchValue}`).then(x => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        })
    },
    remove: function (key) {
        axios.delete(`api/Functions?key=${key}`).then(x => {
            showSuccessToast(IntlMessage("S0007"))
        })
    }
});

export const TeamStore = new CustomStore({
    key: "TeamID",
    load: function (loadOptions) {
        return axios.get("/api/Team").then((x) => {
            return {
                data: x.data,
                totalCount: x.data.length,
            }
        });
    },
    update: function (key, val) {
        return axios.put("/api/Team", {
            TeamID: key,
            ...val
        }).then((x) => {
            showSuccessToast(IntlMessage("S0003"))
        }).catch((e) => {
            showErrorToast(IntlMessage("E0003"))
        })
    },
    remove: function (key) {
        return axios.delete(`api/Team?id=${key}`).then((x) => {
            showSuccessToast(IntlMessage("S0004"))
        }).catch((x) => {
            showErrorToast(IntlMessage("E0004"))
        })
    }
});

export const StatusStore = new CustomStore({
    key: "StatusID",
    load: function () {
        return axios.get("/api/Status").then(x => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        }).catch(err => {
            return {
                data: [],
                totalCount: 0
            }
        })
    },
    byKey: function (id) {
        return axios.get(`/api/Status?id=${id}`).then(x => {
            return x.data
        }).catch(err => {
            console.log("🚀 ~ file: liveStores.jsx ~ line 275 ~ returnaxios.get ~ err", err)
        })
    }
})


export const ProjectStatusStore = new CustomStore({
    load: function () {
        return axios.get("/api/Status/GetProjectStatus").then(x => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        }).catch(err => {
            return {
                data: [],
                totalCount: 0
            }
        })
    }
})

export const UserOrgRoles = new CustomStore({
    // key: ['OrganizationID', 'RoleId'],
    key: "sKey",
    load: function (loadOptions) {
        return axios.get(`/api/User/GetOrgRolesFlat?UserId=${loadOptions.searchValue}`)
            .then(x => {
                return {
                    data: x.data,
                    totalCount: x.data.length
                }
            })
            .catch(err => {
                errorMessage(err)
            })
    },
    insert: function (data) {
        return axios.post("/api/User/RoleAdd", { OrgId: data.OrganizationID, RoleId: data.RoleId, UserId: data.UserId })
    },
    remove: function (data) {
        console.log("🚀 ~ file: liveStores.jsx ~ line 283 ~ data", data)
        return axios.delete("/api/User/RoleDelete", {
            data: { OrgId: data.OrganizationID, RoleId: data.RoleId, UserId: data.UserId }
        })
    },
    update: function (key, val) {
        return axios.put("/api/User/RoleUpdate", {
            sKey: key,
            OrganizationID: val.OrganizationID,
            RoleId: val.RoleId,
            UserId: val.UserId
        })
    },
    byKey: function (key) {
        console.log("🚀 ~ file: liveStores.jsx ~ line 271 ~ key", key)
    }
})

export const ContactTypes = new CustomStore({
    key: "ContactTypeID",
    load: function (loadOptions) {
        return axios.get(`/api/Contacts/ContactTypes?search=${loadOptions.searchValue}`)
            .then(x => {
                return {
                    data: x.data,
                    totalCount: x.data.length
                }
            })
            .catch(err => {
                errorMessage(err)
                return {
                    data: [],
                    totalCount: 0
                }
            })
    },
    byKey: function (id) {
        return axios.get(`/api/Contacts/ContactTypes?search=''&key=${id}`)
            .then(x => {
                return x.data
            })
            .catch(err => {
                errorMessage(err)
            })
    }
})

export const PaymentTypes = new CustomStore({
    key: "PaymentTypeID",
    load: function (loadOptions) {
        return axios.get(`/api/Payment/GetPaymentTypes?search=${loadOptions.searchValue}`).then(x => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        }).catch(err => {
        })
    },
    byKey: function (id) {
        return axios.get(`/api/Payment/GetPaymentTypes?search=''&key=${id}`).then(x => {
            return x.data
        }).catch(err => {
            console.log("🚀 ~ file: liveStores.jsx ~ line 356 ~ returnaxios.get ~ err", err)
        })
    }
})

export const ContactRoles = new CustomStore({
    key: "ContactRoleID",
    load: function (loadOptions) {
        return axios.get(`/api/Contacts/ContactRoles?search=${loadOptions.searchValue}`)
            .then(x => {
                return {
                    data: x.data,
                    totalCount: x.data.length
                }
            })
            .catch(err => {
                errorMessage(err)
                return {
                    data: [],
                    totalCount: 0
                }
            })
    },
    byKey: function (id) {
        return axios.get(`/api/Contacts/ContactRoles?search=''&key=${id}`)
            .then(x => {
                return x.data
            })
            .catch(err => {
                errorMessage(err)
            })
    }
})


export { contacts, functionsList, rolesList, teamTypes, teams, userStore };

