//** Manage User roles i.e. Assign existing users to existing roles */
import { rolesListFull } from '@data/liveStores';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageContainer';
import { useJumboTheme } from '@jumbo/hooks';
import { AddCircleOutlineOutlined, Edit, VpnKey } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import { Column, Editing, Pager, Paging, Scrolling, Selection, TreeList } from 'devextreme-react/tree-list';
import { useEffect, useRef, useState } from 'react';
import { roleStyles } from 'theme/cashflowsTheme';
import AddNewRole from '../Partials/AddNewRole';
import OneTab from '../Partials/OneTab';
import FunctionsBody from './Functions';

const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'User Role Manager', isActive: true },
];

const breadcrumbsFunctions = [
    { label: 'Home', link: '/' },
    { label: 'User Function Manager', isActive: true },
];

const allowedPageSizes = [10, 20, 50];

// https://js.devexpress.com/Demos/WidgetsGallery/Demo/TreeList/SimpleArrayHierarchicalStructure/React/Light/
function RolesBody(props) {
    const { theme } = useJumboTheme()
    const classes = roleStyles(theme);
    const tree = useRef();
    const [selectedRoleID, setSelectedRoleID] = useState(0);

    return (
        <>
            <TreeList
                ref={tree}
                style={{
                    ...classes.root, ...classes.rolestable
                }}
                dataSource={rolesListFull}
                showBorders={true}
                columnAutoWidth={true}
                // showBorders={true}
                showRowLines={true}
                keyExpr="RoleId"
                itemsExpr={"items"}
                onRowClick={x => {
                    setSelectedRoleID(x.data.RoleId)
                }}
                onRowRemoved={function (e) {
                    let { component, data, key } = e
                    component.refresh()
                }}
                // parentIdExpr="RoleParent" //* sub tasks parent ket id */
                dataStructure='tree'
                id="UsrRoleMgr" >
                <Selection mode="single" />
                <Editing
                    allowDeleting={true}
                    mode="row" />
                <Scrolling
                    mode="standard" />
                <Paging
                    enabled={true}
                    defaultPageSize={20} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showInfo={true} />
                <Column dataField="RoleCode" caption="Role Code" />
                <Column dataField="RoleDescription" />
            </TreeList>
            <AddNewRole
                mode={props.editMode}
                selectedRole={selectedRoleID}
                show={props.showNewRole}
                onClose={(refresh) => {
                    if (refresh) tree.current.instance.refresh();
                    props.setShowNewRole(false)
                    props.setEditMode("new")
                }} />
        </>
    );
}

/**
 * Shows Functions Tab
 * @param {*} props 
 * @returns 
 */
export function UserFunctionsManager(props) {
    const [showNewFunc, setShowNewFunc] = useState(false);
    const [editMode, setEditMode] = useState("new");
    const editBtn = useRef(null)

    useEffect(() => {
        const listener = x => {
            editBtn.current.click()
        }
        document.addEventListener("dblclick", listener)
        return () => {
            document.removeEventListener("dblclick", listener)
        }
    }, [])

    const { theme } = useJumboTheme()
    const classes = roleStyles(theme);
    return (
        <PageContainer
            breadcrumbs={breadcrumbsFunctions}>
            <GridContainer>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box sx={classes.root} width={1}>
                        <OneTab
                            child={
                                <FunctionsBody
                                    setShowAddDlg={setShowNewFunc}
                                    showNewFunc={showNewFunc}
                                    editMode={editMode}
                                    setEditMode={setEditMode}
                                />}
                            label="Function Manager"
                            iconTitle={"Functions Manager"}
                            iconSubTitle={"Current Functions & Actions menus"}
                            addButton={true}
                            addButtonfx={
                                <IconButton
                                    size="small"
                                    title={"Add new function"}
                                    onClick={(e) => {
                                        setShowNewFunc(true);
                                    }}>
                                    <AddCircleOutlineOutlined fontSize="medium" />
                                </IconButton>
                            }
                            editButtonfx={
                                <IconButton
                                    ref={editBtn}
                                    size="small"
                                    onClick={x => {
                                        setShowNewFunc(true);
                                        setEditMode("edit")
                                    }}>
                                    <Edit fontSize="medium" />
                                </IconButton>
                            }
                            icon={<VpnKey />}
                        />
                    </Box>
                </Grid>
            </GridContainer>
        </PageContainer>);
}

/**
 * Shows 1 tab role manager
 * @param {*} props 
 * @returns 
 */
export default function UserRoleManager(props) {
    const [showNewRole, setShowNewRole] = useState(false);
    const [editMode, setEditMode] = useState("new");
    const editBtn = useRef(null)

    useEffect(() => {
        const listener = x => {
            editBtn.current.click()
        }
        document.addEventListener("dblclick", listener)
        return () => {
            document.removeEventListener("dblclick", listener)
        }
    }, [])

    const { theme } = useJumboTheme()
    const classes = roleStyles(theme);
    return (
        <PageContainer breadcrumbs={breadcrumbs}>
            <GridContainer>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box sx={classes.root} width={1}>
                        <OneTab
                            child={
                                <RolesBody
                                    props={props}
                                    setShowNewRole={setShowNewRole}
                                    showNewRole={showNewRole}
                                    editMode={editMode}
                                    setEditMode={setEditMode}
                                />}
                            label="Role Manager"
                            iconTitle={"Roles Manager"}
                            iconSubTitle={"Current Roles"}
                            addButton={true}
                            addButtonfx={
                                <IconButton
                                    size="small"
                                    title={"Add new role"}
                                    onClick={(e) => {
                                        setShowNewRole(true);
                                    }}>
                                    <AddCircleOutlineOutlined fontSize="medium" />
                                </IconButton>
                            }
                            editButtonfx={
                                <IconButton
                                    ref={editBtn}
                                    size="small"
                                    onClick={x => {
                                        setShowNewRole(true)
                                        setEditMode("edit")
                                    }}>
                                    <Edit fontSize="medium" />
                                </IconButton>
                            }
                            icon={<VpnKey />}
                        />
                    </Box>
                </Grid>
            </GridContainer>
        </PageContainer>);
}
