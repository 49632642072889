import axios from "axios";
import { atom, } from "recoil";

const hiddenProjects = atom({
    key: 'hiddenProjects',
    default: [],
});

async function loadPreferences() {
    var resp = await axios.post("/api/Login/GetPrefs")
    // resp.data
    console.log("🚀 ~ file: localStore.jsx ~ line 12 ~ loadPreferences ~ resp.data", resp.data)
    if (resp.data.length < 1) return {
        'hiddenProjects': []
    }
    return {
        pref: JSON.parse(resp.data.pref),
        popupOrg: resp.data.popupOrg
    }
}

async function savePreferences(json) {
    console.log("🚀 ~ file: localStore.jsx ~ line 20 ~ savePreferences ~ json", json)
    var resp = await axios.post("/api/Login/SavePrefs", { json: JSON.stringify(json) })
    if (resp.status == 200) {
    } else {
        throw "Could not save preferences"
    }
}

const newProjData = atom({
    key: 'newProject', default: {}
})

const projSaveState = atom({
    key: 'projSaveState', default: true
})

const userOrgDlg = atom({
    key: "userOrgDlg", default: false
})

const _userInvited = atom({
    key: "userInvited", default: {}
})

export const innerTab = atom({
    key: "innerTab", default: "0"
})

export const reloadOrg = atom({
    key: 'reloadOrg', default: {
        reload: false
    }
})

export const projectInnerTab = atom({
    key: "projectInnerTab", default: "0"
})


export { hiddenProjects, loadPreferences, savePreferences, newProjData, projSaveState, userOrgDlg, _userInvited }
