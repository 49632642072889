import { useJumboTheme } from '@jumbo/hooks';
import { Alert, AlertTitle, Button, CircularProgress, Dialog, DialogActions, DialogContent, Snackbar } from '@mui/material';
import { errorMessage } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import { isEmpty, isNil, isNull } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { buttonStyles } from 'theme/cashflowsTheme';
import { array, boolean, date, number, object, string } from 'yup';
import { newProjData, projSaveState } from '../../../@data/localStore';
import BlogContext from '../../../@data/projectStore';
import { m5 } from '../Partials/Mui5';
import AddNewProject from '../Partials/Project/AddNewProject';
import Steppers from '../Partials/Stepper';
import { PageLoader } from '../auth/SignIn';

const max_steps = 4

export default function ProjectDetail(props) {
    const projData = useRecoilValue(newProjData)
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(false)
    const [errMessage, setErrorMessage] = useState("")
    const [projSaveState1] = useRecoilState(projSaveState)
    const [step, setStep] = useState(1)
    const confirm = useConfirm()
    const { loadProjData } = useContext(BlogContext)
    const { theme } = useJumboTheme()
    const styles = m5(theme)
    const btnStyle = buttonStyles(theme)
    const [loading, setLoading] = useState(false)

    const back = (e) => {
        e.preventDefault();
        if (step <= 1) return
        setStep(step - 1)
    }
    const next = (e) => {
        console.log(projData, 'DATA')
        e.preventDefault();
        validation1.validate(projData).then(then => {
            setErrorMessage("")
            setStep(step + 1)
        }).catch((err) => {
            console.error("Validation error:", err);
            setErrorMessage(err.errors);
            setError(true);
        })
        if (step >= max_steps) return
    }
    useEffect(() => {
        setStep(1)
    }, [props.show])
    const validation = object({
        ProjectOrg: object().required(),
        StatusID: number().required().min(0).positive().integer(),
        EstConstructionStart: date().required(),
        Active: boolean().required(),
        SortOrder: number().required().min(0).positive().integer(),
        ProjectContacts: array().required("At least a default developer is required").min(1)
    })
    const validation1 = object({
        ProjectValue: number().required("Project Value is required").min(0).positive('Project Value must be a positive number'),
        ProjectShortCode: string().required("Project Short Code is required").length(3),
        Project: string().required("Project Name is required").min(3),
        // projectAddress: object().required("Valid Project Address is required"),
    })


    async function saveProject() {
        //verify prject data!
        validation.validate(projData)
            .then(resp => {
                setErrorMessage("")
                //upload project info
                //show loading here:
                setLoading(true)
                axios.post('/api/Project/Save', {
                    Project: projData['Project'],
                    ProjectShortCode: projData['ProjectShortCode'],
                    ProjectValue: isNil(projData['ProjectValue']) ? 0 : projData['ProjectValue'],
                    ProjectOrg: projData['ProjectOrg'].OrganisationID,
                    StatusID: projData['StatusID'],
                    EstConstructionStart: projData['EstConstructionStart'],
                    EstFinishDate: projData['EstFinishDate'],
                    Active: true,
                    SortOrder: 1,
                    EstConstructionDuration: 100,
                    ProjectContacts: projData['ProjectContacts'],
                    projectAddress: projData['projectAddress'] === undefined ? null : projData['projectAddress'],
                    billingAddress: projData['billingAddress'] === undefined ? null : projData['billingAddress'],
                    MaxBuilderPercent: projData['MaxBuilderPercent']
                }).then((resp) => {
                    if (projData['attachment'] != null) {
                        console.log('Uploading attachment')
                        uploadAttachment(resp.data['ProjectID'])
                    } else {
                        setOpen(true)
                        //close popup
                        props.onClose(false)
                    }
                    setStep(1)
                    loadProjData()
                    setLoading(false)
                }).catch(err => {
                    // console.log("🚀 ~ file: ProjectDetails.jsx ~ line 67 ~ saveProject ~ err", err)
                    const txt = errorMessage(err)
                    console.log("🚀 ~ file: ProjectDetails.jsx:104 ~ saveProject ~ errorMessage(err):", txt)
                    setErrorMessage([txt])
                    setError(true)
                    setLoading(false)
                })
            }).catch(err => {
                setErrorMessage(err.errors)
                setError(true)
                setLoading(false)
            })
    }

    function uploadAttachment(pid) {
        var f = new FormData();
        if (isNull(projData['attachment'])) {
            setOpen(true)
            return
        }
        f.append('ProjectID', pid)
        f.append('type', 'project')
        f.append('attachment', projData['attachment'])

        axios.post("/api/Attachments/UploadAttachment", f
            , {
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Accept': '*/*',
                    'Accept-Encoding': 'gzip, deflate, br'
                }
            }).then(resp => {
                setOpen(true)
                //close popup
                props.onClose(false)
            }).catch(err => {
                console.error("🚀 ~ file: ProjectDetails.jsx ~ line 68 ~ uploadAttachment ~ err", err)
                setError(true)
            })
    }

    return (
        <Dialog
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            sx={styles.dialog}
            maxWidth={'lg'}
            open={props.show}>
            <DialogContent
                sx={styles.root}>
                <Steppers step={step - 1} stepLabels={['Project Details', 'Add Financier', 'Add Quantity Suveyor', 'Add Head Contractor']} />
                <AddNewProject step={step} />
                {loading && <PageLoader />}
            </DialogContent>
            <DialogActions disableSpacing>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='info'
                    disabled={step == 1}
                    onClick={(e) => back(e)}>Back</Button>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='info'
                    disabled={step == max_steps}
                    onClick={(e) => {
                        next(e)
                    }}>Next</Button>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='inherit'
                    onClick={x => {
                        var confirmationMessage = "All data related to this project will be discarded, do you want to continue?";
                        confirm({
                            allowClose: false,
                            description: confirmationMessage,
                            title: "Confirm action",
                        }).then(resp => {
                            props.onClose(false)
                        }).catch((e) => {
                            console.error("🚀 ~ file: ProjectDetails.jsx:157 ~ ProjectDetail ~ e:", e)
                        })
                    }}>Cancel</Button>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='info'
                    disabled={projSaveState1}
                    onClick={async x => {
                        await saveProject()
                    }}>Save</Button>
            </DialogActions>
            <Snackbar open={open} autoHideDuration={6000} onClose={x => setOpen(false)}>
                <Alert severity="success">
                    Project saved
                </Alert>
            </Snackbar>
            <Snackbar open={error} autoHideDuration={6000} onClose={x => setError(false)}>
                <Alert severity="error">
                    <AlertTitle><strong>Failed to save Project</strong></AlertTitle>
                    {(!isNil(errMessage) && isEmpty(errMessage)) ? "Failed to save Project info" : errMessage[0]}
                </Alert>
            </Snackbar>
        </Dialog>)
}
