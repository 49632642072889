import axios from 'axios';
import { get, isNil, isNull } from 'lodash';
import { createContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import tables from './Tables.json';
import { reloadOrg } from './localStore';
import { useEffect } from 'react';
import { useState } from 'react';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

export default function DataLoader(d, k) {
    switch (d) {
        case "tables":
            if (isNull(tables) === false) {
                return get(tables, k);
            }
            break;
        default:
            break;
    }
}

export const OrgContext = createContext({})

export const OrgProvider = ({ children }) => {
    const [orgs, setOrgs] = useState([])
    const [contactsTypes, setContactTypes] = useState([])
    const reloadOrgVal = useRecoilValue(reloadOrg)
    const { authUser } = useJumboAuth()

    useEffect(() => {
        if(isNil(authUser)) return
        axios.get(`/api/Organisation`).then((x) => {
            setOrgs(x.data)
        }).catch(e => {
        })
        axios.get(`/api/Contacts/ContactTypes`).then(res => {
            setContactTypes(res.data)
        }).catch(e => {
            console.log("🚀 ~ axios.get ~ e:", e)
        })
    }, [reloadOrgVal.reload, authUser])

    const orgData = useMemo(() => {
        return orgs
    }, [reloadOrgVal.reload, orgs, authUser])

    const contactTypes1 = useMemo(() => {
        return contactsTypes
    }, [contactsTypes])

    return <OrgContext.Provider value={{ orgData, contactTypes: contactTypes1 }}>
        {children}
    </OrgContext.Provider>
}
