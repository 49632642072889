import GridContainer from '@jumbo/components/GridContainer';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { errorMessage } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import { isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { buttonStyles } from 'theme/cashflowsTheme';
import Dropdown from '../Partials/DropBox';
import { m5 } from '../Partials/Mui5';
import { showErrorToast } from '../Partials/Notify';

export default function AddEditProjectBankAccount(props) {
    const [bankAccountRow, setBankAccountRow] = useState({})
    const [bankAccountData, setBankAccountData] = useState([])
    const [bankAccount, setBankAccount] = useState(0)
    const confirm = useConfirm()
    const { theme } = useJumboTheme()
    const classes = m5(theme)
    const btnStyles = buttonStyles(theme)
    const [submit, setSumbit] = useState(false)
    // const [startDate, setStartDate] = useState(null)
    // const [endDate, setEndDate] = useState(null)
    // const [endDateErr, setEndDateErr] = useState("")
    // const [errorTxt, setErrorTxt] = useState("")
    const [userOrg, setUserOrg] = useState(0)

    useEffect(() => {
        //pull info logged in user id
        if (userOrg == 0) {
            axios.get("api/Organisation/MyOrgs").then(resp => {
                setUserOrg(resp.data.OrganisationID)
                axios.get(`/api/Payment/GetOrgAccounts?OrgID=${resp.data.OrganisationID}`).then(resp => {
                    setBankAccountData(resp.data)
                }).catch(err => {
                    console.error("🚀 ~ file: ProjectBankAccount.jsx ~ line 57 ~ axios.get ~ err", err)
                })
            }).catch(err => {
                console.error(err)
            })
        }
        if (userOrg != 0) {
            axios.get(`/api/Payment/GetOrgAccounts?OrgID=${userOrg}`).then(resp => {
                setBankAccountData(resp.data)
            }).catch(err => {
                console.error("🚀 ~ file: ProjectBankAccount.jsx ~ line 57 ~ axios.get ~ err", err)
            })
        }
    }, [props.refresh])

    useEffect(() => {
        if (props.mode === "new") {
            setBankAccountRow(prev => ({
                ...prev,
                ProjectID: props.projID,
                StartDate: new Date(),
                StatusID: 13,
                EndDate: null,
                BankAccountID: -1,
                MapProjectBankAccountID: -1
            }))
            setBankAccount(-1)
            // setStartDate(new Date())
            // setEndDate(null)
        }
        else if (props.mode === "edit" && props.mapProjAccountID > 0 && props.show) {
            axios.get(`/api/Project/GetProjectBankAccount?key=${props.mapProjAccountID}`)
                .then(({ data }) => {
                    console.log("🚀 ~ file: ProjectBankAccount.jsx ~ line 76 ~  ~ props", data)
                    setBankAccountRow(prev => ({
                        ...prev,
                        ProjectID: props.projID,
                        StartDate: new Date(data.StartDate),
                        StatusID: data.StatusID,
                        EndDate: !isNil(data.EndDate) ? new Date(data.EndDate) : null,
                        BankAccountID: data.BankAccountID,
                        MapProjectBankAccountID: data.MapProjectBankAccountID
                    }))
                    setBankAccount(data.BankAccountID)
                    // setStartDate(new Date(data.StartDate))
                    // if (!isNil(data.EndDate)) {
                    //     setEndDate(new Date(data.EndDate))
                    // } else {
                    //     setEndDate(null)
                    // }
                })
                .catch(err => {
                })
        }
        // setErrorTxt("")
    }, [props.mode, props.show])

    const onSave = async (type) => {
        try {
            let d = {
                "ProjectID": bankAccountRow.ProjectID,
                "StartDate": bankAccountRow.StartDate,
                'BankAccountID': bankAccount,
                "StatusID": 13, //Draft
                'EndDate': bankAccountRow.EndDate,
                "MapProjectBankAccountID": bankAccountRow.MapProjectBankAccountID
            }
            if (type === "save") {
                //d.StatusID = 58 //"Submitted"
                //send to server
                axios.post('/api/Project/AddProjectBankAccount', { ...d }).then(resp => {
                    props.onClose(true)
                }).catch(e => {
                    showErrorToast(errorMessage(e))
                    // e.response.statusText
                })
            } else if (type === "draft") {
                d.Status = "Draft"
            }
        }
        catch (err) {
            console.error(err);
            // setError("failed")
        }
    }
    // Generate the current date and time in the format "ddmmyyhhmmss"
    const currentDate = new Date();
    const formattedDate = currentDate
        .toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })
        .replace(/\/|,|:| /g, ''); // Remove special characters
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
    let time = formattedDate.concat(milliseconds);

    return (
        <Dialog sx={classes.dialog}
            maxWidth={'lg'}
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            open={props.show}>
            <DialogTitle sx={{ ...classes.root, ...classes.dlgTitle }}
                disableTypography={true}
                children={
                    props.mode === "new" ? <IntlMessages id="ProjectBankAccount.DetailDlg.Title" /> : <IntlMessages id="ProjectBankAccount.DetailDlg.Title" />
                } />
            {/* remove scrollbar */}
            <DialogContent sx={{ ...classes.root, ...classes.dlgContent }}>
                <GridContainer>
                    <Grid item lg={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Box sx={{
                                    fontSize: "0.90rem",
                                    fontWeight: "normal",
                                }}>
                                    <Dropdown
                                        size={'small'}
                                        placeholder="Select an Bank Account"
                                        data={bankAccountData}
                                        value={bankAccount}
                                        onChange={setBankAccount}
                                        mapping={x => {
                                            // console.log("🚀 ~ file: AddPayment.jsx:263 ~ AddPayment ~ x.StatusID:", x)
                                            return { value: x.BankAccountID, label: x.AccountName, isDisabled: x.Active == false }
                                        }}
                                        sx={{ ...classes.root, ...classes.formControl }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        format="dd/MM/yyyy HH:mm"
                                        formatDensity='dense'
                                        id="date-picker-inline"
                                        label="Start Date"
                                        timezone='system'
                                        value={bankAccountRow.StartDate}
                                        variant={'inline'}
                                        maxDate={bankAccountRow.EndDate ?? moment(new Date()).add(1, 'y').toDate()}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Change date',
                                        }}
                                        sx={{
                                            ...classes.root, ...classes.formControl
                                        }}
                                        onChange={x => {
                                            setBankAccountRow(prev => ({
                                                ...prev,
                                                StartDate: x
                                            }))
                                        }}
                                    /></LocalizationProvider>
                            </Grid>
                            <Grid item xs={3} >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        format="dd/MM/yyyy HH:mm"
                                        formatDensity='dense'
                                        id="date-picker-inline"
                                        label="End Date"
                                        timezone='system'
                                        value={bankAccountRow.EndDate}
                                        variant={'inline'}
                                        onOpen={x => {
                                            setBankAccountRow(prev => ({
                                                ...prev,
                                                EndDate: Date.now()
                                            }))
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Change date',
                                        }}
                                        sx={{
                                            ...classes.root, ...classes.formControl
                                        }}
                                        minDate={bankAccountRow.StartDate}
                                        onChange={x => {
                                            // const now = moment()
                                            // const d = moment(x).hours(now.hours()).minutes(now.minutes()).seconds(now.seconds()).toDate()
                                            setBankAccountRow(prev => ({
                                                ...prev,
                                                EndDate: x
                                            }))
                                        }}
                                    /></LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>

                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    tabIndex={9}
                    sx={btnStyles.button}
                    color='info'
                    variant='contained'
                    disabled={submit}
                    onClick={x => {
                        const confirmationMessage = "Do you want create this account?";
                        confirm({
                            allowClose: true,
                            description: confirmationMessage,
                            title: "Confirm action",
                        }).then(async resp => {
                            setSumbit(true)
                            onSave("save").then(resp => {
                                setSumbit(false)
                            }).catch(err => {
                                setSumbit(false)
                            })
                        }).catch((e) => {
                            console.error("🚀 ~ file: Add Payment.jsx:312 ~ Add Payment ~ e", e)
                        })
                    }}>Save</Button>
                <Button
                    tabIndex={10}
                    sx={btnStyles.button}
                    color='inherit'
                    variant='contained'
                    onClick={x => {
                        props.onClose(false)
                    }}>Cancel</Button>
            </DialogActions>
        </Dialog>)
}
