//SX Prop for Mui5 Components
export const m5 = theme => ({
    dlgContent: {
        overflowY: "initial",
        

    },
    paperDialogContent : {
        height:'45px',
        overflowY: "hidden",
    },
    dialog: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
    },
    root: {
        fontWeight: theme.typography.fontWeight,
        fontFamily: theme.typography.fontFamily,
        '& .MuiInputBase-root': {
            fontSize: "0.85rem",
            lineHeight: "1.0rem",
        },
        margin: "0 5px"
    },
    formControl: {
        fontSize: '0.85rem',
        // backgroundColor: 'green',
        // color:'blue'
    },
    dlgTitle: {
        fontSize: '1.2em !important',
        fontWeight: 'bold !important',
        lineHeight: '1.2em !important',
        color: "rgba(33, 33, 33, 0.75)"
    },
    table: {
        '& .dx-datagrid': {
            backgroundColor: theme.palette.background.paper,
            fontFamily: theme.typography.fontFamily
        },
    },
    toolbar: {
        '& .dx-datagrid-header-panel': {
            backgroundColor: theme.palette.background.paper
        },
        '& .dx-toolbar': {
            backgroundColor: theme.palette.background.paper
        }
    },
    cRoot: {
        backgroundColor: theme.palette.background.paper,
        marginTop: '0.85rem'
    },
    cardRoot: {
        position: 'relative',
        '& .Cmt-card-content': {
            padding: 0,
            paddingBottom: 24,
        },
    },
    scrollbarRoot: {
        height: 280,
    },
    cardHeaderRoot: {
        padding: "10px 24px",
    },
    badgeRoot: {
        fontSize: 14,
        letterSpacing: 0.25,
        color: theme.palette.warning.main,
        padding: '2px 10px',
        borderRadius: 30,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    notificationConfirmationButton: {
        fontSize: '11px',
        margin: '0 15px 0 0',
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 'bold',
        maxHeight: "35px",
        backgroundColor: '#2EB5C9',
        color: '#FFF',
        "&:hover": {
            backgroundColor: "#008598"
        },
        marginBottom:'5px'
    },
    label: {
        fontWeight: 600,
        fontSize: '0.90rem',
        // backgroundColor:'red'
    },
    error: {
        fontWeight:'lighter', 
        fontSize:'0.82rem',
        color:'#E73145', 
        marginTop:'5px',
        fontFamily: theme.typography.fontFamily,
    },
    headerText : {
        paddingRight:'1.0rem',
        textTransform:'capitalize',
        color:'white',
        fontWeight: 500,
        fontSize: '1.25rem',
    },
    stepper: {
        '& .MuiStepConnector-root': {
          position: 'absolute',
          top: '18px',
        },
        '& .MuiStepIcon-root, & .Mui-active .MuiStepIcon-root, & .Mui-completed .MuiStepIcon-root': {
          fontSize: '2.5rem',
          color: '#E0E0E0',
        },
        '& .Mui-active .MuiStepIcon-root, & .Mui-completed .MuiStepIcon-root': {
          color: '#2EB5C9',
        },
      }
      
});
