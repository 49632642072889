import { LAYOUT_CONTAINER_STYLES } from "@jumbo/utils/constants/layout";
import { mainTheme } from "../themes/main/default";
import { headerTheme } from "../themes/header/default";
import { sidebarTheme } from "../themes/sidebar/dark";
import { footerTheme } from "../themes/footer/default";
import LAYOUT_NAMES from "../layouts/layouts";
import { createJumboTheme } from "@jumbo/utils";
import authServices from "app/services/auth-services";
import jwtAuthAxios from "app/services/auth/jwtAuth";

const config = {
    defaultLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
    containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,
    authSetting: {
        axiosObject: jwtAuthAxios,
        fallbackPath: "/signin",
        getAuthUserService: authServices.getCurrentUser,
        redirectNotAuthenticatedPath: "/dash"
    },
    theme: createJumboTheme(mainTheme, headerTheme, sidebarTheme, footerTheme),
};

export { config };
