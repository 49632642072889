//** Manage User roles i.e. Assign existing users to existing roles */
import { useJumboTheme } from '@jumbo/hooks';
import { Alert, Box, Button, Snackbar } from '@mui/material';
import axios from 'axios';
import { isEmpty, isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useContext, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { buttonStyles } from 'theme/cashflowsTheme';
import { newProjData } from '../../../@data/localStore';
import { EditorCtx } from '../CurrentProjectsSummary';
import MuiGrid, { TableColumns } from '../Partials/MuiTable';
import Notes from '../Partials/Notes';
import AssessClaim from './AssessClaim';
import ClaimDetails from './ClaimsDetails';
import ReSubmitClaim from './ReSubmitClaim';
import AttachedClaim from './AttachedClaim';
import AttachFileIcon from '@mui/icons-material/AttachFile';

// const onSave = async (type, claimID) => {

//     try {
//         if (type === "infoprovided") {
//             console.log("🚀 ~ file: Claim.jsx ~ line 116 ~ Request ~ save", claimID)
//             //send to server
//             await axios.post(`/api/Project/ProvideMoreInfo?key=${claimID}`);
//         }
//     }
//     catch (err) {
//         console.error(err);
//         // setError("failed")
//     }
// }

export default function ClaimsGrid(props) {
    const { editorProps, setEditorProps, setShowClaims, setUpdateTotal } = useContext(EditorCtx)
    const [showAssessClaim, setshowAssessClaim] = useState(false)
    const [showAttachedClaim, setshowAttachedClaim] = useState(false)
    const [showNewClaim, setshowNewClaim] = useState(false)
    const [editMode, setEditMode] = useState("new")
    const [error, setError] = useState('')
    const [claimsData, setClaimsData] = useState(null)
    const [projData, setProjData] = useRecoilState(newProjData)
    const [selectedRow, setSelectedRow] = useState({})
    const confirm = useConfirm()
    const [showClaimNotes, setshowClaimNotes] = useState(false)
    const { theme } = useJumboTheme()
    const btnStyles = buttonStyles(theme)
    const [showReSubmitClaim, setshowReSubmitClaim] = useState(false)

    //Load data
    useEffect(() => {
        if (!isNil(props.projID) && props.projID > 0) {
            console.log("🚀 ~ file: Claims.jsx:51 ~ useEffect ~ props.projID:", props.projID)
            axios.get(`/api/Project/GetClaimFor?project=${props.projID}`).then(resp => {
                setClaimsData(resp.data)
            }).catch(err => {
                console.error("🚀 ~ file: Claims.jsx ~ line 79 ~ axios.get ~ err", err)
            })
        }
    }, [props.projID])
    const cols = TableColumns("tables.ClaimsTable.header.columns")
    const cols1 = [{
        field: "ClaimNumber",
        headerName: "Claim Number",
        renderCell: ({ row, formattedValue }) => {
            return <>
                <AttachFileIcon
                    style={{
                        display: row.HasAttachment ? 'initial' : 'none',
                        paddingEnd: '5px',
                        fontSize: '18px',
                        transform: 'rotate(45deg)'
                    }} />
                <div style={{
                    display: row.HasAttachment ? 'none' : 'initial',
                    width: '18px'
                }}></div>
                <span>{formattedValue}</span>
            </>
        },
        flex: 1,
        coLSpan: 1,
        hideable: false,
        editable: false
    }, ...cols]

    return <Box>
        <Button
            variant="contained"
            aria-controls="fade-menu"
            onClick={x => {
                setshowNewClaim(true)
                setshowAssessClaim(false)
                setEditMode("new")
                setshowClaimNotes(false)
                setshowReSubmitClaim(false)
            }}
            style={{
                margin: '10px 10px 0'
            }}
            color='info'
            sx={btnStyles.button}
            aria-haspopup="true">New Claim</Button>
        <Button
            disabled={!isEmpty(selectedRow) ? selectedRow.StatusID != 58 : true} //58 : Submitted
            variant="contained"
            aria-controls="fade-menu"
            onClick={x => {
                setshowNewClaim(false)
                setshowAssessClaim(true)
                setEditMode("edit")
                setshowClaimNotes(false)
                setshowReSubmitClaim(false)
            }}
            style={{
                margin: '10px 10px 0'
            }}
            color='info'
            sx={btnStyles.button}
            aria-haspopup="true">Assess Claim</Button>
        <Button
            disabled={!isEmpty(selectedRow) ? selectedRow.StatusID != 68 : true}  //68 : Provide More Info
            variant="contained"
            aria-controls="fade-menu"
            style={{
                margin: '10px 10px 0'
            }}
            onClick={x => {
                var confirmationMessage = "Do you want to Resubmit this claim?";
                confirm({
                    allowClose: true,
                    description: confirmationMessage,
                    title: "Confirm action",
                }).then(resp => {
                    //onSave("infoprovided", selectedRow.ClaimID)
                    setshowNewClaim(false)
                    setshowAssessClaim(false)
                    setEditMode("edit")
                    setshowClaimNotes(false)
                    setshowReSubmitClaim(true)
                }).catch((e) => {
                    console.error("🚀 ~ file: claims.jsx:237 ~ Provide More Info  ~ e", e)
                })
            }}
            color='info'
            sx={btnStyles.button}
            aria-haspopup="true">Provide More Info</Button>

        <Button
            disabled={isEmpty(selectedRow)}
            variant="contained"
            aria-controls="fade-menu"
            onClick={x => {
                setshowNewClaim(false)
                setshowAssessClaim(false)
                setshowClaimNotes(true)
                setshowReSubmitClaim(false)
            }}
            color='info'
            sx={{ ...btnStyles.button, margin: '10px 10px 0' }}
            aria-haspopup="true">Claim History</Button>

        {/* Attached Claims */}
        <Button
            disabled={isEmpty(selectedRow)}
            variant="contained"
            aria-controls="fade-menu"
            onClick={x => {
                setshowNewClaim(false)
                setEditMode("edit")
                setshowClaimNotes(false)
                setshowReSubmitClaim(false)
                setshowAttachedClaim(true)
            }}
            style={{
                margin: '10px 10px 0'
            }}
            color='info'
            sx={btnStyles.button}
            aria-haspopup="true">Claim Attachments</Button>
        <Box sx={{ height: 700, width: '100%' }}>
            <MuiGrid
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                    sorting: {
                        sortModel: [{ field: 'ClaimNumber', sort: 'asc' }],
                    },
                }}
                rows={claimsData ?? []}
                loading={claimsData == null}
                columns={cols1}
                onRowClick={x => {
                    setSelectedRow(x.row)
                }}
                getRowId={(row) => row.ClaimID} />
        </Box>

        <AssessClaim
            show={showAssessClaim}
            projID={props.projID}
            claimID={!isEmpty(selectedRow) ? selectedRow.ClaimID : 0}
            onClose={x => {
                setshowAssessClaim(false)
                if (x && !isNil(props.projID) && props.projID > 0) {
                    axios.get(`/api/Project/GetClaimFor?project=${props.projID}`).then(resp => {
                        console.log("🚀 ~ file: Claims.jsx ~ line 129 ~ axios.get ~ resp.data", resp.data)
                        setClaimsData(resp.data)
                    }).catch(err => {
                        console.error("🚀 ~ file: Claims.jsx ~ line 139 ~ axios.get ~ err", err)
                    })
                    //reload project data
                    setProjData({
                        ...projData,
                        reload: !projData.reload
                    })
                }
            }} />

        <AttachedClaim
            show={showAttachedClaim}
            projID={props.projID}
            claimID={!isEmpty(selectedRow) ? selectedRow.ClaimID : 0}
            onClose={x => {
                setshowAttachedClaim(false)
                if (x && !isNil(props.projID) && props.projID > 0) {
                    axios.get(`/api/Project/GetClaimFor?project=${props.projID}`).then(resp => {
                        setClaimsData(resp.data)
                    }).catch(err => {
                    })
                    //reload project data
                    setProjData({
                        ...projData,
                        reload: !projData.reload
                    })
                }
            }} />

        <ReSubmitClaim
            show={showReSubmitClaim}
            projID={props.projID}
            claimID={!isEmpty(selectedRow) ? selectedRow.ClaimID : 0}
            onClose={x => {
                setshowReSubmitClaim(false)
                if (x && !isNil(props.projID) && props.projID > 0) {
                    axios.get(`/api/Project/GetClaimFor?project=${props.projID}`).then(resp => {
                        console.log("🚀 ~ file: Claims.jsx ~ line 129 ~ axios.get ~ resp.data", resp.data)
                        setClaimsData(resp.data)
                    }).catch(err => {
                        console.error("🚀 ~ file: Claims.jsx ~ line 139 ~ axios.get ~ err", err)
                    })
                    //reload project data
                    setProjData({
                        ...projData,
                        reload: !projData.reload
                    })
                }
            }} />

        <Notes
            //claimID={!isEmpty(selectedRow) ? selectedRow.ClaimID :0 }  
            show={showClaimNotes}
            moduleID={7}
            ID={!isEmpty(selectedRow) ? selectedRow.ClaimID : 0}
            onClose={x => {
                setshowClaimNotes(false)
            }} />
        <ClaimDetails
            show={showNewClaim}
            mode={editMode}
            projID={props.projID}
            projDetails={props.projDetails}
            onClose={x => {
                setshowNewClaim(false)
                if (x && !isNil(props.projID) && props.projID > 0) {
                    axios.get(`/api/Project/GetClaimFor?project=${props.projID}`).then(resp => {
                        console.log("🚀 ~ file: Claims.jsx ~ line 152 ~ axios.get ~ resp.data", resp.data)
                        setClaimsData(resp.data)
                        if (editorProps.dataGrid != null && editorProps.dataGrid.current != null) {
                            try {
                                editorProps.dataGrid.current.instance.refresh()
                            } catch (e) {
                                console.error(e)
                            }
                        }
                        //TODO reload project
                        setProjData({
                            ...projData,
                            reload: !projData.reload
                        })
                    }).catch(err => {
                        console.error("🚀 ~ file: Claims.jsx ~ line 162 ~ axios.get ~ err", err)
                    })
                }
            }} />
        <Snackbar open={error === 'success'} autoHideDuration={6000} onClose={x => setError('closed')}>
            <Alert severity="error">
                Failed to save Claims data, please try again later
            </Alert>
        </Snackbar>
        <Snackbar open={error === 'failed'} autoHideDuration={6000} onClose={x => setError('closed')}>
            <Alert severity="error">
                Failed to save Claims data, please try again later
            </Alert>
        </Snackbar>
    </Box>
}
