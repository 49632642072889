import GridContainer from '@jumbo/components/GridContainer';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { buttonStyles } from 'theme/cashflowsTheme';
import { m5 } from '../Partials/Mui5';
import { EmptyTextarea } from '../Partials/StyledTextArea';
import { isEmpty, isNil } from 'lodash';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const materialUITextFieldProps = x => {
    return {
        label: x,
        multiline: false,
        margin: "dense",
        fullWidth: true,
        size: 'small',
        variant: 'standard'
    }
}

export default function AssessClaim(props) {
    // const [attachment, setAttachment] = useState(null)
    const [claimRow, setClaimRow] = useState({})
    const confirm = useConfirm()
    const { theme } = useJumboTheme()
    const styles = m5(theme)
    const btnStyle = buttonStyles(theme)

    const claim = {
        ClaimID: 0,
        ClaimNumber: '',
        ClaimPeriod: Date.now(),
        ClaimAmount: 0,
        Notes: "",
        RetentionAmount: 0,
        AssessedAmount: 0,
        RetainedNet: 0,
        ApprovedNet: 0,
        MethodOfApproval: '',
        StatusId: 0,
        attachment: null,
        originalNames: {}
    }

    useEffect(() => {
        if (props.claimID > 0 && props.show) {
            axios.get(`/api/Project/GetClaim?key=${props.claimID}`)
                .then(({ data }) => {
                    console.log("🚀 ~ file: AssessClaim.jsx ~ line 62 ~  ~ props", data)
                    claim.ClaimID = data.ClaimID
                    claim.ClaimNumber = data.ClaimNumber
                    claim.ClaimAmount = data.ClaimedAmount
                    claim.Notes = data.Notes
                    claim.ClaimPeriod = data.ClaimPeriod
                    claim.AssessedAmount = data.AssessedAmount
                    claim.RetentionAmount = data.RetentionAmount
                    claim.ClaimNumber = data.ClaimNumber
                    claim.StatusId = data.StatusId
                    claim.attachment = data.attachments
                    claim.originalNames = data?.originalNames ?? {}
                    setClaimRow({
                        ...claim
                    })
                })
                .catch(err => {
                })
        }
    }, [props.claimID, props.show])

    const onSave = async (type) => {
        try {
            if (type === "approve") {
                let d = {
                    "ClaimID": claimRow.ClaimID,
                    "AssessedAmount": numeral(claimRow.AssessedAmount).value(),
                    'Notes': claimRow.Notes,
                    'RetentionAmount': numeral(claimRow.RetentionAmount).value(),
                    'MethodOfApproval': claimRow.MethodOfApproval,
                    'ProjectID': props.projID, //Get ProjectId of selected Project
                    "StatusID": claimRow.StatusID,
                    "ClaimNumber": claimRow.ClaimNumber,
                    "ClaimedAmount": claimRow.ClaimAmount
                }
                console.log("🚀 ~ file: AssessClaim.jsx ~ line 272 ~ Approve ~ save", d)
                //send to server    
                await axios.post('/api/Project/ApproveClaim', { ...d });

                props.onClose(true)
            } else if (type === "reject") {
                let d = {
                    "ClaimID": claimRow.ClaimID,
                    "AssessedAmount": numeral(claimRow.AssessedAmount).value(),
                    'Notes': claimRow.Notes,
                    'RetentionAmount': numeral(claimRow.RetentionAmount).value(),
                    'MethodOfApproval': claimRow.MethodOfApproval,
                    'ProjectID': props.projID, //Get ProjectId of selected Project
                    "StatusID": claimRow.StatusID,
                    "ClaimNumber": claimRow.ClaimNumber,
                    "ClaimedAmount": claimRow.ClaimAmount
                }
                console.log("🚀 ~ file: AssessClaim.jsx ~ line 139 ~ Reject ~ save", d)

                await axios.post('/api/Project/RejectClaim', { ...d });
                props.onClose(true)
            } else if (type === "request") {
                let d = {
                    "ClaimID": claimRow.ClaimID,
                    "AssessedAmount": numeral(claimRow.AssessedAmount).value(),
                    'Notes': claimRow.Notes,
                    'RetentionAmount': numeral(claimRow.RetentionAmount).value(),
                    'MethodOfApproval': claimRow.MethodOfApproval,
                    'ProjectID': props.projID, //Get ProjectId of selected Project
                    "StatusID": claimRow.StatusID,
                    "ClaimNumber": claimRow.ClaimNumber,
                    "ClaimedAmount": claimRow.ClaimAmount
                }
                await axios.post('/api/Project/RequestForMoreInfo', { ...d });

                props.onClose(true)
            }
        }
        catch (err) {
            console.error(err);
            // setError("failed")
        }
    }
    const attachments = () => {
        // console.log("🚀 ~ file: AssessClaim.jsx:135 ~ arr ~ claimRow.attachment:", claimRow.attachment)
        const arr = claimRow.attachment?.map(elem => {
            return <List dense>
            <ListItem >
            <ListItemText>
              <a
                style={{  fontSize: '12px' }}
                href={"images/"+`${elem}`}
                target='_blank'
              >
                {/* {elem} */}
                {claimRow.originalNames[elem]}
              </a>
            </ListItemText>
          </ListItem>
          </List>
        })
        return arr
    }
    // const attachments = () => {
    //     // console.log("🚀 ~ file: AssessClaim.jsx:135 ~ arr ~ claimRow.attachment:", claimRow.attachment)
    //     const arr = claimRow.attachment?.map(elem => {
    //         return <a style={{ color: 'black', fontSize: '12px' }} href={"images/"+`${elem}`} target='_blank'>{elem}</a>
    //     })
    //     return arr
    // }

    return (
        <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            sx={styles.dialog}
            open={props.show}>
            <DialogTitle
                sx={styles.dlgTitle}
                children={
                    <IntlMessages id="AssessClaim.DetailDlg.Title" />
                } />
            {/* remove scrollbar */}
            <DialogContent
                sx={{ ...styles.root, ...styles.dlgContent }}
            >
                <GridContainer>
                    <Grid item lg={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    variant='standard'
                                    size={'small'}
                                    placeholder={'Claim Number'}
                                    value={claimRow.ClaimNumber}
                                    readOnly
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ ...styles.root, ...styles.formControl }}
                                    label="Claim Number" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    variant='standard'
                                    size={'small'}
                                    placeholder={'Claim Period'}
                                    value={moment(claimRow.ClaimPeriod).format('yyyyMM')}
                                    sx={{ ...styles.root, ...styles.formControl }}
                                    label="Claim Period" />
                            </Grid>
                            <Grid item xs={6}>
                                <NumericFormat
                                    value={claimRow.ClaimAmount}
                                    prefix='$'
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    customInput={TextField}
                                    onClick={(event) => {
                                        if (event.target.value === '$0.00') {
                                            event.target.value = '';
                                        }
                                    }}
                                    onFocus={(e) => {
                                        const value = e.target.value;                                   
                                        const dotIndex = value.indexOf('.');
                                        e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                                      }}
                                    {...materialUITextFieldProps("Claim Amount")} />
                            </Grid>
                            <Grid item xs={6}>
                                <NumericFormat
                                    value={claimRow.RetentionAmount}
                                    prefix='$'
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    onClick={(event) => {
                                        if (event.target.value === '$0.00') {
                                            event.target.value = '';
                                        }
                                    }}
                                    onFocus={(e) => {
                                        const value = e.target.value;                                   
                                        const dotIndex = value.indexOf('.');
                                        e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                                      }}
                                    onChange={x => setClaimRow({
                                        ...claimRow,
                                        RetentionAmount: x.target.value
                                    })}
                                    customInput={TextField}
                                    {...materialUITextFieldProps("Retention Amount")} />
                            </Grid>
                            <Grid item xs={6}>
                                <NumericFormat
                                    value={claimRow.AssessedAmount}
                                    prefix='$'
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    onClick={(event) => {
                                        if (event.target.value === '$0.00') {
                                            event.target.value = '';
                                        }
                                    }}
                                    onFocus={(e) => {
                                        const value = e.target.value;                                   
                                        const dotIndex = value.indexOf('.');
                                        e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                                      }}
                                    onChange={x => setClaimRow({
                                        ...claimRow,
                                        AssessedAmount: x.target.value
                                    })}
                                    customInput={TextField}
                                    {...materialUITextFieldProps("Assessed Amount")} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    required={true}
                                    variant='standard'
                                    size={'small'}
                                    placeholder={'Assessed Retention'}
                                    InputLabelProps={{ shrink: true }}
                                    label="Assessed Retention" />
                            </Grid>
                            {/* <Grid item xs={6}>
                                <TextField margin="dense" fullWidth required={true} size={'small'} placeholder={'Method of Approval'} value={claimRow.MethodOfApproval}
                                    className={clsx(classes.root, classes.input, classes.formControl)}
                                    label="Method of Approval" />
                            </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item lg={6}>
                        {/* <div className="fileuploader-container">
                            <FileUploader
                                multiple={false}
                                onValueChange={x => {
                                    console.log("🚀 ~ file: ClaimsDetails.jsx ~ line 272 ~ ClaimDetails ~ x", x[0])
                                    setAttachment(x[0])
                                }}
                                selectButtonText="Select Attachment"
                                accept="image/*"
                                uploadMode="useForm" />
                        </div> */}
                        <div id="attachment">
                            {/* {claimRow.attachment && (
                                <a style={{ fontWeight: 'normal', fontSize: '15px' }}
                                    href={`data:application/octet-stream;base64,${claimRow.attachment?.Filebody}`}
                                    download={claimRow.attachment?.Name}>
                                    {claimRow.attachment?.Name}
                                </a>
                            )} */}
                            {attachments()}
                        </div>
                    </Grid>
                    <Grid item lg={12}>
                        <EmptyTextarea
                            aria-label='Claim Notes'
                            placeholder='Claim Notes'
                            value={claimRow.Notes}
                            onChange={x => {
                                setClaimRow({
                                    ...claimRow,
                                    Notes: x.target.value
                                })
                            }}
                        />
                    </Grid>
                    {/* //TODO Jason asked to disable in mvp */}
                    {/* <Grid item lg={12}>
                        <ClaimEditGrid />
                    </Grid> */}
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='info'
                    onClick={x => {
                        const claimedAmt = numeral(claimRow.ClaimAmount).value()
                        const assessedAmt = numeral(claimRow.AssessedAmount).value()
                        console.log(`🚀 ~ file: AssessClaim.jsx:272 ~ AssessClaim ~ claimedAmt: ${claimedAmt} ~ assessedAmt: ${assessedAmt}`)

                        if (claimedAmt == assessedAmt) {
                            var confirmationMessage = "Please confirm you paying this claim in full?";
                            confirm({
                                allowClose: true,
                                description: confirmationMessage,
                                title: "Confirm action",
                            }).then(resp => {
                                onSave("approve")
                            }).catch((e) => {
                                console.error("🚀 ~ file: AssessClaim.jsx:287 ~ Approve Claim ~ e", e)
                            })
                        }
                        else if (claimedAmt < assessedAmt) {
                            confirm({
                                description: "You cannot pay more than the claimed amount",
                                title: "Claim Amount",
                                hideCancelButton: true
                            }).then(resp => {
                            }).catch(err => {
                            })
                        }
                        else if (claimedAmt > assessedAmt) {
                            var confirmationMessage = "Please confirm that you are partially approving this claim?";
                            confirm({
                                allowClose: true,
                                description: confirmationMessage,
                                title: "Confirm action",
                            }).then(resp => {
                                onSave("approve")
                            }).catch((e) => {
                                console.error("🚀 ~ file: AssessClaim.jsx:309 ~ Partial Approve Claim ~ e", e)
                            })
                        }
                    }}>Approve</Button>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='inherit'
                    onClick={x => {
                        if (isEmpty(claimRow.Notes) || claimRow.Notes.length < 10) {
                            confirm({
                                description: "Please add rejection notes",
                                title: "Claim Notes",
                                hideCancelButton: true
                            }).then(resp => {
                            }).catch(err => {
                            })
                        }
                        else {
                            var confirmationMessage = "Do you want to Reject this claim?";
                            confirm({
                                allowClose: true,
                                description: confirmationMessage,
                                title: "Confirm action",
                            }).then(resp => {
                                onSave("reject")
                            }).catch((e) => {
                            })
                        }
                    }}>Reject</Button>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='inherit'
                    onClick={x => {
                        if (claimRow.Notes === "") {
                            confirm({
                                description: "Please add notes",
                                title: "Claim Notes",
                                hideCancelButton: true,
                            }).then(resp => { }).catch(err => {
                            })
                        }
                        else {
                            var confirmationMessage = "Do you want to Request More Information for this claim?";
                            confirm({
                                allowClose: true,
                                description: confirmationMessage,
                                title: "Confirm action",
                            }).then(resp => {
                                onSave("request")
                            }).catch((e) => {
                                console.error("🚀 ~ file: AssessClaim.jsx:361 ~ Request More Info ~ e", e)
                            })
                        }
                    }}>Request More Info</Button>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='inherit'
                    onClick={x => {
                        props.onClose(false)
                        //subCons.clear()
                    }}>Cancel</Button>
            </DialogActions>
        </Dialog>)
}
