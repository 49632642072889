//** Manage User roles i.e. Assign existing users to existing roles */
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageContainer';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userOrgDlg } from '../../../@data/localStore';
import { EditorCtx } from '../CurrentProjectsSummary';
import OrgDetails from './OrgDetails';

const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Organisation Manager', isActive: true },
];


/**
 * @param {*} props 
 * @returns 
 */
export default function OrgManagerNoGrid(props) {
    // const classes = roleStyles();
    // const [showNewOrg, setShowNewOrg] = useState(true)
    // const [editMode, setEditMode] = useState("edit")
    // const editBtn = useRef(null)
    const [showDlg, setShowDlg] = useRecoilState(userOrgDlg)
    const [userOrg, setUserOrg] = useState({})
    const [editorProps, setEditorProps] = useState({})
    const history = useNavigate()

    useEffect(() => {
        //pull info logged in user id
        axios.get("api/Organisation/MyOrgs").then(resp => {
            setUserOrg(resp.data.OrganisationID)
        }).catch(err => {
            console.error(err)
        })
    }, [])

    return (
        <PageContainer
            breadcrumbs={breadcrumbs}>
            <GridContainer>
                {/* <Typography variant='h4'>Organisation</Typography> */}
                <EditorCtx.Provider value={{ editorProps, setEditorProps }}>
                    <OrgDetails
                        mode={props.mode}
                        show={showDlg}
                        editID={props.mode === "new" ? -1 : userOrg}
                        onClose={x => {
                            setShowDlg(false)
                            history("/developers")
                        }} />
                </EditorCtx.Provider>
            </GridContainer>
        </PageContainer>);
}

OrgManagerNoGrid.propTypes = {
    mode: PropTypes.oneOf(["new", "edit"]).isRequired
}
