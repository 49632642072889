import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { errorMessage } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';

export const Projects = new CustomStore({
    key: "ProjectID",
    load: function (loadOptions) {
        return axios.get("/api/Project").then((x) => {
            return {
                data: x.data,
                totalCount: x.data.length
            }
        }).catch(err => {
            errorMessage('Could not load projects')
        });
    },
    byKey: function (k) { }
});

const BlogContext = React.createContext();
export const BlogProvider = ({ children }) => {
    const [data, setData] = useState(null);
    const { authUser } = useJumboAuth()

    const loadProjData = async () => {
        try {
            await axios.get('/api/Project').then((response) => {
                if (typeof (response?.data) === 'string') {
                    setData([])
                } else {
                    setData(response?.data ?? [])
                }
            }).catch((error) => {
                console.log(error);
                setData([])
            });
        } catch (error) {
            console.error(error);
            setData([])
        }
    }
    const projects = useMemo(() => {
        return data
    }, [data])

    useEffect(() => {
        if(isNil(authUser)) return
        axios.get('/api/Project').then((response) => {
            if (typeof (response?.data) === 'string') {
                setData([])
            } else {
                setData(response?.data ?? [])
            }
        }).catch((error) => {
            console.error("🚀 ~ file: projectStore.jsx:47 ~ axios.get ~ error:", error)
            setData([])
        });
    }, []);

    return (
        <BlogContext.Provider value={{
            projects: projects,
            loadProjData: loadProjData,
            // orgData
        }}>{children}</BlogContext.Provider>
    );
};

export default BlogContext;
