import '../Partials/autofill.css'
import { useJumboTheme } from '@jumbo/hooks';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import IntlMessage from '@jumbo/utils/IntlMessage';
import {
  Box,
  Button, Checkbox, CircularProgress,
  FormControlLabel, Snackbar, TextField,
  Typography, alpha, Tooltip
} from '@mui/material';
import { fetchError } from 'app/redux/actions/common';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import axios from 'axios';
import { isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import { iv, key1 } from 'app/utils/constants/AppConstants';
var CryptoJS = require("crypto-js");

const SignIn = ({ variant, wrapperVariant }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const signin = useRef(null)
  const { theme } = useJumboTheme();
  const navigate = useNavigate()
  // const { authUser } = useSelector(({ auth }) => auth);
  const { setAuthToken } = useJumboAuth()

  const onSubmit = () => {
    var ciphertext1 = CryptoJS.AES.encrypt(password, CryptoJS.enc.Base64.parse(key1), {
      iv: CryptoJS.enc.Base64.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
    }).ciphertext
    // dispatch(AuhMethods[method].onLogin({ email, password: CryptoJS.enc.Base64.stringify((ciphertext1)) }));
    axios
      .post('/api/Login/Login', {
        Username: email,
        Password: CryptoJS.enc.Base64.stringify((ciphertext1)),//password
      })
      .then(({ data }) => {
        console.log("🚀 ~ file: SignIn.js:45 ~ .then ~ data?.access_token:", data?.access_token)
        if (!isNil(data) && data?.access_token) {
          setAuthToken(data?.access_token)
          navigate("/dash")
          // localStorage.setItem('token', data.access_token);
          // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
        } else {
          console.log("🚀 ~ file: index.js ~ line 49 ~ .then ~ data.error", data.error)
        }
      })
      .catch(function (error) {
        console.log("🚀 ~ file: SignIn.js:49 ~ onSubmit ~ error:", error)
      });
  };

  useEffect(() => {
    const listener = e => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        signin.current.click()
      }
    }
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [])

  // if (isNil(authUser)) {
  // } else {
  //   navigate("/dash", { replace: true })
  //   return
  // }

  return (
    <AuthWrapper>
      <Box sx={{ width: '100%', padding: '20px', height: "100%" }}>
        <Box mb={'5px'}>
          <img src={`${ASSET_IMAGES}/payarklogo.png`} alt='Payark' />
        </Box>
        <Typography component="div" variant="h1" sx={{
          marginBottom: '10px',
          color: theme.palette.text.primary,
        }}>
          Login
        </Typography>
        <form noValidate>
          <Box mb={'5px'} lg={6}>
          <Tooltip title="Email address" arrow >
            <TextField
              data-testid="email"
              label={<IntlMessage id="appModule.email" />}
              fullWidth
              onChange={ev => setEmail(ev.target.value)}
              value={email}
              margin="normal"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: alpha(theme.palette.primary.dark, 0.12),
                },
              }}
              required = {true}
            /></Tooltip>
          </Box>
          <Box mb={'5px'}>
            <TextField
              data-testid="password"
              type="password"
              label={<IntlMessage id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              required={true}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: alpha(theme.palette.primary.dark, 0.12),
                },
              }}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={'5px'}>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  [theme.breakpoints.down('xs')]: {
                    fontSize: 12,
                  },
                },
              }}
              control={<Checkbox name="checkedA" />}
              label="Remember me"
            />
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/forgot-password">
                <IntlMessage id="appModule.forgotPassword" />
              </NavLink>
            </Box>
          </Box>
          <Box style={{ marginTop: '38px' }} display="flex" alignItems="center" justifyContent="space-between" mb={'5px'}>
            <Button role={'signin-button'} btn onClick={onSubmit} variant="contained" color="primary" ref={signin}>
              <IntlMessage id="appModule.signIn" />
            </Button>
          </Box>
        </form>
        {/* {dispatch(AuhMethods[method].getSocialMediaIcons())} */}
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export const ContentLoader = () => {
  const { error, loading, message } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(fetchError(''));
    }, 3000);
    return () => {
      clearTimeout(timeout)
    }
  }, [dispatch, error, message]);

  console.log(`🚀 ~ file: SignIn.js:140 ~ ContentLoader ~ loading: ${loading} ~ error: ${error} ~ message: ${message}`)
  return (
    <React.Fragment>
      {loading && <PageLoader />}
      {Boolean(error) && <Snackbar open={Boolean(error)} message={error} />}
      {Boolean(message) && <Snackbar open={Boolean(message)} message={message} />}
    </React.Fragment>
  );
};

export const PageLoader = () => {
  return (
    <Box sx={
      {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 999999,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }
    }>
      <CircularProgress />
    </Box>
  );
};

export default SignIn;
