import { useJumboTheme } from '@jumbo/hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Button, FormControlLabel, Grid, Snackbar, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { buttonStyles } from 'theme/cashflowsTheme';
import { ContactTypes, StatusStore } from '../../../@data/liveStores';
import { newProjData, projSaveState } from '../../../@data/localStore';
import { EditParticipants } from '../../../@data/project_api';
import OrgDetails from '../OrgManager/OrgDetails';
import Dropdown from '../Partials/DropBox';
import { CustomPagination, printDate } from '../Partials/MaterialGrid';
import PaymentType from '../Partials/PaymentType';
import { validate } from './ProjectParticipants';
import { OrgContext } from '@data';

function getRoles(d) {
    switch (d) {
        case 'Financier':
            return 78
        case 'Quantity Surveyor':
            return 81
        case 'Head Contractor':
            return 43
        default:
            throw Error("No Role specified")
    }
}

export default function ProjectParticipantsWizard(props) {
    const { orgData } = useContext(OrgContext)
    const [selectedStatus, setStatus] = useState(null)
    const [selectedContactRole, setSelectedContactRole] = useState(null)
    const [selectedPaymentType, setSelectedPaymentType] = useState(null)
    const [org, setOrg] = useState(0)
    const [showNewOrg, setShowNewOrg] = useState(false)
    const [editorProps, setEditorProps] = useState({
        selectedUser: {
            StartDate: null,
            EndDate: null,
            Organization: "",
            Role: "",
            Status: "",
            FRP: false
        }
    })
    const [projData, setProjData] = useRecoilState(newProjData)
    const setProjSaveState1 = useSetRecoilState(projSaveState)
    const [projectParticipants, setProjectParticipants] = useState([])
    const [errorTxt, setErrorTxt] = useState('')
    const { theme } = useJumboTheme()
    const [participantFRP, setParticipantFRP] = useState(false)
    const [disableFRP, setDisableFRP] = useState(false)
    const editBtn = useRef(null)
    const [orgMode, setOrgMode] = useState("new")
    const [abnRetry, setAbnRetry] = useState(false)
    const btnStyle = buttonStyles(theme)

    useEffect(() => {
        const role = getRoles(props.role)
        setSelectedContactRole(role)
    }, [props.role])
    useEffect(() => {
        setDisableFRP(false)
        projectParticipants.forEach(element => {
            if (element.FRP) {
                setDisableFRP(true)
            }
        });
    }, [projData['ProjectContacts'], projectParticipants])

    useEffect(() => {
        setStatus(13)
        setProjSaveState1(false)
        //load saved project info
        if (!isNil(projData['ProjectContacts'])) {
            setProjectParticipants(projData['ProjectContacts'])
        }
        if (props.role === "Financier") {
            axios.get("api/Organisation/MyOrgs").then(resp => {
                let row = {
                    Organization: resp.data.BusinessName,
                    Organisation: resp.data.BusinessName,
                    OrgID: resp.data.OrganisationID,
                    ContactTypeID: 77, //Developer
                    Role: 'Developer',
                    Status: 'Active',
                    StatusID: 13,
                    StartDate: utcDate,
                    EndDate: null,
                    FRP: false
                }
                const x = projData['ProjectContacts']?.filter(e => { return row.OrgID !== e.OrgID })
                if (!isNil(x)) {
                    setProjectParticipants([...x, row])
                    setProjData(prev => ({
                        ...prev,
                        ProjectContacts: [...x, row]
                    }))
                } else if (isNil(x)) {
                    setProjectParticipants([row])
                    setProjData(prev => ({
                        ...prev,
                        ProjectContacts: [row]
                    }))
                }
            }).catch(err => {
                console.error(err)
            })
        }
        return () => {
            setProjSaveState1(true)
        }
    }, [])

    const handleDelete = (x) => {
        let temp = projectParticipants.filter(e => {
            return e.OrgID !== x.OrgID
        })
        setProjectParticipants([...temp])
        //call server to delete
        EditParticipants({
            ProjectID: projData.ProjectID,
            OrgID: x.OrgID,
            ContactTypeID: x.ContactTypeID,
            StatusID: x.StatusID,
            FRP: x.FRP,
            deleted: true,
            edited: false
        })
        setProjData({
            ...projData,
            ProjectContacts: [...temp]
        })
    }
    const label = { inputProps: { 'aria-label': 'Checkbox' } };

    const columns = [
        {
            field: 'Organisation',
            headerName: 'Organisation',
            flex: 2,
        },
        {
            field: 'Role',
            headerName: 'Role',
            flex: 1,
        },
        {
            field: 'FRP',
            headerName: 'FRP',
            renderCell: (params) => {
                const check = params.row.FRP
                if (check === true) {
                    return <Checkbox {...label} disabled checked />
                }
                else {
                    return <Checkbox {...label} disabled />
                }

            },
            flex: 1,
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
        },
        {
            field: 'StartDate',
            headerName: 'Start Date',
            renderCell: (params) => {
                return printDate(params.row.StartDate)
            },
            flex: 1,
        },
        {
            field: 'EndDate',
            headerName: 'End Date',
            renderCell: (params) => {
                const endDate = params.row.EndDate
                return printDate(params.row.EndDate)
            },
            flex: 1,
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            renderCell: (x) => {
                return <IconButton aria-label="delete" data-value={JSON.stringify(x.row)} onClick={e => {
                    handleDelete(x.row)
                    e.stopPropagation()
                }}>
                    <DeleteIcon />
                </IconButton>
            },
            flex: 1,
        },
    ];

    /**
     * Only do loading if edit mode for selected row
     */
    const utcDate = (new Date()).toISOString()
    return (<>
        <Box>
            <span style={{ fontSize: "1.0rem", fontWeight: "bold" }}>Add {props.role}</span>
            <Grid container rowSpacing={"10px"} columnSpacing={"0px"} style={{ marginTop: "5px" }}>
                {/* spacing={3} */}
                <Grid item xs={6}>
                    <Dropdown
                        placeholder="Add an Existing Organisation to this Project"
                        data={orgData}
                        value={org}
                        onChange={setOrg}
                        mapping={x => {
                            return { value: x.OrganisationID, label: x.BusinessName }
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        sx={{ ...btnStyle.button, marginLeft: 0 }}
                        variant="contained"
                        color='info'
                        aria-controls="fade-menu"
                        onClick={(e) => {
                            setShowNewOrg(true);
                        }}>
                        Add New Organisation
                    </Button>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <FormControlLabel
                        control={<Checkbox
                            value={participantFRP}
                            checked={participantFRP}
                            disabled={disableFRP}
                            onChange={(v) => {
                                setParticipantFRP(v.target.checked)
                            }} />} label="FRP" />
                </Grid>
                {
                    selectedContactRole === 51 ? (
                        <Grid item xs={4}>
                            <PaymentType selectedStatus={selectedPaymentType} setStatus={setSelectedPaymentType} />
                        </Grid>
                    ) : null
                }
                {
                    selectedContactRole === 51 ? (
                        <Grid item xs={4}>
                            <TextField
                                id="standard-basic"
                                label="Reference Number"
                                variant={'standard'}
                                margin={'none'}
                                fullWidth
                            />
                        </Grid>
                    ) : null
                }
                <Grid item xs={12} lg={12} md={12} xl={12}>
                    <Button
                        sx={{ ...btnStyle.button, marginLeft: 0 }}
                        color='info'
                        variant="contained"
                        aria-controls="fade-menu"
                        onClick={async (e) => {
                            if (org === 0 || isNil(selectedStatus) || isNil(selectedContactRole)) return
                            var r = orgData.filter(e => {
                                return e.OrganisationID === org
                            })
                            var r2 = await ContactTypes.byKey(selectedContactRole)
                            var r3 = await StatusStore.byKey(13)

                            let row = {
                                Organization: r[0].BusinessName,
                                Organisation: r[0].BusinessName,
                                OrgID: org,
                                ContactTypeID: selectedContactRole,
                                Role: r2.Name,
                                Status: r3[0].Name,
                                StatusID: selectedStatus,
                                StartDate: utcDate,
                                EndDate: null,
                                FRP: participantFRP
                            }
                            if (validate(row, projectParticipants, setErrorTxt)) {
                                setProjectParticipants([...projectParticipants, row])
                                // editorProps.dataGrid.current.instance.refresh()
                                setProjData({
                                    ...projData,
                                    ProjectContacts: [...projectParticipants, row]
                                })
                            }
                            //reset selections:
                            setOrg(0)
                            setSelectedPaymentType(null)
                            setParticipantFRP(false)
                        }}>
                        Add Existing Organisation
                    </Button>
                </Grid>
            </Grid>

            <Box sx={{ height: 400, width: '100%', marginTop: "15px" }} id="participantTable">
                <DataGrid
                    rows={projectParticipants}
                    onRowClick={e => {
                        console.log("🚀 ~ file: ProjectParticipants.jsx:501 ~ ProjectParticipants ~ e", e.row)
                        setEditorProps({
                            selectedUser: {
                                StartDate: e.row['StartDate'],
                                EndDate: e.row['EndDate'],
                                Organization: e.row['Organisation'],
                                Role: e.row['Role'],
                                Status: e.row['Status'],
                                FRP: e.row['FRP'],
                                ProjectID: projData.ProjectID,
                                OrgID: e.row['OrgID'],
                                ContactTypeID: e.row['ContactTypeID'],
                                StatusID: e.row['StatusID'],
                            }
                        })
                    }}
                    onRowDoubleClick={e => {
                        editBtn.current.click()
                    }}
                    disableColumnSelector
                    disableDensitySelector
                    density={'standard'}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    columns={columns}
                    getRowId={(row) => {
                        return `${row.OrgID}${row.ContactTypeID}`
                    }}
                    responsive='onResize'
                    sx={{
                        fontFamily: theme.typography.fontFamily,
                        "& .MuiDataGrid-row:hover": {
                            backgroundColor: "#03DAC5"
                        },
                        "& .Mui-selected": {
                            backgroundColor: "#03DAC5  !important"
                        },
                    }}
                    // pagination
                    pageSize={20}
                    // rowsPerPageOptions={[15]}
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            sx: {
                                '& .MuiButtonBase-root': {
                                    color: '#ff5722'
                                }
                            }
                        },
                    }}
                />
            </Box>
            <OrgDetails
                mode={orgMode}
                show={showNewOrg}
                projID={projData.ProjectID}
                editID={editorProps.selectedUser['OrganisationID']}
                abnRetry={abnRetry}
                selectedContactRole={selectedContactRole}
                onClose={(x, abn, row) => {
                    // check for selectedContactRole
                    setShowNewOrg(false)
                    setOrgMode("new")
                    let o = orgData.filter(e => {
                        return e.ABN.replace(/\W/g, '') === abn
                    })
                    if (!isNil(abn)) {
                        let found = false
                        //check for existing contacts
                        if (!isNil(projData['ProjectContacts']) && !isEmpty(o)) {
                            projData['ProjectContacts'].forEach(element => {
                                if (element.OrgID === o[0]['OrganisationID']) {
                                    found = true
                                }
                            });
                            setOrgMode("edit")
                            setAbnRetry(true)
                        }
                        if (!isEmpty(o)) {
                            setEditorProps({
                                selectedUser: {
                                    OrganisationID: o[0].OrganisationID
                                }
                            })
                            if (!found) {
                                setShowNewOrg(true)
                            }
                        }
                    } else {
                        setOrgMode("new")
                        setAbnRetry(false)
                    }
                    if (!isNil(row) && !isEmpty(row) && validate(row, projectParticipants, setErrorTxt)) {
                        //check again if role is nil
                        if (isNil(row.ContactTypeID)) {
                            row.ContactTypeID = selectedContactRole
                        }
                        setProjectParticipants(prev => [...prev, row])
                        setProjData(prev => ({
                            ...prev,
                            ProjectContacts: [...projectParticipants, row]
                        }))
                    }
                }} />
        </Box>
        <Snackbar open={errorTxt !== ''} autoHideDuration={6000}>
            <Alert severity="error">
                {errorTxt}
            </Alert>
        </Snackbar>
    </>)
}

ProjectParticipantsWizard.propTypes = {
    role: PropTypes.oneOf(['Financier', 'Quantity Surveyor', 'Head Contractor']).isRequired,
    editMode: PropTypes.oneOf(['new', 'edit']).isRequired,
    step: PropTypes.number.isRequired
}
