import axios from "axios";

export function EditParticipants(data) {
    axios.post('/api/Project/EditParticipants', { ...data }).then(resp => { }).catch(err => console.error(err))
}


export function AddProjectContactRole(data) {
    axios.post('/api/Project/AddProjectTeam', { ...data }).then(resp => { }).catch(err => console.error(err))
}


export function EditParticipant(row) {
    return axios.post("/api/Project/EditParticipants", { ...row, edited: true }, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate, br'
        }
    })
}

export function GetProjectContacts(projectID) {
    return axios.get(`/api/Project/GetProjectContacts?project=${projectID}`)
}

export function SaveAccountInfo(row) {
    return axios.post("/api/Payment/AddInfo", row, {
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function InviteUserToOrg(email, firstName, lastName, phone, OrgID, primary = false) {
    phone = phone.replace(/\s/g, '')
    return axios.post("/api/Organisation/InviteUser", {
        email: email,
        FirstName: firstName,
        LastName: lastName,
        Phone: phone,
        OrgID: OrgID,
        Primary: primary
    }, {
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function ValidEmail(email) {
    return axios.get(`/api/User/ValidEmail?email=${email}`)
}
