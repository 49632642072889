import { useJumboTheme } from '@jumbo/hooks';
import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { blue } from '@mui/material/colors';
import PropTypes from 'prop-types';

const useStyles = theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export function SimplePopup(props) {
  const { theme } = useJumboTheme()
  const classes = useStyles(theme);
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="image-popup"
      maxWidth={'xl'}
      open={open}>
      <DialogActions>
        <IconButton aria-label="close" sx={classes.closeButton} onClick={onClose} aria-labelledby='close'>
          <Close />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <img src={props.image} draggable={false} width={"100%"} />
      </DialogContent>
      {/* <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle> */}
      {/* <List>
        {emails.map((email) => (
          <ListItem button onClick={() => handleListItemClick(email)} key={email}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add account" />
        </ListItem>
      </List> */}
    </Dialog>
  );
}

SimplePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
