import '../Partials/autofill.css'
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessage from '@jumbo/utils/IntlMessage';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { alpha } from "@mui/material/styles";
import { CurrentAuthMethod, iv, key1 } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import { RecaptchaVerifier, getAuth, signInWithPhoneNumber } from "firebase/auth";
import _, { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import { ContentLoader } from './SignIn';
import { firebaseApp } from './firebase/config';
import { showErrorToast, showSuccessToast } from '../Partials/Notify';
import PhoneNumber from 'google-libphonenumber';
var CryptoJS = require("crypto-js");

const useStyles = (theme, variant) => ({
  authThumb: {
    backgroundColor: 'rgba(115, 82, 199, 0.12)',//alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "20px",
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: "15px",
    [theme.breakpoints.up('md')]: {
      width: (variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: "20px",
      width: "50vw"
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.primary.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
})

const userCheck = async (email) => {
  return axios.get(`api/User/Valid?username=${email}`).then(x => {
    if (x.data) {
      return true;//no conflict
    } else {
      return false;//username already taken
    }
  }).catch((e) => {
    showErrorToast(IntlMessage("E0008"))
  });
}

function Timer(props) {
  let count = 120
  const [time, setTime] = useState(count)

  useEffect(() => {
    console.log("🚀 ~ file: SignUp.js ~ line 85 ~ useEffect ~ props.start", props.start)
    if (props.start) {
      const timer = setInterval(() => {
        if (count > 0) {
          setTime(count--)
        } else {
          clearInterval(timer)
          count = 120
          props.onEnable(true)
        }
      }, 1000);
    }
  }, [props.start])

  if (time == 1) {
    return <><span><small>Expired</small></span></>
  }
  if (!props.start) return <></>
  return <>
    <span><small>{time} sec to expire</small></span>
  </>
}

//variant = 'default', 'standard', 'bgColor'
const SignUp = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [name, setName] = useState(null);
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [passwordErr, setPasswordErr] = useState("")
  const [firstnameErr, setFirstnameErr] = useState("")
  const [lastnameErr, setLastnameErr] = useState("")
  const [nameErr, setNameErr] = useState("")
  const [emailErr, setEmailErr] = useState("")
  const [phone, setPhone] = useState("")
  const [phoneErr, setPhoneErr] = useState("")
  const [sms, setSMS] = useState("")
  const [smsVerificationErr, setSMSVerificationErr] = useState("")
  const [otpGenerated, setOTPGenerated] = useState(false)
  const [generateEnable, setGenerateEnable] = useState(false)
  const [smsDisabled, setSMSDisabled] = useState(false)
  const [startTime, setStartTime] = useState(false)
  const { theme } = useJumboTheme()
  const classes = useStyles(theme, variant);
  const location = useLocation()
  const [password2Err, setPassword2Err] = useState("");
  const navigate = useNavigate()


  const handlePhoneChange = (event) => {
    const inputValue = event.target.value;
    setPhone(inputValue); // Update the state without formatting during typing
  };

  const handlePhoneBlur = (event) => {
    const inputValue = event.target.value;

    try {
      const phoneUtil = PhoneNumber.PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parse(inputValue, 'AU');
      const formattedPhone = phoneUtil.format(phoneNumber, PhoneNumber.PhoneNumberFormat.INTERNATIONAL);
      setPhone(formattedPhone);
      setPhoneErr('');
    } catch (error) {
      // Handle parsing errors
      setPhone(inputValue);
      setPhoneErr('Invalid phone number');
    }
  };


  useEffect(() => {
    let q = queryString.parse(location.search)
    if (_.isUndefined(q["invitation"]) == false) {
      axios.get(`/api/Organisation/GetInvite?invitation=${q['invitation']}`).then(resp => {
        setFirstName(resp.data['FirstName'])
        setLastName(resp.data['LastName'])
        setEmail(resp.data['Email'])
        setPhone(resp.data['Phone'])
      }).catch(err => {
        console.error(err)
        setSMSDisabled(true)
        showErrorToast("Invalid invitation link")
      })
    }
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier('sign-up-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
      }
    }, auth);
  }, [])

  useEffect(() => {
    if (isEmpty(firstnameErr) && isEmpty(lastnameErr) &&
      isEmpty(passwordErr) && isEmpty(emailErr) &&
      !isEmpty(firstName) && !isEmpty(lastName) && !isEmpty(name) &&
      !isEmpty(password) && !isEmpty(password2) && (password === password2) &&
      !isEmpty(phone)) {
      setGenerateEnable(true)
    } else {
      setGenerateEnable(false)
    }
  }, [firstnameErr, lastnameErr, passwordErr, emailErr, phoneErr, phone, name, firstName, lastName, password, password2, password2Err])

  const onSubmit = async () => {
    // let res = await userCheck(name)
    if (isEmpty(nameErr)) {
      setNameErr("")
      //register on server:
      var ciphertext1 = CryptoJS.AES.encrypt(password, CryptoJS.enc.Base64.parse(key1), {
        iv: CryptoJS.enc.Base64.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
      }).ciphertext
      axios.post("/api/Login/register", {
        email: email,
        password: CryptoJS.enc.Base64.stringify((ciphertext1)),
        name: name,
        FirstName: firstName,
        LastName: lastName,
        Phone: phone
      }).then(resp => {
        showSuccessToast("Registration success, Redirecting to login page")
        setTimeout(() => {
          navigate("/signin")
        }, 2500);
      }).catch(e => {
        // setNameErr("User Already Exist")
      })
    } else {
      setNameErr("User Already Exist")
      return
    }
    // dispatch(AuhMethods[method].onRegister({ firstName, lastName, name, email, password, Phone: phone }));
  };
  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box sx={classes.authThumb}>
          <img src={'images/auth/sign-up-img.png'} />
        </Box>
      ) : null}
      <Box sx={classes.authContent}>
        <Box mb={1}>
          <img src={'images/payarklogo.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Create your account
        </Typography>
        <form autoComplete='off' >
          <Grid container spacing={4}>
            <Grid item md={6} lg={6} sm={6}>
              <TextField

                data-testid={"firstname"}
                fullWidth
                label={<IntlMessages id="appModule.FirstName" />}
                onChange={event => {
                  setFirstName(event.target.value)
                  if (event.target.value.length < 1) {
                    setFirstnameErr("Value can not be blank and less than 50 characters")
                  }
                  else if (event.target.value.length > 50) {
                    setFirstnameErr("Value must be less than 50 characters")
                  }
                  else {
                    setFirstnameErr("")
                  }
                }}
                helperText={firstnameErr}
                InputLabelProps={{ shrink: true }}
                error={firstnameErr.length > 0 ? true : false}
                value={firstName}
                margin="normal"
                variant="outlined"
                sx={classes.textFieldRoot}
                required={true}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} lg={6} sm={6}>
              <TextField
                data-testid={'lastname'}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={<IntlMessages id="appModule.LastName" />}
                onChange={event => {
                  setLastName(event.target.value)
                  if (event.target.value.length < 1) {
                    setLastnameErr("Value can not be blank and less than 50 characters")
                  }
                  else if (event.target.value.length > 50) {
                    setLastnameErr("Value must be less than 50 characters")
                  }
                  else {
                    setLastnameErr("")
                  }
                }}
                helperText={lastnameErr}
                error={lastnameErr.length > 0 ? true : false}
                value={lastName}
                margin="normal"
                variant="outlined"
                sx={classes.textFieldRoot}
                required={true}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Box mb={2}>
                <TextField
                  data-testid={'username'}
                  label={<IntlMessages id="appModule.name" />}
                  fullWidth
                  onChange={async event => {
                    setName(event.target.value)
                    if (event.target.value.length < 6) {
                      setNameErr("value should be greater than 5 characters")
                      return
                    }
                    else if (event.target.value.length > 50) {
                      setNameErr("value should be less than 50 characters")
                      return
                    }
                    else {
                      setNameErr("")
                    }
                  }}
                  onBlur={async (event) => {
                    let res = await userCheck(event.target.value)
                    if(res){
                      setNameErr("")
                    }else{
                      setNameErr("User Already Exist")
                    }
                  }}
                  helperText={nameErr}
                  error={nameErr.length > 0 ? true : false}
                  defaultValue={name}
                  margin="normal"
                  variant="outlined"
                  sx={classes.textFieldRoot}
                  required={true}
                  inputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  data-testid={'email'}
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  onChange={event => {
                    setEmail(event.target.value)
                    if (event.target.value.length < 10 || event.target.value.length > 100) {
                      setEmailErr("Invalid Email")
                    } else {
                      setEmailErr("")
                    }
                  }}
                  type={"email"}
                  InputLabelProps={{ shrink: true, }}
                  helperText={emailErr}
                  error={emailErr.length > 0 ? true : false}
                  value={email}
                  margin="normal"
                  variant="outlined"
                  sx={classes.textFieldRoot}
                  required={true}
                  InputProps={{
                    readOnly: true,
                    autoComplete: 'off'
                  }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  data-testid={'password'}
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={event => {
                    setPassword(event.target.value)
                    if (event.target.value.length < 6 || event.target.value.length > 25) {
                      setPasswordErr("password length should be between 6 and 25")
                    } else {
                      setPasswordErr("")
                    }
                  }}
                  helperText={passwordErr}
                  error={passwordErr.length > 0 ? true : false}
                  defaultValue={password}
                  margin="normal"
                  variant="outlined"
                  sx={classes.textFieldRoot}
                  required={true}
                  inputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  data-testid={'confirmpassword'}
                  type="password"
                  label={"Confirm Password"}
                  fullWidth
                  onChange={event => {
                    setPassword2(event.target.value);
                    if (event.target.value !== password) {
                      setPassword2Err("Password doesn't match");
                    } else {
                      setPassword2Err("");
                    }
                  }}
                  value={password2}
                  helperText={password2Err}
                  error={password2Err.length > 0 ? true : false}
                  //error={password2Err.length > 0}
                  margin="normal"
                  variant="outlined"
                  sx={classes.textFieldRoot}
                  required={true}
                  inputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} lg={3}>
              <Box mb={2}>
                <InputMask
                  required
                  value={phone}
                  // onChange={event => {
                  //   setPhone(event.target.value)
                  // }}
                  onChange={(event) => handlePhoneChange(event)}
                  onBlur={(event) => handlePhoneBlur(event)}
                  mask={"+99 999 999 999"}>
                  {() => <TextField
                    data-testid={'phonenumber'}
                    label={'Phone Number'}
                    InputProps={{
                      autoComplete: "new-password",
                      readOnly: true,
                    }}
                    required={true}
                    helperText={phoneErr}
                    error={phoneErr.length > 0 ? true : false}
                    margin="normal"
                    variant="outlined"
                    sx={classes.textFieldRoot}
                  >
                  </TextField>}
                </InputMask>
              </Box>
            </Grid>
            <Grid item xs={6} md={6} lg={3} style={{ textAlign: 'center' }}>
              <Grid container>
                <Grid item lg={12} sm={12} ms={12}>
                  <Box style={{ display: otpGenerated ? 'none' : 'initial' }}>
                    <Button variant='contained'
                      role={'otp'}
                      color='primary'
                      disabled={!generateEnable}
                      style={{ marginTop: "25px" }}
                      onClick={x => {
                        if (phone.length > 6) {
                          setOTPGenerated(true)
                          const auth = getAuth();
                          signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
                            .then((confirmationResult) => {
                              console.log("🚀 ~ file: index.js ~ line 29 ~ .then ~ confirmationResult", confirmationResult)
                              window.confirmationResult = confirmationResult;
                              window.recaptchaVerifier = null
                            }).catch((error) => {
                              console.log("🚀 ~ file: index.js ~ line 35 ~ .then ~ error", error)
                            });
                        } else {
                          setPhoneErr("Please enter correct number")
                        }
                        setStartTime(true)
                      }}>Generate OTP</Button>
                  </Box>
                  <Box mb={2} style={{ display: !otpGenerated ? 'none' : 'initial' }}>
                    <TextField
                      data-testid={'verificationcode'}
                      type="text"
                      label={'SMS verification code'}
                      disabled={smsDisabled}
                      onChange={event => {
                        setSMS(event.target.value)
                        if (event.target.value.length < 6 || event.target.value.length > 6) {
                          setSMSVerificationErr("Please enter correct code")
                        } else if (event.target.value.length == 6) {
                          //kick off verification
                          window.confirmationResult.confirm(event.target.value).then(resp => {
                            setSMSVerificationErr("")
                            setSMSDisabled(true)
                            console.log("🚀 ~ file: SignUp.js ~ line 299 ~ window.confirmationResult.confirm ~ resp", resp)
                            //phone number verified
                          }).catch(err => {
                          })
                        }
                      }}
                      helperText={smsVerificationErr}
                      error={smsVerificationErr.length > 0 ? true : false}
                      defaultValue={sms}
                      margin="normal"
                      variant="outlined"
                      sx={classes.textFieldRoot}
                      required={true}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} sm={12} ms={12} style={{ textAlign: 'center', padding: "2px 0" }}>
                  <Box style={{ display: smsDisabled ? 'none' : 'initial' }}>
                    <Timer start={startTime} onEnable={x => {
                      if (!smsDisabled) {
                        setOTPGenerated(false)
                      }
                    }} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button role={"register"}
                disabled={!smsDisabled}
                onClick={onSubmit}
                //onClick={() => console.log(phone, 'NUMBER')}           
                variant="contained" color="primary" id="sign-up-button">
                <IntlMessages id="appModule.register" />
              </Button>
            </Box>

            {/* <Typography className={classes.alrTextRoot}>
              <NavLink to="/signin">
                <IntlMessages id="signUp.alreadyMember" />
              </NavLink>
            </Typography> */}
          </Box>
        </form>

        {/* <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box> */}

        {/* <Typography className={classes.textAcc}>
          Have an account?
          <NavLink to="/signin">Sign In</NavLink>
        </Typography> */}
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignUp;
