import { Grid } from '@mui/material';
import React from 'react';

const GridContainer = ({ children, ...rest }) => {
  return (
    <Grid container spacing={6} {...rest}>
      {children}
    </Grid>
  );
};

export default GridContainer;
