import GridContainer from '@jumbo/components/GridContainer';
import { useJumboTheme } from '@jumbo/hooks';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { errorMessage } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSetRecoilState } from 'recoil';
import { _userInvited } from '../../../@data/localStore';
import { InviteUserToOrg, ValidEmail } from '../../../@data/project_api';
import { buttonStyles, useStyles } from '../../../theme/cashflowsTheme';
import { m5 } from '../Partials/Mui5';
import PhoneNumber from 'google-libphonenumber';
import { startCase } from 'lodash';

function InviteUser(props) {
    const { theme } = useJumboTheme()
    const classes = useStyles(theme)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState(" ")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const invitedData = useSetRecoilState(_userInvited)
    const confirm = useConfirm()
    const btnStyle = buttonStyles(theme)
    const dlgStyle = m5(theme)

    useEffect(() => {
        if (props.show) {
            setFirstName("")
            setLastName("")
            setPhone("")
            setEmail("")
        }
    }, [props.show])

    const userCheck = async (email) => {
        return axios.get(`api/User/Valid?username=${email}`).then(x => {
            if (x.data)
                return true;
            return false;
        }).catch((e) => {
        });
    }


    const handlePhoneChange = (event) => {
        const inputValue = event.target.value;
        setPhone(inputValue); // Update the state without formatting during typing
    };

    const handlePhoneBlur = (event) => {
        const inputValue = event.target.value;

        try {
            const phoneUtil = PhoneNumber.PhoneNumberUtil.getInstance();
            const phoneNumber = phoneUtil.parse(inputValue, 'AU');
            const formattedPhone = phoneUtil.format(phoneNumber, PhoneNumber.PhoneNumberFormat.INTERNATIONAL);
            console.log('Parsed phone number:', phoneNumber);
            console.log('Formatted phone:', formattedPhone);
            setPhone(formattedPhone);
        } catch (error) {
            // Handle parsing errors
            setPhone(inputValue);
        }
    };

    return <>
        <Dialog
            maxWidth={"lg"}
            fullWidth
            sx={{
                ...classes.dialog,
                ...classes.root,
            }}
            open={props.show}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}>
            <DialogTitle
                typography={false}
                sx={dlgStyle.dlgTitle}>
                Invite new User
                {/* {props.mode === "user" ? "User" : "Organisation"} */}
            </DialogTitle>
            <DialogContent aria-autocomplete='none' >
                <GridContainer >
                    <Grid item lg={6} md={6} sm={6}>
                        <TextField
                            label={"First Name"}
                            variant='standard'
                            fullWidth
                            required
                            placeholder='First Name'
                            margin='dense'
                            onChange={x => {
                                setFirstName(startCase(x.target.value))
                            }}
                            InputProps={{
                                autoComplete: 'none'
                            }}
                            value={firstName} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6}>
                        <TextField
                            variant='standard'
                            label={"Last Name"}
                            fullWidth
                            placeholder='Last Name'
                            margin='dense'
                            InputProps={{
                                autoComplete: 'none'
                            }}
                            value={lastName}
                            onChange={x => {
                                setLastName(startCase(x.target.value))
                            }} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6}>
                        <TextField
                            variant='standard'
                            label={"Email"}
                            type={'email'}
                            required={true}
                            fullWidth
                            placeholder='Email'
                            margin='dense'
                            InputProps={{
                                autoComplete: 'none'
                            }}
                            value={email}
                            onChange={x => {
                                setEmail(x.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6}>
                        <InputMask
                            required={true}
                            value={phone}
                            //onChange={x => setPhone(x.target.value)}
                            onChange={(event) => handlePhoneChange(event)}
                            onBlur={(event) => handlePhoneBlur(event)}
                            mask={"+61 999 999 999"}>
                            {() => <TextField
                                label={"Phone Number"}
                                variant='standard'
                                InputProps={{
                                    autoComplete: 'none'
                                }}
                                aria-autocomplete='none'
                                required={true}
                                fullWidth
                                placeholder='Phone'
                                margin='dense'
                            />}
                        </InputMask>
                    </Grid>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color='info'
                    sx={btnStyle.button}
                    onClick={x => {
                        if (email.length < 3 || firstName.length < 1 || phone.length < 5) {
                            confirm({
                                description: "Please complete the form",
                                title: "User Details",
                                hideCancelButton: true
                            }).then(resp => { }).catch(err => {
                            })
                        }
                        if (props.mode === 'user') {
                            InviteUserToOrg(email, firstName, lastName, phone, props.OrgID).then(resp => {
                                console.log("🚀 ~ file: InviteUser.jsx ~ line 111 ~ InviteUserToOrg ~ resp", resp.data)
                                props.onClose(false)
                            }).catch(err => {
                                console.error("🚀 ~ file: InviteUser.jsx ~ line 93 ~ InviteUserToOrg ~ err", err)
                                confirm({
                                    description: errorMessage(err),
                                    title: "Invite User",
                                    hideCancelButton: true
                                }).then(resp => { }).catch(err => {
                                })
                            })
                        } else if (props.mode === 'org') {
                            //check Email here
                            ValidEmail(email).then(resp => {
                                if (resp.data) {
                                    invitedData({
                                        ContactID: -1,
                                        email: email,
                                        FirstName: firstName,
                                        LastName: lastName,
                                        Phone: phone,
                                        OrgID: -1
                                    })
                                    props.onClose(false)
                                } else {
                                    confirm({
                                        description: "Please add a different email address",
                                        title: "Email already exists",
                                        hideCancelButton: true,
                                    }).then(resp => { }).catch(err => {
                                    })
                                }
                            }).catch(err => {
                            })
                        }
                    }}>Invite</Button>
                <Button
                    variant='contained'
                    color='inherit'
                    sx={btnStyle.button}
                    onClick={x => {
                        props.onClose(false)
                    }}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>
}

InviteUser.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['org', 'user']).isRequired,
    // OrgID: PropTypes.number.isRequired
}

export default InviteUser;
