import ArrayStore from 'devextreme/data/array_store';
// import * as claimstestData from './testg01.json'

export const claimsData = new ArrayStore({
    key: "ClaimNumber",
    data: [],
});


export const subCons = new ArrayStore({
    data: [],
});
