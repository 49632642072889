import _, { isEmpty, isNil } from 'lodash';

export const CurrentAuthMethod = 'jwtAuth'; //jwtAuth,firebase,basic
export const ErrorMsgTimeout = 5000;
export const SuccessMsgTimeout = 1000;
export const key1 = process.env.REACT_APP_KEY
export const iv = process.env.REACT_APP_IV

export const isNotNullUndef = (val) => {
  return !isNil(val)
}

export const isUndefOrNull = (val) => {
  return isNil(val)
}

export const errorMessage = (err) => {
  let txt = ""

  if (err.message) {
    if (err.code) {
      txt += `${err.code} - ${err.message}`
    } else {
      txt += `${err.message}`
    }
  }
  if (_.has(err, 'response') && !isNil(err.response) && !isEmpty(err.response)) {
    try {
      txt = `${err.response.status} - ${err.response.statusText}`
    } catch (error) {
    }
    if (!isNil(err.response.data)) {
      if (!isNil(err.message)) {
        txt = ""// err.message + ", "
      }
      txt += _.has(err, 'response.data.title') ? err.response.data.title : err.response.data
    }
  }
  return txt
}
