//** Add / Edit Functions Dialog */
import { functionsList } from '@data/liveStores';
import { useJumboTheme } from '@jumbo/hooks';
import { IntlMessage } from '@jumbo/utils/IntlMessages';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { ErrorMsgTimeout, SuccessMsgTimeout, errorMessage } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import Form, {
    AsyncRule, GroupItem, Label,
    RequiredRule, SimpleItem
} from 'devextreme-react/form';
import { StringLengthRule } from 'devextreme-react/tree-list';
import { isNull } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useStyles } from 'theme/cashflowsTheme';
import Dropdown from '../Partials/DropBox';
import { showErrorToast, showSuccessToast } from '../Partials/Notify';

function checkFuncCode(email) {
    return axios.get(`api/Functions/Valid?code=${email.value}&id=${row.FunctionId}`).then(x => {
        if (x.data)
            return true;
        return false;
    }).catch((e) => {
        showErrorToast(IntlMessage("E0010"))
    });
}

const row = {
    FunctionParentId: null,
    FunctionId: 0,
    FunctionCode: "",
    FunctionDescription: "",
    FunctionControllerName: "",
    MenuDisplayText: "",
    FunctionControllerVirtualPath: "",
    RoleId: 0//associated role
};

export default function AddNewFunction(props) {
    const roleForm = useRef(null);
    const { theme } = useJumboTheme()
    const classes = useStyles(theme);
    const [combo1, setCombo1] = useState(null)
    const [combo1data, setCombo1data] = useState([]);
    const [reload, setReload] = useState(false)

    useEffect(() => {
        if (!props.show) return
        setCombo1(null)
        if (props.mode === "edit") preLoad(props.funcId)
        else if (props.mode === "new") {
            // roleForm.current.instance.resetValues()
            row.FunctionParentId = null
            row.FunctionId = 0
            row.FunctionCode = ""
            row.FunctionDescription = ""
            row.FunctionControllerName = ""
            row.MenuDisplayText = ""
            row.FunctionControllerVirtualPath = ""
            row.RoleId = 0//associated role
        }
    }, [props.show])

    useEffect(() => {
        functionsList.load({ searchValue: null })
            .then(x => {
                setCombo1data(x.data)
            })
            .catch(err => { })
    }, [reload])

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    function onSubmit() {
        //call backend
        if (props.mode === "edit") {
            axios.put("/api/Functions", {
                FunctionParentId: combo1,
                FunctionCode: row.FunctionCode,
                FunctionDescription: row.FunctionDescription,
                FunctionControllerName: row.FunctionControllerName,
                MenuDisplayText: row.MenuDisplayText,
                FunctionId: row.FunctionId,
                FunctionControllerVirtualPath: row.FunctionControllerVirtualPath,
            }).then(x => {
                showSuccessToast(IntlMessage("S0009"))
                roleForm.current.instance.resetValues();
                setReload(true)
                props.onClose(true);
            }).catch(e => {
                if (e.response) {
                    showErrorToast(IntlMessage("E0013", e.response.data))
                }
                showErrorToast(IntlMessage("E0013"))
            });
        }
        else {
            axios.post("/api/Functions", {
                FunctionParentId: combo1,
                FunctionCode: row.FunctionCode,
                FunctionDescription: row.FunctionDescription,
                FunctionControllerName: row.FunctionControllerName,
                MenuDisplayText: row.MenuDisplayText,
                FunctionControllerVirtualPath: row.FunctionControllerVirtualPath,
            }).then(x => {
                showSuccessToast(IntlMessage("S0009"))
                roleForm.current.instance.resetValues();
                setReload(true)
                props.onClose(true);
            }).catch(e => {
                if (e.response) {
                    showErrorToast(IntlMessage("E0011", e.response.data))
                }
                showErrorToast(IntlMessage("E0013"))
            });
        }
    }

    function renderPopup() {
        return (
            <form action=""
                id="newFuncForm"
                style={{ ...classes.popupSm }}
            >
                <Form
                    ref={roleForm}
                    style={classes.form}
                    colCount={1}
                    showColonAfterLabel={false}
                    validationGroup="userData4"
                    showValidationSummary={true}
                    formData={row}>
                    <GroupItem colSpan={1} >
                        <SimpleItem
                            dataField="FunctionCode"
                            // cssClass={classes.formInput}
                            editorType="dxTextBox">
                            <RequiredRule />
                            <StringLengthRule min={2} max={6} message={"Must be 2-6 characters long"} />
                            <Label text="Function Code" />
                            <AsyncRule validationCallback={checkFuncCode}
                                ignoreEmptyValue={true}
                                reevaluate={false}
                                message="Function Code already exist" />
                        </SimpleItem>
                        <SimpleItem
                            // cssClass={classes.formInput}
                            dataField="FunctionDescription"
                            editorType="dxTextBox">
                            <RequiredRule />
                            <StringLengthRule min={3} max={100} message={"Must be 3-100 characters long"} />
                        </SimpleItem>
                        <SimpleItem
                            // cssClass={classes.formInput}
                            dataField="FunctionControllerName"
                            editorType="dxTextBox">
                            <StringLengthRule max={100} message={"Max 100 characters long"} />
                        </SimpleItem>
                        <SimpleItem
                            // cssClass={classes.formInput}
                            dataField="MenuDisplayText"
                            editorType="dxTextBox">
                            <StringLengthRule max={100} message={"Max 100 characters long"} />
                            <RequiredRule />
                        </SimpleItem>
                        <SimpleItem>
                            <Label text="Select a parent function" />
                            <Dropdown
                                placeholder={"Select parent function..."}
                                marginBottom={'125px'}
                                onChange={setCombo1}
                                value={combo1}
                                data={combo1data}
                                mapping={x => {
                                    return { value: x.FunctionId, label: `${x.FunctionCode} - ${x.FunctionDescription}` }
                                }}
                            />
                        </SimpleItem>
                    </GroupItem>
                </Form>
            </form>
        );
    }

    function buttonName(mode) {
        let t = mode === "edit" ? "Update" : "Add"
        return t
    }

    function title(mode) {
        let t = mode === "edit" ? "Edit Function" : "Add New Function";
        return t;
    }

    //*reload to make sure we have no data issues */
    function preLoad(id) {
        axios.get(`/api/Functions/Single?id=${id}`).then(x => {
            row.FunctionCode = x.data.FunctionCode
            row.FunctionControllerName = x.data.FunctionControllerName
            row.FunctionDescription = x.data.FunctionDescription
            row.FunctionId = x.data.FunctionId
            row.MenuDisplayText = x.data.MenuDisplayText
            row.FunctionControllerVirtualPath = x.data.FunctionControllerVirtualPath
            setCombo1(x.data.FunctionParentId)
            row.FunctionId = x.data.FunctionId
            if (!isNull(roleForm.current)) {
                roleForm.current.instance.updateData(row)
            }
        }).catch(e => {
            errorMessage(e)
        });
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            onClose={x => {
                props.onClose(false)
            }}
            open={props.show}
            sx={classes.dialog}
            maxWidth="lg"
            fullWidth={false}>
            <DialogTitle
                sx={classes.dialogTitle}
                disableTypography={true}
                children={
                    title(props.mode)
                } />
            <DialogContent
                sx={classes.root}>
                {renderPopup()}
            </DialogContent>
            <DialogActions>
                <Button style={{ minWidth: "100px" }} onClick={x => {
                    onSubmit()
                }}>{buttonName(props.mode)}</Button>
                <Button style={{ minWidth: "100px" }} onClick={x => {
                    setCombo1(null)
                    props.onClose(false)
                }}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
