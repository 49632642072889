import enMessages from '../locales/en_US.json';
import enErrors from '../locales/errors_enUS.json';

const EnLang = {
  messages: {
    ...enMessages,
    ...enErrors
  },
  locale: 'en-US',
};
export default EnLang;
