import GridContainer from '@jumbo/components/GridContainer';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import axios from 'axios';
import FileUploader from 'devextreme-react/file-uploader';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { buttonStyles } from 'theme/cashflowsTheme';
import { m5 } from '../Partials/Mui5';
import { EmptyTextarea } from '../Partials/StyledTextArea';
import { subCons } from './data';
import { padStart, sumBy } from 'lodash';
import { isUndefOrNull } from 'app/utils/constants/AppConstants';

const materialUITextFieldProps = x => {
    return {
        label: x,
        multiline: false,
        margin: "dense",
        fullWidth: true,
        size: 'small',
        variant: 'standard'
    }
}

export default function ReSubmitClaim(props) {
    const [attachment, setAttachment] = useState(null)
    const [claimRow, setClaimRow] = useState({})
    const confirm = useConfirm()
    const { theme } = useJumboTheme()
    const styles = m5(theme)
    const btnStyle = buttonStyles(theme)

    const claim = {
        ClaimID: 0,
        ClaimNumber: '',
        ClaimPeriod: Date.now(),
        ClaimAmount: 0,
        Notes: "",
        RetentionAmount: 0,
        AssessedAmount: 0,
        RetainedNet: 0,
        ApprovedNet: 0,
        MethodOfApproval: '',
        StatusId: 0
    }

    useEffect(() => {
        if (props.claimID > 0 && props.show) {
            axios.get(`/api/Project/GetClaim?key=${props.claimID}`)
                .then(({ data }) => {
                    console.log("🚀 ~ file: ReSubmitClaim.jsx ~ line 56 ~  ~ props", data)
                    claim.ClaimID = data.ClaimID
                    claim.ClaimNumber = data.ClaimNumber
                    claim.ClaimAmount = data.ClaimedAmount
                    claim.Notes = data.Notes
                    claim.ClaimPeriod = data.ClaimPeriod
                    claim.AssessedAmount = data.AssessedAmount
                    claim.RetentionAmount = data.RetentionAmount
                    claim.ClaimNumber = data.ClaimNumber
                    claim.StatusId = data.StatusId
                    setClaimRow({
                        ...claim
                    })
                })
                .catch(err => {
                })
        }
    }, [props.claimID, props.show])

    const onSave = async (type) => {
        try {
            if (type === "resubmit") {
                let d = {
                    "ClaimID": claimRow.ClaimID,
                    "AssessedAmount": claimRow.AssessedAmount,
                    'Notes': claimRow.Notes,
                    'RetentionAmount': numeral(claimRow.RetentionAmount).value(),
                    'MethodOfApproval': claimRow.MethodOfApproval,
                    'ProjectID': props.projID, //Get ProjectId of selected Project
                    "StatusID": claimRow.StatusID,
                    "ClaimNumber": claimRow.ClaimNumber,
                    "ClaimedAmount": numeral(claimRow.ClaimAmount).value()
                }
                console.log("🚀 ~ file: ReSubmitClaim.jsx ~ line 89 ~ ReSubmitClaim ~ save", d)
                //send to server    
                await axios.post('/api/Project/ProvideMoreInfo', { ...d });

                props.onClose(true)
            } 
        }
        catch (err) {
            console.error(err);
            // setError("failed")
        }
    }

    return (
        <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            sx={styles.dialog}
            open={props.show}>
            <DialogTitle
                sx={styles.dlgTitle}
                children={
                    <IntlMessages id="ReSubmitClaim.DetailDlg.Title" />
                } />
            {/* remove scrollbar */}
            <DialogContent
                sx={{ ...styles.root, ...styles.dlgContent }}
            >
                <GridContainer>
                    <Grid item lg={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    variant='standard'
                                    size={'small'}
                                    placeholder={'Claim Number'}
                                    value={claimRow.ClaimNumber}
                                    readOnly
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ ...styles.root, ...styles.formControl }}
                                    label="Claim Number" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    variant='standard'
                                    size={'small'}
                                    placeholder={'Claim Period'}
                                    value={moment(claimRow.ClaimPeriod).format('yyyyMM')}
                                    sx={{ ...styles.root, ...styles.formControl }}
                                    label="Claim Period" />
                            </Grid>
                            <Grid item xs={6}>
                                <NumericFormat
                                    value={claimRow.ClaimAmount}
                                    prefix='$'
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    onClick={(event) => {
                                        if (event.target.value === '$0.00') {
                                            event.target.value = '';
                                        }
                                    }}
                                    onFocus={(e) => {
                                        const value = e.target.value;                                   
                                        const dotIndex = value.indexOf('.');
                                        e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                                      }}
                                    onChange={x => setClaimRow({
                                        ...claimRow,
                                        ClaimAmount: x.target.value
                                    })}
                                    customInput={TextField}
                                    {...materialUITextFieldProps("Claim Amount")} />
                            </Grid>
                            <Grid item xs={6}>
                                <NumericFormat
                                    value={claimRow.RetentionAmount}
                                    prefix='$'
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    onClick={(event) => {
                                        if (event.target.value === '$0.00') {
                                            event.target.value = '';
                                        }
                                    }}
                                    onFocus={(e) => {
                                        const value = e.target.value;                                   
                                        const dotIndex = value.indexOf('.');
                                        e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                                      }}
                                    onChange={x => setClaimRow({
                                        ...claimRow,
                                        RetentionAmount: x.target.value
                                    })}
                                    customInput={TextField}
                                    {...materialUITextFieldProps("Retention Amount")} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={6}>
                        <div className="fileuploader-container">
                            <FileUploader
                                multiple={false}
                                onValueChange={x => {
                                    console.log("🚀 ~ file: ReSubmitClaim.jsx ~ line 178 ~ ReSubmitClaim ~ x", x[0])
                                    setAttachment(x[0])
                                }}
                                selectButtonText="Select Attachment"
                                accept={'.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .zip, .gif'}
                                //accept="image/*"
                                uploadMode="useForm" />
                        </div>
                    </Grid>
                    <Grid item lg={12}>
                        <EmptyTextarea
                            aria-label='Claim Notes'
                            placeholder='Claim Notes'
                            value={claimRow.Notes}
                            onChange={x => {
                                setClaimRow({
                                    ...claimRow,
                                    Notes: x.target.value
                                })
                            }}
                        />
                    </Grid>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='info'
                    onClick={x => {
                        const claimedAmt = numeral(claimRow.ClaimAmount).value()
                        let t = sumBy(subCons._array, x => {
                            return x.AssessedAmount
                        })
                        if (isUndefOrNull(t) == false && claimRow.ClaimAmount < t) {
                            confirm({
                                description: "Cannot submit claim of value less than Sub-Contractor payments",
                                title: "Claim Amount",
                                hideCancelButton: true
                            }).then(resp => { }).catch(err => {
                            })
                        } 
                        else  {
                            var confirmationMessage = "Do you want to resubmit this claim?";
                            confirm({
                                allowClose: true,
                                description: confirmationMessage,
                                title: "Confirm action",
                            }).then(resp => {
                                onSave("resubmit")
                            }).catch((e) => {
                                console.error("🚀 ~ file: ReSubmitClaim.jsx:234 ~ ReSubmitClaim Claim ~ e", e)
                            })
                        }
                    }}>ReSubmit</Button>
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='inherit'
                    onClick={x => {
                        props.onClose(false)
                        //subCons.clear()
                    }}>Cancel</Button>
            </DialogActions>
        </Dialog>)
}
