import styled from '@emotion/styled';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages, { IntlMessage } from '@jumbo/utils/IntlMessages';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import { buttonStyles, useStyles } from '../../../theme/cashflowsTheme';
import { showSuccessToast, showErrorToast } from './Notify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// import RoleTableGrid from './RoleTableGrid';
// import TeamSelection from './TeamSelection';

const StyledDTitle = styled(DialogTitle)((theme) => ({
    "&.MuiDialogTitle-root": {
        fontSize: '1.50rem',
        lineHeight: '1.2rem',
        fontWeight: '600'
    },
}))

export const PersonEditCtx = createContext()

export const PersonAddDlg = (props) => {
    const { theme } = useJumboTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, setState] = useState({
        FirstName: "",
        LastName: "",
        UserName: "",
        UserPassword: "",
        UserEmail: "",
        ContactID: 0,
        IsLocked: false,
        IsActive: true
    })
    const [editData, setEditData] = useState({ orgRoles: [], funcRoles: [] })
    const classes = useStyles(theme)
    const btn = buttonStyles(theme)

    const validationSchema = Yup.object().shape({
        FirstName: Yup.string().required().min(1).max(50),
        LastName: Yup.string().required().min(1).max(50),
        UserName: Yup.string().required().min(4).max(50).test("Username test", "User Name already exist", async (ctx, val) => {
            return axios.get(`api/User/Valid?username=${val.originalValue}&editingID=${props.selectedUser}`).then(x => {
                return x.data
            }).catch((e) => {
                showErrorToast(IntlMessage("E0008"))
            });
        }),
        UserEmail: Yup.string().email().required().min(8).max(50).test("Email Valid", "Email already exist", async (ctx, val) => {
            return axios.get(`api/User/ValidEmail?email=${val.originalValue}&editingID=${props.selectedUser}`).then(x => {
                return x.data
            }).catch((e) => {
                showErrorToast(IntlMessage("E0008"))
            });
        }),
    })

    useEffect(() => {
        if (props.mode === "new") {
            setState({
                UserName: "",
                FirstName: "",
                LastName: "",
                UserEmail: "",
                UserPassword: "",
                UserId: 0,
                IsLocked: false,
                IsActive: false
            })
        }
    }, [props.mode])

    useEffect(() => {
        if (!props.show) return
        axios.get(`api/User/GetUser?id=${props.selectedUser}`).then(x => {
            setState({
                UserEmail: x.data.Email,
                FirstName: x.data.FirstName,
                LastName: x.data.LastName,
                UserName: x.data.UserName,
                IsActive: x.data.IsActive,
                IsLocked: x.data.IsLocked,
                UserId: props.selectedUser,
            })
        }).catch(e => console.log(e.message))
    }, [props.show, props.selectedUser])

    // const PostAction = (data) => {
    //     if (props.mode === "new") {
    //         userStore.insert({ ...data }).then(x => {
    //             props.onClose({ refresh: true })
    //             axios.post("/api/Contacts/OrgContactEdit", { ...editData }).then(({ data }) => { }).catch(err => {
    //                 showErrorToast("Could not update roles")
    //             })
    //         }).catch(e => {
    //             showErrorToast("Could not create user")
    //         })
    //     } else if (props.mode === "edit") {
    //         userStore.update(state.UserId, { ...data }).then(x => {
    //             props.onClose({ refresh: true })
    //             axios.post("/api/Contacts/OrgContactEdit", { ...editData }).then(({ data }) => { }).catch(err => {
    //                 showErrorToast("Could not update roles")
    //             })
    //         }).catch(e => {
    //             showErrorToast("Could not edit roles")
    //         })
    //     }
    // }
    const formik = useFormik({
        validationSchema: validationSchema,
        onSubmit: function (values) {
            console.log("🚀 ~ file: PersonAddDlg.js:119 ~ PersonAddDlg ~ values:", JSON.stringify(values))
            axios.put("/api/User", {
                ...state
            }).then(resp => {
                showSuccessToast("User info Updated")
            }).catch(err => {
                showErrorToast("Failed to update user")
            })
        },
        initialValues: {
            ...state,
            UserId: props.selectedUser
        },
        enableReinitialize: true
    })

    return (
        <PersonEditCtx.Provider value={{ editData, setEditData }}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                onClose={x => {
                    props.onClose(false)
                }}
                sx={classes.dialog}
                maxWidth={'lg'}
                open={props.show}>
                <StyledDTitle
                    children={
                        props.mode === "new" ? <IntlMessages id="UserManager.AddPersonDlg.Title" /> : <IntlMessages id="UserManager.AddPersonDlg.TitleE" />
                    }></StyledDTitle>
                <DialogContent sx={classes.root}>
                    <Grid container alignItems={'flex-start'}>
                        <form>
                            <Grid container columnSpacing={'10px'} rowSpacing={'15px'} sx={{ padding: '10px' }}>
                                <Grid item sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        margin={'none'}
                                        label="First Name"
                                        placeholder='First Name'
                                        error={formik.touched.FirstName && Boolean(formik.errors.FirstName)}
                                        helperText={formik.touched.FirstName && formik.errors.FirstName}
                                        value={formik.values.FirstName}
                                        onChange={x => {
                                            setState(prev => ({
                                                ...prev,
                                                FirstName: x.target.value
                                            }))
                                        }}
                                        variant='outlined' />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        margin={'none'}
                                        label="Last Name"
                                        placeholder='Last Name'
                                        error={formik.touched.LastName && Boolean(formik.errors.LastName)}
                                        helperText={formik.touched.LastName && formik.errors.LastName}
                                        value={formik.values.LastName}
                                        onChange={x => {
                                            setState(prev => ({
                                                ...prev,
                                                LastName: x.target.value
                                            }))
                                        }}
                                        variant='outlined' />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        margin={'none'}
                                        label="User Name"
                                        placeholder='User Name'
                                        error={formik.touched.UserName && Boolean(formik.errors.UserName)}
                                        helperText={formik.touched.UserName && formik.errors.UserName}
                                        value={formik.values.UserName}
                                        onChange={x => {
                                            setState(prev => ({
                                                ...prev,
                                                UserName: x.target.value
                                            }))
                                        }}
                                        inputProps={{
                                            autoComplete: "off",
                                        }}
                                        variant='outlined' />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        margin={'none'}
                                        type='email'
                                        label="Email"
                                        error={formik.touched.UserEmail && Boolean(formik.errors.UserEmail)}
                                        helperText={formik.touched.UserEmail && formik.errors.UserEmail}
                                        value={formik.values.UserEmail}
                                        onChange={x => {
                                            setState(prev => ({
                                                ...prev,
                                                UserEmail: x.target.value
                                            }))
                                        }}
                                        variant='outlined' />
                                </Grid>
                            </Grid>
                            <Grid direction={'row'} container alignItems={'flex-start'}>
                                <Grid item sm={3}>
                                    <FormControlLabel label={'Active'} control={<Checkbox checked={state.IsActive} />} />
                                </Grid>
                                <Grid item sm={3}>
                                    <FormControlLabel label={'Locked'} control={<Checkbox checked={state.IsLocked} />} />
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={x => {
                            formik.submitForm()
                        }}
                        sx={btn.button}
                        variant='contained'>
                        {props.mode === "new" ? "Add" : "Update"}
                    </Button>
                    <Button sx={btn.button} variant='contained' color='inherit'
                        onClick={
                            () => props.onClose(false)
                        }>Close</Button>
                </DialogActions>
            </Dialog>
        </PersonEditCtx.Provider>
    );
}
