//** Manage User roles i.e. Assign existing users to existing roles */
import { useJumboTheme } from '@jumbo/hooks';
import { Alert, Button, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { isEmpty, isNil } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { buttonStyles } from 'theme/cashflowsTheme';
import { newProjData } from '../../../@data/localStore';
import { EditorCtx } from '../CurrentProjectsSummary';
import AddBankAccount from '../OrgManager/AddBankAccount';
import MuiGrid, { TableColumns } from '../Partials/MuiTable';
import AddEditProjectBankAccount from './ProjectBankAccount';

export default function ProjectBankAccountsGrid(props) {
    const [showNewPayment, setshowNewPayment] = useState(false)
    const [editMode, setEditMode] = useState("new")
    const [error, setError] = useState('')
    const [bankAccountData, setBankAccountData] = useState(null)
    const [selectedRow, setSelectedRow] = useState({})
    const [showAddAccount, setShowAddAccount] = useState(false)
    const [userOrg, setUserOrg] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const { theme } = useJumboTheme()
    const btnStyle = buttonStyles(theme)
    const [projData, setProjData] = useRecoilState(newProjData)
    const { editorProps, setEditorProps } = useContext(EditorCtx)

    //Load data
    useEffect(() => {
        console.log("🚀 ~ ProjectBankAccountsGrid ~ refresh:", refresh)
        if (!isNil(props.projID) && props.projID != -1) {
            axios.get(`/api/Project/GetProjectBankAccounts?project=${props.projID}`).then(resp => {
                setBankAccountData(resp.data)
            }).catch(err => {
                console.error("🚀 ~ file: ProjectBankAccount.jsx ~ line 33 ~ axios.get ~ err", err)
            })
        }
    }, [props.projID, refresh])
    const cols = TableColumns("tables.ProjectAccountsTable.header.columns")

    useEffect(() => {
        if (userOrg != 0) return
        axios.get("api/Organisation/MyOrgs").then(resp => {
            setUserOrg(resp.data.OrganisationID)
        }).catch(e => {
        })
    }, [])

    return <Box>
        <Button
            variant="contained"
            color='info'
            aria-controls="fade-menu"
            onClick={x => {
                setshowNewPayment(true)
                //setshowApprovePayment(false)
                setEditMode("new")
            }}
            sx={{
                ...btnStyle.button,
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">Add Account</Button>
        <Button
            variant="contained"
            aria-controls="fade-menu"
            color='info'
            disabled={(!isNil(selectedRow) && !isEmpty(selectedRow)) ? selectedRow.StatusID != 13 : true} //58 : Submitted
            onClick={x => {
                setshowNewPayment(true)
                setEditMode("edit")
            }}
            sx={{
                ...btnStyle.button,
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">Update Account</Button>
        <Button
            variant="contained"
            color='info'
            aria-controls="fade-menu"
            onClick={x => {
                setShowAddAccount(true)
            }}
            sx={{
                ...btnStyle.button,
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">Create Bank Account</Button>
        <div id="recaptha"></div>
        <Box sx={{ height: 700, width: '100%' }}>
            <MuiGrid
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                    sorting: {
                        sortModel: [{ field: 'AccountName', sort: 'asc' }],
                    },
                }}
                rows={bankAccountData ?? []}
                loading={bankAccountData == null}
                columns={cols}
                getRowId={(row) => row.MapProjectBankAccountID}
                onRowClick={x => {
                    setSelectedRow(x.row)
                }}
            ></MuiGrid>
        </Box>
        <Box>
            <AddBankAccount
                show={showAddAccount}
                mode={'new'}
                OrgID={userOrg}
                projID={props.projID}
                projDetails={props.projDetails}
                editRow={editorProps.selectedUser}
                onClose={d => {
                    setShowAddAccount(false)
                    setRefresh(prev => !prev)
                }} />
        </Box>
        <AddEditProjectBankAccount
            show={showNewPayment}
            mode={editMode}
            projID={props.projID}
            projDetails={props.projDetails}
            projData={projData}
            refresh={refresh}
            mapProjAccountID={!isEmpty(selectedRow) ? selectedRow.MapProjectBankAccountID : 0}
            onClose={x => {
                setshowNewPayment(false)
                if (!isNil(props.projID) && props.projID != -1 && x) {
                    axios.get(`/api/Project/GetProjectBankAccounts?project=${props.projID}`)
                        .then(resp => {
                            setBankAccountData(resp.data)
                            setProjData(projData => ({
                                ...projData,
                                reload: !projData.reload
                            }))
                        }).catch(err => {
                            console.error("🚀 ~ file: Payments.jsx ~ line 169 ~ axios.get ~ err", err)
                        })
                }
            }} />
        <Snackbar open={error === 'success'} autoHideDuration={6000} onClose={x => setError('closed')}>
            <Alert severity="error">
                Failed to save Project bank account, please try again later
            </Alert>
        </Snackbar>
        <Snackbar open={error === 'failed'} autoHideDuration={6000} onClose={x => setError('closed')}>
            <Alert severity="error">
                Failed to save Project bank account, please try again later
            </Alert>
        </Snackbar>
    </Box>
}
