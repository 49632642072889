//** Manage User roles i.e. Assign existing users to existing roles */
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageContainer';
import { useJumboTheme } from '@jumbo/hooks';
import { AddCircleOutline, BusinessOutlined, Edit } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import { ErrorMsgTimeout } from 'app/utils/constants/AppConstants';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { orgs } from '../../../@data/liveStores';
import { roleStyles } from '../../../theme/cashflowsTheme';
import { EditorCtx } from '../CurrentProjectsSummary';
import MuiGrid, { TableColumns } from '../Partials/MuiTable';
import { showErrorToast } from '../Partials/Notify';
import OneTab from '../Partials/OneTab';
import OrgDetails from './OrgDetails';

const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Organisation Manager', isActive: true },
];

function OrgBody(props) {
    const [data, setData] = useState([])
    useEffect(() => {
        orgs.load().then(resp => {
            setData(resp.data)
        }).catch(e => {
        })
    }, [])
    return <MuiGrid
        rows={data}
        columns={TableColumns("tables.OrgTable.header.columns")}
        getRowId={x => x.OrganisationID}
        autoPageSize={false}
        pageSize
        pageSizeOptions={[25, 50]} />
}

/**
 * @param {*} props
 * @returns
 */
export default function OrgManager(props) {
    const { theme } = useJumboTheme()
    const classes = roleStyles(theme);
    const [showNewOrg, setShowNewOrg] = useState(false)
    const [editMode, setEditMode] = useState("new")
    const editBtn = useRef(null)
    const [editorProps, setEditorProps] = useState({})

    useEffect(() => {
        const listener = x => {
            editBtn.current.click()
        }
        document.addEventListener("dblclick", listener)
        return () => {
            document.removeEventListener("dblclick", listener)
        }
    }, [])

    return (
        <PageContainer
            breadcrumbs={breadcrumbs}>
            <GridContainer>
                <EditorCtx.Provider value={{ editorProps, setEditorProps }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={classes.root}
                            id={"one_tab"}
                            width={1}>
                            <OneTab
                                child={
                                    <OrgBody
                                        props={props}
                                        setShowNewRole={setShowNewOrg}
                                        showNewRole={showNewOrg}
                                        editMode={editMode}
                                        setEditMode={setEditMode}
                                    />}
                                label="Organisation Manager"
                                iconTitle={"Organisation Manager"}
                                iconSubTitle={"Current Organisations"}
                                addButton={true}
                                addButtonfx={
                                    <IconButton
                                        size="small"
                                        title={"Add new Organisation"}
                                        onClick={(e) => {
                                            setEditMode("new")
                                            setShowNewOrg(true);
                                        }}>
                                        <AddCircleOutline fontSize="medium" />
                                    </IconButton>
                                }
                                editButtonfx={
                                    <IconButton
                                        ref={editBtn}
                                        size="small"
                                        title="Edit Organisation"
                                        onClick={x => {
                                            if (isNil(editorProps.selectedUser)) {
                                                showErrorToast("No Organisation selected for editing")
                                                return
                                            }
                                            setShowNewOrg(true)
                                            setEditMode("edit")
                                        }}>
                                        <Edit fontSize="medium" />
                                    </IconButton>
                                }
                                icon={<BusinessOutlined />}
                            />
                        </Box>
                    </Grid>
                    {console.log("🚀 ~ file: OrgManager.jsx ~ line 139 ~ OrgManager ~ editorProps.selectedUser", editorProps.selectedUser)}
                    <OrgDetails
                        mode={editMode}
                        show={showNewOrg}
                        editID={editorProps.selectedUser}
                        onClose={x => {
                            if (x) {
                                if (!isNil(editorProps.dataGrid) && !isNil(editorProps.dataGrid.current)) {
                                    editorProps.dataGrid.current.instance.refresh()
                                }
                            }
                            setShowNewOrg(false)
                        }} />
                </EditorCtx.Provider>
            </GridContainer>
        </PageContainer>);
}
