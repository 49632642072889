import { useEffect, useState } from "react";
import { StatusStore } from "../../../@data/liveStores";
import Dropdown from "./DropBox";
import { ProjectStatusStore } from "../../../@data/liveStores";
import { isNil, isEmpty } from 'lodash'

export default function StatusSelect({ selectedStatus, setStatus, filtered = false, filter }) {
    const [orgData, setOrgData] = useState([])
    useEffect(() => {
        if (filtered) {
            ProjectStatusStore.load().then(({ data }) => {
                if (!isNil(filter) && !isEmpty(filter)) {
                    data = data.filter(({ Name }) => filter.indexOf(Name) > -1)
                }
                setOrgData(data)
            })
        }
        else {
            StatusStore.load().then(({ data }) => {
                if (!isNil(filter) && !isEmpty(filter)) {
                    data = data.filter(({ Name }) => filter.indexOf(Name) > -1)
                }
                setOrgData(data)
            })
        }
    }, [])

    return (<Dropdown
        placeholder="Status"
        value={selectedStatus}
        data={orgData}
        mapping={x => {
            return { value: x.StatusID, label: x.Name }
        }}
        onChange={setStatus}
    />)
}
