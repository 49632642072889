import React from "react";
// import Home from "../pages/home";
import Page from "@jumbo/shared/Page";
import SignIn from "app/pages/auth/SignIn";
import { BlankPage } from "app/pages/404";
import OrgManager from "app/pages/OrgManager/OrgManager";
import OrgManagerNoGrid from "app/pages/OrgManager/OrgManagerNoGrid";
import { Developers } from "app/pages/Construction/Developers";
import SignUp from "app/pages/auth/SignUp";
import { DeveloperProjectDetails } from "app/pages/Construction/DeveloperProjects";
import UserProfile from "app/pages/UserManagement/UserProfile";
import ForgotPassword from "app/pages/auth/ForgotPassword";
import { Jobs } from "app/pages/WorkFlow/Jobs";
import UserRoleManager, { UserFunctionsManager } from "app/pages/RoleManager/UserRoleManager";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    // {
    //     path: "/",
    //     element: <Page component={Home} />,
    // },
    {
        path: "/signin",
        element: <Page component={SignIn} layout={"solo-page"} variant="default" />,
    },
    {
        path: "/signup",
        element: <Page component={SignUp} layout={"solo-page"} variant="standard" />,
    },
    {
        path: "/forgot-password",
        element: <Page component={ForgotPassword} layout={'solo-page'} variant="standard" />
    },
    {
        path: "/reset-password",
        element: <Page component={ForgotPassword} layout={'solo-page'} variant="standard" />
    }
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Page component={BlankPage} />,
    },
    {
        path: "/dash",
        element: <Page component={BlankPage} />,
    },
    {
        path: "/orgs",
        element: <Page component={OrgManager} />,
    },
    {
        path: "/orgdetails",
        element: <Page component={OrgManagerNoGrid} mode="edit" />,
    },
    {
        path: "/orgadd",
        element: <Page component={OrgManagerNoGrid} mode="new" />,
    },
    {
        path: "/developers",
        element: <Page component={Developers} />
    },
    {
        path: "/projects",
        element: <Page component={DeveloperProjectDetails} />
    },
    {
        path: "/profile",
        element: <Page component={UserProfile} />
    },
    {
        path: "/jobs",
        element: <Page component={Jobs} />
    },
    {
        path: "/roles",
        element: <Page component={UserRoleManager} />
    },
    {
        path: "/funcs",
        element: <Page component={UserFunctionsManager} />
    }
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };
