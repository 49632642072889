import { OrgContext } from '@data';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Close } from '@mui/icons-material';
import {
    Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControlLabel, Grid, IconButton,
    Link,
    Snackbar, TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { clone, cloneDeep, isEmpty, isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { buttonStyles, gridStyles } from 'theme/cashflowsTheme';
import { ContactTypes, StatusStore } from '../../../@data/liveStores';
import { newProjData, projSaveState } from '../../../@data/localStore';
import { EditParticipant, EditParticipants } from '../../../@data/project_api';
import OrgDetails from '../OrgManager/OrgDetails';
import ContactsType from '../Partials/ContactsType';
import Dropdown from '../Partials/DropBox';
import { CustomPagination, printDate } from '../Partials/MaterialGrid';
import { m5 } from '../Partials/Mui5';
import { showErrorToast, showSuccessToast } from '../Partials/Notify';
import PaymentType from '../Partials/PaymentType';
import StatusSelect from '../Partials/StatusSelect';

const label = { inputProps: { 'aria-label': 'Checkbox' } };

export function validate(row, projectParticipants, setErrorTxt) {
    /*  Status: "Active"
        StatusID: 13 */
    /*  Role: "Financial Responsible Party"
        RoleID: 79 */
    /*  Role: "Head Contractor"
        RoleID: 43 */
    /*  Role: "Developer"
        RoleID: 77 */
    // RoleID: 66, Role: "Subcontractor"
    // RoleID: 51, Role: "Supplier"

    let error = false
    if (row.ContactTypeID == 43 && row.FRP) {
        error = true
        setErrorTxt("Head Contractor cannot be FRP")
    }
    if (row.ContactTypeID == 66 && row.FRP) {
        error = true
        setErrorTxt("Sub Contractor cannot be FRP")
    }
    projectParticipants.forEach(e => {
        // console.log(`🚀 ~ file: ProjectParticipants.jsx:64 ~ validate ~ e.Status: ${e.Status} - OrgID: ${e.OrgID}`)
        if (e.Status !== "Ended" && e.OrgID == row.OrgID) {
            error = true
            setErrorTxt("Organisation already exist")
        }
        if (e.ContactTypeID == row.ContactTypeID && e.OrgID == row.OrgID) {
            error = true
            setErrorTxt("Organisation already exist with same role")
        }
        if (e.FRP && row.FRP) {
            error = true
            setErrorTxt("Project has already FRP assigned")
        }
        if (true) {//TODO add back this check: e.StatusID == 13 && row.StatusID == 13
            if (e.ContactTypeID == 43 && row.ContactTypeID == 43) {
                error = true
                setErrorTxt('Cannot have two active Contractors on same project')
            }
            if (e.ContactTypeID == 79 && row.ContactTypeID == 79) {
                error = true
                setErrorTxt('Cannot have two active FRP on same project')
            }
            if (e.ContactTypeID == 77 && row.ContactTypeID == 77) {
                error = true
                setErrorTxt('Cannot have two active Developers on same project')
            }
        }
        if (row.ContactTypeID == 43) {
            if (e.OrgID == row.OrgID) {
                error = true
                setErrorTxt('Cannot have Contractor from same Organisation on same project')
            }
        }
        if (e.ContactTypeID == 77 || e.ContactTypeID == 79) {
            if (row.ContactTypeID == 51 || row.ContactTypeID == 66) {
                if (e.OrgID == row.OrgID) {
                    error = true
                    setErrorTxt('Cannot have same org as supplier and developer / FRP at the same time')
                }
            }
        }
    })

    // console.log("🚀 ~ file: ProjectParticipants.jsx ~ line 129 ~ validate ~ !error", !error)
    if (error == false) {
        setErrorTxt("")
    }
    return !error
}

export default function ProjectParticipants(props) {
    const [selectedStatus, setStatus] = useState(null)
    const [selectedContactRole, setSelectedContactRole] = useState(null)
    const [selectedPaymentType, setSelectedPaymentType] = useState(null)
    const [org, setOrg] = useState(0)
    const [showNewOrg, setShowNewOrg] = useState(false)
    const [editorProps, setEditorProps] = useState({
        selectedUser: {
            StartDate: null,
            EndDate: null,
            Organization: "",
            Role: "",
            Status: "",
            FRP: false
        }
    })
    const [projData, setProjData] = useRecoilState(newProjData)
    const setProjSaveState1 = useSetRecoilState(projSaveState)
    const [projectParticipants, setProjectParticipants] = useState([])
    const [errorTxt, setErrorTxt] = useState('')
    const [disableEditing, setDisableEditing] = useState(true)
    const [showEditor, setShowEditor] = useState(false)
    const [participantFRP, setParticipantFRP] = useState(false)
    const editBtn = useRef(null)
    const [orgMode, setOrgMode] = useState("new")
    const [abnRetry, setAbnRetry] = useState(false)
    const [busy, setBusy] = useState(false)
    const { orgData } = useContext(OrgContext)
    const { theme } = useJumboTheme()
    const btnStyles = buttonStyles(theme)
    const gridStyle = gridStyles(theme)
    const confirm = useConfirm();

    useEffect(() => {
        if (!isNil(editorProps) && !isNil(editorProps.selectedUser) && editorProps.selectedUser['Organization'] !== "") {
            setDisableEditing(false)
        } else {
            setDisableEditing(true)
        }
    }, [editorProps, editorProps.selectedUser])

    useEffect(() => {
        setStatus(13)
        setProjSaveState1(false)
        //load saved project info
        if (!isNil(projData['ProjectContacts']) && props.editMode !== "new") {
            // console.log("🚀 ~ file: ProjectParticipants.jsx:157 ~ useEffect ~ projData['ProjectContacts']:", projData['ProjectContacts'])
            setProjectParticipants(projData['ProjectContacts'])
        }
        //*** For participant wizard(new project)
        if (props.editMode === "new") {
            axios.get("api/Organisation/MyOrgs").then(resp => {
                let row = {
                    Organization: resp.data.BusinessName,
                    Organisation: resp.data.BusinessName,
                    OrgID: resp.data.OrganisationID,
                    ContactTypeID: 77, //Developer
                    Role: 'Developer',
                    Status: 'Active',
                    StatusID: 13,
                    StartDate: utcDate,
                    EndDate: null,
                    FRP: false
                }
                // console.log("🚀 ~ file: ProjectParticipants.jsx:173 ~ axios.get ~ setProjectParticipants:")
                setProjectParticipants(prev => {
                    return [...prev, row]
                })
                setProjData(prev => ({
                    ...prev,
                    'ProjectContacts': [...prev['ProjectContacts'], row]
                }))
            }).catch(err => {
                console.error(err)
            })
        }
        return () => {
            setProjSaveState1(true)
        }
    }, [])

    const columns = [
        {
            field: 'Organisation',
            headerName: 'Organisation',
            flex: 2,
        },
        {
            field: 'ContactType',
            headerName: 'Role',
            flex: 1,
        },
        {
            field: 'FRP',
            headerName: 'FRP',
            renderCell: (params) => {
                const check = params.row.FRP
                if (check === true) {
                    return <Checkbox {...label} disabled checked />
                }
                else {
                    return <Checkbox {...label} disabled />
                }

            },
            flex: 0.5,
        },
        {
            field: 'Status',
            headerName: 'Status',
            renderCell: (params) => {
                const checkStatus = params.row.StatusID
                if (checkStatus === 74) {
                    return <Tooltip title="Send Reminder" arrow>
                        <Link component="button"
                            variant="body2"
                            onClick={async () => {
                                // console.log("🚀 ~ file: ProjectParticipants.jsx:279 ~ ProjectParticipants ~ params:", params.row)
                                axios.get(`/api/Organisation/OrgWarning1?OrgID=${params.row.OrgID}`).then(resp => {
                                    showSuccessToast("Email reminder sent")
                                }).catch(err => {
                                    showErrorToast("Failed to send email reminder")
                                })
                            }}>{params.row.Status}</Link>
                    </Tooltip>
                }
            },
            flex: 1,
        },
        {
            field: 'StartDate',
            headerName: 'Start Date',
            renderCell: (params) => {
                return printDate(params.row.StartDate)
            },
            flex: 1,
        },
        {
            field: 'EndDate',
            headerName: 'End Date',
            renderCell: (params) => {
                return printDate(params.row.EndDate)
            },
            flex: 1,
        },
        //TODO Jason asked to disable it for now
        // {
        //     field: 'Delete',
        //     headerName: 'Delete',
        //     renderCell: (x) => {
        //         return <IconButton aria-label="delete" data-value={JSON.stringify(x.row)} onClick={e => {
        //             handleDelete(x.row)
        //             e.stopPropagation()
        //         }}>
        //             <DeleteIcon />
        //         </IconButton>
        //     },
        //     flex: 1,
        // },
    ];

    /**
     * Only do loading if edit mode for selected row
     */

    const utcDate = (new Date()).toISOString()
    return (<>
        <Box>
            <IntlMessages id="ProjectParticipants.DetailDlg.Title" />
            <Grid container spacing={3} rowSpacing={'8px'}>
                <Grid item xs={6}>
                    <Dropdown
                        placeholder="Add an Existing Organisation to this Project"
                        data={orgData}
                        value={org}
                        onChange={setOrg}
                        mapping={x => {
                            return { value: x.OrganisationID, label: x.BusinessName }
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        aria-controls="fade-menu"
                        sx={btnStyles.button}
                        variant='contained'
                        color='info'
                        disabled={busy}
                        onClick={(e) => {
                            setBusy(true)
                            setShowNewOrg(true);
                        }}>
                        Add New Organisation
                    </Button>
                </Grid>
                {/* <Grid item xs={4}>
                    <StatusSelect selectedStatus={selectedStatus} setStatus={setStatus} />
                </Grid> */}
                <Grid item xs={4} style={{ marginTop: '5px' }}>
                    <ContactsType selectedStatus={selectedContactRole} setStatus={setSelectedContactRole} />
                </Grid>
                {/* //TODO Jason asked to remove FRP box from here */}
                {/* <Grid item xs={2} md={2} lg={2} style={{ marginTop: '5px' }}>
                    <FormControlLabel
                        sx={classes.label}
                        control={<Checkbox
                            value={participantFRP}
                            checked={participantFRP}
                            disabled={disableFRP}
                            onChange={(v) => {
                                setParticipantFRP(v.target.checked)
                            }} />} label="FRP" />
                </Grid> */}
                {
                    selectedContactRole === 51 ? (
                        <Grid item xs={4}>
                            <PaymentType selectedStatus={selectedPaymentType} setStatus={setSelectedPaymentType} />
                        </Grid>
                    ) : null
                }
                {
                    selectedContactRole === 51 ? (
                        <Grid item xs={4}>
                            <TextField
                                id="standard-basic"
                                label="Reference Number"
                                variant={'standard'}
                                margin={'none'}
                                fullWidth
                                onChange={x => {
                                    //setCodeError('')
                                    //setProjShortCode(x.target.value)
                                }}
                            />
                        </Grid>
                    ) : null
                }
                <Grid item xs={12}>
                    <Button
                        aria-controls="fade-menu"
                        variant='contained'
                        color='info'
                        disabled={busy}
                        sx={btnStyles.button}
                        onClick={async (e) => {
                            if (org === 0 || org === -1 || selectedStatus == null) return
                            else {
                                if (selectedContactRole === 0 || selectedContactRole == null) {
                                    confirm({
                                        description: "Please select a Role for existing Organisation",
                                        title: "Missing Role",
                                        hideCancelButton: true,
                                    }).then(resp => {
                                    }).catch(err => {
                                    })
                                    return
                                }
                            }
                            setBusy(true)
                            var r = orgData.filter(e => {
                                return e.OrganisationID == org
                            })
                            var r2 = await ContactTypes.byKey(selectedContactRole)
                            var r3 = await StatusStore.byKey(13)

                            let row = {
                                Organization: clone(r[0].BusinessName),
                                Organisation: clone(r[0].BusinessName),
                                OrgID: clone(org),
                                ContactTypeID: clone(selectedContactRole),
                                ContactType: clone(r2.Name),
                                Status: clone(r3[0].Name),
                                StatusID: clone(selectedStatus),
                                StartDate: clone(utcDate),
                                EndDate: null,
                                FRP: clone(participantFRP),
                                MapProjectContactID: Date.now()
                            }
                            if (validate(row, projectParticipants, setErrorTxt)) {
                                setProjectParticipants(prev => {
                                    return [...prev, row]
                                })
                                //if in edit mode save
                                if (props.editMode === "edit") {
                                    EditParticipants({
                                        ProjectID: projData.ProjectID,
                                        OrgID: org,
                                        ContactTypeID: selectedContactRole,
                                        StatusID: selectedStatus,
                                        StartDate: utcDate,
                                        EndDate: null,
                                        FRP: participantFRP,
                                        deleted: false,
                                        edited: false
                                    })
                                }
                                setProjData(prev => ({
                                    ...prev,
                                    'ProjectContacts': [...prev['ProjectContacts'], row]
                                }))
                            }
                            //reset selections:
                            setOrg(0)
                            setSelectedContactRole(null)
                            setSelectedPaymentType(null)
                            setParticipantFRP(false)
                            setBusy(false)
                        }}>
                        Add Existing Organisation
                    </Button>
                </Grid>
            </Grid>
            <Box padding={"10px 0px"} id="participant-grid">
                <div style={{ width: "100%", textAlign: "right" }}>
                    <IconButton
                        ref={editBtn}
                        disabled={disableEditing}
                        style={{ marginRight: "50px" }}
                        title={"Select a row to edit user"}
                        onClick={() => {
                            setShowEditor(true)
                        }}
                        size={'medium'}>
                        {/* <Edit /> */}
                    </IconButton>
                </div>
            </Box>
            <Box sx={{ width: '100%', height: '100%' }} id="participantTable">
                <DataGrid
                    rows={projectParticipants}
                    onRowClick={e => {
                        // console.log("🚀 ~ file: ProjectParticipants.jsx:501 ~ ProjectParticipants ~ e", e.row)
                        setEditorProps({
                            selectedUser: {
                                StartDate: e.row['StartDate'],
                                EndDate: e.row['EndDate'],
                                Organization: e.row['Organisation'],
                                ContactTypeID: e.row['Role'],
                                Status: e.row['Status'],
                                FRP: e.row['FRP'],
                                ProjectID: projData.ProjectID,
                                OrgID: e.row['OrgID'],
                                ContactTypeID: e.row['ContactTypeID'],
                                StatusID: e.row['StatusID'],
                            }
                        })
                    }}
                    onRowDoubleClick={e => {
                        editBtn.current.click()
                    }}
                    disableColumnSelector={true}
                    columns={columns}
                    getRowId={(row) => {
                        return row.MapProjectContactID
                    }}
                    responsive='onResize'
                    sx={gridStyle}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'Organisation', sort: 'asc' }],
                        },
                    }}
                    rowSelection
                    pageSizeOptions={[10, 20, 50]}
                    // experimentalFeatures={{ newEditingApi: true }}
                    slots={{ Toolbar: GridToolbar, Pagination: CustomPagination, }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            sx: {
                                '& .MuiButtonBase-root': {
                                    color: '#ff5722'
                                }
                            }
                        },
                    }}
                />
            </Box>
            <OrgDetails
                mode={orgMode}
                show={showNewOrg}
                projID={projData.ProjectID}
                editID={editorProps.selectedUser['OrganisationID']}
                abnRetry={abnRetry}
                selectedContactRole={0}
                onClose={(x, abn, row) => {
                    setShowNewOrg(false)
                    setBusy(false)
                    setOrgMode("new")
                    let o = orgData.filter(e => {
                        return e.ABN.replace(/\W/g, '') === abn
                    })
                    if (!isNil(abn)) {
                        let found = false
                        //check for existing contacts
                        if (!isNil(projData['ProjectContacts']) && !isEmpty(o)) {
                            projData['ProjectContacts'].forEach(element => {
                                if (element.OrgID === o[0]['OrganisationID']) {
                                    found = true
                                }
                            });
                        }
                        setOrgMode("edit")
                        setAbnRetry(true)
                        if (!isEmpty(o)) {
                            setEditorProps({
                                selectedUser: {
                                    OrganisationID: o[0].OrganisationID
                                }
                            })
                            if (!found) {
                                setShowNewOrg(true)
                            }
                        }
                    } else {
                        setOrgMode("new")
                        setAbnRetry(false)
                    }
                    //TODO update participant
                    // EditParticipants({
                    //     ProjectID: projData.ProjectID,
                    //     OrgID: orgID,
                    //     RoleID: selectedContactRole,
                    //     StatusID: selectedStatus,
                    //     StartDate: utcDate,
                    //     EndDate: endDate,
                    //     FRP: participantFRP,
                    // })
                    if (!isNil(row) && !isEmpty(row) && validate(row, projectParticipants, setErrorTxt)) {
                        setProjectParticipants(prev => (
                            [...prev, cloneDeep(row)]
                        ))
                        setProjData(prev => ({
                            ...prev,
                            'ProjectContacts': [...prev['ProjectContacts'], cloneDeep(row)]
                        }))
                    }
                }} />
            <EditDates
                projectParticipants={projData['ProjectContacts']}
                open={showEditor}
                close={(x, refresh, arr) => {
                    setShowEditor(false)
                    if (!isNil(refresh) && refresh) {
                        setProjectParticipants(arr)
                    }
                }} selected={isNil(editorProps.selectedUser) ? {} : editorProps.selectedUser} />
        </Box>
        <Snackbar open={errorTxt !== ''} autoHideDuration={6000}>
            <Alert severity="error">
                {errorTxt}
            </Alert>
        </Snackbar>
    </>)
}

function EditDates(props) {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [endDateErr, setEndDateErr] = useState("")
    const [errorTxt, setErrorTxt] = useState("")
    const [participantFRP, setParticipantFRP] = useState(props.selected['FRP'] != null ? props.selected['FRP'] : false)
    const [selectedContactRole, setSelectedContactRole] = useState(null)
    const [selectedStatus, setStatus] = useState(null)
    const [frpDisabled, setFrpDisabled] = useState(false)
    const { theme } = useJumboTheme()
    const classes = m5(theme)
    const btnStyles = buttonStyles(theme)
    const setProjData = useSetRecoilState(newProjData)
    const confirm = useConfirm()

    useEffect(() => {
        if (!isNil(props.selected['StartDate'])) {
            setStartDate(new Date(props.selected['StartDate']))
        } else {
            setStartDate(null)
        }
        if (!isNil(props.selected['EndDate'])) {
            setEndDate(new Date(props.selected['EndDate']))
        } else {
            setEndDate(null)
        }
        if (props.selected['FRP'] != null) {
            setParticipantFRP(props.selected['FRP'])
        } else {
            setParticipantFRP(null)
        }
        if (!isNil(props.selected['StatusID'])) {
            setStatus(props.selected['StatusID'])
        }
        if (!isNil(props.selected['ContactTypeID'])) {
            setSelectedContactRole(props.selected['ContactTypeID'])
        }
        setErrorTxt("")
    }, [props.selected['StartDate'], props.selected['EndDate'], props.selected['FRP'], props.selected['StatusID'], props.selected['ContactTypeID']])

    useEffect(() => {
        const found = props.projectParticipants.filter(({ FRP }) => FRP)
        if (!isNil(found) && !isEmpty(found)) {
            setFrpDisabled(true)
        } else {
            setFrpDisabled(false)
        }
    }, [props.projectParticipants])

    return <Dialog
        maxWidth={"md"}
        fullWidth
        open={props.open}
        onClose={x => props.close(false)}
        sx={classes.dialog}
    >
        <DialogTitle
            children={<div style={{ marginLeft: "-10px", width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><Typography><span style={{
                padding: "0 15px",
                fontSize: "0.95rem",
                fontWeight: "bold"
            }}>Edit Participant</span><strong style={{ fontSize: "1.0rem" }}>{props.selected['Organization']}</strong></Typography>
                <IconButton style={{ padding: 0 }} aria-label="close" sx={classes.closeButton} onClick={x => props.close(false)}><Close /></IconButton></div>} />
        <DialogContent
            sx={classes.root}>
            <Grid container columnSpacing={"5px"} rowSpacing={"10px"}>
                <Grid item xs={6} md={6} lg={6}>
                    <Box sx={{ fontSize: "0.95rem", fontWeight: 'regular' }}>
                        <ContactsType selectedStatus={selectedContactRole} setStatus={setSelectedContactRole} />
                    </Box>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                    <Box sx={{ fontSize: "0.95rem", fontWeight: 'regular' }}>
                        <StatusSelect selectedStatus={selectedStatus} setStatus={setStatus} filter={['Ended', 'Active', 'Invited']} />
                    </Box>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={participantFRP}
                            sx={classes.label}
                            onChange={(v) => {
                                if (frpDisabled && !participantFRP) {
                                    confirm({
                                        description: "Please remove the existing FRP before proceeding, only one FRP per Project is allowed",
                                        title: "FRP already assigned to Project",
                                        hideCancelButton: true
                                    }).then(resp => { }).catch(err => {
                                    })
                                } else {
                                    setParticipantFRP(v.target.checked)
                                }
                            }} />} label="FRP" />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            format="dd/MM/yyyy"
                            formatDensity='dense'
                            id="date-picker-inline"
                            label="Start Date"
                            value={startDate}
                            variant={'inline'}
                            disabled={true}
                            // onChange={(v) => {
                            //     setStartDate(new Date(v))
                            // }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        /></LocalizationProvider>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            formatDensity='dense'
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label="End Date"
                            value={endDate}
                            variant={'inline'}
                            disablePast
                            // helperText={endDateErr}
                            error={endDateErr.length > 0}
                            onChange={(v) => {
                                const start = moment(startDate).set({ hour: 0, minute: 0, seconds: 0, millisecond: 0 })
                                const end = moment(v).set({ hour: 0, minute: 0, seconds: 0, millisecond: 0 })
                                if (end.isBefore(start)) {
                                    setEndDateErr("End Date should be greater than start date")
                                    setErrorTxt("End Date should be greater than start date")
                                } else {
                                    setEndDateErr("")
                                    setErrorTxt("")
                                    try {
                                        setEndDate(new Date(v))
                                    } catch (error) {
                                    }
                                    //set status to Ended
                                    setStatus(71)
                                }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        /></LocalizationProvider>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                variant='contained'
                sx={btnStyles.button}
                color='inherit'
                onClick={x => {
                    const today = moment({ hour: 0, minute: 0, seconds: 0, millisecond: 0 })
                    const end = moment(endDate).set({ hour: 0, minute: 0, seconds: 0, millisecond: 0 })
                    const start = moment(startDate).set({ hour: 0, minute: 0, seconds: 0, millisecond: 0 })
                    //end date can be same or greater
                    if (moment(end).isBefore(today)) {
                        setErrorTxt("End Date cannot be in the past")
                        return
                    }
                    if (moment(end).isBefore(moment(start))) {
                        setErrorTxt("End Date cannot be less than start date")
                        return
                    }
                    //check roles
                    let r = {
                        ProjectID: props.selected['ProjectID'],
                        OrgID: props.selected['OrgID'],
                        ContactTypeID: props.selected['ContactTypeID'],
                        StatusID: props.selected['StatusID'],
                        StartDate: isNil(startDate) ? null : clone(startDate),
                        EndDate: isNil(endDate) ? null : clone(endDate),
                        FRP: clone(participantFRP),
                        edited: true,
                        deleted: false,
                        newRole: clone(selectedContactRole),
                        newStatus: clone(selectedStatus),
                        MapProjectContactID: Date.now()
                    }
                    // e.OrgID !== r.OrgID && e.ContactTypeID !== r.ContactTypeID
                    const rem = props.projectParticipants.filter(e => {
                        if (e.OrgID !== r.OrgID) return true
                        else if (e.ContactTypeID !== r.ContactTypeID) return true
                        return false
                    })
                    let old = props.projectParticipants.filter(e => (e.OrgID === r.OrgID && e.ContactTypeID === r.ContactTypeID))[0]
                    let newItem = structuredClone(old)
                    if (validate(r, rem, setErrorTxt)) {
                        EditParticipant(r).then(async resp => {
                            setErrorTxt("")
                            newItem.ContactTypeID = clone(selectedContactRole)
                            newItem.StatusID = clone(selectedStatus)
                            var r2 = await ContactTypes.byKey(selectedContactRole)
                            var r3 = await StatusStore.byKey(selectedStatus)
                            newItem.ContactType = r2.Name
                            newItem.Status = r3[0].Name
                            newItem.EndDate = clone(endDate)
                            newItem.StartDate = clone(startDate)
                            newItem.FRP = clone(participantFRP)
                            setProjData(prev => ({
                                ...prev,
                                'ProjectContacts': [...rem, newItem]
                            }))
                            //reload
                            props.close(false, true, [...rem, newItem])
                        }).catch(err => {
                            setErrorTxt("Failed to edit participant")
                            console.error(err)
                        })
                    }
                }}>Update</Button>
            <Button
                variant='contained'
                color='inherit'
                sx={btnStyles.button}
                onClick={x => props.close(false)}>Close</Button>
        </DialogActions>
        <Snackbar open={errorTxt !== ''} autoHideDuration={6000}>
            <Alert severity="error">
                {errorTxt}
            </Alert>
        </Snackbar>
    </Dialog>
}
