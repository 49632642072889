import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
} from '@mui/material';
import { ErrorMsgTimeout, SuccessMsgTimeout } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { showErrorToast, showSuccessToast } from '../Partials/Notify';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone number is required'),
  password: Yup
    .string()
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: Yup
    .string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export default function UserProfile(props) {
  const [state, setState] = useState({})
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: state.firstName,
      lastName: state.lastName,
      password: '',
      confirmPassword: '',
      phone: state.phone,
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      console.log('VAL', values)
      Save(values)
    },
  });
  useEffect(() => {
    axios.get("/api/User/Profile")
      .then(({ data }) => {
        setState({
          firstName: data.FirstName,
          lastName: data.LastName,
          phone: data.Phone
        })
      })
      .catch(err => {
        console.error("🚀 ~ file: UserProfile.jsx:55 ~ useEffect ~ err:", err)
      })
  }, [])

  function Save(values) {
    if (values.password !== values.confirmPassword) {
      showErrorToast("Password and Confirm Password don't match")
      return
    }
    axios.put("/api/User/Profile", {
      FirstName: values.firstName,
      LastName: values.lastName,
      Password: values.password,
      Phone: values.phone,
    })
      .then(resp => {
        showSuccessToast("Updated")
      })
      .catch(err => {
        showErrorToast("Failed to save profile")
      })
  }

  return <div>
    <Container maxWidth="sm">
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.firstName && formik.errors.firstName}
              helperText={formik.touched.firstName && formik.errors.firstName}
              variant='standard'
              color='success'
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && formik.errors.lastName}
              helperText={formik.touched.lastName && formik.errors.lastName}
              variant='standard'
              color='success'
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              color='success'
              variant='standard'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.password &&
                formik.values.password.length > 0 &&
                Boolean(formik.errors.password)
              }
              helperText={
                formik.touched.password &&
                formik.values.password.length > 0 &&
                formik.errors.password
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              color='success'
              variant='standard'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword &&
                formik.values.confirmPassword.length > 0 &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword &&
                formik.values.confirmPassword.length > 0 &&
                formik.errors.confirmPassword
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="phone"
              name="phone"
              label="Phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && formik.errors.phone}
              helperText={formik.touched.phone && formik.errors.phone}
              variant='standard'
              color='success'
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Button variant="contained" color="primary" type="submit" style={{ backgroundColor: '#2EB5C9' }} >
                Update
              </Button>          
            </Box>
          </Grid>
        </Grid>
      </form>
    </Container>
  </div>
}
