import { useJumboTheme } from '@jumbo/hooks';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Snackbar, TextField } from '@mui/material';
import { isNotNullUndef } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import { Column } from 'devextreme-react/data-grid';
import { isEmpty, isNil, isUndefined, keys, startCase } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useContext, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { buttonStyles } from 'theme/cashflowsTheme';
import DataLoader from '../../../@data';
import { SaveAccountInfo } from '../../../@data/project_api';
import { EditorCtx } from '../CurrentProjectsSummary';
import DevxTable from '../Partials/DevxTable';
import { printCurrency, printDatePeriod, printPercentage } from '../Partials/MaterialGrid';
import { m5 } from '../Partials/Mui5';
import AddBankAccount from './AddBankAccount';

const details = () => {
    const columns = DataLoader("tables", "tables.BankAccountTable.header.columns");
    const cols = keys(columns);
    function renderType(d) {
        if (d === "percentage") return printPercentage
        if (d === "currency") return printCurrency
        if (d === "date") return printDatePeriod
        return null
    }
    function maskedRenderBSB(cellInfo) {
        // console.log("🚀 ~ file: BankDetails.jsx ~ line 37 ~ maskedRender ~ cellInfo", cellInfo)
        return <InputMask value={cellInfo.value} mask={"999-999"} readOnly alwaysShowMask={true} maskPlaceholder={"#"}>
            {() => <TextField
                variant='standard'
                margin='dense'></TextField>}
        </InputMask>
    }
    function maskedRenderAcctNum(cellInfo) {
        return <InputMask value={cellInfo.value} mask={"*****************"} readOnly alwaysShowMask={true} maskPlaceholder={"#"}>
            {() => <TextField
                variant='standard'
                margin='dense'></TextField>}
        </InputMask>
    }
    function maskedRender(element) {
        if (element === "BSB") return maskedRenderBSB
        if (element === "AccountNumber") return maskedRenderAcctNum
    }

    return cols.map((element, i) => {
        // console.log("🚀 ~ file: BankDetails.jsx ~ line 42 ~ returncols.map ~ element", element)
        let d = "string";
        let r = false;
        if (!isUndefined(columns[element].f)) {
            d = columns[element].f;
        }
        if (!isUndefined(columns[element].r)) {
            r = columns[element].r;
        }
        const addOn = x => {
            return x === "percentage" ? " %" : ''
        }
        const name = (elem) => {
            if (elem === "DefStatus") return "Default"
            return startCase(element) + addOn(d)
        }

        return (
            <Column
                caption={name(element)}
                dataField={element}
                dataType={d}
                cellRender={(element === "BSB" || element === "AccountNumber") ? maskedRender(element) : renderType(d)}
                key={i}
                width={'auto'}
                allowEditing={!r}>
            </Column>)
    });
}

export default function BankDetails(props) {
    const [showNewAccount, setshowNewAccount] = useState(false)
    const [editMode, setEditMode] = useState("new")
    const [error, setError] = useState('')
    const [paymentData, setPaymentData] = useState([])
    const { theme } = useJumboTheme()
    const { editorProps, setEditorProps } = useContext(EditorCtx)
    const [refresh, setRefresh] = useState(false)
    const editBtn = useRef(null)
    const confirm = useConfirm()
    const [showSelect, setShowSelect] = useState(false)
    const btnStyle = buttonStyles(theme)

    //Load data
    useEffect(() => {
        if (isNotNullUndef(props.OrgID) && props.OrgID != -1 && typeof (props.OrgID) !== 'object') {
            axios.get(`/api/Payment/GetInfo?OrgID=${props.OrgID}`)
                .then(resp => {
                    setPaymentData(resp.data)
                }).catch(err => {
                    console.error("🚀 ~ file: BankDetails.jsx ~ line 86 ~ axios.get ~ err", err)
                })
        }
    }, [props.OrgID, refresh])

    useEffect(() => {
        //
        const handler = () => {
            editBtn.current.click()
        }
        document.getElementById("devxtable").addEventListener('dblclick', handler)
        return () => {
            try {
                document.getElementById("devxtable").removeEventListener('dblclick', handler)
            } catch (error) {
            }
        }
    }, [])

    return <Box>
        <Button
            variant="contained"
            aria-controls="fade-menu"
            color='info'
            sx={{
                ...btnStyle.button,
            }}
            onClick={x => {
                setshowNewAccount(true)
                setEditMode("new")
                // setShowSelect(true)
            }}
            aria-haspopup="true">Add New Account</Button>
        <div id="recaptha"></div>
        <SelectDefault
            show={showSelect}
            close={x => {
                setShowSelect(false)
            }}
            data={paymentData} />

        <Box sx={{
            "& .dx-selection": {
                backgroundColor: theme.palette.secondary.light
            }
        }}>
            <Box padding={"10px 0px"} id="participant-grid">
                <div style={{ width: "100%", textAlign: "right" }}>
                    <IconButton
                        ref={editBtn}
                        style={{ marginRight: "50px" }}
                        title={"Select a row to edit account information"}
                        onClick={() => {
                            setEditMode("edit")
                            setshowNewAccount(true)
                        }}
                        size={'medium'}>
                    </IconButton>
                </div>
                <Box sx={{
                    "& .dx-selection": {
                        backgroundColor: "#03DAC5"
                    }
                }}>
                    <DevxTable
                        data={paymentData}
                        confirmDelete={false}
                        columns={details}
                        editingFx={({ row }) => {
                            return row.data.Active
                        }}
                        onRowRemoving={x => {
                            x.cancel = new Promise((resolve, reject) => {
                                reject("Please confirm action")
                            })
                            // console.log("🚀 ~ file: BankDetails.jsx:172 ~ BankDetails ~ x.data", x.data)
                            let msg = "Making the account Inactive. Are you sure you wish to continue?"
                            if (x.data.Active && x.data.DefStatus) {
                                msg = "WARNING: Making the default account Inactive will stop you from being paid. Are you sure you wish to continue?"
                            }
                            confirm({
                                content: msg,
                                title: "Confirm action",
                                contentProps: {
                                    style: { color: 'red' }
                                },
                            }).then(() => {
                                SaveAccountInfo({ ...x.data, OrgID: x.data.OrganisationID, account: x.data.AccountNumber, edit: true, Active: false }).then(resp => {
                                    try {
                                        let temp = [...paymentData].filter(e => e.AccountNumber === x.data.AccountNumber)
                                        let temp1 = [...paymentData].filter(e => e.AccountNumber !== x.data.AccountNumber)
                                        temp[0].Active = false
                                        setPaymentData([...temp, ...temp1])
                                        // editorProps.dataGrid.current.instance.refresh()
                                    } catch (e) {
                                        console.error("🚀 ~ file: BankDetails.jsx ~ line 161 ~ SaveAccountInfo ~ e", e)
                                    }
                                    setShowSelect(true)
                                }).catch(err => {
                                })
                            }).catch(() => { })
                        }}
                    />
                </Box>
            </Box>
        </Box>
        <AddBankAccount
            show={showNewAccount}
            mode={editMode}
            OrgID={props.OrgID}
            projID={props.projID}
            projDetails={props.projDetails}
            editRow={editorProps.selectedUser}
            onClose={x => {
                setshowNewAccount(false)
                try {
                    editorProps.dataGrid.current.instance.refresh()
                    setRefresh(!refresh)
                } catch (e) { }
            }} />
        <Snackbar open={error === 'success'} autoHideDuration={6000} onClose={x => setError('closed')}>
            <Alert severity="error">
                Failed to save Payment data, please try again later
            </Alert>
        </Snackbar>
        <Snackbar open={error === 'failed'} autoHideDuration={6000} onClose={x => setError('closed')}>
            <Alert severity="error">
                Failed to save Payment data, please try again later
            </Alert>
        </Snackbar>
    </Box>
}

function SelectDefault(props) {
    const [selected, setSelected] = useState(0)
    const { theme } = useJumboTheme()
    const classes = m5(theme)
    const handleChange = (d) => {
        setSelected(`${d.target.value}`)
    }
    useEffect(() => {
        if (!isNil(props.data) && typeof (props.data) === 'object' && props.data.length > 0) {
            console.log("🚀 ~ file: BankDetails.jsx:249 ~ useEffect ~ props.data[0].BankAccountID", props.data[0].BankAccountID)
            setSelected(`${props.data[0].BankAccountID}`)
        }
    }, [props.data])

    function Save() {
        //save first then close
        let d = props.data.filter(e => e.BankAccountID == selected)
        SaveAccountInfo({ ...d[0], OrgID: d[0].OrganisationID, account: d[0].AccountNumber, edit: true, Active: true, DefStatus: true }).then(resp => {
            console.log("🚀 ~ file: BankDetails.jsx:258 ~ SaveAccountInfo ~ resp", resp)
            props.close()
        }).catch(err => {
            console.error("🚀 ~ file: BankDetails.jsx:260 ~ SaveAccountInfo ~ err", err)
        })
    }
    function accounts() {
        if (!isNil(props.data) && !isEmpty(props.data))
            return props.data.map(e => {
                return <FormControlLabel value={`${e.BankAccountID}`} control={<Radio />} label={e.AccountName} />
            })
    }

    return (
        <Dialog
            fullWidth={true}
            disableEscapeKeyDown={true}
            onClose={(x, reason) => {
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') return
                Save()
                props.close()
            }}
            maxWidth={'sm'}
            open={props.show}>
            <DialogTitle sx={{ ...classes.root, ...classes.dlgTitle }}
                children={
                    'Select a different default Account'
                } />
            <DialogContent>
                <FormControl component="fieldset">
                    {/* <FormLabel component="legend">Select default Account</FormLabel> */}
                    <RadioGroup aria-label="Bank Account" name="Default Account" value={selected} onChange={handleChange}>
                        {accounts()}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={x => {
                    Save()
                    props.close()
                }}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
