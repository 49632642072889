import { useEffect, useState } from "react";
import { PaymentTypes } from "../../../@data/liveStores";
import Dropdown from "./DropBox";

export default function PaymentType({ selectedStatus, setStatus }) {
    const [orgData, setOrgData] = useState([])
    useEffect(() => {
        PaymentTypes.load().then(({ data }) => {
            console.log("🚀 ~ file: PaymentType.jsx ~ line 9 ~ PaymentTypes.load ~ data", data)
            setOrgData(data)
        })
    }, [])

    return (<Dropdown
        placeholder="PaymentType"
        value={selectedStatus}
        data={orgData}
        mapping={x => {
            return { value: x.PaymentTypeID, label: x.PaymentTypeName }
        }}
        onChange={setStatus}
    />)
}
