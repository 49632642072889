import GridContainer from '@jumbo/components/GridContainer';
import { useJumboTheme } from '@jumbo/hooks';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField } from '@mui/material';
import { errorMessage } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import { RecaptchaVerifier, getAuth, signInWithPhoneNumber } from "firebase/auth";
import { isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { buttonStyles, useStyles } from 'theme/cashflowsTheme';
import { SaveAccountInfo } from '../../../@data/project_api';
import { m5 } from '../Partials/Mui5';
import { showErrorToast } from '../Partials/Notify';
import { EmptyTextarea } from '../Partials/StyledTextArea';

export default function AddBankAccount(props) {
    const { theme } = useJumboTheme()
    const dlgStyle = m5(theme)
    const btnStyle = buttonStyles(theme)
    const [accountRow, setAccountRow] = useState({
        BSB: "",
        AccountNumber: "",
        AccountName: "",
        FriendlyName: "",
        Notes: "",
        DefStatus: false,
        Active: true
    })
    const [otpDisabled, setOTPDisabled] = useState(false)
    const [smsOTP, setSmsOTP] = useState("")
    const [verified, setVerified] = useState(false)
    const [phone, setPhone] = useState("")
    const confirm = useConfirm()
    const classes = useStyles(theme)

    useEffect(() => {
        axios.get("/api/User/Profile").then(resp => {
            console.log("🚀 ~ file: AddBankAccount.jsx ~ line 62 ~ axios.get ~ resp.data.Phone", resp.data.Phone)
            setPhone(resp.data.Phone)
        }).catch(err => {
            console.error("🚀 ~ file: AddBankAccount.jsx ~ line 61 ~ axios.get ~ err", err)
        })
    }, [])
    useEffect(() => {
        try {
            const auth = getAuth();
            setOTPDisabled(false)
            window.recaptchaVerifier = new RecaptchaVerifier('recaptha', {
                'size': 'invisible',
                'callback': (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                }
            }, auth);
            return () => {
                console.log("🚀 ~ return ~ ENDING")
                window.recaptchaVerifier = null
            }
        } catch (e) {
            console.error("🚀 ~ file: AddBankAccount.jsx ~ line 70 ~ useEffect ~ e", e)
        }
    }, [props.show])
    useEffect(() => {
        if (!isNil(props.editRow)) {
            setAccountRow({
                BSB: props.editRow.BSB,
                AccountNumber: props.editRow.AccountNumber,
                AccountName: props.editRow.AccountName,
                FriendlyName: props.editRow.FriendlyName,
                Notes: props.editRow.Notes,
                OrgID: props.editRow.OrgID,
                BankAccountID: props.editRow.BankAccountID,
                DefStatus: props.editRow.DefStatus,
                Active: props.editRow.Active
            })
        }
    }, [props.editRow])
    useEffect(() => {
        if (smsOTP.length != 6) return
        if (smsOTP.length == 6) {
            window.verifier.confirm(smsOTP)
                .then(resp => {
                    console.log("🚀 ~ file: AddBankAccount.jsx ~ line 99 ~ useEffect ~ resp", resp)
                    setVerified(true)
                })
                .catch(err => {
                    console.error("🚀 ~ file: AddBankAccount.jsx ~ line 95 ~ window.verifier.confirm ~ err", err)
                })
        }
    }, [smsOTP])

    const onSave = async (type) => {
        if (!verified) {
            confirm({
                description: "Please verify yourself with Generate OTP Button (OTP will be sent to your registered phone number)",
                title: "User Not Verified",
                dialogProps: { disableEnforceFocus: true, color: "red" },
                titleProps: {
                    style: {
                        fontSize: '16px'
                    }
                }
            }).then(x => {
            }).catch((e) => {
                console.error("🚀 ~ file: AddBankAccount.jsx:129 ~ onSave ~ e", e)
            })
            return
        }

        let row = {
            account: accountRow.AccountNumber,
            BSB: accountRow.BSB,
            AccountNumber: accountRow.AccountNumber,
            AccountName: accountRow.AccountName,
            FriendlyName: accountRow.FriendlyName,
            Notes: accountRow.Notes,
            OrgID: props.OrgID,
            edit: props.mode === "edit",
            BankAccountID: accountRow.BankAccountID,
            DefStatus: accountRow.DefStatus,
            Active: accountRow.Active
        }
        console.log("🚀 ~ file: AddBankAccount.jsx ~ line 93 ~ onSave ~ row", row)
        if (row.DefStatus && row.Active) {
            confirm({
                description: "WARNING: Making the default account Inactive will stop you from being paid. Are you sure you wish to continue?",
                title: "Confirm action",
                dialogProps: { disableEnforceFocus: true, color: "red" },
                titleProps: {
                    style: {
                        fontSize: '16px'
                    }
                }
            }).then(x => {
                SaveAccountInfo(row).then(resp => {
                    //add to project if we have a project iD
                    if (!isNil(props.projID) && props.projID > 0) {
                        const d = {
                            "ProjectID": props.projID,
                            "StartDate": new Date(),
                            'BankAccountID': resp.data,
                            "StatusID": 13, //Draft
                            "MapProjectBankAccountID": -1 //new mapping
                        }
                        axios.post('/api/Project/AddProjectBankAccount', { ...d }).then(resp => {
                            props.onClose(false)
                        }).catch(e => {
                            showErrorToast(errorMessage(e))
                        })
                    } else {
                        props.onClose(false)
                    }
                    setAccountRow({
                        BSB: "",
                        AccountNumber: "",
                        AccountName: "",
                        FriendlyName: "",
                        Notes: "",
                        DefStatus: false,
                        Active: true
                    })
                }).catch(err => {
                    console.error("🚀 ~ file: AddBankAccount.jsx:171 ~ SaveAccountInfo ~ err", err)
                })
            }).catch(() => { })
        } else {
            SaveAccountInfo(row).then(resp => {
                if (!isNil(props.projID) && props.projID > 0) {
                    const d = {
                        "ProjectID": props.projID,
                        "StartDate": new Date(),
                        'BankAccountID': resp.data,
                        "StatusID": 13, //Draft
                        "MapProjectBankAccountID": -1 //new mapping
                    }
                    axios.post('/api/Project/AddProjectBankAccount', { ...d }).then(resp => {
                        props.onClose(false)
                    }).catch(e => {
                        showErrorToast(errorMessage(e))
                    })
                } else {
                    props.onClose(false)
                }
                setAccountRow({
                    BSB: "",
                    AccountNumber: "",
                    AccountName: "",
                    FriendlyName: "",
                    Notes: "",
                    DefStatus: false,
                    Active: true
                })
            }).catch(err => {
                console.error("🚀 ~ file: AddBankAccount.jsx:187 ~ SaveAccountInfo ~ err", err)
            })
        }
    }

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            sx={dlgStyle.dialog}
            open={props.show}>
            <DialogTitle
                sx={dlgStyle.dlgTitle}
            >{props.mode === "new" ? "Add Bank Account" : "Edit Bank Account"}</DialogTitle>
            {/* TODO remove scrollbar */}
            <DialogContent sx={{ ...classes.root, overflowY: "initial" }}>
                <GridContainer>
                    <Grid item lg={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={5} lg={5} md={5}>
                                <InputMask
                                    mask={"999-999"}
                                    value={accountRow.BSB}
                                    onChange={x => {
                                        setAccountRow({
                                            ...accountRow,
                                            BSB: x.target.value
                                        })
                                    }}>
                                    {() => <TextField
                                        margin="dense"
                                        fullWidth
                                        required
                                        variant='standard'
                                        size={'small'}
                                        placeholder={'BSB'}
                                        sx={{
                                            ...classes.input,
                                            ...classes.formControl
                                        }}
                                        // inputProps={{ shrink: true }}
                                        InputLabelProps={{ shrink: true }}
                                        label="BSB"
                                    />}
                                </InputMask>
                            </Grid>
                            <Grid item xs={5} lg={5} md={5}>
                                <InputMask
                                    mask={"*****************"}
                                    value={accountRow.AccountNumber}
                                    onChange={x => {
                                        setAccountRow({
                                            ...accountRow,
                                            AccountNumber: x.target.value
                                        })
                                    }}>
                                    {() => <TextField
                                        margin="dense"
                                        fullWidth
                                        variant='standard'
                                        required={true}
                                        size={'small'}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder={'Account Number'}
                                        sx={{
                                            ...classes.input,
                                            ...classes.formControl
                                        }}
                                        label="Account Number" />}
                                </InputMask>
                            </Grid>
                            <Grid item lg={2} xs={2} md={2}>
                                <InputLabel>Default</InputLabel>
                                <Checkbox checked={accountRow.DefStatus}
                                    onChange={x => {
                                        setAccountRow({
                                            ...accountRow,
                                            DefStatus: !accountRow.DefStatus
                                        })
                                    }} />
                            </Grid>
                            <Grid item xs={5} lg={5} md={5}>
                                <TextField variant='standard' margin="dense" fullWidth required={true} placeholder={'Account Name'} value={accountRow.AccountName}
                                    onChange={x => {
                                        setAccountRow({
                                            ...accountRow,
                                            AccountName: x.target.value
                                        })
                                    }}
                                    sx={{
                                        ...classes.input,
                                        ...classes.formControl
                                    }}
                                    label="Account Name" />
                            </Grid>
                            <Grid item xs={5} lg={5} md={5}>
                                <TextField variant='standard' margin="dense" fullWidth required={false} size={'small'} placeholder={'Friendly Name'} value={accountRow.FriendlyName}
                                    onChange={x => {
                                        setAccountRow({
                                            ...accountRow,
                                            FriendlyName: x.target.value
                                        })
                                    }}
                                    sx={{
                                        ...classes.input,
                                        ...classes.formControl
                                    }}
                                    label="Friendly Name" />
                            </Grid>
                            <Grid item lg={2} xs={2} md={2}>
                                <InputLabel>Active</InputLabel>
                                <Checkbox checked={accountRow.Active}
                                    onChange={x => {
                                        setAccountRow({
                                            ...accountRow,
                                            Active: !accountRow.Active
                                        })
                                    }} />
                            </Grid>
                            <Grid item xs={5} lg={5} md={5}>
                                {/* Generate OTP */}
                                <TextField
                                    style={{ display: !otpDisabled ? "none" : "initial" }}
                                    disabled={!otpDisabled}
                                    margin='dense'
                                    fullWidth
                                    required
                                    placeholder='OTP'
                                    helperText={verified ? "Verified" : ""}
                                    onChange={x => {
                                        setSmsOTP(x.target.value)
                                    }}
                                    value={smsOTP} />
                                <Button
                                    id="generate-otp"
                                    variant='contained'
                                    sx={btnStyle.button}
                                    disabled={otpDisabled}
                                    onClick={x => {
                                        VerifyWith()
                                    }}>Generate OTP</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12}>
                        <EmptyTextarea
                            aria-label='Notes'
                            placeholder='Notes'
                            spellCheck={true}
                            minRows={2}
                            value={accountRow.Notes}
                            onChange={x => {
                                setAccountRow({
                                    ...accountRow,
                                    Notes: x.target.value
                                })
                            }}
                        />
                    </Grid>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color='info'
                    sx={btnStyle.button}
                    onClick={x => {
                        var confirmationMessage = props.mode === "edit" ? "Do you want to update this account?" : "Do you want to create this account?";
                        confirm({
                            allowClose: true,
                            description: confirmationMessage,
                            title: "Confirm action",
                            dialogProps: { disableEnforceFocus: true },
                            titleProps: {
                                style: {
                                    fontSize: '16px'
                                }
                            }
                        }).then(resp => {
                            onSave("save")
                        }).catch((e) => {
                            console.error("🚀 ~ file: AddBankAccount.jsx:354 ~ AddBankAccount ~ e", e)
                        })
                    }}>Save</Button>
                {/* <Button onClick={x => {
                    props.onClose(false)
                    onSave("draft")
                }}>Save Draft</Button> */}
                <Button
                    sx={btnStyle.button}
                    variant='contained'
                    color='inherit'
                    onClick={x => {
                        props.onClose(false)
                    }}>Cancel</Button>
            </DialogActions>
        </Dialog>)

    async function VerifyWith() {
        // PhoneAuthProvider
        setOTPDisabled(true)
        const auth = getAuth()
        //** Phone numbers are saved with country code */
        if (!isNil(window.recaptchaVerifier)) {
            window.verifier = await signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
        }
    }
}
