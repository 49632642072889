import { innerTab, newProjData, projectInnerTab } from '@data/localStore';
import BlogContext from '@data/projectStore';
import { useJumboTheme } from '@jumbo/hooks';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, Badge, Box, Button, Grid, Snackbar, Tab, Tabs, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Banking from 'app/pages/Construction/Banking';
import ClaimsGrid from 'app/pages/Construction/Claims';
import PaymentGrid from 'app/pages/Construction/Payments';
import ProjectBankAccountsGrid from 'app/pages/Construction/ProjectBankAccounts';
import ProjectTeam from 'app/pages/Construction/ProjectTeam';
import ProjectTrade from 'app/pages/Construction/ProjectTradeBreakdown';
import axios from "axios";
import _, { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useRecoilState, useRecoilValue } from 'recoil';
import { buttonStyles } from 'theme/cashflowsTheme';
import ProjectParticipants from '../../Construction/ProjectParticipants';
import Address from '../Address';
import ChartFirst from "../ChartFirst";
import { SimplePopup } from '../Popup';
import StatusSelect from '../StatusSelect';

const useStyles = (x) => ({
  root: {
    fontWeight: x.typography.fontWeight,
    fontFamily: x.typography.fontFamily,
    '& .MuiInputBase-root': {
      fontSize: "0.85rem",
      lineHeight: "1.0rem",
    },
    '& .dx-theme-material-typography a': {
      color: x.palette.primary.dark,
    },
  },
  formControl: {
    fontSize: '0.85rem'
  },
});

const ClaimsContext = createContext()

export default function ProjectContent({ projDetails, currTab, setCurrTab }) {
  const { theme } = useJumboTheme()
  const classes = useStyles(theme)
  const btnStyle = buttonStyles(theme)

  const projData = useRecoilValue(newProjData)
  const [selectedStatus, setStatus] = useState(13)
  const [projName, setProjName] = useState("")
  const [projShortCode, setProjShortCode] = useState("")
  const [org, setOrg] = useState(0)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [image, setImage] = useState(null)
  const [imageData, setImageData] = useState(null)
  const [open, setOpen] = useState(null)
  const [error, setError] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const [popupImage, setPopupImage] = useState(null)
  const [projectValue, setProjectValue] = useState(0)
  const [maxBuilderPercent, setMaxBuilderPercent] = useState(0);
  const [outervalue, setouterValue] = useState(currTab);
  const [innerTabValue, setInnerTab] = useRecoilState(innerTab)
  const [actionRequired, setActionRequired] = useState(0)
  const [projectInnerTabValue, setprojectInnerTab] = useRecoilState(projectInnerTab)
  const { loadProjData } = useContext(BlogContext)

  const materialUITextFieldProps = x => {
    return {
      label: x,
      multiline: false,
      margin: "dense",
      fullWidth: true,
      variant: 'standard'
    }
  }
  const handleChange = (event, newValue) => {
    setouterValue(newValue);
    setCurrTab(newValue)
  };

  const handleChange2 = (event, newValue) => {
    // setinnerValue(newValue);
    setInnerTab(newValue)
  };


  const handleChange3 = (event, newValue) => {
    setprojectInnerTab(newValue)
  };

  useEffect(() => {
    if (!isNil(projDetails)) {
      setStatus(projDetails.StatusID)
      setProjName(projDetails.Project)
      setOrg(projDetails.PrincipleOrg)
      setProjShortCode(projDetails.ProjectShortCode)
      setProjectValue(projDetails.ProjectValue)
      if (!isNil(projDetails.EstContructionStart)) {
        setStartDate(new Date(projDetails.EstContructionStart))
      }
      if (!isNil(projDetails.EstFinishDate)) {
        setEndDate(new Date(projDetails.EstFinishDate))
      }
      setMaxBuilderPercent(projDetails.MaxBuilderPercent)

      if (!isNil(projDetails.Filebody)) {
        setImage(`data:image/png;base64,${projDetails.Filebody}`)
        setImageData(`data:image/png;base64,${projDetails.Filebody}`);
      }
    }
  }, [projDetails.ProjectShortCode, projDetails.StatusID, projDetails.PrincipleOrg, projDetails.Name])

  useEffect(() => {
    if (isNil(projDetails.ProjectID) || projDetails.ProjectID < 1) return
    console.log("🚀 ~ useEffect ~ projDetails.ProjectID:", projDetails.ProjectID)
    axios.post(`/api/Project/ProjectBankAccountNotifications?projectID=${projDetails.ProjectID}`)
      .then(resp => {
        if (!isEmpty(resp.data) && _.has(resp.data, 'ActionItems') && !isEmpty(resp.data['ActionItems'])) {
          // setActionRequired(resp.data.Notification.length)
          setActionRequired(resp.data.ActionItems.length)
        } else {
        }
      })
      .catch(err => {
      })
  }, [projDetails.ProjectID])

  useEffect(() => {
    const listener = x => {
      if (x.target.getAttribute('class') === 'popup') {
        setPopupImage(x.target.getAttribute('src'))
        setShowPopup(true)
      }
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])

  useEffect(() => {
    if (!isNil(image) && image.length == 1) {
      setImageData(window.URL.createObjectURL(image[0]))
    }
  }, [image])

  //** Editing */
  function save() {
    //upload project info
    axios.post('/api/Project/Edit', {
      editing: true,
      Project: projName,
      ProjectShortCode: projShortCode,
      StatusID: selectedStatus,
      ProjectID: projDetails.ProjectID,
      Active: true,
      SortOrder: 1,
      EstConstructionStart: startDate,
      EstFinishDate: endDate,
      EstConstructionDuration: 100,
      ProjectOrg: org,
      ProjectValue: numeral(projectValue).value(),
      MaxBuilderPercent: maxBuilderPercent,
      // ProjectContacts: projData['ProjectContacts'],
      projectAddress: projData['projectAddress'],
      billingAddress: projData['billingAddress'] === undefined ? null : projData['billingAddress']
    }).then(resp => {
      if (!isNil(image)) {
        console.log('Uploading attachment')
        uploadAttachment(resp.data['ProjectID'])
      } else {
        setOpen(true)
      }
      // setProjData({
      //   ...projData,
      //   reload: !projData.reload
      // })
      loadProjData()
    }).catch(err => {
      console.log("🚀 ~ file: ProjectContent.jsx ~ line 138 ~ save ~ err", err)
      setError(true)
    })
  }

  async function uploadAttachment(pid) {
    const readFile = (file) => {
      if (isNil(file)) return
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          const theFile = event.target;
          return resolve([file, theFile]);
        };
        fileReader.readAsDataURL(file);
      });
    };
    const [fileData, theFile] = await readFile(image[0]);

    var f = new FormData();
    f.append('ProjectID', pid)
    f.append('type', 'project')
    f.append('attachment', fileData)

    axios.post("/api/Attachments/UploadAttachment", f
      , {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'Accept': '*/*',
          'Accept-Encoding': 'gzip, deflate, br'
        }
      }).then(resp => {
        setOpen(true)
      }).catch(err => {
        setError(true)
      })
  }

  const projectAmounts = ["Project Value $", "Paid to Project $"]
  const projectPayments = ["Paid to Head Contractor $", "Paid to Trades $", "Available Funds $"]

  return <>
    <ClaimsContext.Provider value={{}}>
      <Box sx={{ bgcolor: 'background.paper' }}>
        <SimplePopup selectedValue={'xxx'} open={showPopup} onClose={x => {
          setShowPopup(false)
        }}
          image={popupImage}
        />
        <Grid container spacing={2} style={{ padding: '5px 20px', marginTop: '10px' }}>
          <Grid item md={4} lg={4} sm={4}>
            <Badge badgeContent={actionRequired} color="primary" anchorOrigin={{ horizontal: "left", vertical: "top" }} showZero>
              <Typography style={{ fontSize: '0.85rem', paddingLeft: '10px' }}>Actions Required by you</Typography>
            </Badge>
          </Grid>
          <Grid item md={4} lg={4} sm={4}>
            <Badge badgeContent={0} color="secondary" anchorOrigin={{ horizontal: "left", vertical: "top" }} showZero>
              <Typography style={{ fontSize: '0.85rem', paddingLeft: '10px' }}>Actions You've Requested</Typography>
            </Badge>
          </Grid>
          <Grid item md={4} lg={4} sm={4}>
            <Badge badgeContent={0} color="error" anchorOrigin={{ horizontal: "left", vertical: "top" }} showZero>
              <Typography style={{ fontSize: '0.85rem', paddingLeft: '10px' }}>Notifications</Typography>
            </Badge>
          </Grid>
        </Grid>

        <TabContext value={outervalue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs onChange={handleChange} aria-label="lab API tabs example" value={outervalue}>
              <Tab fullWidth label="Financials" value="0"></Tab>
              <Tab fullWidth label="Project Info" value="1"></Tab>
              <Tab fullWidth label="Team" value="2"></Tab>
              <Tab fullWidth label="Participants" value="3x"></Tab>
              <Tab fullWidth label="Trade BreakDown" value="4"></Tab>
            </Tabs>

            <TabPanel value='0' tabIndex={0}> {/*Finance Tab*/}
              <Grid container spacing={3} rowSpacing={"8px"}>
                <Grid item xs={6} lg={6} md={6}>
                  <Grid container spacing={3} rowSpacing={'5px'}>
                    <Grid item xs={4}>
                      <TextField
                        margin="dense"
                        fullWidth
                        variant='standard'
                        aria-readonly={'true'}
                        sx={{ ...classes.root, ...classes.formControl }}
                        label="Project Value"
                        value={numeral(projectValue).format("$0,0.00")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        margin="dense"
                        fullWidth
                        variant='standard'
                        aria-readonly={'true'}
                        sx={{ ...classes.root, ...classes.formControl }}
                        label="Unapproved Claims"
                        value={numeral(projDetails.UnapprovedClaims).format("$0,0.00")} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        margin="dense"
                        fullWidth
                        variant='standard'
                        aria-readonly={'true'}
                        sx={{ ...classes.root, ...classes.formControl }}
                        label="Paid To Date"
                        value={numeral(projDetails.PaidtoDate).format("$0,0.00")} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        margin="dense"
                        fullWidth
                        variant='standard'
                        aria-readonly={'true'}
                        sx={{ ...classes.root, ...classes.formControl }}
                        label="Paid To Head Contractor"
                        value={numeral(projDetails.PaidToBuilder).format("$0,0.00")} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        margin="dense"
                        fullWidth
                        variant='standard'
                        aria-readonly={'true'}
                        sx={{ ...classes.root, ...classes.formControl }}
                        label="Paid to Trades"
                        value={numeral(projDetails.PaidToTrades).format("$0,0.00")} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        margin="dense"
                        fullWidth
                        variant='standard'
                        aria-readonly={'true'}
                        sx={{ ...classes.root, ...classes.formControl }}
                        label="Available Funds"
                        value={numeral(projDetails?.PaidtoDate - projDetails?.PaidToBuilder - projDetails?.PaidToTrades).format("$0,0.00")} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3} lg={3} md={3} style={{ marginTop: "5px" }}>
                  <ChartFirst
                    labels={projectAmounts}
                    value1={projectValue == 0 ? projectValue : projectValue > projDetails?.PaidtoDate ? projectValue - projDetails?.PaidtoDate : 0}
                    value2={projDetails?.PaidtoDate ?? 0}
                    value3={-1} />
                </Grid>
                <Grid item xs={3} lg={3} md={3} style={{ marginTop: "5px" }}>
                  <ChartFirst
                    labels={projectPayments}
                    value1={projDetails?.PaidToBuilder ?? 0}
                    value2={projDetails?.PaidToTrades ?? 0}
                    value3={projDetails?.PaidtoDate - projDetails?.PaidToBuilder - projDetails?.PaidToTrades}
                  />
                </Grid>
              </Grid>
              <Box>
                <TabContext value={innerTabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange2} aria-label="Claims and Payments" value={innerTabValue}>
                      <Tab label="Claims" value="0" />
                      <Tab label="Payments" value="1" />
                      <Tab label="Banking" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="0">
                    <ClaimsGrid editMode={'edit'} projID={projDetails.ProjectID} projDetails={projDetails} />
                  </TabPanel>

                  <TabPanel value="1">
                    <PaymentGrid editMode={'edit'} projDetails={projDetails} projID={projDetails.ProjectID} />
                  </TabPanel>

                  <TabPanel value="2">
                    <Banking projID={projDetails.ProjectID} />
                  </TabPanel>
                </TabContext>
              </Box>
            </TabPanel>

            <TabPanel value="1" tabIndex={1}> {/*Project Tab*/}
              <Grid container spacing={2} rowSpacing={'8px'}>
                <Grid item xs={4}>
                  <TextField
                    margin="dense"
                    fullWidth
                    variant='standard'
                    sx={{ ...classes.root, ...classes.formControl }}
                    onChange={x => {
                      setProjName(x.target.value)
                    }}
                    label="Project" value={projName} />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin="dense"
                    fullWidth
                    variant='standard'
                    sx={{ ...classes.root, ...classes.formControl }}
                    onChange={x => {
                      setProjShortCode(x.target.value)
                    }}
                    label="Project Short Code" value={projShortCode}
                    inputProps={
                      { maxLength: 3 }
                    } />
                </Grid>
                <Grid item xs={4} style={{ paddingTop: '25px' }}>
                  <StatusSelect selectedStatus={selectedStatus} setStatus={setStatus} filtered={true} />
                </Grid>
                <Grid item xs={4}>
                  <NumericFormat
                    value={projectValue}
                    prefix='$'
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    onClick={(event) => {
                      if (event.target.value === '$0.00') {
                        event.target.value = '';
                      }
                    }}
                    onFocus={(e) => {
                      const value = e.target.value;
                      const dotIndex = value.indexOf('.');
                      e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                    }}
                    onChange={x => setProjectValue(x.target.value)}
                    customInput={TextField}
                    {...materialUITextFieldProps("Project Value")} />
                </Grid>
                <Grid item xs={4}>
                  <NumericFormat
                    value={maxBuilderPercent}
                    suffix='%'
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    onClick={(event) => {
                      if (event.target.value === '0.00%') {
                        event.target.value = '';
                      }
                    }}
                    onFocus={(e) => {
                      const value = e.target.value;
                      const dotIndex = value.indexOf('.');
                      e.target.setSelectionRange(dotIndex !== -1 ? dotIndex : 0, dotIndex !== -1 ? dotIndex : 0);
                    }}
                    onValueChange={(v) => {
                      setMaxBuilderPercent(v.floatValue)
                    }}
                    valueIsNumericString={true}
                    customInput={TextField}
                    {...materialUITextFieldProps("Max Head Contractor %")} />
                </Grid>
                <Grid item xs={2} sx={{ marginTop: "7px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      style={{
                        margin: '5px'
                      }}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label="Actual Start Date"
                      value={startDate}
                      defaultValue={new Date()}
                      formatDensity={'dense'}
                      minDate={moment().subtract(5, 'years').toDate()}
                      maxDate={moment().add(5, 'years').toDate()}
                      onChange={(v) => {
                        setStartDate(new Date(v))
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    /></LocalizationProvider>
                </Grid>
                <Grid item xs={2} sx={{ marginTop: "7px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      style={{
                        margin: '5px'
                      }}
                      format="dd/MM/yyyy"
                      id="date-picker-inline2"
                      label="Actual End Date"
                      formatDensity={'dense'}
                      value={endDate}
                      minDate={moment().subtract(5, 'years').toDate()}
                      maxDate={moment().add(5, 'years').toDate()}
                      onChange={(v) => {
                        setEndDate(new Date(v))
                      }}
                      slotProps={{
                        error: "",
                        fullWidth: true,
                        variant: 'standard'
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    /></LocalizationProvider>
                </Grid>
                <Grid container direction={'column'} >
                  <Grid item xs={12} lg={2} md={2}>
                    <img
                      className='popup'
                      src={imageData}
                      height={'100%'}
                      style={{
                        maxHeight: '150px',
                        padding: '5px',
                        margin: '2px',
                        border: '0.20px solid lightgray',
                        borderRadius: '5px',
                        //display: isNil(imageData) ? 'none' : "initial"
                        opacity: isNil(imageData) ? 0 : 1,
                      }} />
                  </Grid>
                  <Grid item xs={12} lg={1} md={1}>
                    <Box sx={{
                      '& a': {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '0.85rem'
                      },
                      '& span': {
                        padding: '0 5px',
                      },
                    }}>
                      <a href="javascript:document.getElementById('image-upload').click()"
                        sx={classes.root}>Upload Image<span>+</span></a>
                      <input id='image-upload'
                        type={'file'}
                        multiple={false}
                        accept={'image/*'}
                        style={{ display: 'none' }}
                        name={'Upload Image'}
                        onChange={(e) => {
                          if (!isNil(e.target.files) && e.target.files.length == 1) {
                            setImage(e.target.files)
                          }
                        }} />
                    </Box>
                  </Grid>
                </Grid>
                <Snackbar open={open} autoHideDuration={6000} onClose={x => setOpen(false)}>
                  <Alert severity="success">
                    Project saved
                  </Alert>
                </Snackbar>
                <Snackbar open={error} autoHideDuration={6000} onClose={x => setError(false)}>
                  <Alert severity="error">
                    Failed to save Project info
                  </Alert>
                </Snackbar>

                <Box sx={{ minWidth: '100%' }}>
                  <TabContext value={projectInnerTabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange3} aria-label="Address and Bank Account" value={projectInnerTabValue}>
                        <Tab label="Address" value="0" />
                        <Tab label="Bank Account" value="1" />
                      </TabList>
                    </Box>

                    <TabPanel value="0" tabIndex={0}>
                      <Grid container>
                        <Grid item xs={12} md={12} lg={6}>
                          <Address addressType="Address" onAddressChanged={() => { }}></Address>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <Address addressType="Billing Address" onAddressChanged={() => { }}></Address>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={12} lg={8}>
                          <Button
                            variant="contained"
                            color='info'
                            sx={btnStyle.button}
                            onClick={x => save()}>Save</Button>
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <TabPanel value="1" tabIndex={1}>
                      <Grid container sx={{ minWidth: '100%' }}>
                        <Grid item xs={12} md={12} lg={12}>
                          <ProjectBankAccountsGrid editMode={'edit'} projDetails={projDetails} projID={projDetails.ProjectID} />
                        </Grid>
                      </Grid>
                    </TabPanel>

                  </TabContext>
                </Box>
              </Grid>
            </TabPanel>

            {/*Project Team Tab*/}
            <TabPanel value="2" tabIndex={2}>
              {/* <Grid container spacing={3}>
              </Grid> */}
              <ProjectTeam editMode={'edit'}></ProjectTeam>
            </TabPanel>

            {/*Project Participants Tab*/}
            <TabPanel value="3x" tabIndex={3}>
              <ProjectParticipants editMode={'edit'} />
            </TabPanel>

            {/*Project Trade BreakDown Tab*/}
            <TabPanel value="4" tabIndex={4}>
              <ProjectTrade />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </ClaimsContext.Provider>
  </>
}
