import GridContainer from '@jumbo/components/GridContainer';
import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { buttonStyles } from 'theme/cashflowsTheme';
import { printDateTime } from '../Partials/MaterialGrid';
import { m5 } from './Mui5';
import MuiGrid from './MuiTable';

const dlgContent = (x) => ({
    root: {
        overflowY: "hidden"
    }
})

// const materialUITextFieldProps = x => {
//     return {
//         label: x,
//         multiline: false,
//         margin: "dense",
//         fullWidth: true,
//         size: 'small'
//     }
// }

const columns = [
    // { field: 'ClaimID', headerName: 'ClaimID', width: 90 },
    {
        field: 'WorkFlowStep',
        headerName: 'Task Type',
        flex: 1,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        flex: 1,
    },
    {
        field: 'CreatedOn',
        headerName: 'Created On',
        renderCell: (params) => {
            return printDateTime(params.row.CreatedOn)
        },
        flex: 1
    },
    {
        field: 'Notes',
        headerName: 'Notes',
        flex: 2,
    },    
];

export default function Notes(props) {
    const { theme } = useJumboTheme()
    const classes = m5(theme)
    const classes2 = dlgContent()
    const [notesData, setNotesData] = useState([])
    const [selectedRow, setSelectedRow] = useState({})
    const btnStyle = buttonStyles(theme)

    useEffect(() => {
        if (props.ID > 0 && props.moduleID ===7) {
            axios.get(`/api/Project/GetClaimNotes?key=${props.ID}`).then(resp => {
                setNotesData(resp.data)
            }).catch(err => {
                console.error("🚀 ~ file: Notes.jsx ~ line 83 ~ axios.get ~ err", err)
            })
        }  
        else if (props.ID > 0 && props.moduleID ===8) {
            axios.get(`/api/Project/GetPaymentNotes?key=${props.ID}`).then(resp => {
                setNotesData(resp.data)
            }).catch(err => {
                console.error("🚀 ~ file: Notes.jsx ~ line 83 ~ axios.get ~ err", err)
            })
        }
    }, [props.ID])

    return (
        <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            onClose={(x, reason) => {
                if (reason === "backdropClick") return
                props.onClose(false)
            }}
            sx={classes.dialog}
            open={props.show}>
            <DialogTitle
                sx={classes.dlgTitle}
                disableTypography={true}
                children={
                    <IntlMessages id="Notes.DetailDlg.Title" />
                } />
            {/* remove scrollbar */}
            <DialogContent sx={{
                ...classes.root,
                ...classes2.root
            }}>
                <GridContainer>
                    <Grid item lg={12}>
                        <Grid container spacing={12}>
                            <Grid item xs={12}>
                                <Box sx={{ width: '100%', marginTop: '15px' }}>
                                    <MuiGrid
                                        disableColumnFilter
                                        disableDensitySelector
                                        disableColumnSelector
                                        disableExpo
                                        rows={notesData}
                                        columns={columns}
                                        getRowId={(row) => row.MapObjectNotesID}
                                        onRowClick={x => {
                                            setSelectedRow(x.row)
                                        }} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color='inherit'
                    sx={btnStyle.button}
                    onClick={x => {
                        props.onClose(false)
                    }}>Close</Button>
            </DialogActions>
        </Dialog>)
}
