import axios from 'axios';
import { isUndefined, keys, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { cardStyles, gridStyles } from '../../../theme/cashflowsTheme';
import { EditorCtx } from '../CurrentProjectsSummary';
import DataLoader from '@data';
import GridContainer from '@jumbo/components/GridContainer';
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';
import PageContainer from '@jumbo/components/PageContainer';
import { useJumboTheme } from '@jumbo/hooks';
import { AssignmentInd } from '@mui/icons-material';
import { Avatar, Box, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Workflow', isActive: true },
];

const details = () => {
    const columns = DataLoader("tables", "tables.WorkFlowTable.header.columns");
    const cols = keys(columns);

    return cols.map((element, i) => {
        let d = "string";
        let r = false;
        if (!isUndefined(columns[element].f)) {
            d = columns[element].f;
        }
        if (!isUndefined(columns[element].r)) {
            r = columns[element].r;
        }
        return ({
            field: element,
            headerName: startCase(element),
            type: d,
            flex: 0.5
        })
    });
}


export function Jobs(props) {
    return (
        <PageContainer breadcrumbs={breadcrumbs}>
            <GridContainer>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box>
                        <FullWidthTabs />
                    </Box>
                </Grid>
            </GridContainer>
        </PageContainer>
    );
};

// function a11yProps(index) {
//     return {
//         id: `full-width-tab-${index}`,
//         'aria-controls': `full-width-tabpanel-${index}`,
//     };
// }


function FullWidthTabs() {
    const [editorProps, setEditorProps] = useState({})
    const [workflowData, setWorkflowData] = useState([])
    const { theme } = useJumboTheme()
    const classesCard = cardStyles(theme)
    const [showWorkData, setShowWorkData] = useState(true);
    const gridStyle = gridStyles(theme)

    //Load data
    useEffect(() => {
        //if (isNotNullUndef(props.projID) && props.projID != -1) {
        if (showWorkData == false) return
        axios.get(`/api/WorkFlow/LoadClaimsPendingAction`).then(resp => {
            // console.log("🚀 ~ file: Job.jsx ~ line 83 ~ axios.get ~ resp.data", resp.data)
            setWorkflowData(resp.data)
            setShowWorkData(false)
        }).catch(err => {
        })
    }, [])

    return (
        <EditorCtx.Provider value={{ editorProps, setEditorProps }}>
            <Box sx={classesCard.root} width={1}>
                <JumboCardQuick
                    title={"Jobs"}
                    headerSx={classesCard.cardHeaderRoot}
                    sx={classesCard.cardRoot}
                    avatar={<Avatar color="primary" size="small"><AssignmentInd fontSize="small" /></Avatar>}>
                    <DataGrid
                        rows={workflowData}
                        getRowId={x => x.ServiceRequestID}
                        columns={details()}
                        density='standard'
                        responsive='onResize'
                        sx={gridStyle}
                        pagination
                        pageSize={25}
                        rowsPerPageOptions={[25, 50, 100]} />
                </JumboCardQuick>
            </Box>
        </EditorCtx.Provider>
    );
}
