export const builderProjects = [
  {
    Project: "Burke Road",
    ProjectShortCode: "701",
    Address: "701 Burke RdCamberwell, VIC 3124",
    Status: "Post-Completion",
    Organization: "Probuild Constructions (Aust) Pty Ltd",
    ABN: "93 095 250 945",
    ACN: "095 250 945",
    PaidToDate: 350000,
    PaidToBuilder: 130000,
    PaidToNTS: 220000,
    PercentPaidToNTS: 62.86,
    Value: 220000
  },
  {
    Project: "Rutland Road",
    ProjectShortCode: "609",
    Address: "609, Rutland Rd, Berwick VIC 3806",
    Status: "Active",
    Organization: "Probuild Constructions (Aust) Pty Ltd",
    ABN: "93 095 250 945",
    ACN: "095 250 945",
    PaidToDate: 350000,
    PaidToBuilder: 130000,
    PaidToNTS: 220000,
    PercentPaidToNTS: 62.86,
    Value: 220000
  },
  {
    Project: "Malvern Road",
    ProjectShortCode: "705",
    Address: "705 Malvern Rd, Toorak, VIC 3142",
    Status: "Active",
    Organization: "Condev Construction Pty Ltd",
    ABN: "39 101 213 825",
    ACN: "101 213 82",
    PaidToDate: 350000,
    PaidToBuilder: 130000,
    PaidToNTS: 220000,
    PercentPaidToNTS: 62.86,
    Value: 220000
  },
  {
    Project: "Canterbury Road",
    ProjectShortCode: "603",
    Address: "603 Canterbury Rd, Surrey Hills VIC 3127",
    Status: "Post-Completion",
    Organization: "Condev Construction Pty Ltd",
    ABN: "39 101 213 825",
    ACN: "101 213 82",
    PaidToDate: 350000,
    PaidToBuilder: 130000,
    PaidToNTS: 220000,
    PercentPaidToNTS: 62.86,
    Value: 220000
  },
];


export const projectParticipants = [
  {
    Organization: "Bendigo Bank",
    Role: "Financier",
    Status: "Active",
    StartDate: "2021-12-20"
  },
  {
    Organization: "Bobs Builder",
    Role: "Builder",
    Status: "Active",
    StartDate: "2022-01-15",
    EndDate: "2022-03-30"
  },
  {
    Organization: "Jims QS Service",
    Role: "FRP",
    Status: "Active",
    StartDate: "2020-01-21"
  }
];

export const projectBreakDown = [
  {
    TradeItem: "Preliminaries - Tower Crane",
    NTS: true,
    Amount: 150000.00
  },
  {
    TradeItem: "Preliminaries - Other",
    NTS: true,
    Amount: 5000.00
  },
  {
    TradeItem: "Overheads & Margin",
    NTS: true,
    Amount: 9000.00
  },
  {
    TradeItem: "Demolition",
    NTS: false,
    Amount: 0.00
  }
]