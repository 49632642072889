import { useJumboTheme } from '@jumbo/hooks';
import { Box, Button, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FileUploader from 'devextreme-react/file-uploader';
import { isUndefined, keys, startCase } from 'lodash';
import { buttonStyles, gridStyles } from 'theme/cashflowsTheme';
import DataLoader from '../../../@data';
import { projectBreakDown } from './constructiondata';
//import TradeBreakDown from '../../../../public/documents/TradeBreakDown.xlsx';

const details = () => {
    const columns = DataLoader("tables", "tables.BreakDownTable.header.columns");
    const cols = keys(columns);

    return cols.map((element, i) => {
        let d = "string";
        let r = false;
        if (!isUndefined(columns[element].f)) {
            d = columns[element].f;
        }
        if (!isUndefined(columns[element].r)) {
            r = columns[element].r;
        }
        return ({
            field: element,
            headerName: startCase(element),
            type: d,
            flex: 0.5
        })
    });
}

export default function ProjectTrade(props) {
    //const { editorProps, setEditorProps } = useContext(EditorCtx)
    const { theme } = useJumboTheme()
    const btnStyle = buttonStyles(theme)

    return (
        <Grid container lg={8}>
            <Grid item lg={4}>
                <div className="fileuploader-container">
                    <FileUploader
                        selectButtonText="Upload Trade Breakdown"
                        labelText=""
                        //accept="image/*"
                        accept={'.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .zip, .gif'}
                        uploadMode="useForm" />
                </div>
            </Grid>
            <Grid item lg={4}>
                <div className="fileuploader-container">
                    <a href="../../../../public/documents/TradeBreakDown.xlsx" download='TradeBreakDown' target='_blank' >
                        <Button
                            variant="contained"
                            color='info'
                            aria-controls="fade-menu"
                            sx={{
                                ...btnStyle.button,
                                margin: '10px 10px 0'
                            }}
                            aria-haspopup="true">Download Template</Button>
                    </a>
                </div>
            </Grid>
            <Box sx={{ height: 700, width: '100%' }}>
                <DataGrid
                    rows={projectBreakDown}
                    columns={details()}
                    responsive='onResize'
                    density={'standard'}
                    editMode={'row'}
                    sx={{
                        ...gridStyles(theme)
                    }}
                    pagination
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    // experimentalFeatures={{ newEditingApi: true }}
                    // components={{ Toolbar: GridToolbar, Pagination: CustomPagination, }}
                    getRowId={(row) => row.TradeItem}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            sx: {
                                '& .MuiButtonBase-root': {
                                    color: '#ff5722'
                                }
                            }
                        },
                    }}
                ></DataGrid>
            </Box>
        </Grid>
    )
}
