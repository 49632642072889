import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../utils/constants/paths";


const Logo = ({ mini, mode, sx }) => {
    return (
        <Div sx={{ display: "inline-flex", ...sx }}>
            <Link href={'/'}>
                {
                    !mini ?
                        <img src={`${ASSET_IMAGES}payarklogo.svg`} alt="Payark" height={'60px'} />
                        :
                        <img src={`${ASSET_IMAGES}payarklogo.svg`} alt="Payark" height={'60px'} />
                }
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
