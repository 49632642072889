import clsx from 'clsx';
import DataGrid, {
    Editing,
    Pager,
    Paging,
    SearchPanel
} from 'devextreme-react/data-grid';
import { isUndefined } from 'lodash';
import { useContext, useRef } from 'react';
import { EditorCtx } from '../CurrentProjectsSummary';
import { useJumboTheme } from '@jumbo/hooks';

const useStyles = x => ({
    table: {
        '& .dx-datagrid': {
            backgroundColor: x.palette.background.paper,
            fontFamily: x.typography.fontFamily
        },
    },
    toolbar: {
        '& .dx-datagrid-header-panel': {
            backgroundColor: x.palette.background.paper
        }
    },
    toolbar1: {
        '& .dx-toolbar': {
            backgroundColor: x.palette.background.paper
        }
    },
    rows: {
        '& .dx-data-row:hover ': {
            backgroundColor: "rgba(0,215,200, 0.5)"
        },
        '& .dx-datagrid tr:nth-child(even) td': {
            // opacity: '0.70'
        }
    }
});

/**
 * Basic table for simple data display
 * @returns 
 */

function DevxTable({ columns, data, keyCol, editingFx, confirmDelete = true, ...props }) {
    const { theme } = useJumboTheme()
    const classes = useStyles(theme);
    const { editorProps, setEditorProps } = useContext(EditorCtx);
    const dataGrid = useRef(null)
    if (isUndefined(editingFx)) {
        editingFx = true
    }

    return <DataGrid
        ref={dataGrid}
        id="devxtable"
        className={clsx(classes.table, classes.rows, classes.toolbar, classes.toolbar1)}
        dataSource={data}
        allowColumnReordering={true}
        showBorders={true}
        height={'100%'}
        repaintChangesOnly={true}
        selection={{ mode: 'single' }}
        keyExpr={keyCol}
        width={'100%'}
        onInitialized={function (x) {
            setEditorProps({
                ...editorProps,
                dataGrid: dataGrid
            })
        }}
        onSelectedRowKeysChange={x => {
            setEditorProps({
                ...editorProps,
                mode: "edit",
                selectedUser: x[0],
                // dataGrid: dataGrid
            })
        }}
        {...props}>
        <SearchPanel
            visible={true}
            highlightCaseSensitive={true} />
        <Editing
            mode="row"
            allowDeleting={editingFx}
            useIcons={true}
            confirmDelete={confirmDelete}
        />
        {columns()}
        <Pager allowedPageSizes={[10, 25, 50]} showPageSizeSelector={true} />
        <Paging defaultPageSize={25} />
    </DataGrid>;
}

export default DevxTable
