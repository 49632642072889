import { useJumboTheme } from '@jumbo/hooks';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Tab } from '@mui/material';
import { errorMessage } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { buttonStyles } from 'theme/cashflowsTheme';
import BatchPaymentDetails from '../Partials/BatchPaymentDetails';
import MuiGrid, { TableColumns } from '../Partials/MuiTable';
import { showErrorToast, showSuccessToast } from '../Partials/Notify';

const ABA = require('aba-generator')

const BankingContext = createContext()

export default function Banking({ projID }) {
    const [innerTabValue, setInnerTab] = useState("0")

    const handleChange2 = (event, newValue) => {
        // setinnerValue(newValue);
        setInnerTab(newValue)
    };

    return <Box>
        <TabContext value={innerTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange2} aria-label="Claims and Payments" value={innerTabValue}>
                    <Tab label="Approved Payments" value="0" />
                    <Tab label="Batches" value="1" />
                </TabList>
            </Box>
            <TabPanel value="0">
                <BankingTab projID={projID} />
            </TabPanel>

            <TabPanel value="1">
                <BatchesTab projID={projID} />
            </TabPanel>
        </TabContext>
    </Box>
}

function BatchesTab(props) {
    const { theme } = useJumboTheme()
    const btnStyle = buttonStyles(theme)
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedBatch, setSelectedBatch] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const [showBatchPayments, setshowBatchPayments] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})
    const [disabled, setDisabled] = useState(false)
    const bt1 = useRef(null)
    const bt2 = useRef(null)

    useEffect(() => {
        if (selectedRow.Status !== "Pending Payment") {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [selectedRow.Status, selectedRow.BatchPaymentId, refresh])

    return <>
        <Button
            variant="contained"
            color='info'
            ref={bt1}
            aria-controls="fade-menu"
            disabled={disabled}
            onClick={x => {
                x.currentTarget.disabled = true
                axios.post(`/api/Processpayments/Cancel?key=${selectedRow.BatchPaymentId}`).then(resp => {
                    showSuccessToast("Batch Cancelled")
                    bt1.current.disabled = false
                    setRefresh(p => !p)
                }).catch(err => {
                    console.log("🚀 ~ file: Banking.jsx:62 ~ BatchesTab ~ errorMessage(err):",)
                    showErrorToast(`Failed to Cancel the batch: ${errorMessage(err)}`)
                    bt1.current.disabled = false
                })
            }}
            sx={{
                ...btnStyle.button,
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">Cancel Batch</Button>
        <Button
            variant="contained"
            color='info'
            ref={bt2}
            aria-controls="fade-menu"
            disabled={disabled}
            onClick={x => {
                x.currentTarget.disabled = true
                axios.post(`/api/Processpayments/MarkasPaid?key=${selectedRow.BatchPaymentId}`).then(resp => {
                    showSuccessToast("Batch Mark as Paid")
                    bt2.current.disabled = false
                    setRefresh(p => !p)
                }).catch(err => {
                    console.log("🚀 ~ file: Banking.jsx:77 ~ BatchesTab ~ err:", err)
                    showErrorToast("Failed to Update Batch")
                    bt2.current.disabled = false
                })
            }}
            sx={{
                ...btnStyle.button,
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">Mark as Paid</Button>
        <Button
            variant="contained"
            color='info'
            aria-controls="fade-menu"
            onClick={x => {
                setshowBatchPayments(true)
            }}
            sx={{
                ...btnStyle.button,
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">View Payments</Button>
        <BatchPaymentDetails
            show={showBatchPayments}
            ID={!isEmpty(selectedRow) ? selectedRow.BatchPaymentId : 0}
            onClose={x => {
                setshowBatchPayments(false)
            }} />
        <Box sx={{ height: 700, width: '100%' }}>
            <BankingContext.Provider value={{
                selectedRows, setSelectedRows, setSelectedBatch, setSelectedRow, selectedRow
            }}>
                <BatchesGrid projID={props.projID} refresh={refresh} />
            </BankingContext.Provider>
        </Box>
    </>
}

function BankingTab(props) {
    const { theme } = useJumboTheme()
    const btnStyle = buttonStyles(theme)
    const [selectedRows, setSelectedRows] = useState([])
    const [refresh, setRefresh] = useState(false)
    const btnRef = useRef(null)

    return <>
        <Button
            variant="contained"
            ref={btnRef}
            color='info'
            aria-controls="fade-menu"
            onClick={async x => {
                //send request to server
                x.currentTarget.disabled = true
                axios.post("/api/Processpayments/GenABARecords", {
                    PaymentIDs: [...selectedRows]
                }).then(resp => {
                    generate(resp.data)
                    setRefresh(p => !p)
                    btnRef.current.disabled = false
                }).catch(err => {
                    showErrorToast(`Failed to Generate ABA File: ${errorMessage(err)}`)
                    btnRef.current.disabled = false
                })
            }}
            sx={{
                ...btnStyle.button,
                margin: '10px 10px 0'
            }}
            aria-haspopup="true">Generate ABA File</Button>
        <BankingContext.Provider value={{
            selectedRows, setSelectedRows
        }}>
            <BankingGrid projID={props.projID} refresh={refresh} />
        </BankingContext.Provider>
    </>
}

function BankingGrid({ projID, refresh }) {
    const [paymentData, setPaymentData] = useState(null)
    const { selectedRows, setSelectedRows } = useContext(BankingContext)

    useEffect(() => {
        if (isNil(projID) || projID < 1) return
        axios.get(`/api/Payment/GetPaymentFor?project=${projID}`).then(resp => {
            setPaymentData(resp.data?.filter(elem =>
                elem.Status === "Approved" || elem.Status === "Approved with Comments"
            ))
            let g = resp.data.filter(elem => elem.Status === "Approved")
            setSelectedRows(g?.map(elem => elem.PaymentID))
        }).catch(err => {
        })
    }, [projID, refresh])

    const cols = TableColumns("tables.PaymentsTable.header.columns")
    // {
    //     field: 'BatchNo',
    //     flex: 1,
    // },
    // {
    //     field: 'BankProcessingStatus',
    //     flex: 1,
    // }
    //]

    return <Box sx={{ height: 700, width: '100%' }}>
        <MuiGrid
            initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
                sorting: {
                    sortModel: [{ field: 'PaymentNumber', sort: 'asc' }],
                },
            }}
            rows={paymentData ?? []}
            checkboxSelection={true}
            disableRowSelectionOnClick
            loading={paymentData == null}
            columns={cols}
            getRowId={(row) => row.PaymentID}
            editMode={'row'}
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(prev) => {
                setSelectedRows(prev)
            }}
            onRowClick={x => {
                // setSelectedRow(x.row)
            }}
        ></MuiGrid>
    </Box >
}

function BatchesGrid({ projID, refresh }) {
    const [paymentData, setPaymentData] = useState(null)
    const { selectedRows, setSelectedRows, setSelectedBatch, setSelectedRow, selectedRow } = useContext(BankingContext)

    useEffect(() => {
        axios.get(`/api/Payment/GetBatches?project=${projID}`).then(resp => {
            if (resp.data?.length == 0 ?? true) {
                setPaymentData([])
            } else {
                setPaymentData(resp.data)
            }
            //reload selected row
            setSelectedRow({})
        }).catch(err => {
        })
    }, [projID, refresh])

    const cols = TableColumns("tables.BatchPaymentTable.header.columns")

    return <Box sx={{ height: 700, width: '100%' }}>
        <MuiGrid
            initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
                sorting: {
                    sortModel: [{ field: 'CreatedAt', sort: 'asc' }],
                },
            }}
            rows={paymentData ?? []}
            //checkboxSelection={true}
            //disableRowSelectionOnClick
            loading={paymentData == null}
            columns={cols}
            getRowId={(row) => row.BatchPaymentId}
            editMode={'row'}
            //rowSelectionModel={selectedRows}
            // onRowSelectionModelChange={(prev) => {
            //     console.log(`🚀 ~ file: Banking.jsx:236 ~ BatchesGrid ~ prev: ${prev} ~ selectedRows: ${selectedRows}`)
            //     if (isEmpty(prev)) {
            //         setSelectedRows([])
            //         setSelectedBatch(null)
            //     }
            //     try {
            //         const bNo = paymentData?.filter(elem => elem.PaymentID == prev)[0].BatchNo
            //         const p = paymentData?.filter(elem => elem.BatchNo === bNo).map(elem => elem.PaymentID)
            //         if (selectedRows.filter(e => e == prev)?.length > 0) {
            //             setSelectedBatch(null)
            //             setSelectedRows([])
            //         }
            //         else if (!isNil(p)) {
            //             setSelectedRows(p)
            //             setSelectedBatch(bNo)
            //         }
            //     } catch (error) {
            //     }
            // }}
            onRowClick={x => {
                setSelectedRow(x.row)
            }}
        ></MuiGrid>
    </Box >
}

function generate(batch) {
    const trans = JSON.parse(batch.transactions)
    console.log("🚀 ~ file: Banking.jsx:277 ~ generate ~ batch.BatchNo:", batch.BatchNo)
    const aba = new ABA({
        header: {
            bank: "MBL", // The bank processing this file
            user: "Payark", // Your (money sender) company name
            userNumber: "000000", // Your ID in the bank system, often hardcoded to some number. Consult with your bank
            description: `#${batch.BatchNo}`, // Description of the transactions within the file
            // Optional
            // bsb: String, // Main account BSB. Not in the ABA spec, but required by most banks
            // account: String, // Main account number. Not in the ABA spec, but required by most banks
            date: moment().format("DDMMYY"), // The date to be processed, default is now. If string must be DDMMYY format
            // time: Date|String|Number, // The time to be processed. Not in the ABA spec, but required by most banks. If string must be HHmm format
        },
    });
    // const transaction = {
    //     bsb: "061021", // recipient's account BSB
    //     transactionCode: ABA.CREDIT,
    //     // indicates "you are sending money (debit), they are receiving (credit)". ABA.PAY works in the same way but with code 53
    //     account: "123456", // recipient's account number
    //     amount: 12.0, // Number|String sending amount the recipient will see in their bank account
    //     accountTitle: "Georgian Council of New South Wales", // recipient account name
    //     reference: "Invoice # 1234", // payment reference, will be visible to the recipient
    //     traceBsb: "061123", // your (sender) bank account BSB
    //     traceAccount: "1234567", // your (sender) bank account number
    //     remitter: "Acme Inc", // Your (sender) company name

    //     // Optional
    //     // tax: "N"|"W"|"X"|"Y"|" "|"", // Tax withholding indicator. Typically blank, meaning no tax. See ABA specification.
    //     // taxAmount: Number|String, // Tax amount. Typically 0.
    // };

    const file = aba.generate(trans);
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(file));
    element.setAttribute('download', `PaymentsBatch-${batch.BatchNo}.aba`);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
}

Banking.propTypes = {
    projID: PropTypes.number
}
