// import firebase from 'firebase';

// // Initialize Firebase
// const config = {
//   apiKey: 'AIzaSyCYaTKjfam_qMXDnGfcdnBxScEq89VQtLk',
//   authDomain: 'curious-sandbox-196209.firebaseapp.com',
//   databaseURL: 'https://curious-sandbox-196209.firebaseio.com',
//   projectId: 'curious-sandbox-196209',
//   storageBucket: '',
//   messagingSenderId: '1034032747860',
// };

// firebase.initializeApp(config);
// const auth = firebase.auth();

// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
// const githubAuthProvider = new firebase.auth.GithubAuthProvider();
// const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

// const database = firebase.database();
// export { auth, database, googleAuthProvider, githubAuthProvider, facebookAuthProvider, twitterAuthProvider };

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_6-QMAtC4X-kwo50bkwjrIau6e3gNemY",
  authDomain: "pure-lantern-351111.firebaseapp.com",
  projectId: "pure-lantern-351111",
  storageBucket: "pure-lantern-351111.appspot.com",
  messagingSenderId: "319729997551",
  appId: "1:319729997551:web:31a651f43e8ecdfa992d1a",
  measurementId: "G-EQ963B7RYB"
};

export const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase
// export default firebaseApp;
export const analytics = getAnalytics(firebaseApp);
