import React from 'react'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { m5 } from '../Partials/Mui5';
import { useJumboTheme } from '@jumbo/hooks';
function Steppers(props) {
const { theme } = useJumboTheme()
const styles = m5(theme)
const { stepLabels , step} = props;
  return (
    <div style={{ paddingBottom: '25px' }}>
    <Stepper activeStep={step} alternativeLabel sx={styles.stepper}>
      {stepLabels.map((label, index) => (
        <Step key={index}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </div>
  )
}

export default Steppers