import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import layoutConfig from "./layoutConfig";
import { ASSET_IMAGES } from "app/utils/constants/paths";

const SoloPage = ({ children }) => {
    const { setJumboLayoutOptions } = useJumboLayout();

    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig);
    }, []);

    return (
        <JumboLayout>
            <div style={{
                background: `url(${ASSET_IMAGES}/auth/auth-bg-pattern.png) center no-repeat`,
                backgroundSize: "cover",

            }}>
                {children}
            </div>
        </JumboLayout>
    );
};

export default SoloPage;
