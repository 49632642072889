import Dropdown from "./DropBox";
import { useContext } from "react";
import { OrgContext } from "@data";

export default function ContactsType({ selectedStatus, setStatus }) {
    const { contactTypes } = useContext(OrgContext)

    const orgData = contactTypes.map(e => {
        return {
            value: e.ContactTypeID,
            Name: e.Name,
            Description: e.Description,
            ContactTypeID: e.ContactTypeID
        }
    })

    return (<Dropdown
        placeholder="Role"
        value={selectedStatus}
        data={orgData}
        mapping={x => {
            return { value: x.ContactTypeID, label: x.Name }
        }}
        onChange={setStatus}
    />)
}
