import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./app/App";
import './app/config/i18n';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { showErrorToast } from 'app/pages/Partials/Notify';
axios.interceptors.request.use(function (request) {
  const token = sessionStorage.getItem("token")
  request.headers.common["Authorization"] = 'Bearer ' + token;
  return request;
});

axios.interceptors.response.use(
  response => {
    return response
  }, err => {
    if (err?.response?.status === 403 || err?.response?.status === 401) {
      console.log("🚀 ~ file: config.js ~ line 39 ~ err.response.status", err?.response?.status)
      showErrorToast('User not Authorized');
      sessionStorage.removeItem("token")
      // document.location = "/signin"
    }
    return Promise.reject(err);
  })

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
