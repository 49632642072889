import { Slide } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const PageContainer = ({ heading, breadcrumbs, children, className, restProps }) => {

  return (
    <Slide in={true} direction="up" mountOnEnter unmountOnExit>
      <Box sx={{ width: '100%' }} {...restProps}>
        {/* {(heading || breadcrumbs) && (
          <PageHeader heading={heading} breadcrumbComponent={breadcrumbs && <PageBreadcrumbs items={breadcrumbs} />} />
        )} */}
        {children}
      </Box>
    </Slide>
  );
};

export default PageContainer;
