import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};

authServices.getCurrentUser = async () => {
    const { data } = await jwtAuthAxios.post("/api/Login/me");
    sessionStorage.setItem("routes", JSON.stringify(data))
    return data;
};

//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
// authServices.signIn = async (loginCreds) => {
//     const { data } = await jwtAuthAxios.post('/auth', loginCreds);
//     return data;
// };

export default authServices;

// user: data.user,
// email: data.email,
// allowedRoutes: data.allowedRoutes
