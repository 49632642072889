import { Box, Grid, Input, TextField, Typography } from '@mui/material'
import React, { memo, useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useRecoilState, useRecoilValue } from "recoil";
import { newProjData } from "../../../@data/localStore";
import { has, isEmpty, isNil } from "lodash";
import PropTypes from 'prop-types';

const at = (type) => {
  if (type === 'Address') {
    return 'projectAddress'
  } else if (type === 'Billing Address') {
    return 'billingAddress'
  } else if (type === "") {
    return ''
  }
}

function Address(props) {
  const [showAutoComplete, setShowAutoComplete] = useState(true)
  const [addressComponents, setAddressComponents] = useState([])
  const [projData, setProjData] = useRecoilState(newProjData)

  const parser = (data) => {
    let res = {
      street: '',
      route: '',
      city: '',
      suburb: '',
      state: '',
      postal_code: '',
      country: ''
    }
    data.forEach(element => {
      element['types'].forEach(x => {
        switch (x) {
          case 'postal_code':
            res['postal_code'] = element['long_name']
            break;
          case 'country':
            res['country'] = element['long_name']
            break;
          case 'administrative_area_level_1':
            res['state'] = element['short_name']
            break;
          case 'administrative_area_level_2':
            res['city'] = element['long_name'];
            break;
          case 'locality':
            res['suburb'] = element['long_name']
            break;
          case 'route':
            res['route'] = element['long_name']
            break
          case 'street_number':
            res['street'] = element['long_name']
            break
        }
      })
    });
    return res
  }

  const { addressType, onAddressChanged } = props;

  useEffect(() => {
    //one check to load saved data!
    if (!isNil(projData['projectAddress'])) {
      if (at(addressType) === 'projectAddress') {
        //load data
        setShowAutoComplete(false)
      }
    }
    if (!isNil(projData['billingAddress'])) {
      if (at(addressType) === 'billingAddress') {
        setShowAutoComplete(false)
      }
    }
    if (addressType === '' && !isNil(props.orgAddress) && !isEmpty(props.orgAddress)) {
      setShowAutoComplete(false)
    }
  }, [])

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="div">
            {addressType}
          </Typography>
          <CompletedAddress parsed={parser(addressComponents)} type={addressType} orgAddress={props.orgAddress} />
          <div style={{
            width: '95%',
            display: showAutoComplete ? 'initial' : 'none'
          }}>
            <Input
              fullWidth
              color="secondary"
              inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                <Autocomplete
                  options={{
                    types: ['address'], componentRestrictions: { country: 'au' }, strictBounds: false,
                    fields: ['formatted_address', "address_components"],
                  }}
                  apiKey={'AIzaSyC_6-QMAtC4X-kwo50bkwjrIau6e3gNemY'}
                  {...props}
                  onPlaceSelected={(selected) => {
                    setShowAutoComplete(false)
                    setAddressComponents(selected['address_components'])
                    let temp = {
                      ...projData
                    }
                    const p = parser(selected['address_components'])
                    onAddressChanged(p)
                    if (addressType !== '') {
                      temp[at(addressType)] =
                      {
                        'StreetAddress': p['street'] + ' ' + p['route'],
                        'City': p['city'],
                        'Suburb': p['suburb'],
                        'State': p['state'],
                        'PostalCode': p['postal_code'],
                        'Country': p['country'],
                      }
                      setProjData({
                        ...temp
                      })
                    }
                  }}
                />//Autocomplete
              )}
            />
          </div>
        </Grid>
      </Grid>
    </Box>)

  function CompletedAddress(props) {
    const [street, setStreet] = useState(props.parsed['street'] + ' ' + props.parsed['route'])
    const [city, setCity] = useState(props.parsed['city'])
    const [state, setState] = useState(props.parsed['state'])
    const [postal, setPostal] = useState(props.parsed['postal_code'])
    const [country, setCountry] = useState(props.parsed['country'])
    const [suburb, setSuburb] = useState(props.parsed['suburb'])
    const projData = useRecoilValue(newProjData)

    useEffect(() => {
      //TODO change this block need only one!
      let type = at(props.type)
      if (type === 'billingAddress' && !isNil(projData['billingAddress'])) {
        setStreet(projData[type]['StreetAddress'])
        setCity(projData[type]['City'])
        setState(projData[type]['State'])
        setPostal(projData[type]['PostalCode'])
        setCountry(projData[type]['Country'])
        setSuburb(projData[type]['Suburb'])
      }
      else if (type === 'projectAddress' && !isNil(projData['projectAddress'])) {
        setStreet(projData[type]['StreetAddress'])
        setCity(projData[type]['City'])
        setState(projData[type]['State'])
        setPostal(projData[type]['PostalCode'])
        setCountry(projData[type]['Country'])
        setSuburb(projData[type]['Suburb'])
      }
      else if (!isNil(props.orgAddress) && !isEmpty(props.orgAddress)) {
        if (has(props.orgAddress, 'AddressLine1')) {
          setStreet(props.orgAddress['AddressLine1'] + ' ' + (isNil(props.orgAddress['AddressLine2']) ? '' : props.orgAddress['AddressLine2']))
        } else {
        }
        if (has(props.orgAddress, 'City')) {
          setCity(props.orgAddress['City'])
        }
        if (has(props.orgAddress, 'State')) {
          setState(props.orgAddress['State'])
        }
        if (has(props.orgAddress, 'ZipCode')) {
          setPostal(props.orgAddress['ZipCode'])
        }
        if (has(props.orgAddress, 'Country')) {
          setCountry(props.orgAddress['Country'])
        }
        if (has(props.orgAddress, 'Suburb')) {
          setSuburb(props.orgAddress['Suburb'])
        }
      }
    }, [])

    return <>
      <Grid container style={{ display: showAutoComplete ? 'none' : 'flex' }}>
        <Grid item sm={12}>
          <TextField
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={street == null ? 'Street Address' : ''}
            variant='standard'
            value={street}
            onChange={e => {
              setShowAutoComplete(true)
            }} />
        </Grid>
        <Grid item sm={4} lg={4} md={4}>
          <TextField
            label={city == null ? 'City' : ''}
            required
            value={suburb}
            variant='standard'
            InputLabelProps={{ shrink: true }} />
        </Grid>
        <Grid item sm={4} lg={4} md={4}>
          <TextField
            label={state == null ? 'State' : ''}
            required
            InputLabelProps={{ shrink: true }}
            value={state}
            style={{ paddingLeft: '5px' }}
            variant='standard' />
        </Grid>
        <Grid item sm={4} lg={4} md={4}>
          <TextField
            label={postal == null ? 'Postal Code' : ''}
            required
            InputLabelProps={{ shrink: true }}
            value={postal}
            style={{ paddingLeft: '5px' }}
            variant='standard' />
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <TextField
            label={country == null ? 'Country' : ''}
            required
            InputLabelProps={{ shrink: true }}
            value={country}
            variant='standard' />
        </Grid>
      </Grid>
    </>
  }
}

export default memo(Address)

Address.propTypes = {
  addressType: PropTypes.string.isRequired,
  orgAddress: PropTypes.object
}
