import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';
import { useJumboTheme } from '@jumbo/hooks';
import { Group, PersonAdd } from '@mui/icons-material';
import { Avatar, IconButton } from '@mui/material';
import { isNil } from 'lodash';
import React, { createContext, useEffect, useRef, useState } from 'react';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import { cardStyles } from '../../../theme/cashflowsTheme';
import { PersonAddDlg } from '../Partials/PersonAddDlg';
import UserListTable from '../UserManagement/UserTable';

export const EditorCtx = createContext();

const CurrentProjectsSummary = (props) => {
  const { theme } = useJumboTheme()
  const classes = cardStyles(theme)
  const [addPeronShow, setAddPeronShow] = useState(false);
  const [editorProps, setEditorProps] = useState({});
  const editBtn = useRef(null)

  useEffect(() => {
    //setup double click
    const listener = e => {
      editBtn.current.click()
    }
    document.addEventListener("dblclick", listener)
    return () => {
      document.removeEventListener("dblclick", listener)
    }
  }, [])

  return (
    <React.Fragment>
      <EditorCtx.Provider value={{ editorProps, setEditorProps }}>
        <JumboCardQuick
          sx={classes.cardRoot}
          avatar={<Avatar color="primary" size="small"><Group fontSize="small" /></Avatar>}
          title={props.title}
          subheader={<IntlMessages id="UserManager.Dlg.SubTitle" />}
          action={<IconButton
            title={"Add new user"}
            onClick={() => {
              setAddPeronShow(true)
              setEditorProps({
                dataGrid: editorProps.dataGrid,
                mode: "new"
              })
            }}
            size={'medium'}><PersonAdd /></IconButton>}
        >
          <PersonAddDlg
            show={addPeronShow}
            onClose={({ refresh }) => {
              setAddPeronShow(false)
              if (!isNil(editorProps.dataGrid) && !isNil(editorProps.dataGrid.current)) {
                if (refresh) {
                  editorProps.dataGrid.current.instance.refresh()
                }
              }
            }}
            selectedUser={editorProps.selectedUser}
            mode={editorProps.mode} />
          <UserListTable />
        </JumboCardQuick>
      </EditorCtx.Provider>
    </React.Fragment>
  );
};

export default CurrentProjectsSummary;
