import { useJumboTheme } from '@jumbo/hooks';
import { Box, Button } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { hiddenProjects, loadPreferences, userOrgDlg } from '../../../@data/localStore';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
const Error404 = () => {
  const { theme } = useJumboTheme();

  const classes = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    errorNumber: {
      color: theme.palette.text.primary,
      fontWeight: 800,
      lineHeight: 1,
      marginBottom: 30,
      textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
    }
  }

  return (
    <Box className={classes.root}>
      <Box fontSize={{ xs: 100, sm: 160 }} className={classes.errorNumber}>
        404
      </Box>
      <Box fontSize={{ xs: 16, sm: 24 }} mb={8} color="grey.500">
        <IntlMessages id="extraPages.404Msg" />
      </Box>
      <Box mt={8}>
        <Button variant="contained" color="primary">
          <IntlMessages id="extraPages.goHome" />
        </Button>
      </Box>
    </Box>
  );
};

export const BlankPage = () => {
  const setHiddenProjs = useSetRecoilState(hiddenProjects)
  const navigate = useNavigate()
  const showOrgDlg = useSetRecoilState(userOrgDlg)
  const { theme } = useJumboTheme();
  const classes = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    errorNumber: {
      color: theme.palette.text.primary,
      fontWeight: 800,
      lineHeight: 1,
      marginBottom: 30,
      textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
    }
  }

  useEffect(() => {
    const token = sessionStorage.getItem("token")
    if (isNil(token) || isEmpty(token)) { return }
    loadPreferences().then(x => {
      try {
        setHiddenProjs(x['pref']['hiddenProjects'])
      } catch (error) {
      }
      if (x['popupOrg']) {
        navigate("/orgdetails")
        showOrgDlg(true)
      }
      else { navigate("/developers") }
    })
  }, [])

  return (
    <Box sx={{
      ...classes.root
    }}>
      <Box fontSize={{ xs: 12, sm: 16, md: 100, lg: 100 }} sx={{ ...classes.errorNumber }}>
        Welcome
      </Box>
      <Box fontSize={{ xs: 12, sm: 16 }} mb={8} color="grey.500">
        {/* Let's get started */}
      </Box>
      {/* <Box mt={8}>
        <Button variant="contained" color="primary">
        </Button>
      </Box> */}
    </Box>
  );
}

export default Error404;
