import GridContainer from '@jumbo/components/GridContainer';
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';
import PageContainer from '@jumbo/components/PageContainer';
import { useJumboTheme } from '@jumbo/hooks';
import { LocationCity } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Avatar, Box, Grid } from '@mui/material';
import { isNotNullUndef } from 'app/utils/constants/AppConstants';
import axios from 'axios';
import _, { findIndex, has, isEmpty, isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/dist';
import { useRecoilState } from 'recoil';
import { hiddenProjects, newProjData } from '../../../@data/localStore';
import { EditorCtx } from '../CurrentProjectsSummary';
import { m5 } from '../Partials/Mui5';
import ProjectContent from '../Partials/Project/ProjectContent';
import { MenuItemC, MoreMenu } from './Developers';

const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Developer Projects', isActive: true },
];

export const DeveloperProjectDetails = (props) => {
    // const [showProjectTrade, setShowProjectTrade] = useState(false)
    // const [showProjectTeam, setShowProjectTeam] = useState(false)
    // const [showAssessClaim, setShowAssessClaim] = useState(false)
    // const [showBankDetails, setShowBankDetails] = useState(false)
    // const [showProjectDetails, setShowProjectDetails] = useState(false)
    const { theme } = useJumboTheme()
    const confirm = useConfirm()
    const m5style = m5(theme)
    const [currTab, setCurrTab] = useState("0")
    const [projDetails, setProjDetails] = useState({
        PaidtoDate: 0,
        PaidToBuilder: 0,
        PaidToTrades: 0,
        ProjectShortCode: '',
        StatusID: 0,
        PrincipleOrg: 0,
        Name: '',
        ProjectID: 0
    })
    const [projData, setProjData] = useRecoilState(newProjData)
    const location = useLocation()
    const [warning, shownWarning] = useState(false)
    // const setProjData = useSetRecoilState(newProjData)

    useEffect(() => {
        axios.get(`/api/Project/GetProject?id=${location.state.projID}`).then(resp => {
            setProjDetails(resp.data)
            //setup address:
            if (isNotNullUndef(resp.data.postalAddress)) {
                setProjData({
                    ...resp.data,
                    projectAddress: {
                        ...resp.data.postalAddress
                    },
                    billingAddress: isNotNullUndef(resp.data.billingAddress) ? { ...resp.data.billingAddress } : null,
                    ProjectContacts: resp.data.ProjectContacts,
                    ProjectTeams: resp.data.ProjectTeams,
                    reload: projData.reload
                })
            }
            //check for notifications here and popup message:
            if (!warning && !isNil(location.state.projID)) {
                console.log("🚀 ~ file: DeveloperProjects.jsx:64 ~ axios.get ~ warning:", warning)
                axios.post(`/api/Project/ProjectBankAccountNotifications?projectID=${location.state.projID}`)
                    .then(resp => {
                        shownWarning(true)
                        if (!isEmpty(resp.data) && has(resp.data, 'Notification') && !isEmpty(resp.data['Notification'])) {
                            confirm({
                                description: resp.data.Notification[0] + '.',
                                title: (
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginRight: '2px' }}>
                                            <NotificationsIcon style={{ fontSize: '30px', color: '#2EB5C9', paddingBottom: '2px', paddingRight: '2px' }} />
                                        </div>
                                        <div style={{ fontSize: '21px', paddingTop: '3px', fontFamily: "Plus Jakarta Sans", fontWeight: 'normal' }}>
                                            Notification
                                        </div>
                                    </div>
                                ),
                                hideCancelButton: true,
                                titleProps: {
                                    sx: { ...m5style.dlgTitle, paddingBottom: '5px' }
                                },
                                confirmationButtonProps: {
                                    variant: 'contained',
                                    color: 'info',
                                    sx: {
                                        ...m5style.notificationConfirmationButton,
                                    },
                                },
                                contentProps: {
                                    sx: {
                                        ...m5style.paperDialogContent,
                                    },
                                },
                                dialogProps: {
                                    maxWidth: 'xs',
                                    sx: {
                                        ...m5style.dlgContent,
                                    },
                                }
                                // dialogProps: { disableEnforceFocus: true, color: "red" },
                            }).then(x => {
                            }).catch((e) => {
                            })
                        }
                    })
                    .catch(err => {
                    })
            }
        }).catch(err => {
            setProjDetails({
                status: 1,
                name: "",
                shortCode: "",
                Filebody: null,
                reload: false
            })
        })
    }, [location.state.projID, projData.reload])
    // const details = () => {
    //     const columns = DataLoader("tables", "tables.ProjectTable.header.columns");
    //     const cols = keys(columns);
    //     function renderType(d) {
    //         if (d === "percentage") return printPercentage
    //         if (d === "currency") return printCurrency
    //         return null
    //     }

    //     return cols.map((element, i) => {
    //         let d = "string";
    //         let r = false;
    //         if (!isUndefined(columns[element].f)) {
    //             d = columns[element].f;
    //         }
    //         if (!isUndefined(columns[element].r)) {
    //             r = columns[element].r;
    //         }
    //         const addOn = x => {
    //             return x === "percentage" ? " %" : ''
    //         }
    //         return (
    //             <Column
    //                 caption={startCase(element) + addOn(d)}
    //                 dataField={element}
    //                 dataType={d}
    //                 cellRender={renderType(d)}
    //                 key={i}
    //                 width={'auto'}
    //                 allowEditing={!r}>
    //             </Column>)
    //     });
    // }

    // function editingFx(e) {
    //     if (e.row.data.Status === "New" || e.row.data.Status === "Draft" || e.row.data.Status === "Submitted") return true
    //     return false
    // }

    // function a11yProps(index) {
    //     return {
    //         id: `full-width-tab-${index}`,
    //         'aria-controls': `full-width-tabpanel-${index}`,
    //     };
    // }

    function FullWidthTabs(props) {
        // const [value, setValue] = useState(0)
        const [editorProps, setEditorProps] = useState({})
        const [showProject, setShowProjects] = useState(false)
        const [editMode, setEditMode] = useState("new")
        const [hiddenProjs, setHiddenProjs] = useRecoilState(hiddenProjects)
        const { theme } = useJumboTheme()
        const classes = m5(theme)

        return (<Box
            sx={classes.cRoot}
            width={1}>
            <JumboCardQuick
                sx={classes.cardRoot}
                headerSx={classes.cardHeaderRoot}
                title={"Project Details"}
                subheader={location.state.Project}
                action={<MoreMenu>
                    <MenuItemC
                        label={<span style={{ paddingLeft: '5px' }}>Show/Hide from Dashboard</span>}
                        dense={true}
                        disableGutters={true}
                        aria-label="Show/Hide from Dashboard"
                        sx={{
                            fontSize: "0.70rem"
                        }}
                        onClick={x => {
                            const pCode = location.state.ProjectShortCode
                            console.log("🚀 ~ file: DeveloperProjects.jsx:146 ~ FullWidthTabs ~ pCode:", pCode)
                            let i = findIndex(hiddenProjs, x => {
                                return x === pCode
                            })
                            if (i > -1) {
                                //toggle
                                let arr = []
                                hiddenProjs.forEach(element => {
                                    if (element !== pCode) {
                                        arr.push(element)
                                    }
                                });
                                setHiddenProjs(arr)
                                console.log("🚀 ~ file: DeveloperProjects.jsx ~ line 151 ~ FullWidthTabs ~ arr", arr)
                            } else {
                                let a = hiddenProjs.concat(pCode)
                                console.log("🚀 ~ file: DeveloperProjects.jsx ~ line 154 ~ FullWidthTabs ~ a", a)
                                setHiddenProjs(a)
                            }
                        }} />
                </MoreMenu>}
                avatar={
                    <Avatar color="primary" size="small">
                        {isEmpty(projDetails.Filebody) ? <LocationCity fontSize="small" />
                            : <img className='popup'
                                src={`data:image/png;base64,${projDetails.Filebody}`}
                                height='100%'
                                style={{ maxHeight: '32px' }}
                            />}
                    </Avatar>}>
                <EditorCtx.Provider value={{ editorProps, setEditorProps, setShowProjects, setEditMode }}>
                    <ProjectContent projDetails={projDetails} parent={"developProjects"} currTab={currTab} setCurrTab={setCurrTab} />
                </EditorCtx.Provider>
            </JumboCardQuick>
        </Box>)
    }

    // function Footer(props) {
    //     const classes = boxStyles()
    //     return <Box className={classes.root}>
    //         <Button onClick={x => {
    //             setShowProjectDetails(true)
    //         }}>Update Project</Button>
    //         <Button onClick={x => {
    //             setShowProjectTrade(true)
    //         }}>Update Trade Breakdown</Button>
    //         <Button onClick={x => {
    //             setShowProjectTeam(true)
    //         }}>Update Team</Button>
    //         <Button onClick={x => {
    //             setShowAssessClaim(true)
    //         }}>Assess Claim</Button>
    //         <Button onClick={x => {
    //             setShowBankDetails(true)
    //         }}>Update Bank Details</Button>
    //         {/* <Button onClick={x => {
    //             props.onClose(false)
    //             subCons.clear()
    //         }}>Cancel</Button> */}
    //         <ProjectTrade
    //             show={showProjectTrade}
    //             onClose={x => {
    //                 setShowProjectTrade(false)
    //             }} />
    //         <ProjectTeam
    //             show={showProjectTeam}
    //             onClose={x => {
    //                 setShowProjectTeam(false)
    //             }} />
    //         <AssessClaim
    //             show={showAssessClaim}
    //             onClose={x => {
    //                 setShowAssessClaim(false)
    //             }} />
    //         <BankDetails
    //             show={showBankDetails}
    //             onClose={x => {
    //                 setShowBankDetails(false)
    //             }} />
    //         <ProjectDetail
    //             show={showProjectDetails}
    //             onClose={x => {
    //                 setShowProjectDetails(false)
    //                 //refresh grid
    //             }} /></Box>
    // }

    return (
        <PageContainer breadcrumbs={breadcrumbs}>
            <GridContainer>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {/* <ProjectContent></ProjectContent> */}
                    <FullWidthTabs />
                </Grid>
            </GridContainer>
            {/* <Footer /> */}
        </PageContainer>
    )
}
