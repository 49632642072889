import { useJumboTheme } from '@jumbo/hooks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Close } from '@mui/icons-material';
import { Alert, Box, Button, Collapse, IconButton, TextField, Typography, alpha } from '@mui/material';
import { setForgetPassMailSent } from 'app/redux/actions/Auth';
import { CurrentAuthMethod, errorMessage, iv, key1 } from 'app/utils/constants/AppConstants';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import { ContentLoader } from './SignIn';
import axios from 'axios';
import { fetchError, fetchSuccess } from 'app/redux/actions/common';
var CryptoJS = require("crypto-js");

const useStyles = theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),//#7352C7
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: '50px',
    },
  },
  titleRoot: {
    marginBottom: '14px',
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.primary.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: '10px',
  },
});

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const { theme } = useJumboTheme()
  const classes = useStyles(theme);
  const history = useNavigate()
  const [emailErr, setEmailErr] = useState("")
  const search = useLocation().search
  const link = new URLSearchParams(search).get("link")

  useEffect(() => {
    if (send_forget_password_email) {
      setOpen(true);

      setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history('/');
      }, 1500);
    }
  }, [send_forget_password_email]);

  const onSubmit = () => {
    if (email.length > 1 && emailErr.length < 1) {
      var ciphertext1 = CryptoJS.AES.encrypt(email, CryptoJS.enc.Base64.parse(key1), {
        iv: CryptoJS.enc.Base64.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
      }).ciphertext
      const t = CryptoJS.enc.Base64.stringify((ciphertext1))
      if (mode === "Reset") {
        axios.post("/api/Login/ResetPassword", {
          password: t,
          link: link
        }).then(resp => {
          // dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess("Password Reset"));
        }).catch(err => {
          dispatch(fetchError("Failed to reset password"));
        })
      } else {
        axios.post("/api/Login/ForgotEmail", {
          email: email
        }).then(resp => {
          // dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess("Email Sent"));
        }).catch(err => {
          dispatch(fetchError(errorMessage(err)));
        })
      }
    }
  };
  const mode = !isNil(link) && link.length > 1 ? "Reset" : "Password"

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box sx={{ ...classes.authThumb }}>
          <img src={`${ASSET_IMAGES}/auth/forgot-img.png`} />
        </Box>
      ) : null}
      <Box sx={{ ...classes.authContent }} id="box1">
        <Box mb={7}>
          <img src={`${ASSET_IMAGES}/payarklogo.png`} />
        </Box>
        <Typography component="div" variant="h1" sx={{ ...classes.titleRoot }}>
          {mode === "Reset" ? "Reset Password" : "Forgot Password"}
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            sx={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false)
                }}>
                <Close fontSize="inherit" />
              </IconButton>
            }>
            {mode === "Reset" ? "Password Reset" : "A mail has been sent on your email address with reset password link."}
          </Alert>
        </Collapse>
        <form>
          <Box mb={5}>
            <TextField
              type={mode === "Reset" ? "password" : "email"}
              label={mode === "Reset" ? "New Password" : <IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => {
                let v = event.target.value
                if (v.length < 1 || v.length > 100) {
                  setEmailErr("Invalid Input")
                  return
                }
                setEmailErr("")
                setEmail(v)
              }}
              error={emailErr.length > 0 ? true : false}
              helperText={emailErr}
              // defaultValue={email}
              margin="normal"
              variant="outlined"
              required={true}
              sx={classes.textFieldRoot}
            />
          </Box>
          <Box mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              <IntlMessages id="appModule.resetPassword" />
            </Button>
          </Box>
          {/* <Box>
            <Typography>
              Don't remember your email?
              <Box component="span" ml={2}>
                <Link href="#">
                  <IntlMessages id="appModule.contactSupport" />
                </Link>
              </Box>
            </Typography>
          </Box> */}
        </form>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ForgotPassword;
