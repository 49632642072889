import { userOrgDlg } from "@data/localStore";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import Div from "@jumbo/shared/Div";
import { Business, ContentPaste, ListAlt, VideoSettings } from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Box, ListItemIcon, ListItemText, ThemeProvider, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { m5 } from "app/pages/Partials/Mui5";
import { isEmpty, isNil } from "lodash";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const { setAuthToken } = useJumboAuth();
    const setUsrOrgDlg = useSetRecoilState(userOrgDlg)
    const authUser = JSON.parse(sessionStorage.getItem("routes"))
    const classes = m5(theme)
    const allowAddOrg = useMemo(() => {
        if (isNil(authUser)) return false
        const a1 = authUser['allowedRoutes']?.filter(e => e.name === "System Administration")
        if (!isEmpty(a1)) {
            const ret = a1[0]?.children?.filter(e => e.path === "/orgadd")
            if (!isNil(ret) && !isEmpty(ret)) {
                return true
            }
        }
        return false
    }, [authUser])//['allowedRoutes']

    const onLogout = () => {
        setAuthToken(null);
        navigate("/signin");
    };
    return (<>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ ...classes.headerText }} >{`${authUser?.name.split(',')[0]}${authUser?.name.split(',')[1]}`}</div>
            <div style={{ ...classes.headerText, fontWeight: 400, fontSize: '0.8rem', textTransform: 'none' }}>{`${authUser?.email}`}</div>
        </div>
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        src={authUser?.profile_pic}
                        sizes={"small"}
                        sx={{ boxShadow: 25, cursor: 'pointer' }}
                    />
                }>
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar src={authUser?.profile_pic} alt={authUser?.user} sx={{ width: 60, height: 60, mb: 2 }} />
                    <Typography variant={"h5"}>{authUser?.user}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{authUser?.email}</Typography>
                </Div>
                <Divider />
                <nav>
                    <List disablePadding sx={{ pb: 1 }}>
                        <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <PersonOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="Profile" sx={{ my: 0 }} onClick={(x) => {
                                navigate("/profile")
                            }} />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }} onClick={x => {
                                navigate("/developers")
                            }}>
                                <ContentPaste /><Box sx={{ padding: "4px 10px" }}>Projects</Box>
                            </ListItemIcon>
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }} onClick={x => {
                                navigate("/orgdetails")
                                setUsrOrgDlg(true)
                            }}>
                                <Business /><Box sx={{ padding: "4px 10px" }}>Organisation</Box>
                            </ListItemIcon>
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }} onClick={x => {
                                navigate("/jobs")
                            }}>
                                <VideoSettings /><Box sx={{ padding: "4px 10px" }}>Jobs</Box>
                            </ListItemIcon>
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }} onClick={x => {
                                navigate("/jobs")
                            }}>
                                <ListAlt /><Box sx={{ padding: "4px 10px" }}>Audit</Box>
                            </ListItemIcon>
                        </ListItemButton>
                        {allowAddOrg ? <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }} onClick={x => {
                                navigate("/orgadd")
                                setUsrOrgDlg(true)
                            }}>
                                <ListAlt /><Box sx={{ padding: "4px 10px" }}>Add Organisation</Box>
                            </ListItemIcon>
                        </ListItemButton>
                            : null}
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{ my: 0 }} />
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    </>
    );
};

export default AuthUserDropdown;
