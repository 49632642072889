import DataLoader from "@data";
import { useJumboTheme } from "@jumbo/hooks";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { isUndefined, keys, startCase } from "lodash";
import { gridStyles } from "theme/cashflowsTheme";
import { printCurrency2, printDatePeriod, printDateTime2, printDateTime3, printPercentage2 } from "./MaterialGrid";
import { CustomPagination } from '../Partials/MaterialGrid';

export default function MuiGrid({ rows, columns, sx, getRowId, ...rest }) {
    const { theme } = useJumboTheme()

    return <div style={{
        width: '100%',
        height: "100%",
        padding: "0",
        margin: 0
    }}>
        <DataGrid
            rows={rows}
            columns={columns}
            density={'standard'}
            getRowId={getRowId}
            sx={{ ...sx, ...gridStyles(theme) }}
            // pagination
            initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
            }}
            slots={{ Toolbar: GridToolbar, Pagination: CustomPagination, }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    sx: {
                        '& .MuiButtonBase-root': {
                            color: '#ff5722'
                        }
                    }
                },
            }}
            // autoPageSize   //it was causing rows not to show
            disableMultipleRowSelection
            autoHeight={false}
            // experimentalFeatures={{ newEditingApi: true }}
            pageSizeOptions={[10, 25, 50]}
            {...rest}></DataGrid>
    </div>
}

export function TableColumns(table) {
    const columns = DataLoader("tables", table);
    const cols = keys(columns);
    function renderType(d) {
        if (d === "percentage") return printPercentage2
        if (d === "currency") return printCurrency2
        if (d === "date") return printDatePeriod
        if (d === "datetime") return printDateTime2
        if (d === "dateOnly") return printDateTime3
        return null
    }

    return cols.map((element, i) => {
        let d = "string";
        let r = false;
        if (!isUndefined(columns[element].f)) {
            d = columns[element].f;
        }
        if (!isUndefined(columns[element].r)) {
            r = columns[element].r;
        }
        const addOn = x => {
            return x === "percentage" ? " %" : ''
        }
        return ({
            field: element,
            headerName: startCase(element) + addOn(d),
            renderCell: renderType(d),
            flex: 1,
            coLSpan: 1,
            hideable: false,
            editable: r
        })
    });
}
