import { useJumboTheme } from '@jumbo/hooks';
import { IntlMessage } from '@jumbo/utils/IntlMessages';
import { ArrowDropDownCircleOutlined, ArrowRight, Label } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import { Checkbox, Typography } from '@mui/material';
import { isNotNullUndef, isUndefOrNull } from "app/utils/constants/AppConstants";
import axios from "axios";
import _ from "lodash";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { showErrorToast } from './Notify';

const useStyles = (t) => ({
    root: {
        flexGrow: 1,
        padding: "5px 15px",
        maxWidth: "100%",
        maxHeight: "250px",
        marginBottom: "15px",
        overflowY: "scroll",
        // borderColor: t.palette.borderColor.dark,
        borderWidth: "0.5px",
        borderStyle: "solid"
    }
});

const useTreeItemStyles = (theme) => ({
    root: {
        // color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        // '&:focus > $content, &$selected > $content': {
        //     backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        //     color: 'var(--tree-view-color)',
        // },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        // fontWeight: theme.typography.fontWeightMedium,
        // '$expanded > &': {
        //     fontWeight: theme.typography.fontWeightRegular,
        // },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        fontFamily: theme.typography.fontFamily,
        fontSize: 12
    },
});

function StyledTreeItem(props) {
    const { theme } = useJumboTheme()
    const classes = useTreeItemStyles(theme);
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, data, ...other } = props;
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (isUndefOrNull(props.FunctionCode) == false && isUndefOrNull(props.selected) == false) {
            if (isUndefOrNull(props.selected[props.FunctionCode]) == false) {
                setChecked(props.selected[props.FunctionCode].Selected)
            }
        }
    }, [])

    return (
        <TreeItem
            label={
                <div style={{ ...classes.labelRoot }}>
                    <Checkbox
                        checked={checked}
                        name={props.FunctionCode}
                        onChange={x => {
                            let x1 = {
                                ...props.selected,
                            }
                            x1[props.FunctionCode].Selected = !checked
                            console.log("🚀 ~ file: AssociatedFunc.jsx:106 ~ StyledTreeItem ~ x:", x1)
                            props.setSelected(x1)
                            setChecked(!checked)
                        }}
                    />
                    <Typography variant="body2" style={{ ...classes.labelText }}>
                        {labelText}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

export default function AssociatedFuncs({ roleId, selected, setSelected }) {
    const { theme } = useJumboTheme()
    const classes = useStyles(theme);
    const [data, setData] = useState([])

    useEffect(() => {
        axios.get(`/api/Functions/FuncsForRole?roleId=${roleId}`).then(x => {
            setData(x.data)
        }).catch(e => {
            showErrorToast(IntlMessage("E0010"))
        })
    }, [roleId])


    function render(data) {
        function desc(x) {
            if (x.FunctionDescription != null) return x.FunctionCode + " " + x.FunctionDescription
            return x.FunctionCode
        }
        function subs(y, parent, j) {
            let res = y.map((elem, i) => {
                return <StyledTreeItem
                    nodeId={elem.FunctionCode + ',' + parent + '-' + j}
                    labelText={desc(elem)}
                    labelIcon={Label}
                    FunctionCode={elem.FunctionCode}
                    data={elem}
                    selected={selected}
                    setSelected={setSelected} />
            })
            return res
        }
        return data.map((elem, i) => {
            if (elem.items == null) return <StyledTreeItem
                nodeId={elem.FunctionCode}
                labelText={desc(elem)}
                labelIcon={Label}
                data={elem}
                selected={selected}
                setSelected={setSelected}
                FunctionCode={elem.FunctionCode} />
            else {
                return <StyledTreeItem
                    nodeId={elem.FunctionCode}
                    labelText={desc(elem)}
                    labelIcon={Label} data={elem}
                    selected={selected}
                    setSelected={setSelected}
                    FunctionCode={elem.FunctionCode}>
                    {subs(elem.items, elem.FunctionCode, i)}
                </StyledTreeItem>
            }
        })
    }

    function nodeSelect(x, y) {
        let n = {}
        if (_.indexOf(y, ',') == -1) {
            x.preventDefault()
            let i = data.findIndex(elem => {
                return elem.FunctionCode === y
            })
            if (isNotNullUndef(data[i].items)) {
                n = {
                    ...selected,
                    [y]: { "FunctionCode": y, Selected: x.target.checked }
                }
                _.forEach(data[i].items, elem => {
                    n = {
                        ...n,
                        [elem.FunctionCode]: { "FunctionCode": elem.FunctionCode, Selected: x.target.checked }
                    }
                })
            } else {
                n = {
                    ...selected,
                    [y]: { "FunctionCode": y, Selected: x.target.checked }
                }
            }
            setSelected(n)
        } else {
            try {
                // let parentIndex = y.match(/-.+/)[0].replace('-', '')
                let child = y.match(/^.+,/)[0].replace(',', '')
                let parent = y.match(/,.+-/)[0].replace(',', '').replace('-', '')
                let n = {
                    ...selected,
                    [parent]: { "FunctionCode": parent, Selected: true },//x.target.checked
                    [child]: { "FunctionCode": child, Selected: x.target.checked }
                }
                setSelected(n)
            } catch (e) {
            }
        }
    }

    return (
        <TreeView
            style={{ ...classes.root }}
            defaultCollapseIcon={<ArrowDropDownCircleOutlined />}
            defaultExpandIcon={<ArrowRight />}
            defaultEndIcon={<div style={{ width: 24 }} />}
        // onNodeSelect={nodeSelect}
        >
            {render(data)}
        </TreeView>
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};
